import React, { useEffect, useRef, useState } from "react";
import DatePicker from "../../../../component/library/datePicker";
import moment from "moment";
import { orderApiService } from "../../../../service/seller/orderApiService";
import { useLoader } from "../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";
import Pagination from "../../../../component/library/pagination";
import { MdModeEdit } from "react-icons/md";
import WebDropdown from "../../../../component/library/webDropdown";
import WebButton from "../../../../component/library/webButton";
import WebInput from "../../../../component/library/webInput";
import { useLocation, useNavigate } from "react-router-dom";
import EditOrderPopup from "../../../../component/views/seller/orders/list/editOrderPopup";
import "./style.css";

const Orders = () => {
  const [data, setData] = useState([]);
  const [orderType, setOrderType] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [uploadFile, setUploadFile] = useState("");
  const [open, setOpen] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [uploadFileErr, setUploadFileErr] = useState("");

  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();
  const location=useLocation()
  const orderFileRef = useRef(null);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [pages, setPages] = useState({
    currentPage: "",
    totalPages: "",
    totalItems: "",
  });

  const options = [
    {
      name: "All Types",
      value: "",
      count: orderType.totalOrders,
    },
    {
      name: "Abnormal",
      value: "abnormal",
      count: orderType.abnormalOrder,
    },
    {
      name: "Payment Pending",
      value: "payment pending",
      count: orderType.paymentPending,
    },
    {
      name: "Processing",
      value: "processing",
      count: orderType.processingOrder,
    },
    {
      name: "Shipped",
      value: "shipped",
      count: orderType.shippedOrder,
    },
    {
      name: "Delivered",
      value: "delivered",
      count: orderType.deliveredOrder,
    },
    {
      name: "Cancelled",
      value: "cancelled",
      count: orderType.cancelOrder,
    },
    {
      name: "Ticket",
      value: "ticket",
      count: orderType.ticketOrder,
    },
  ];

  const queryParams = `?status=${status}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${page}`;

  useEffect(()=>{
    const search=new URLSearchParams(location.search)

    if(search.get('status')){
      setStatus(search.get('status'))
    }
    
    const date= new Date()
    let nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);

    if(search.get('startDate')){
      setStartDate(date)
      setEndDate(nextDay)
    }
  },[])

  useEffect(() =>  {
    if (startDate && endDate) {
       fetchOrders();
    } else if (!startDate && !endDate) {
       fetchOrders();
    }


    const search=new URLSearchParams(location.search);
    if(search.size){
      navigate('/seller/orders')
    }

  }, [status, startDate, endDate, page]);



  const fetchOrders =  async() => {
    await loader.showLoading(true);
      orderApiService
      .getAllOrderList(queryParams)
      .then((res) => {
        loader.showLoading(false);
         if (res.status.code === 200) {
          setData(res?.data?.orders);
          setOrderType(res?.data);
          setPages({
            ...pages,
            currentPage: res.data.currentPage,
            totalPages: res.data.totalPages,
            totalItems: res.data.totalOrders,
          });
          setPage(res.data.currentPage);
        } else {
          setData([]);
          setOrderType({});
          setPages({
            currentPage: "",
            totalPages: "",
            totalItems: "",
          });
          setPage(1);
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        setData([]);
        setOrderType({});
        setPages({
          currentPage: "",
          totalPages: "",
          totalItems: "",
        });
        setPage(1);
      });
  };

  const handleOrderType = (value) => {
    setStatus(value);
    setStartDate('')
    setEndDate('')
    setPage(1)
  };

  const handleOrdersFile = (e) => {
    setUploadFile(e.target.files[0]);
    setUploadFileErr("");
  };

  const validateUploadFile = () => {
    let result;

    if (!uploadFile) {
      setUploadFileErr("File is required");
      result = false;
    } else {
      result = true;
    }
    return result;
  };

  const handleUploadOrders = () => {
    if (validateUploadFile()) {
      loader.showLoading(true);

      let filePayload = new FormData();
      filePayload.append("file", uploadFile);

      orderApiService
        .addBulkOrders(filePayload)
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            message.showToast(res.status.message, ToastTypes.SUCCESS);
            setUploadFile();
            fetchOrders();
            orderFileRef.current.value = "";
          } else if (res.status.code === 400) {
            res.data.map((item) => {
              message.showToast(item, ToastTypes.ERROR);
            });
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    }
  };

  const handleOrderById = (id) => {
    navigate(`/seller/orders/orderDetails/${id}`);
  };

  const handleEdit = (id) => {
    setOpen(true);
    setActiveId(id);
  };

  const jsonToCsv = () => {
    // Writing CSV headers
    let csvContent =
      "order-id,order-item-id,sku,quantity-purchased,consumer-price,recipient-name,buyer-phone-number,ship-address-1,ship-address-2,ship-address-3,ship-city,ship-state,ship-postal-code" +
      "\n";

    // Writing CSV data
    return csvContent;
  };

  function downloadCsv(csvContent, fileName) {
    // Create a Blob object
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a download URL
    const url = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Append the link to the body
    document.body.appendChild(link);

    // Click the link to trigger download
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  const handleDownloadCsv = () => {
    downloadCsv(jsonToCsv(), "data.csv");
  };

  const handleUploadOneOrder = () => {
    navigate("/seller/orders/addOrder");
  };

  const downloadAbnormalOrders = () => {
    orderApiService
      .getAbnormalOrders()
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          const url = res.data.url;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "products.csv");
          link.click();
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };



  return (
    <div className="order_list_bg">
      <div className="order_type_row">
        {options.map((item, index) => {
          return (
            <div
              key={index}
              className={`order_type_col ${
                status !== item.value
                  ? "order_type_col_active"
                  : "order_type_col_inactive"
              }`}
              onClick={() => handleOrderType(item.value)}
            >
              <p className="order_type_head">{item.name}</p>
              <p className="order_type_count">{item?.count}</p>
            </div>
          );
        })}
      </div>

      <div className="order_list_filteration">
        <WebDropdown
          className="filter_dropdown"
          options={options}
          selected={status}
          onChange={(e) => handleOrderType(e.target.value)}
        />

        <DatePicker setStartDate={setStartDate} setEndDate={setEndDate}/>

        <div className="upload_order_box_container">
          <div className="upload_order_box">
            <WebInput
              ref={orderFileRef}
              type="file"
              onChange={handleOrdersFile}
              error={uploadFileErr}
              className="order_file_input"
            />

            <WebButton
              text="Upload"
              className="active_btn_with_shadow filter_file_btn"
              onClick={handleUploadOrders}
            />
          </div>

          <div className="order_format_link_row">
            <p
              className="order_format_link"
              onClick={() => handleDownloadCsv()}
            >
              Check the order file format
            </p>
            <p
              className="order_format_link"
              onClick={() => downloadAbnormalOrders()}
            >
              Download abnormal orders
            </p>
          </div>
        </div>

        <WebButton
          text="Add Order"
          className="active_btn_with_shadow"
          onClick={handleUploadOneOrder}
        />
      </div>

      <div className="seller_table_wrapper">
        <table className="seller_table">
          <thead>
            <tr>
              <td>Upsaleo Order ID</td>
              <td>Order ID</td>
              <td>Quantity</td>
              <td>Order Value</td>
              <td>Consumer Price</td>
              <td>Order Date</td>
              <td>Marketplace</td>
              <td>Status</td>
            </tr>
          </thead>

          <tbody>
            {data && data?.length > 0 ? (
              data?.map((item, index) => {
                return (
                  <tr key={index} className="cursor-pointer">
                    <td onClick={() => handleOrderById(item?._id)}>
                      {item.upsaleoOrderId}
                    </td>

                    <td onClick={() => handleOrderById(item?._id)}>
                      {item.orderId}
                    </td>

                    <td onClick={() => handleOrderById(item?._id)}>
                      {item.totalQuantity} unit
                    </td>

                    <td onClick={() => handleOrderById(item?._id)}>
                      ₹{item.totalPrice}
                    </td>

                    <td
                      onClick={() => handleOrderById(item?._id)}
                      className={`${
                        item.totalPrice > item.totalPrice
                          ? "loss_consumer_price"
                          : ""
                      }`}
                    >
                      {item?.totalConsumerPrice
                        ? "₹" + item.totalConsumerPrice
                        : 0}
                    </td>

                    <td onClick={() => handleOrderById(item?._id)}>
                      {moment(item.orderDate).format("Do MMM YYYY,  LT")}
                    </td>

                    <td>{item.markeplace}</td>

                    <td>
                      <div className="flex items-center gap-3 table_overflow_text">
                        {item.status}
                        {item.status === "abnormal" ? (
                          <MdModeEdit
                            className="cursor-pointer"
                            onClick={() => handleEdit(item._id)}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <p> No Data Found</p>
            )}
          </tbody>
        </table>
      </div>

      {data.length ? (
        <Pagination pages={pages} page={page} setPage={setPage} />
      ) : null}

      {open ? (
        <EditOrderPopup
          modalIsOpen={open}
          setIsOpen={setOpen}
          id={activeId}
          refresh={fetchOrders}
        />
      ) : null}
    </div>
  );
};

export default Orders;
