import React, { useState } from "react";
import WebInput from "../../../component/library/webInput";
import WebButton from "../../../component/library/webButton";
import { FcGoogle } from "react-icons/fc";
import content from "../../../helper/content/login.json";
import { useDispatch } from "react-redux";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { useNavigate } from "react-router-dom";
import { AuthApiService } from "../../../service/authApiService";
import { emailRegex } from "../../../helper/regex";
import { addItems } from "../../../redux/slices/sidebarSlice";
import { adminSidebarArray, sellerSidebarArray } from "../../../helper/utils";
import { setAuthLocalStorage } from "../../../storage/localStorage";
import { useGoogleLogin } from "@react-oauth/google";
import LoginImage from "../../../assets/images/login/login.png";
import Navbar from "../../../component/common/authNavbar";
import "./style.css";

export default function Login() {
  const navigate = useNavigate();
  const message = useMessage();
  const loader = useLoader();
  const dispatch = useDispatch();

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const handleOnchange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const validate = () => {
    let result = true;
    if (!data.email.length) {
      setError({ ...error, email: "Email is required" });
      result = false;
    } else if (!data.email.match(emailRegex)) {
      setError({ ...error, email: "Not a valid email address" });
      result = false;
    } else if (!data.password.length) {
      setError({ ...error, password: "Password is required" });
      result = false;
    }
    return result;
  };

  const handleUpdateSidebar = (type) => {
    if (type === "seller") {
      dispatch(addItems(sellerSidebarArray));
    } else if (type === "admin") {
      dispatch(addItems(adminSidebarArray));
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (validate()) {
      loader.showLoading(true);
      AuthApiService.login(JSON.stringify(data))
        .then(async (res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            await handleUpdateSidebar(res.data.role);
            const data = {
              loginHeader: res.data.token,
              userType: res.data.role,
            };

            setAuthLocalStorage(data);
            if (res.data.role === "seller") {
              navigate("/seller/dashboard");
            } else if (res.data.role === "admin") {
              navigate("/admin/dashboard");
            }
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    }
  };

  const handleCreateAccount = async () => {
    navigate("/signup");
  };

  const handleForgetPassword = () => {
    navigate("/login/forgetPassword/verifyEmail");
  };

  const login = (e) => {
    e.stopPropagation();
    loginWithGoogle();
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const authPayload = {
        accessToken: tokenResponse.access_token,
      };

      loader.showLoading(true);
      AuthApiService.googleLogin(JSON.stringify(authPayload))
        .then(async (res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            await handleUpdateSidebar(res.data.role);
            const data = {
              loginHeader: res.data.token,
              userType: res.data.role,
            };
            setAuthLocalStorage(data);
            if (res.data.role === "seller") {
              navigate("/seller/profile");
            }
            message.showToast("Login Successfully", ToastTypes.SUCCESS);
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    },
    onError: (err) => {
      console.log("Login Failed", err);
    },
  });

  return (
    <div>
      <Navbar />
      <div className="auth_container">
        <div className="auth_row">
          <div className="auth_col_one">
            <h1 className="auth_heading">
              {content.upsaleoHeading}
              <br />
              {content.upsaleoSubHeading}
            </h1>
            <img src={LoginImage} className="auth_image" />
          </div>

          <div className="auth_col_two login_auth_col_two">
            <form className="auth_form" onSubmit={(e) => handleLogin(e)}>
              <h1>{content.mainHeading}</h1>
              <h3>{content.subHeading}</h3>
              <WebInput
                parentClassName="input_parent"
                className="input_auth"
                id="emailInput"
                type="email"
                placeholder="Enter your email"
                name="email"
                value={data.email}
                error={error.email}
                onChange={handleOnchange}
              />
              <WebInput
                parentClassName="input_parent"
                className="input_auth"
                id="passInput"
                type="password"
                placeholder="Password"
                name="password"
                value={data.password}
                error={error.password}
                onChange={handleOnchange}
              />
              <h2 onClick={() => handleForgetPassword()}>
                {content.forgetPassword}
              </h2>
              <WebButton className="auth_btn" text="Log in" type="submit" />

              <div className="continue_msg">
                <span></span>or continue with<span></span>
              </div>

              <button
                className="auth_google_btn"
                onClick={(e) => login(e)}
                type="button"
              >
                <FcGoogle size={20} />
                {content.googleLogin}
              </button>

              <div className="auth_message">
                <p>{content.signUpMessage}</p>
                <p onClick={() => handleCreateAccount()}>
                  {content.signUpText}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
