import { createContext, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const MessageContext = createContext();

export const ToastTypes = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING",
  INFO: "INFO",
};

const MessageProvider = ({ children }) => {
  const showToast = (toastMsg, type) => {
    switch (type) {
      case ToastTypes.SUCCESS: {
        toast.success(toastMsg);
        break;
      }
      case ToastTypes.ERROR: {
        toast.error(toastMsg);
        break;
      }
      case ToastTypes.WARNING: {
        toast.warning(toastMsg);
        break;
      }
      case ToastTypes.INFO: {
        toast.info(toastMsg);
        break;
      }
      default: {
        toast(toastMsg);
      }
    }
  };

  return (
    <MessageContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer />
    </MessageContext.Provider>
  );
};

export const useMessage = () => useContext(MessageContext);

export default MessageProvider;
