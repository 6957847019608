// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step_bg {
    width: 100%;
}

.steps_row{
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.step_box{
    width: 186px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.step_img{
    border-radius: 100%;
    width: 100px;
    height: 100px;
}

.step_head{
    color: #B82F04;
    font-size: 1rem;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    text-align: center;
}

.step_para{
    color: rgba(0,0,0, 0.4);
    font-size: 0.9rem;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    text-align:center;
}




@media (max-width:768px) {
    
.steps_row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.step_box{
    width: 100%;
}
}`, "",{"version":3,"sources":["webpack://./src/website/component/views/home/dropshipWorkSteps/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gCAAgC;IAChC,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,iBAAiB;IACjB,gCAAgC;IAChC,gBAAgB;IAChB,iBAAiB;AACrB;;;;;AAKA;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,WAAW;AACf;AACA","sourcesContent":[".step_bg {\n    width: 100%;\n}\n\n.steps_row{\n    display: flex;\n    justify-content: center;\n    gap: 2rem;\n}\n\n.step_box{\n    width: 186px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 0.5rem;\n}\n\n.step_img{\n    border-radius: 100%;\n    width: 100px;\n    height: 100px;\n}\n\n.step_head{\n    color: #B82F04;\n    font-size: 1rem;\n    font-family: \"Inter\", sans-serif;\n    font-weight: 500;\n    text-align: center;\n}\n\n.step_para{\n    color: rgba(0,0,0, 0.4);\n    font-size: 0.9rem;\n    font-family: \"Inter\", sans-serif;\n    font-weight: 500;\n    text-align:center;\n}\n\n\n\n\n@media (max-width:768px) {\n    \n.steps_row{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 2rem;\n}\n\n.step_box{\n    width: 100%;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
