import { NetworkConfiguration } from "../../network/networkConfriguation"
import { callAPI } from "../../network/networkConnection"


const getTicket = (id) => {
    return new Promise((resolve, reject) => {
        callAPI(process.env.REACT_APP_APIUrl + NetworkConfiguration.GET_CHAT + `/${id}/chat`, "GET", {
            "Content-Type" :"application/json",
        }).then((res) => {
            resolve(res);
        }).catch((err) => {
            reject(err);
        })
    })
}

const updateTicket = (id , formData) => {
    return new Promise((resolve, reject) => {
        callAPI(process.env.REACT_APP_APIUrl + NetworkConfiguration.UPDATE_CHAT + `/${id}/chat`, "PUT", {} , formData).then((res) => {
            resolve(res);
        }).catch((err) => {
            reject(err);
        })
    })
}


export const ticketApiService = {
    getTicket,
    updateTicket,
}