import React, { useEffect, useState } from "react";
import content from "../../../../../helper/content/forgot.json";
import { AuthApiService } from "../../../../../service/authApiService";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import WebButton from "../../../../../component/library/webButton";
import WebInput from "../../../../../component/library/webInput";
import ForgotImage from "../../../../../assets/images/forgot/forgot.png";
import Navbar from "../../../../../component/common/authNavbar";
import "../../style.css";

export default function ResetPassword() {
  const message = useMessage();
  const loader = useLoader();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
    token: "",
  });

  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleOnchange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const validate = () => {
    let result = true;
    if (!data.password) {
      setError({ ...error, password: "Password is required" });
      result = false;
    } else if (!data.confirmPassword) {
      setError({ ...error, confirmPassword: "Password is required" });
      result = false;
    } else if (data.password !== data.confirmPassword) {
      setError({ ...error, confirmPassword: "Password not matched" });
      result = false;
    }
    return result;
  };

  useEffect(() => {
    setData({ ...data, token: searchParams.get("token") });
  }, [searchParams]);

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (validate()) {
      loader.showLoading(true);
      AuthApiService.resetPassword(JSON.stringify(data))
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            message.showToast(res.status.message, ToastTypes.SUCCESS);
            navigate("/login");
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    }
  };

  return (
    <div>
      <Navbar />
      <div className="auth_container">
        <div className="auth_row">
          <div className="auth_col_one">
            <h1 className="auth_heading">{content.upsaleoMainHeading}</h1>
            <img src={ForgotImage} className="auth_image" />
          </div>

          <div className="auth_col_two">
            <form className="auth_form" onSubmit={handleResetPassword}>
              <h1>{content.mainHeading}</h1>
              <h3>{content.resetPassword}</h3>
              <WebInput
                parentClassName="input_parent"
                className="input_auth"
                id="passInput"
                type="password"
                placeholder="new password"
                name="password"
                value={data.password}
                error={error.password}
                onChange={handleOnchange}
              />

              <WebInput
                parentClassName="input_parent"
                className="input_auth"
                id="confirmpassInput"
                type="password"
                placeholder="confirm password"
                name="confirmPassword"
                value={data.confirmPassword}
                error={error.confirmPassword}
                onChange={handleOnchange}
              />

              <WebButton
                className="auth_btn"
                text={content.continueBtn}
                onClick={(e) => handleResetPassword(e)}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
