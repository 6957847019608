import { StorageConstant } from "../helper/constants";

export const setAuthLocalStorage = (res) => {
  localStorage.setItem(StorageConstant.IS_LOGGED_IN, true);
  localStorage.setItem(StorageConstant.LOGIN_HEADER, res.loginHeader);
  localStorage.setItem(StorageConstant.USER_TYPE, res.userType);
};

export const getAuthLocalStorage = () => {
  const response = {};
  response.isLoggedIn = localStorage.getItem(StorageConstant.IS_LOGGED_IN);
  response.loginHeader = localStorage.getItem(StorageConstant.LOGIN_HEADER);
  response.userType = localStorage.getItem(StorageConstant.USER_TYPE);
  return response;
};

export const clearAuthLocalStorage = () => {
  localStorage.removeItem(StorageConstant.IS_LOGGED_IN);
  localStorage.removeItem(StorageConstant.LOGIN_HEADER);
  localStorage.removeItem(StorageConstant.USER_TYPE);
};

export const setAuthVerifiedData = (res) => {
  localStorage.setItem(StorageConstant.VERIFIED_PHONE, res.phone);
  localStorage.setItem(StorageConstant.VERIFIED_EMAIL, res.email);
};

export const getAuthVerifiedData = () => {
  let response = {};
  response.verifiedPhone = localStorage.getItem(StorageConstant.VERIFIED_PHONE);
  response.verifiedEmail = localStorage.getItem(StorageConstant.VERIFIED_EMAIL);
  return response;
};

export const clearAuthVerifiedData = () => {
  localStorage.clear(StorageConstant.VERIFIED_PHONE);
  localStorage.clear(StorageConstant.VERIFIED_EMAIL);
};
