import React, { useEffect, useState } from "react";
import "./style.css";

export default function WebOtpInput({ setOtpVal }) {
  const [otp, setOtp] = useState({
    otpOne: "",
    otpTwo: "",
    otpThree: "",
    otpFour: "",
    otpFive: "",
    otpSix: "",
  });

  const handleOtp = (e) => {
    setOtp({
      ...otp,
      [e.target.name]: e.target.value.replace(/[^0-9]+/g, ""),
    });
  };

  const handleOnKeyUp = (e) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      const next = e.target.tabIndex - 2;

      if (next > -1) {
        e.target.form.elements[next].focus();
      }
    } else {
      const next = e.target.tabIndex;
      if (next < 6 && e.target.value) {
        e.target.form.elements[next].focus();
      }
    }
  };

  useEffect(() => {
    setOtpVal(
      otp.otpOne +
        otp.otpTwo +
        otp.otpThree +
        otp.otpFour +
        otp.otpFive +
        otp.otpSix
    );
  }, [otp]);

  return (
    <form className="otp_form">
      <input
        type="text"
        maxLength="1"
        value={otp.otpOne}
        tabIndex="1"
        onKeyUp={handleOnKeyUp}
        name="otpOne"
        onChange={handleOtp}
      />
      <input
        type="text"
        maxLength="1"
        value={otp.otpTwo}
        tabIndex="2"
        onKeyUp={handleOnKeyUp}
        name="otpTwo"
        onChange={handleOtp}
      />
      <input
        type="text"
        maxLength="1"
        value={otp.otpThree}
        tabIndex="3"
        onKeyUp={handleOnKeyUp}
        name="otpThree"
        onChange={handleOtp}
      />
      <input
        type="text"
        maxLength="1"
        value={otp.otpFour}
        tabIndex="4"
        onKeyUp={handleOnKeyUp}
        name="otpFour"
        onChange={handleOtp}
      />
      <input
        type="text"
        maxLength="1"
        value={otp.otpFive}
        tabIndex="5"
        onKeyUp={handleOnKeyUp}
        name="otpFive"
        onChange={handleOtp}
      />
      <input
        type="text"
        maxLength="1"
        value={otp.otpSix}
        tabIndex="6"
        onKeyUp={handleOnKeyUp}
        name="otpSix"
        onChange={handleOtp}
      />
    </form>
  );
}
