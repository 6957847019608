// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inventory_sync_bg{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
    padding: 0 1rem;
}

.inventory_sync_head{
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    font-weight: 500;
}

.inventory_sync_option_row{
    display: flex;
    gap: 2rem;
    padding: 1rem 0;
}

.inventory_sync_option_row div{
    display: flex;
    align-items: center;
    gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/component/views/seller/settings/inventorySync/style.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;IAC3C,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,gCAAgC;IAChC,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".inventory_sync_bg{\n    border-bottom: 1px solid rgba(0, 0, 0, 0.2);\n    margin-bottom: 1rem;\n    padding: 0 1rem;\n}\n\n.inventory_sync_head{\n    font-family: \"Inter\", sans-serif;\n    font-size: 1rem;\n    font-weight: 500;\n}\n\n.inventory_sync_option_row{\n    display: flex;\n    gap: 2rem;\n    padding: 1rem 0;\n}\n\n.inventory_sync_option_row div{\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
