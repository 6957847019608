import React from "react";
import { IoMdPricetag } from "react-icons/io";
import { IoMdSettings } from "react-icons/io";
import { IoMdSearch } from "react-icons/io";
import { SiGooglesheets } from "react-icons/si";

import "./style.css";

export default function HelpCenter() {
  const userGuide = [
    {
      icon: <IoMdPricetag />,
      head: "Register, Log in and Pricing Plan",
      para: "Register, Log in and Introduction of Pricing Plan",
      article: "4 Articles ",
    },
    {
      icon: <IoMdSettings />,
      head: "Store Connection and Store Setting",
      para: "How to Connect Your Store to Upsaleo",
      article: "9 Articles ",
    },
    {
      icon: <IoMdSearch />,
      head: "Search Products and Suppliers",
      para: "How to Search Products and Suppliers",
      article: "8 Articles ",
    },
    {
      icon: <IoMdSearch />,
      head: "Search Products and Suppliers",
      para: "How to Search Products and Suppliers",
      article: "8 Articles ",
    },

    {
      icon: <SiGooglesheets />,
      head: "Order and Refund",
      para: "Register, Log in and Introduction of Pricing Plan",
      article: "4 Articles ",
    },
    {
      icon: <SiGooglesheets />,
      head: "Order and Refund",
      para: "Register, Log in and Introduction of Pricing Plan",
      article: "4 Articles ",
    },
  ];

  return (
    <div className="help_center_bg">
      <div className="banner">
        <h1>Help Center</h1>
        <p>Let us know how we may help you?</p>
      </div>

      <div className="content">
        <div className="heading">Latest Tutorials</div>

        <div className="ques">
          1. BRANDING IS SIMPLY A MORE EFFICIENT WAY TO SELL THINGS?
        </div>
        <div className="ans">
          Welcome to our Help Centre's latest tutorials! Dive into how effective
          branding can streamline your sales and elevate your business with
          Upsaleo. Learn to create a compelling brand identity that deeply
          resonates with your audience. Discover how to use logos, colors, and
          design elements to craft a cohesive and appealing brand presence.
        </div>
        <div className="ans">
          Explore creating engaging brand stories that foster customer loyalty
          and trust. Implement strategic marketing techniques on Upsaleo to
          boost product visibility and appeal. Effectively leverage social media
          platforms to amplify your brand message and engage your audience.
          Ensure consistent branding across all channels, from your website to
          customer interactions.
        </div>
        <div className="ans">
          With these tutorials, Upsaleo empowers you to harness branding as a
          powerful tool for growth and success. Start refining your brand
          strategy today and experience its transformative impact!
        </div>

        <div className="ques">
          2. IT’S BETTER TO BE FIRST IN THE MIND THAN TO BE FIRST IN THE
          MARKETPLACE?
        </div>
        <div className="ans">
          At Upsaleo, we believe it's better to be first in the mind than to be
          first in the marketplace. Explore our latest tutorials and guides to
          stay ahead of the curve. Whether you're new to Dropshipping or looking
          to refine your strategy, our resources are here to help you succeed.
          Dive into expert tips, step-by-step tutorials, and industry insights
          to elevate your business today.
        </div>

        <div className="ques">
          3. MARKETING IS A COMPANY’S ULTIMATE OBJECTIVE?
        </div>
        <div className="ans">
          Marketing is crucial for driving a company's success. Our latest
          tutorials cover essential strategies to help you excel in this vital
          area. Learn to create compelling content, leverage social media, and
          use data analytics to optimize your campaigns.
        </div>
        <div className="ans">
          With comprehensive guides and expert insights, you'll gain the tools
          and knowledge to implement effective marketing strategies that align
          with your business goals. Whether you're a beginner or looking to
          improve your skills, our tutorials are designed to help you master
          marketing and achieve success. Explore our latest tutorials and
          elevate your marketing game with Upsaleo.
        </div>
      </div>

      <div className="user_guide">
        <h1 className="heading">User Guide</h1>

        <div className="container">
          <div className="row">
            {userGuide.map((item, index) => {
              return (
                <div className="column" key={index}>
                  <div className="icon">{item.icon}</div>
                  <div className="column_content">
                    <p>{item.head}</p>
                    <p>{item.para}</p>
                    <p>{item.article}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
