import React, { useEffect, useRef, useState } from "react";
import { ProductSubscriptionApiService } from "../../../../../service/seller/productSubscriptionApiService";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";
import { useOutsideClick } from "../../../../../base/context/OutsideClick";
import { RiArrowDropDownLine } from "react-icons/ri";
import './style.css'

export default function SyncProducts({
  selectedSKUs,
  setSelectedSKUs,
  setSelectAll,
}) {
  const [showOptions, setShowOptions] = useState(false);

  const loader = useLoader();
  const message = useMessage();
  const outsideClick = useOutsideClick();
  const ref = useRef(null);

  const options = [
    { name: "All Products ", value: "all" },
    { name: "Selected Products ", value: "selected" },
  ];

  const handleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleSync = (type) => {
    if (type === "all") {
      loader.showLoading(true);
      ProductSubscriptionApiService.syncAllProducts()
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            message.showToast(res.status.message, ToastTypes.SUCCESS);
            setSelectedSKUs([]);
            setSelectAll(false);
            setShowOptions(false);
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          setShowOptions(false);
          loader.showLoading(false);
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
        });
    } else if (type === "selected") {
      if (selectedSKUs.length) {
        loader.showLoading(true);

        const productPayload = {
          skus: selectedSKUs,
        };

        ProductSubscriptionApiService.syncSelectedProducts(
          JSON.stringify(productPayload)
        )
          .then((res) => {
            loader.showLoading(false);
            if (res.status.code === 200) {
              message.showToast(res.status.message, ToastTypes.SUCCESS);
              setSelectedSKUs([]);
              setSelectAll(false);
              setShowOptions(false);
            } else {
              message.showToast(res.error.message, ToastTypes.ERROR);
            }
          })
          .catch((err) => {
            setShowOptions(false);
            loader.showLoading(false);
            message.showToast(
              "Server error. Try again later",
              ToastTypes.ERROR
            );
          });
      } else {
        setShowOptions(false);
        message.showToast("Select at least one product", ToastTypes.ERROR);
      }
    }
  };

  const onClickOutside = () => {
    setShowOptions(false);
  };

  useEffect(() => {
    outsideClick.getComponent(ref, onClickOutside);
  }, [onClickOutside]);

  return (
    <div className="relative">
      <div
        onClick={() => handleOptions()}
        className="filter_dropdown export_dropdown_btn"
      >
        Sync Products <RiArrowDropDownLine className="export_dropdown_icon" />
      </div>

      {showOptions ? (
        <div
          ref={ref}
          className={`${
            showOptions ? "w-60 h-auto" : null
          } h-0 bg-white border absolute shadow-xl flex flex-col py-4`}
        >

          <p className="sync_popup_head">On Shopify</p>
          {options.map((item, key) => {
            return (
              <p
                key={key}
                className="sync_popup_option"
                onClick={() => handleSync(item.value)}
              >
                {item.name}
              </p>
            );
          })}

          {/* <p className="sync_popup_head">On Amazon</p>
          {options.map((item, key) => {
            return (
              <p
                key={key}
                className="sync_popup_option"
                onClick={() => handleSync(item.value)}
              >
                {item.name}
              </p>
            );
          })} */}
        </div>
      ) : null}
    </div>
  );
}
