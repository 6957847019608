import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { BsDot } from "react-icons/bs";
import "./style.css";

export default function Pagination({ pages, page, setPage }) {
  const length = pages.totalPages;
  const pagesArray = Array.from({ length });

  const handleNext = () => {
    if (length > page) {
      setPage(page + 1);
    }
  };

  const handlePrev = () => {
    if (page !== 1) {
      setPage(page - 1);
    }
  };

  const updatePage = (val) => {
    setPage(val);
  };

  return (
    <div className="pagination_row">
      <div className="pagination_box">
        <IoIosArrowBack
          className="next_prev_icon"
          onClick={() => handlePrev()}
        />
        {length <= 5 ? (
          pagesArray.map((item, index) => {
            return (
              <div
                className={index + 1 === page ? "active_page" : "page"}
                onClick={() => updatePage(index + 1)}
              >
                {index + 1}
              </div>
            );
          })
        ) : (
          <>
            <div
              className={page === 1 ? "active_page" : "page"}
              onClick={() => updatePage(1)}
            >
              1
            </div>
            <div
              className={page === 2 ? "active_page" : "page"}
              onClick={() => updatePage(2)}
            >
              2
            </div>

            {page === 3 ? (
              <>
                <div className="active_page" onClick={() => updatePage(3)}>
                  3
                </div>
                <div className="page" onClick={() => updatePage(4)}>
                  4
                </div>
              </>
            ) : page > 4 && page !== length - 1 && page !== length ? (
              <>
                <div className="dots_row">
                  <BsDot className="dots" />
                  <BsDot className="dots" />
                  <BsDot className="dots" />
                </div>
                <div className="active_page" onClick={() => updatePage(page)}>
                  {page}
                </div>
              </>
            ) : page <= 4 ? (
              <>
                <div className="page" onClick={() => updatePage(3)}>
                  3
                </div>
                <div
                  className={page === 4 ? "active_page" : "page"}
                  onClick={() => updatePage(4)}
                >
                  4
                </div>
              </>
            ) : (
              <>
                <div className="dots_row">
                  <BsDot className="dots" />
                  <BsDot className="dots" />
                  <BsDot className="dots" />
                </div>
                <div className="page" onClick={() => updatePage(length - 2)}>
                  {length - 2}
                </div>
              </>
            )}

            {page === length - 1 ? (
              <div
                className="active_page"
                onClick={() => updatePage(length - 1)}
              >
                {length - 1}
              </div>
            ) : page >= length - 2 ? (
              <div className="page" onClick={() => updatePage(length - 1)}>
                {length - 1}
              </div>
            ) : (
              <>
                <div className="dots_row">
                  <BsDot className="dots" />
                  <BsDot className="dots" />
                  <BsDot className="dots" />
                </div>
              </>
            )}

            <div
              className={page === length ? "active_page" : "page"}
              onClick={() => updatePage(length)}
            >
              {length}
            </div>
          </>
        )}

        <IoIosArrowForward
          className="next_prev_icon"
          onClick={() => handleNext()}
        />
      </div>
    </div>
  );
}
