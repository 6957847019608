// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscription_list:nth-child(even){
    background-color: aquamarine;
}`, "",{"version":3,"sources":["webpack://./src/views/admin/subscription/list/style.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC","sourcesContent":[".subscription_list:nth-child(even){\n    background-color: aquamarine;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
