// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../assets/images/home/contact_banner.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact_us_bg{
    background-image: linear-gradient(rgba(0,0,0,0.4), rgba(0, 0, 0, 0.4)),url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: center;
    width: 100%;
    height: 320px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact_input_row{
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 3rem 0;
}

.contact_us_head{
    font-family: "Inter", sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
}

@media (max-width:768px) {
    .contact_input_row{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding:1rem 1rem 2rem 1rem;
        margin: 0;
    }

    .contact_us_bg{
        height: 400px;
    }
}`, "",{"version":3,"sources":["webpack://./src/website/component/views/home/contactUs/style.css"],"names":[],"mappings":"AAAA;IACI,8GAAkI;IAClI,2BAA2B;IAC3B,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,gCAAgC;IAChC,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,SAAS;QACT,2BAA2B;QAC3B,SAAS;IACb;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".contact_us_bg{\n    background-image: linear-gradient(rgba(0,0,0,0.4), rgba(0, 0, 0, 0.4)),url('../../../../../assets/images/home/contact_banner.png');\n    background-position: center;\n    width: 100%;\n    height: 320px;\n    background-size: cover;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.contact_input_row{\n    display: flex;\n    justify-content: space-around;\n    width: 100%;\n    margin: 3rem 0;\n}\n\n.contact_us_head{\n    font-family: \"Inter\", sans-serif;\n    font-size: 1.5rem;\n    font-weight: 700;\n}\n\n@media (max-width:768px) {\n    .contact_input_row{\n        display: flex;\n        flex-direction: column;\n        gap: 1rem;\n        padding:1rem 1rem 2rem 1rem;\n        margin: 0;\n    }\n\n    .contact_us_bg{\n        height: 400px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
