// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit_phone_head{
    font-size: 1.4rem;
    text-align: center;
}

.phone_modal_row{
    display: flex;
    align-items:center;
    justify-content: center;
    width: 500px;
    margin: 1rem 0;
    gap: 0.5rem;
}

.phone_modal_row .input_transparent{
    padding: 0;
}

.phone_modal_row p{
    font-size: 1.1rem;
    height: 40px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

.phone_modal_box{
    border-bottom: 1px solid #000000c7;
    width: 300px;
}

.phone_modal_form{
    display: flex;
    flex-direction: column;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/component/views/seller/profile/phoneInput/style.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,cAAc;IACd,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,UAAU;IACV,SAAS;IACT,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kCAAkC;IAClC,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":[".edit_phone_head{\n    font-size: 1.4rem;\n    text-align: center;\n}\n\n.phone_modal_row{\n    display: flex;\n    align-items:center;\n    justify-content: center;\n    width: 500px;\n    margin: 1rem 0;\n    gap: 0.5rem;\n}\n\n.phone_modal_row .input_transparent{\n    padding: 0;\n}\n\n.phone_modal_row p{\n    font-size: 1.1rem;\n    height: 40px;\n    padding: 0;\n    margin: 0;\n    display: flex;\n    align-items: center;\n}\n\n.phone_modal_box{\n    border-bottom: 1px solid #000000c7;\n    width: 300px;\n}\n\n.phone_modal_form{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
