// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.instagram-posts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.instagram-post {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  height: 300px;
}

.post-media {
  position: relative;
  width: 100%;
  height: 100%;
}

.post-media img {
  width: 100%;
  height: auto;
  display: block;
}

.post-media video {
  width: 100%;
  height: 300px;
  display: block;
  object-fit: cover;
}

.post-link {
  display: block;
  text-align: center;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  border-radius: 0 0 8px 8px;
}

.post-link:hover {
  background-color: #0056b3;
}
.instagram-post {
  position: relative;
  overflow: hidden;
}

.post-link {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.instagram-post:hover .post-link {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/website/component/views/home/instagramVideo/InstagramVideos.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,WAAW;EACX,qBAAqB;EACrB,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,2BAA2B;EAC3B,UAAU;EACV,oCAAoC;EACpC,oCAAoC;EACpC,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".instagram-posts-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  gap: 20px;\n}\n\n.instagram-post {\n  width: 300px;\n  border: 1px solid #ccc;\n  border-radius: 8px;\n  overflow: hidden;\n  height: 300px;\n}\n\n.post-media {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.post-media img {\n  width: 100%;\n  height: auto;\n  display: block;\n}\n\n.post-media video {\n  width: 100%;\n  height: 300px;\n  display: block;\n  object-fit: cover;\n}\n\n.post-link {\n  display: block;\n  text-align: center;\n  padding: 10px;\n  background-color: #007bff;\n  color: #fff;\n  text-decoration: none;\n  font-weight: bold;\n  border-radius: 0 0 8px 8px;\n}\n\n.post-link:hover {\n  background-color: #0056b3;\n}\n.instagram-post {\n  position: relative;\n  overflow: hidden;\n}\n\n.post-link {\n  position: absolute;\n  bottom: 10px;\n  left: 50%;\n  transform: translateX(-50%);\n  opacity: 0;\n  transition: opacity 0.3s ease-in-out;\n  background-color: rgba(0, 0, 0, 0.5);\n  color: white;\n  padding: 5px 10px;\n  border-radius: 5px;\n}\n\n.instagram-post:hover .post-link {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
