import React, { useEffect, useState } from "react";
import WebDropdown from "../../../component/library/webDropdown";
import WebButton from "../../../component/library/webButton";
import { MdEdit } from "react-icons/md";
import { BsEyeFill } from "react-icons/bs";
import { userApiService } from "../../../service/admin/userApiService";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import moment from "moment";
import Pagination from "../../../component/library/pagination";
import { useNavigate } from "react-router-dom";
import { IoMdEyeOff } from "react-icons/io";
import { FaSortUp, FaSortDown } from "react-icons/fa";

const UserManagement = () => {
  const [userManagement, setUserManagement] = useState([]);
  const [userEditData, setUserEditData] = useState({});
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState({
    currentPage: "",
    totalPages: "",
    totalItems: "",
  });
  const [id, setId] = useState("");
  const [status, setStatus] = useState("seller");
  const [searchData, setSearchData] = useState({ search: "" });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserManagement();
  }, [page]);

  useEffect(() => {
    sortData();
  }, [sortField, sortOrder]);

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };
  const query = `?search=${searchData?.search}&role=${status}&page=${page}&limit=${limit}`;

  const fetchUserManagement = () => {
    loader.showLoading(true);
    userApiService
      .getUserList(query)
      .then((res) => {
        if (res.status.code === 200) {
          loader.showLoading(false);
          setUserManagement(res.data.users);
          setPages({
            ...pages,
            currentPage: res.data.currentPage,
            totalPages: res.data.totalPages,
            totalItems: res.data.totalUsers,
          });
          setPage(res.data.currentPage);
        } else if (res.status.code === 402) {
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleOnChange = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  };

  const handleDropdown = (value) => {
    setStatus(value);
  };

  const handleSearch = () => {
    fetchUserManagement();
  };

  const options = [
    { name: "--Select User--", value: "" },
    { name: "Seller", value: "seller" },
    { name: "Supplier", value: "supplier" },
    { name: "Admin", value: "admin" },
  ];

  const handleOnClick = (id) => {
    setId(id);
    navigate(`/admin/user-management/edit-user/${id}`);
  };

  const handleEnable = (id) => {
    const payload = { isDisable: !userEditData?.isDisable };
    userApiService
      .updateUser(JSON.stringify(payload), id)
      .then((res) => {
        if (res.status.code === 200) {
          loader.showLoading(false);
          setUserEditData(res.data);
          fetchUserManagement();
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  // const sortData = (key) => {
  //   let direction = "asc";
  //   if (sortConfig.key === key && sortConfig.direction === "asc") {
  //     direction = "desc";
  //   }
  //   setSortConfig({ key, direction });
  //   const sortedData = [...userManagement].sort((a, b) => {
  //     if (a[key] < b[key]) {
  //       return direction === "asc" ? -1 : 1;
  //     }
  //     if (a[key] > b[key]) {
  //       return direction === "asc" ? 1 : -1;
  //     }
  //     return 0;
  //   });
  //   setUserManagement(sortedData);
  // };
  const sortData = () => {
    if (sortField) {
      const sortedData = [...userManagement].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortField] > b[sortField] ? 1 : -1;
        } else {
          return a[sortField] < b[sortField] ? 1 : -1;
        }
      });
      setUserManagement(sortedData);
    }
  };

  const divStyle = { paddingBottom: "20px" };

  return (
    <div style={divStyle} className="container">
      <header className="header">
        <h1>Admin Portal - User Management List</h1>
      </header>
      <div className="main-content">
        <div className="flex gap-6 mb-4">
          <div className="flex items-center gap-2">
            <label>User Details</label>
            <input
              className="border border-gray-300 w-60 h-10 px-2"
              onChange={handleOnChange}
              onKeyUp={(event) => {
                if (event.key === "Enter") handleSearch();
              }}
              value={searchData?.search}
              name="search"
              type="text"
            />
          </div>
          <div className="flex items-center gap-2">
            <label>Role</label>
            <WebDropdown
              options={options}
              selected={status}
              value={status}
              onChange={(e) => handleDropdown(e.target.value)}
            />
          </div>
          <div className="flex items-center">
            <WebButton
              text="Search"
              onClick={handleSearch}
              className="bg-gray-300 w-20 h-10 rounded-sm"
            />
          </div>
        </div>

        <table className="w-full bg-white border border-gray-200">
          <thead className="bg-slate-400">
            <tr>
              <th className="py-2">S.No.</th>
              <th>
                <button onClick={() => handleSort("firstName")}>
                  Name
                  {sortField === "firstName" &&
                    (sortOrder === "asc" ? " ▲" : " ▼")}
                </button>
              </th>
              <th className="py-2">
                <button onClick={() => handleSort("email")}>
                  Email
                  {sortField === "email" && (sortOrder === "asc" ? " ▲" : " ▼")}
                </button>
              </th>
              <th>Role</th>
              <th className="py-2">Last IP</th>
              <th>
                <button onClick={() => handleSort("createdAt")}>
                  Created At
                  {sortField === "createdAt" &&
                    (sortOrder === "asc" ? " ▲" : " ▼")}
                </button>
              </th>
              <th className="py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {userManagement && userManagement.length > 0 ? (
              userManagement.map((item, index) => {
                const isDisable = item.isDisable;
                const rowClass = isDisable ? "line-through bg-red-300" : "";
                return (
                  <tr key={item._id} className={`text-center ${rowClass}`}>
                    <td className="py-2">{(page - 1) * limit + index + 1}</td>
                    <td className="py-2">
                      {item?.firstName} {item?.lastName}
                    </td>
                    <td className="py-2">{item?.email}</td>
                    <td className="py-2">{item?.role}</td>
                    <td className="py-2">{item?.lastLoginIP}</td>
                    <td className="py-2">
                      {moment(item?.createdAt).format("DD/MM/YYYY , LT")}
                    </td>
                    <td className="py-2">
                      <div className="flex justify-center gap-2">
                        <MdEdit
                          className="text-xl text-blue-600 cursor-pointer"
                          onClick={() => handleOnClick(item?._id)}
                        />
                        {isDisable ? (
                          <IoMdEyeOff
                            className="text-xl cursor-pointer"
                            onClick={() => handleEnable(item?._id)}
                          />
                        ) : (
                          <BsEyeFill
                            className="text-xl cursor-pointer"
                            onClick={() => handleEnable(item?._id)}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="7" className="text-center py-4">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {userManagement.length ? (
        <Pagination pages={pages} page={page} setPage={setPage} />
      ) : null}
    </div>
  );
};

export default UserManagement;
