import React from "react";
import ProductCard from "../../../common/productCard";
import "./style.css";

export default function SelectedProducts({ products }) {
  return (
    <div className="section_box">
      <div className="selected_products_bg">
        <div className="home_section_head">Selected For You</div>

        <div className="selected_product_items_row">
          {products?.map((item, index) => {
            return <ProductCard product={item} />;
          })}
        </div>
      </div>
    </div>
  );
}
