import React, { useEffect, useState } from "react";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { productListApiService } from "../../../service/admin/productApiService";
import { MdDelete, MdModeEdit, MdDescription } from "react-icons/md";
import { GrRevert } from "react-icons/gr";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

const AdminProductDetails = () => {
  const [product, setProduct] = useState({});
  const [activeImage, setActiveImage] = useState("");
  const [variations, setVariations] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const loader = useLoader();
  const message = useMessage();
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc"); // Default ascending
  const [checkHeaderClick, setCheckHeaderClick] = useState(false);
  const [categories, setCategories] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [exportAllData, setExportAllData] = useState();
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);

  const handleVariationProduct = () => {
    loader.showLoading(true);
    productListApiService
      .getProductById(searchParams.get("id"))
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setProduct(res.data);
          // setVariations(res.data.variations || []);
          setActiveImage(res.data.imgUrl ? res.data.imgUrl[0] : "");
        } else if (res.status.code === 402) {
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };
  const handleEdit = (id) => {
    setId(id);
    navigate(`/admin/products/edit/${id}`);
  };
  const handleAllChecked = (e) => {
    if (e.target.checked) {
      let allSelectedRows = variations.map((item) => item);
      setSelectedRows(allSelectedRows);
      setCheckHeaderClick(true);
    } else {
      setSelectedRows([]);
      setCheckHeaderClick(false);
    }
  };
  const handleChange = (item) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some((row) => row._id === item._id);
      if (isSelected) {
        return prevSelectedRows.filter((row) => row._id !== item._id);
      } else {
        return [...prevSelectedRows, item];
      }
    });
  };
  const handleStatus = (id, status) => {
    const payload = { status: status };
    productListApiService
      .editProducts(JSON.stringify(payload), id)
      .then((res) => {
        // setProducts(res.data);
        // fetchProductsList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    setId(id);
    setOpen(true);
  };
  const handleRevert = (id, deleted) => {
    const payload = { is_delete: deleted };
    productListApiService
      .editProducts(JSON.stringify(payload), id)
      .then((res) => {
        // setProducts(res.data);
        // fetchProductsList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSort = (field) => {
    let order = "asc";
    if (sortField === field && sortOrder === "asc") {
      order = "desc";
    }
    setSortField(field);
    setSortOrder(order);

    const sortedData = [...variations].sort((a, b) => {
      const comparison = sortOrder === "asc" ? 1 : -1;

      return comparison * (a[field] > b[field] ? 1 : -1);
    });
    setVariations(sortedData);
  };
  const handleProduct = () => {
    loader.showLoading(true);
    productListApiService
      .getVariationProductById(searchParams.get("id"))
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setProduct(res.data);
          setVariations(res.data.productVariation || []);
          setActiveImage(res.data.imgUrl ? res.data.imgUrl[0] : "");
        } else if (res.status.code === 402) {
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };
  const handleProductVariationData = (id) => {
    loader.showLoading(true);
    productListApiService
      .getProductBySku(id)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setProduct(res.data);
          setActiveImage(res.data.imgUrl ? res.data.imgUrl[0] : "");
        } else if (res.status.code === 402) {
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };
  useEffect(() => {
    handleProduct();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white shadow-md rounded p-6 grid grid-cols-12 gap-6">
        {/* Left Column: Image and Thumbnails */}
        <div className="col-span-5 flex flex-col items-center">
          {activeImage && (
            <img
              src={activeImage}
              alt="Product"
              className="w-full h-96 object-cover mb-4"
            />
          )}
          <div className="flex flex-wrap justify-center">
            {product.imgUrl &&
              product.imgUrl.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Thumbnail ${index}`}
                  className="w-16 h-16 object-cover cursor-pointer mx-2 border rounded mb-2"
                  onClick={() => setActiveImage(url)}
                />
              ))}
          </div>
          <div className="col-span-2">
            <p className="font-medium">Description:</p>
            <span>{product.description}</span>
          </div>
        </div>

        {/* Right Column: Product Details and Variations */}
        <div className="col-span-7">
          <h2 className="text-2xl font-bold mb-4">{product.title}</h2>

          {/* Product Information Below Image */}
          <div className="grid grid-cols-2 gap-6 mb-6">
            <div>
              <p className="font-medium">SKU:</p>
              <span>{product.sku}</span>
            </div>
            <div>
              <p className="font-medium">Vendor ID:</p>
              <span>{product.vendor_id}</span>
            </div>
            <div>
              <p className="font-medium">Category ID:</p>
              <span>{product.categoryId}</span>
            </div>
            <div>
              <p className="font-medium">Node ID:</p>
              <span>{product.nodeId}</span>
            </div>

            <div>
              <p className="font-medium">Dimensions (L x W x H):</p>
              <span>
                {product.length} x {product.width} x {product.height}
              </span>
            </div>
            <div>
              <p className="font-medium">Weight:</p>
              <span>{product.weight}</span>
            </div>
            <div>
              <p className="font-medium">Pack Unit:</p>
              <span>{product.pack_unit}</span>
            </div>
            <div>
              <p className="font-medium">Vendor Price:</p>
              <span>{product.vendor_price}</span>
            </div>
            <div>
              <p className="font-medium">MRP:</p>
              <span>{product.mrp}</span>
            </div>
            <div>
              <p className="font-medium">Selling Price:</p>
              <span>{product.sp}</span>
            </div>
            <div>
              <p className="font-medium">Quantity:</p>
              <span>{product.quantity}</span>
            </div>
            <div>
              <p className="font-medium">HSN:</p>
              <span>{product.hsn}</span>
            </div>
            <div>
              <p className="font-medium">Brand:</p>
              <span>{product.brand}</span>
            </div>
            <div>
              <p className="font-medium">Location:</p>
              <span>{product.location}</span>
            </div>
            <div>
              <p className="font-medium">Category Name:</p>
              <span>{product.c_name}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 p-6 mt-6">
        <h3 className="text-xl font-bold mb-4">Variations</h3>
        <table className="w-full mt-4 bg-white">
          <thead className="bg-slate-200 text-base text-slate-500">
            <tr className="products">
              <td className="py-1 px-4">Image</td>
              <td className="py-1 px-4">Sku</td>
              <td className="py-1 px-4">Color</td>
              <td className="py-1 px-4">Size</td>
              <th
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("sp")}
              >
                Price
              </th>
              <th
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("quantity")}
              >
                Quantity
              </th>
              <td className="py-1 px-4">Actions</td>
            </tr>
          </thead>
          <tbody>
            {variations && variations.length > 0 ? (
              variations.map((item, index) => {
                let isDeleted = item.is_delete;
                const rowClass = isDeleted ? "line-through bg-red-300" : "";
                return (
                  <tr
                    key={index}
                    className={`cursor-pointer text-wrap ${rowClass} `}
                  >
                    <td>
                      <img src={item?.imgUrl[0]} width="70px" />
                    </td>

                    <td
                      className="text-wrap"
                      onClick={() => handleProductVariationData(item.sku)}
                    >
                      {item.sku}
                    </td>
                    <td className="py-2 px-4">{item.color_map}</td>
                    <td className="py-2 px-4">{item.size_map}</td>
                    <td className="py-2 px-4">{item.sp}</td>
                    <td
                      onClick={() =>
                        navigate(`/admin/products/details?id=${item?._id}`)
                      }
                      className="py-2 px-4"
                    >
                      {item.quantity}
                    </td>
                    <td>
                      <div className="flex gap-1">
                        <MdModeEdit
                          className="text-xl cursor-pointer text-blue-600"
                          onClick={() => handleEdit(item._id)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <p>No Data Found</p>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminProductDetails;
