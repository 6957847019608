import React, { useEffect, useRef, useState } from "react";
import WebInput from "../../../../component/library/webInput";
import { useLoader } from "../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";
import { useNavigate } from "react-router-dom";
import WebButton from "../../../../component/library/webButton";
import { orderApiService } from "../../../../service/seller/orderApiService";
import { phoneFirstLetter } from "../../../../helper/regex";
import "./style.css";

export default function AddOrder() {
  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();
  const [orderitems,setOrderItems]=useState([])

  const [data, setData] = useState({
    orderId: "",
    orderDetails:orderitems,
    recipientName: "",
    shippingPhoneNumber: "",
    shippingAddress1: "",
    shippingAddress2: "",
    shippingAddress3: "",
    shippingCity: "",
    shippingState: "",
    shippingPostalCode: "",
  });
  
  const [error, setError] = useState({
    orderId: "",
    orderDetails:"",
    recipientName: "",
    shippingPhoneNumber: "",
    shippingAddress1: "",
    shippingAddress2: "",
    shippingAddress3: "",
    shippingCity: "",
    shippingState: "",
    shippingPostalCode: "",
  });

  const refs={
     orderId : useRef(),
     orderDetails : useRef(),
     sku : useRef(),
     recipientName : useRef(),
     shippingPhoneNumber : useRef(),
     consumerPrice : useRef(),
     quantity : useRef(),
     shippingAddress1 : useRef(),
     shippingAddress2: useRef(),
     shippingAddress3: useRef(),
     shippingCity : useRef(),
     shippingState : useRef(),
     shippingPostalCode : useRef()
  }
 
  useEffect(()=>{
    setData({...data,orderDetails:orderitems})
  },[orderitems])
  
  const handleOnchange = (e) => {
    if(e.target.name === 'shippingPhoneNumber'){
      setData({ ...data, shippingPhoneNumber: e.target.value.slice(0, 10) });
      setError({ ...error, shippingPhoneNumber: "" });
    }
    else if(e.target.name === 'shippingPostalCode'){
      setData({ ...data, shippingPostalCode: e.target.value.slice(0, 6) });
      setError({ ...error, shippingPostalCode: "" });
    }
    else{
      setData({ ...data, [e.target.name]: e.target.value });
      setError({ ...error, [e.target.name]: "" });
    }
  };

  const validate = () => {
    let result = true;
    if (!data.orderId) {
      setError({ ...error, orderId: "Order id is required" });
      refs.orderId.current.focus();
      result = false;
    } 
    
    else if (!data.orderDetails.length) {
      setError({ ...error, orderDetails: "Order item is required" });
      refs.orderDetails.current.focus();
      result = false;
    } 
    
    else if (!data.recipientName) {
      setError({ ...error, recipientName: "Recipient name is required" });
      refs.recipientName.current.focus();
      result = false;
    }
    
    else if (!data.shippingAddress1) {
      setError({
        ...error,
        shippingAddress1: "Address is required",
      });
      refs.shippingAddress1.current.focus();
      result = false;
    }
    
    else if (!data.shippingPhoneNumber) {
      setError({
        ...error,
        shippingPhoneNumber: "Shipping phone number is required",
      });
      refs.shippingPhoneNumber.current.focus();
      result = false;
    }
    
    
    else if (data.shippingPhoneNumber.length !== 10) {
      setError({ ...error, shippingPhoneNumber: "Not a valid phone number" });
        refs.shippingPhoneNumber.current.focus();
        result = false;
    }

    else if (!data.shippingCity) {
      setError({
        ...error,
        shippingCity: "Shipping city is required",
      });
      refs.shippingCity.current.focus();
      result = false;
    } 

    else if (!data.shippingState) {
      setError({
        ...error,
        shippingState: "Shipping state is required",
      });
      refs.shippingState.current.focus();
      result = false;
    }
    
    else if (!data.shippingPostalCode) {
      setError({
        ...error,
        shippingPostalCode: "Shipping postal code is required",
      });
      refs.shippingPostalCode.current.focus();
      result = false;
    }

    else if (data.shippingPhoneNumber.length === 10) {
      let firstLetter = data.shippingPhoneNumber.slice(0, 1);
      if (!phoneFirstLetter.some((num) => num === firstLetter)) {
        setError({ ...error, shippingPhoneNumber: "Not a valid phone number" });
        refs.shippingPhoneNumber.current.focus();
        result = false;
      }
    }
    return result;
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation()
    if (validate()) {
      loader.showLoading(true);
      orderApiService
      .addOrder(JSON.stringify(data))
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          message.showToast(res.status.message, ToastTypes.SUCCESS);
          navigate("/seller/orders");
          
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
    }
  };
  
  
  return (
    <form onSubmit={handleSubmit} className="upload_order_bg">
      <h2>Order Detail</h2>

      <div className="order_id_row">
          <WebInput
          placeholder="Order Id"
          id="orderId"
          name="orderId"
          ref={refs.orderId}
          error={error.orderId}
          value={data.orderId}
          onChange={handleOnchange}
          />
      </div>

      
     {orderitems?.length?<OrderItemList orderitems={orderitems}/>:null} 

      <OrderItem setOrderItems={setOrderItems} orderitems={orderitems} boxRef={refs.orderDetails} err={error.orderDetails} removeErr={()=>setError({...error,orderDetails:''})}/>



      <h2 className="add_order_head">Shipping Detail</h2>


      <div className="order_item_box">
        <div className="order_item_box_row">

      <WebInput
        type="text"
        placeholder="Recipient Name"
        name="recipientName"
        id="recipientName"
        ref={refs.recipientName}
        error={error.recipientName}
        value={data.recipientName}
        onChange={handleOnchange}
      />

      <WebInput
        type="text"
        placeholder="Shipping Address 1"
        name="shippingAddress1"
        id="shippingAddress1"
        ref={refs.shippingAddress1}
        error={error.shippingAddress1}
        value={data.shippingAddress1}
        onChange={handleOnchange}
      />

      <WebInput
        type="text"
        placeholder="Shipping Address 2"
        name="shippingAddress2"
        id="shippingAddress2"
        ref={refs.shippingAddress2}
        error={error.shippingAddress2}
        value={data.shippingAddress2}
        onChange={handleOnchange}
      />

      <WebInput
        type="text"
        placeholder="Shipping Address 3"
        name="shippingAddress3"
        id="shippingAddress3"
        ref={refs.shippingAddress3}
        error={error.shippingAddress3}
        value={data.shippingAddress3}
        onChange={handleOnchange}
      />

      <WebInput
        type="number"
        placeholder="Shipping Phone Number"
        name="shippingPhoneNumber"
        id="shippingPhoneNumber"
        ref={refs.shippingPhoneNumber}
        error={error.shippingPhoneNumber}
        value={data.shippingPhoneNumber}
        onChange={handleOnchange}
      />

      <WebInput
        type="text"
        placeholder="Shipping City"
        name="shippingCity"
        id="shippingCity"
        ref={refs.shippingCity}
        error={error.shippingCity}
        value={data.shippingCity}
        onChange={handleOnchange}
      />

      <WebInput
        type="text"
        placeholder="Shipping State"
        id="shippingState"
        name="shippingState"
        ref={refs.shippingState}
        error={error.shippingState}
        value={data.shippingState}
        onChange={handleOnchange}
      />

      <WebInput
        type="number"
        placeholder="Shipping Postal Code"
        id="shippingPostalCode"
        name="shippingPostalCode"
        ref={refs.shippingPostalCode}
        error={error.shippingPostalCode}
        value={data.shippingPostalCode}
        onChange={handleOnchange}
      />
      </div>
    </div>

      <div className="add_order_btn_row">
        <WebButton className="active_btn_with_shadow" text="Submit" />
      </div>
    </form>
  );
}


const OrderItem=({setOrderItems,orderitems,boxRef,err,removeErr})=>{

  const ref={
    sku:useRef(),
    orderItemId:useRef(),
    consumerPrice:useRef(),
    quantity:useRef(),
  }

  const [data,setData]=useState({
    orderItemId: "",
    sku: "",
    consumerPrice: "",
    quantity: ""
  })

  const [error,setError]=useState({
    orderItemId: "",
    sku: "",
    consumerPrice: "",
    quantity: ""
  })

  const handleOnchange=(e)=>{
    setData({...data,[e.target.name]:e.target.value})
    setError({...error,[e.target.name]:''})
    removeErr()
  }


  const validate=()=>{
    let result=true;
    if(!data.orderItemId){
      setError({...error,orderItemId:'order id is required'})
      ref.orderItemId.current.focus();
      result=false;
    }else if(!data.sku){
      setError({...error,sku:'sku is required'})
      ref.sku.current.focus();
      result=false;
    }else if(!data.consumerPrice){
      setError({...error,consumerPrice:'consumer price is required'})
      ref.consumerPrice.current.focus();
      result=false;
    }else if(!data.quantity){
      setError({...error,quantity:'quantity is required'})
      ref.quantity.current.focus();
      result=false;
    }
    return result
  }

  const handleAdd=(e)=>{
    e.stopPropagation()
    if(validate()){
      setOrderItems([...orderitems,data])
      setData({
        orderItemId: "",
        sku: "",
        consumerPrice: "",
        quantity: ""
      })
      removeErr()
    }
  }
  
  return<><div className='order_item_box'>
          <div className="order_item_box_row">

              <WebInput
                type="text"
                placeholder="Order Item Id"
                id="orderItemId"
                name="orderItemId"
                error={error.orderItemId}
                value={data.orderItemId}
                ref={ref.orderItemId}
                onChange={handleOnchange}
              />

              <WebInput
                type="text"
                placeholder="SKU"
                id="sku"
                name="sku"
                error={error.sku}
                value={data.sku}
                ref={ref.sku}
                onChange={handleOnchange}
              />


              <WebInput
                type="number"
                placeholder="Consumer Price"
                id="consumerPrice"
                name="consumerPrice"
                error={error.consumerPrice}
                value={data.consumerPrice}
                ref={ref.consumerPrice}
                onChange={handleOnchange}
              />

          
              <WebInput
                type="number"
                placeholder="Quantity"
                id="quantity"
                name="quantity"
                error={error.quantity}
                value={data.quantity}
                ref={ref.quantity}
                onChange={handleOnchange}
              />
          </div>

          <button className="add_more_order_item" onClick={(e)=>handleAdd(e)} ref={boxRef} type="button">Add Order Item</button>   

        </div>
          <p className="alert_message">{err}</p></>
}


const OrderItemList=({orderitems})=>{

  return <table className="seller_table">
      <thead>
        <tr>
        <td>Order Item Id</td>
        <td>SKU</td>
        <td>Consumer Price</td>
        <td>Quantity</td>
        </tr>
      </thead>

      <tbody>
        {orderitems?.map((item,index)=>{
          return <tr key={index}>
                  <td>{item.orderItemId}</td>
                  <td>{item.sku}</td>
                  <td>{item.consumerPrice}</td>
                  <td>{item.quantity}</td>
                </tr>
              })}
      </tbody>
    </table>
}