import React from "react";
import { formatDateOnly } from "../../../../../helper/utils";
import { selectIsNightMode } from "../../../../../redux/slices/modeSlice";
import { useSelector } from "react-redux";
import "./style.css";

export default function Membership({ wallet, membership }) {
  const isNightMode = useSelector(selectIsNightMode);

  return (
    <div
      className={`wallet_membership_box ${
        isNightMode ? "wallet_membership_box_dark" : ""
      }`}
    >
      <div className="wallet_membership_row">
        <div>
          <h2>Membership Plan</h2>
          <p>{membership?.subscriptionId?.name}</p>
        </div>
        <div>
          <h2>Valid Untill</h2>
          <p>{formatDateOnly(membership?.endDate)}</p>
        </div>
      </div>
      <h2>Bank Name</h2>
      <p>Upsaleo</p>
      <h2>Card Holder</h2>
      <p>{wallet?.userId?.firstName + " " + wallet?.userId?.lastName}</p>
      <h2>Card number</h2>
      <p>{wallet?.cardNumber}</p>
    </div>
  );
}
