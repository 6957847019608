import React, { forwardRef } from "react";
import { IoAlertCircle } from "react-icons/io5";
import { GoCheckCircleFill } from "react-icons/go";
import "./style.css";

const WebInput = forwardRef(
  (
    {
      type,
      placeholder,
      className,
      label,
      id,
      parentClassName,
      error,
      onChange,
      value,
      name,
      maxLength,
      verified,
      multiple,
      onBlur,
      onClick,
      inputStyle,
      labelStyle,
      readonly,
    },
    ref
  ) => {
    return (
      <div className={parentClassName}>
        {label ? (
          <label for={id} style={labelStyle}>
            {label}
          </label>
        ) : null}

        <div className="input_wrapper">
          <input
            readonly={readonly}
            style={inputStyle}
            ref={ref}
            id={id}
            type={type}
            name={name}
            placeholder={placeholder}
            multiple={multiple}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
            onBlur={onBlur}
            className={`
              ${className ? className : "default_input"}
                ${error ? "error_input" : ""}
              `}
            onClick={onClick}
          ></input>
          {error ? (
            <IoAlertCircle color="#ED1302" size={20} className="alert_icon" />
          ) : null}

          {verified ? (
            <GoCheckCircleFill
              color="#0FA958"
              size={20}
              className="alert_icon"
            />
          ) : null}
        </div>

        {(className && className === "input_transparent") ||
        (className && className === "input_transparent_hide") ? null : error ? (
          <p className="alert_message">{error}</p>
        ) : null}
      </div>
    );
  }
);

export default WebInput;
