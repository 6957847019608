import Dashboard from "../../views/admin/dashboard";
import AddSubscription from "../../views/admin/subscription/add";
import SubscriptionList from "../../views/admin/subscription/list";
import AddProduct from "../../views/admin/products/add";
import ProductList from "../../views/admin/products/list";
import OrderManagement from "../../views/admin/orderManagement";
import EditProduct from "../../views/admin/products/edit";
import ExistingCategory from "../../views/admin/category/existingCategory";
import AdminProductDetails from "../../views/admin/adminProductDetails";
import OrderDetails from "../../views/admin/order/details";
import UserManagement from "../../views/admin/userManagement";
import EditUser from "../../views/admin/userManagement/EditUser";
import UserStatistics from "../../views/admin/userStatistics";
import Ticket from "../../views/admin/Ticket";
import EditSubscription from "../../views/admin/subscription/edit";
import Transactions from "../../views/admin/transactions";
import TransactionDetails from "../../views/admin/transactions/TransactionDetails";
import FailedOrders from "../../views/admin/FailedOrders";
import TicketManagement from "../../views/admin/ticketManagement";
import RefundDetails from "../../views/admin/ticketManagement/RefundDetails.js";
import AddContent from "../../views/admin/category/existingCategory/addContent/index.js";
import MemebershipManagement from "../../views/admin/membershipManagement/index.js";

const adminRoutes = [
  {
    path: "admin/dashboard",
    element: <Dashboard />,
    title: "dashboard",
  },

  {
    path: "admin/user-management",
    element: <UserManagement />,
    title: "userManagement",
  },
  {
    path: "admin/user-management/edit-user/:id",
    element: <EditUser />,
    title: "editUser",
  },

  {
    path: "admin/transactions",
    element: <Transactions />,
    title: "transactions",
  },

  {
    path: "admin/transaction/:id",
    element: <TransactionDetails />,
    title: "userStatistics",
  },

  {
    path: "admin/user-statistics",
    element: <UserStatistics />,
    title: "userStatistics",
  },

  {
    path: "admin/categories",
    element: <ExistingCategory />,
    title: "category",
  },
  {
    path: "admin/categories/content",
    element: <AddContent />,
    title: "addContent",
  },

  {
    path: "admin/order-management",
    element: <OrderManagement />,
    title: "dashboard",
  },
  {
    path: "admin/order-management/details/:id",
    element: <OrderDetails />,
    title: "dashboard",
  },

  {
    path: "admin/ticket-management",
    element: <TicketManagement />,
    title: "dashboard",
  },
  {
    path: "/admin/ticket-management/ticket/:id",
    element: <Ticket />,
    title: "ticket",
  },
  {
    path: "admin/ticket-management/refund/:id",
    element: <RefundDetails />,
    title: "dashboard",
  },

  {
    path: "admin/failed-orders",
    element: <FailedOrders />,
    title: "userStatistics",
  },

  {
    path: "admin/subscription/add",
    element: <AddSubscription />,
    title: "dashboard",
  },
  {
    path: "admin/subscription/list",
    element: <SubscriptionList />,
    title: "dashboard",
  },
  {
    path: "admin/subscription/edit",
    element: <EditSubscription />,
    title: "dashboard",
  },

  {
    path: "admin/products/add",
    element: <AddProduct />,
    title: "addProduct",
  },
  {
    path: "admin/products/list",
    element: <ProductList />,
    title: "productList",
  },
  {
    path: "admin/products/edit/:id",
    element: <EditProduct />,
    title: "editProduct",
  },
  {
    path: "admin/products/details",
    element: <AdminProductDetails />,
    title: "productDetails",
  },

  {
    path: "admin/membership-management",
    element: <MemebershipManagement />,
    title: "addContent",
  },
];

export default adminRoutes;
