// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product_subscribe_bg{
    border-top: 1px solid var(--outline-color);
    padding: 1rem;
}

.product_subscribe_filter_row{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.product_subscribe_filter_col{
    display: flex;
    gap: 1rem;
}


@media (max-width:768px) {

    .product_subscribe_filter_row{
        flex-wrap: wrap;
    }
    .product_subscribe_filter , .product_subscribe_filter_col{
        flex-wrap: wrap;
    }

    .product_subscribe_filter_col{
        margin-top: 1rem;
    }
    
    .export_dropdown_btn {
        justify-content: space-between;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/seller/productSubscription/style.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;;AAGA;;IAEI;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,8BAA8B;IAClC;AACJ","sourcesContent":[".product_subscribe_bg{\n    border-top: 1px solid var(--outline-color);\n    padding: 1rem;\n}\n\n.product_subscribe_filter_row{\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n}\n\n.product_subscribe_filter_col{\n    display: flex;\n    gap: 1rem;\n}\n\n\n@media (max-width:768px) {\n\n    .product_subscribe_filter_row{\n        flex-wrap: wrap;\n    }\n    .product_subscribe_filter , .product_subscribe_filter_col{\n        flex-wrap: wrap;\n    }\n\n    .product_subscribe_filter_col{\n        margin-top: 1rem;\n    }\n    \n    .export_dropdown_btn {\n        justify-content: space-between;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
