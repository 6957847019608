import React from "react";
import { RiHome2Fill } from "react-icons/ri";
import { IoCart } from "react-icons/io5";
import { IoBag } from "react-icons/io5";
import { FaCubes } from "react-icons/fa";
import { BsWalletFill } from "react-icons/bs";
import { FaFileAlt } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import {
  FaTachometerAlt,
  FaUsers,
  FaExchangeAlt,
  FaChartBar,
  FaListAlt,
  FaShoppingCart,
  FaTicketAlt,
  FaBan,
  FaLayerGroup,
  FaBox,
  FaIdBadge,
} from "react-icons/fa";
export default function IconWrapper({ name, className }) {
  const iconComponents = {
    RiHome2Fill: RiHome2Fill,
    IoCart: IoCart,
    IoBag: IoBag,
    FaCubes: FaCubes,
    BsWalletFill: BsWalletFill,
    FaFileAlt: FaFileAlt,
    IoMdSettings: IoMdSettings,
    FaTachometerAlt: FaTachometerAlt,
    FaUsers: FaUsers,
    FaExchangeAlt: FaExchangeAlt,
    FaChartBar: FaChartBar,
    FaListAlt: FaListAlt,
    FaShoppingCart: FaShoppingCart,
    FaTicketAlt: FaTicketAlt,
    FaBan: FaBan,
    FaLayerGroup: FaLayerGroup,
    FaBox: FaBox,
    FaIdBadge: FaIdBadge,
  };

  // Retrieve the corresponding icon component based on the provided name
  const IconComponent = iconComponents[name];

  // Render the retrieved icon component
  return <IconComponent className={className} />;
}
