import React, { useState } from "react";
import WebDropdown from "../../../../library/webDropdown";
import WebButton from "../../../../library/webButton";

const OrderFilter = ({ setQuery }) => {
  const [filterOrder, setFilterOrder] = useState({
    searchFields: "",
    startDate: "",
    endDate: "",
    state: "",
    status: "",
    ticket: "",
    warehouseOrders: "",
    supplierOrders: "",
    paymentStatus: "",
    returnStatus: "",
    commentStatus: "",
  });

  const [statusValue, setStatusValue] = useState("");
  const options = [
    {
      name: "--Choose a Status",
      value: "",
    },
    {
      name: "Abnormal",
      value: "abnormal",
    },
    {
      name: "Payment Pending",
      value: "payment pending",
    },
    {
      name: "Processing",
      value: "processing",
    },
    {
      name: "Shipped",
      value: "shipped",
    },
    {
      name: "Delivered",
      value: "delivered",
    },
    {
      name: "Cancel",
      value: "cancel",
    },
    {
      name: "Ticket",
      value: "ticket",
    },
  ];

  const handleChange = (e) => {
    setFilterOrder({ ...filterOrder, [e.target.name]: e.target.value });
  };

  const handleFilter = () => {
    setQuery(filterOrder);
  };

  const handleStatusChange = (value) => {
    setStatusValue(value);
    setFilterOrder({ ...filterOrder, status: value });
  };
  const handlepaymentStatus = (value) => {
    setFilterOrder({ ...filterOrder, paymentStatus: value });
  };
  const handleTicketChange = (value) => {
    setFilterOrder({ ...filterOrder, ticket: true });
  };
  const clearFilter = () => {
    setFilterOrder({
      searchFields: "",
      startDate: "",
      endDate: "",
      state: "",
      status: "",
      ticket: "",
      warehouseOrders: "",
      supplierOrders: "",
      paymentStatus: "",
      returnStatus: "",
      commentStatus: "",
    });
  };

  const ReturnOptions = [
    {
      name: "--Choose a Return Method--",
      value: "",
    },
    {
      name: "RTO",
      value: "rto",
    },
    {
      name: "Customer Support",
      value: "customerSupport",
    },
  ];

  const PaymentOptions = [
    {
      name: "--Choose a Payment Method--",
      value: "",
    },
    {
      name: "Successful",
      value: "successful",
    },
    {
      name: "Failed",
      value: "failed",
    },
  ];

  const TicketOptions = [
    {
      name: "--Choose a Ticket Options--",
      value: "",
    },
    {
      name: "Open",
      value: "open",
    },
    {
      name: "Closed",
      value: "closed",
    },
  ];

  return (
    <>
      <form onSubmit={handleFilter}>
        <div className="flex">
          <div className="w-1/2 p-5 bg-white">
            <div className="text-xl font-medium mb-4">Order Details</div>
            <hr />
            <div className="flex mt-3 items-center">
              <label className=" w-40">Filter Details</label>
              <input
                className="h-8 border border-gray-300 w-60"
                onChange={handleChange}
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    handleFilter();
                  }
                }}
                value={filterOrder.searchFields}
                name="searchFields"
              />
            </div>

            <div className="flex mt-3 items-center">
              <label className=" w-40">From</label>
              <input
                type="date"
                className="h-8 border border-gray-300 w-60"
                value={filterOrder.startDate}
                onChange={handleChange}
                name="startDate"
              />
            </div>
            <div className="flex mt-3 items-center">
              <label className=" w-40">To</label>
              <input
                type="date"
                className="h-8 border border-gray-300 w-60"
                value={filterOrder.endDate}
                onChange={handleChange}
                name="endDate"
              />
            </div>
            <div className="flex mt-3 items-center">
              <label className=" w-40">State</label>
              <WebDropdown
                value={filterOrder.state}
                onChange={handleChange}
                className="h-8 w-60 outline-none border border-gray-300"
                name="state"
              />
            </div>
            <div className="flex mt-3 items-center">
              <label className=" w-40">Shipping Status</label>
              <WebDropdown
                options={options}
                onChange={(e) => handleStatusChange(e.target.value)}
                selected={statusValue}
                value={statusValue}
                name="status"
                className="h-8 w-60 text-black outline-none border border-gray-300"
              />
            </div>
            <div className="flex mt-3 items-center">
              <label className=" w-40">Ticket</label>
              <WebDropdown
                onChange={(e) => handleTicketChange(e.target.value)}
                selected={statusValue}
                options={TicketOptions}
                value={statusValue}
                name="ticket"
                className="h-8 w-60 outline-none border border-gray-300"
              />
            </div>
            <div className="flex mt-3 items-center">
              <label className=" w-40">Supplier Orders</label>
              <WebDropdown
                onChange={handleChange}
                name="supplierOrders"
                className="h-8 w-60 outline-none border border-gray-300"
              />
            </div>
          </div>
          <div className="w-1/2 p-5 bg-white">
            <div className="text-xl font-medium mb-4">Shipping Address</div>
            <hr />
            <div>
              <div className="flex mt-3 items-center">
                <label className=" w-40">Payment Status</label>
                <WebDropdown
                  options={PaymentOptions}
                  onChange={(e) => handlepaymentStatus(e.target.value)}
                  selected={statusValue}
                  value={statusValue}
                  className="h-8 w-60 outline-none border border-gray-300"
                />
              </div>
              <div className="flex mt-3 items-center">
                <label className=" w-40">Return Status</label>
                <WebDropdown
                  onChange={handleChange}
                  options={ReturnOptions}
                  name="returnStatus"
                  value={filterOrder.returnStatus}
                  className="h-8 w-60 outline-none border border-gray-300"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="flex justify-end gap-10 mt-4">
        <WebButton
          onClick={handleFilter}
          text="search"
          className="bg-blue-500 px-2 py-1 rounded-sm text-white"
        />

        <WebButton
          text="reset"
          onClick={clearFilter}
          className="bg-blue-500 px-2 py-1 rounded-sm text-white"
        />
      </div>
    </>
  );
};

export default OrderFilter;
