import React, { useState } from 'react'
import WebInput from '../../../../component/library/webInput'
import { CheckOutBox } from '../cart'
import './style.css'
import OrderSteps from '../../../component/views/checkout/orderSteps'

export default function Address() {

  const [data,setData]=useState({
    name:'',
    phoneNo:'',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    pincode: '',
    paymentType: ''
  })


  const [error,setError]=useState({
    name:'',
    phoneNo:'',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    pincode: '',
    paymentType: ''
  })

  const handleOnChange = (e) => {
    setData({...data, [e.target.name] : e.target.value})
  }


  const validate = () => {
    let result = true;
    if (!data.name) {
      setError({ ...error, name: "Name is required" });
      result = false;
    } 
    else if (!data.phoneNo) {
      setError({ ...error, phoneNo: "Phone no. is required" });
      result = false;
    }
    else if (!data.address1) {
      setError({ ...error, address1: "Address is required" });
      result = false;
    }
    else if (!data.address2) {
      setError({ ...error, address2: "Address is required" });
      result = false;
    }
    else if (!data.address3) {
      setError({ ...error, address3: "Address is required" });
      result = false;
    }
    else if (!data.state) {
      setError({ ...error, state: "State is required" });
      result = false;
    }
    else if (!data.city) {
      setError({ ...error, city: "City is required" });
      result = false;
    }
    else if (!data.pincode) {
      setError({ ...error, pincode: "Pinode is required" });
      result = false;
    }
    return result;
  };



  return (
    <div>
      <OrderSteps/>

      <div className='address_bg'>
        <p className='address_head'>ADD ADDRESS</p>
        <div className='address_row'>
            <div className='address_form'>
                <p className='address_form_head'>CONTACT DETAILS</p>

                <WebInput  type='text'
                placeholder='Recipient Name'
                id='name'
                error={error.name}
                onChange={handleOnChange}
                value={data.name}
                name='name'
                />

                <WebInput  type='text'
                placeholder='Phone Number'
                id='phoneNo'
                error={error.phoneNo}
                onChange={handleOnChange}
                value={data.phoneNo}
                name='phoneNo'
                />

                <p className='address_form_head'>SHIPPING DETAILS</p>

                <WebInput  type='text'
                placeholder='Shipping Address 1'
                id='address1'
                error={error.address1}
                onChange={handleOnChange}
                value={data.address1}
                name='address1'
                />

                <WebInput  type='text'
                placeholder='Shipping Address 2'
                id='address2'
                error={error.address2}
                onChange={handleOnChange}
                value={data.address2}
                name='address2'
                />

                <WebInput  type='text'
                placeholder='Shipping Address 3'
                id='address3'
                error={error.address3}
                onChange={handleOnChange}
                value={data.address3}
                name='address3'
                />


                <WebInput  type='text'
                placeholder='Shipping State'
                id='state'
                error={error.state}
                onChange={handleOnChange}
                value={data.state}
                name='state'
                />

                <WebInput  type='text'
                placeholder='Shipping City'
                id='city'
                error={error.city}
                onChange={handleOnChange}
                value={data.city}
                name='city'
                />

                <WebInput  type='text'
                placeholder='Shipping Postal Code'
                id='pincode'
                error={error.pincode}
                onChange={handleOnChange}
                value={data.pincode}
                name='pincode'
                />

            </div>

            <CheckOutBox type='address' validate={()=>validate()}/>
        </div>
      </div>
    </div>
  )
}
