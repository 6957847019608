import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import "./style.css";

export default function DatePicker({ setStartDate, setEndDate }) {
  const setValue = (e) => {
    setStartDate(e[0]?.$d);
    setEndDate(e[1]?.$d);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.vaue);
  };
  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  return (
    <div className="date_picker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateRangePicker"]}>
          <DateRangePicker
            localeText={{ start: "Check-in", end: "Check-out" }}
            onChange={setValue}
          />
        </DemoContainer>
      </LocalizationProvider>

      {/* <input type="date" onChange={handleStartDate} placeholder="startDate" />
      <input type="date" onChange={handleEndDate} /> */}
    </div>
  );
}
