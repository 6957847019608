import React from "react";
import Logo from "../../../assets/images/common/logo.png";
import { Link } from "react-router-dom";
import "./style.css";

export default function Navbar() {
  return (
    <div className="auth_navbar_bg">
      <div className="auth_navbar_row">
        <Link to="/">
          <img src={Logo} className="auth_logo" />
        </Link>
      </div>
    </div>
  );
}
