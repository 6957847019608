import React from "react";
import "./style.css";

export default function WalletCard({ heading, amount, boxClassName }) {
  return (
    <div className={`${boxClassName} wallet_card_box`}>
      <div className="card_circle"></div>

      <div className="card_circle_line_parent">
        <div className="card_circle_line_child"></div>
      </div>

      <div className="wallet_card_content">
        <p>&#8377;{amount}</p>
        <p>{heading}</p>
      </div>
    </div>
  );
}
