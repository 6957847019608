import React from "react";
import WebButton from "../../../component/library/webButton";

import BannerImage from "../../assets/images/be-a-supplier/banner_image.png";
import ImageOne from "../../assets/images/be-a-supplier/how_to_sell_one.png";
import ImageTwo from "../../assets/images/be-a-supplier/how_to_sell_two.png";
import ImageThree from "../../assets/images/be-a-supplier/how_to_sell_three.png";
import ImageFour from "../../assets/images/be-a-supplier/how_to_sell_four.png";

import GrowOne from "../../assets/images/be-a-supplier/grow_one.png";
import GrowTwo from "../../assets/images/be-a-supplier/grow_two.png";
import GrowThree from "../../assets/images/be-a-supplier/grow_three.png";
import GrowFour from "../../assets/images/be-a-supplier/grow_four.png";

import GmailIcon from "../../../assets/images/icons/gmail.png";
import ContactIcon from "../../../assets/images/icons/contact.png";

import { Link, useNavigate } from "react-router-dom";
import { upsaleoContact } from "../../helper/constants";
import "./style.css";

export default function BeASupplier() {
  const sellingSteps = [
    {
      img: ImageOne,
      name: "STEP 1: Create Your Account",
      para: "Sign up on Upsaleo.com with your GST/PAN details and an active bank account",
    },
    {
      img: ImageTwo,
      name: "STEP 2: Select Storage & Shipping",
      para: "Choose your preferred storage, packaging, and delivery methods",
    },
    {
      img: ImageThree,
      name: "STEP 3: List Your Products",
      para: "Upload your products by entering product and brand information",
    },
    {
      img: ImageFour,
      name: "STEP 4: Fulfill Orders & Receive Payment",
      para: "Deliver orders promptly to customers and receive payment within 7 days of delivery",
    },
  ];

  const navigate = useNavigate();

  return (
    <div className="be_a_supplier_bg">
      <div className="banner">
        <div className="column_one">
          <h1>
            Boost Your Distribution by Joining the
            <span> Upsaleo </span>
            Marketplace
          </h1>
        </div>
        <div className="column_two">
          <img src={BannerImage} />
        </div>
      </div>

      <div className="join_marketplace_row">
        <p>
          List your products on the Upsaleo Marketplace and gain access to an
          additional 250,000 quality resellers and orders, all managed
          conveniently in one place.
        </p>

        <div className="buttons_row">
          <WebButton
            className="active_btn_with_shadow"
            type="button"
            onClick={() => navigate("/login")}
            text="Join the marketplace"
          />
          <WebButton
            className="inactive_btn_with_shadow"
            type="button"
            onClick={() => navigate("/login")}
            text="Log In"
          />
        </div>
      </div>

      <div className="how_to_sell_container">
        <div className="how_to_sell_head">How to sell on Upsaleo.com?</div>

        <div className="how_to_sell_head_row">
          {sellingSteps.map((step, index) => {
            return (
              <div className="how_to_sell_col" key={index}>
                <img src={step.img} />
                <div className="how_to_sell_col_content">
                  <p>{step.name}</p>
                  <p>{step.para}</p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="how_to_sell_buttons_row">
          <WebButton
            className="active_btn_with_shadow"
            type="button"
            onClick={() => navigate("/login")}
            text="Start Selling"
          />
          <WebButton
            className="inactive_btn_with_shadow"
            type="button"
            onClick={() => navigate("/login")}
            text="How to register as a seller"
          />
        </div>
      </div>

      <div className="grow_your_buisness">
        <h1>Grow Your Business With Upsaleo</h1>

        <div className="grow_your_buisness_row">
          <div className="grow_your_buisness_col">
            <img src={GrowOne} />
            <h1>Sell Across India</h1>
            <p>Connect with 30 Lakhs+ customers across + 28,000 Zip codes.</p>
          </div>
          <div className="grow_your_buisness_col">
            <img src={GrowTwo} />
            <h1>Affordable Shipping Costs</h1>
            <p>
              Sell your products across India to over 28,000+ Zip codes at
              lowest delivery cost.
            </p>
          </div>
        </div>

        <div className="grow_your_buisness_row">
          <div className="grow_your_buisness_col">
            <img src={GrowThree} />
            <h1>Expert Support 24*7 </h1>
            <p>
              Our dedicated support team is here to assist you every step of the
              way. From setting up your store to troubleshooting issues, we
              provide the expertise and guidance you need to succeed.
            </p>
          </div>
          <div className="grow_your_buisness_col">
            <img src={GrowFour} />
            <h1>Multi-Platform API Integration</h1>
            <p>
              API with multiple e-commerce platforms for seamless automation and
              real-time updates. Streamline your operations and boost efficiency
              across Shopify, WooCommerce, and more!"
            </p>
          </div>
        </div>
      </div>

      <Support />
    </div>
  );
}

const Support = () => {
  return (
    <div className="support_contact_section">
      <h1>24/7 Supplier Support Available at Upsaleo</h1>
      <p>
        Our dedicated supplier support team is here to address all your
        inquiries and concerns, providing assistance both before and after you
        launch your online selling business.
      </p>
      <div className="support_contact_row">
        <Link
          className="suport_contact_column"
          to={`mailto:${upsaleoContact.email}`}
        >
          <img src={GmailIcon} />
          <p> {upsaleoContact.email}</p>
        </Link>

        <Link
          className="suport_contact_column"
          to={`tel:${upsaleoContact.phone}`}
        >
          <img src={ContactIcon} />
          <p>{upsaleoContact.phone}</p>
        </Link>
      </div>
    </div>
  );
};
