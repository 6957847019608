import React, { useState } from "react";
import WebInput from "../../../component/library/webInput";
import WebButton from "../../../component/library/webButton";
import { useLoader } from "../../../base/context/LoaderProvider";
import { useNavigate } from "react-router-dom";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { AuthApiService } from "../../../service/authApiService";
import { clearAuthLocalStorage } from "../../../storage/localStorage";

export default function ChangePassword() {
  const loader = useLoader();
  const navigate = useNavigate();
  const message = useMessage();

  const [data, setData] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const [error, setError] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const handleOnchange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const validate = () => {
    let result = true;
    if (!data.oldPassword) {
      setError({ ...error, oldPassword: "Password is required" });
      result = false;
    } else if (!data.newPassword) {
      setError({ ...error, newPassword: "Password is required" });
      result = false;
    }
    return result;
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    if (validate()) {
      loader.showLoading(true);
      AuthApiService.changePassword(JSON.stringify(data))
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            message.showToast(res.status.message, ToastTypes.SUCCESS);
            clearAuthLocalStorage();
            navigate("/login");
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    }
  };

  return (
    <form className="w-1/2" onSubmit={handleChangePassword}>
      <div className="w-full flex flex-col gap-2">
        <WebInput
          id="oldPassInput"
          type="password"
          placeholder="password"
          label="OLD PASSWORD"
          name="oldPassword"
          value={data.oldPassword}
          error={error.oldPassword}
          onChange={handleOnchange}
        />

        <WebInput
          id="newPassInput"
          type="password"
          placeholder="password"
          label="NEW PASSWORD"
          name="newPassword"
          value={data.newPassword}
          error={error.newPassword}
          onChange={handleOnchange}
        />

        <WebButton
          className="rounded bg-sky-600 text-white py-2 my-2 w-1/2"
          text="Submit"
        />
      </div>
    </form>
  );
}
