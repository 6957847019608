import React, { useEffect, useRef, useState } from "react";
import {
  closeIcon,
  prevVIcon,
  searchIcon,
  downVIcon,
} from "../../../../assets/icons";
import { useSelector } from "react-redux";
import { categoryData } from "../../../../../redux/slices/categorySlice";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  queryToUrl,
  setQueryParams,
  setUrl,
  urlToQuery,
} from "../../../../helper/utils";
import AmazonIcon from "../../../../assets/images/common/Amazon_icon.png";
import ShopifyIcon from "../../../../assets/images/common/shopify-icon.png";
import chinaImg from "../../../../assets/images/product/china.png";
import indiaImg from "../../../../assets/images/product/india.png";
import "./style.css";

export default function ProductFilteration({
  setQuery,
  setContentId,
  setPage,
}) {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    setQuery(setQueryParams(searchParams));
  }, [location]);

  const removeFilters = () => {
    navigate(location.pathname);
  };

  return (
    <div>
      <div className="clear_all_filters" onClick={() => removeFilters()}>
        CLEAR ALL
      </div>
      <div className="product_filteration_container">
        <Categories setContentId={setContentId} setPage={setPage} />
        <AmazonFriendly setPage={setPage} />
        <Price setPage={setPage} />
        <DeliveryDays setPage={setPage} />
        <ShippingCountry setPage={setPage} />
      </div>
    </div>
  );
}

const Categories = ({ setContentId, setPage }) => {
  const [search, SetSearch] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryArray, setQueryArray] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [sliceVal, setSliceVal] = useState(9);
  const [showSubCategories, setShowSubCategories] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const searchRef = useRef();
  const allCategories = useSelector(categoryData);

  useEffect(() => {
    setCategoryList(allCategories);
  }, []);

  useEffect(() => {
    if (searchParams.get("category")) {
      setQueryArray(
        urlToQuery(searchParams.get("category"))
          .split("/")
          .filter((item) => item !== "")
      );
    } else {
      setQueryArray([]);
    }

    setSearchVal("");
  }, [location]);

  useEffect(() => {
    if (queryArray.length) {
      handleCategoryByLocation(queryArray);
    } else {
      setSelectedCategories([]);
      setCategoryList(allCategories);
    }
  }, [queryArray]);

  const handleSearch = (val) => {
    SetSearch(val);
  };

  const selectCategory = (item, index) => {
    if (!queryArray.some((category) => category === item.c_name)) {
      const value = `${
        queryToUrl(queryArray.join("/")) +
        (queryArray.length ? "/" : "") +
        queryToUrl(item.c_name)
      }`;
      navigate(setUrl(location.pathname, searchParams, "category", value));
      setPage(1);
    } else {
      if (queryArray.length - 1 === index) {
        setShowSubCategories(!showSubCategories);
      } else {
        setPage(1);
        setShowSubCategories(true);
        const activeIndex = queryArray.findIndex((val) => val === item.c_name);
        const sliceArray = queryArray.slice(0, activeIndex + 1);
        const value = `${queryToUrl(sliceArray.join("/"))}`;
        navigate(setUrl(location.pathname, searchParams, "category", value));
      }
    }
  };

  const handleBack = (item, index) => {
    setPage(1);
    if (index !== 0) {
      setShowSubCategories(true);
      const activeIndex = queryArray.findIndex((val) => val === item.c_name);
      const sliceArray = queryArray.slice(0, activeIndex);
      const value = `${queryToUrl(sliceArray.join("/"))}`;

      console.log('value',value)
      navigate(setUrl(location.pathname, searchParams, "category", value));
    } else {
      setShowSubCategories(true);
      navigate(setUrl(location.pathname, searchParams, "category", null));
      setQueryArray([]);
    }
  };

  const handleCategoryByLocation = (arr) => {
    let newSelectedCategories = [];

    if (allCategories?.length) {
      const parent = allCategories?.filter((cat) => cat.c_name === arr[0]);
      newSelectedCategories = [parent[0]];
    }

    for (let i = 0; i < arr.length - 1; i++) {
      const child = newSelectedCategories[i].children?.filter(
        (item) => item.c_name === arr[i + 1]
      );
      newSelectedCategories.push(child[0]);
    }

    setSelectedCategories(newSelectedCategories);

    if (newSelectedCategories[arr.length - 1]?.children.length) {
      setCategoryList(newSelectedCategories[arr.length - 1]?.children);
    } else {
      setCategoryList([]);
    }
  };

  useEffect(() => {
    if (search) {
      searchRef.current.focus();
    } else {
      setSearchVal("");
    }
  }, [search]);

  const handleSearchVal = (e) => {
    setSearchVal(e.target.value);
  };

  useEffect(() => {
    if (selectedCategories?.length) {
      const id = selectedCategories[selectedCategories.length - 1].contentId;
      if (id) {
        setContentId(
          selectedCategories[selectedCategories.length - 1].contentId
        );
      } else {
        setContentId("");
      }
    } else {
      setContentId("");
    }
  }, [selectedCategories.length]);

  return (
    <div className="product_filteration_section">
      <div className="filter_search_container">
        <div className="filter_search_head_row">
          <h1 className="filter_heading">CATEGORIES</h1>
          <div
            className="filter_search_icon"
            onClick={() => handleSearch(true)}
          >
            {searchIcon}
          </div>
        </div>

        {search ? (
          <div className="filter_searchbar">
            <input
              type="text"
              ref={searchRef}
              onChange={handleSearchVal}
              value={searchVal}
            />
            <span onClick={() => handleSearch(false)}>{closeIcon}</span>
          </div>
        ) : null}

        {selectedCategories.map((category, index) => {
          return (
            <div className="selected_category_row">
              {category?.children?.length &&
              showSubCategories &&
              selectedCategories.length === index + 1 ? (
                <span onClick={() => selectCategory(category, index)}>
                  {downVIcon}
                </span>
              ) : (
                <span onClick={() => handleBack(category, index)}>
                  {prevVIcon}
                </span>
              )}

              <p key={index} onClick={() => selectCategory(category, index)}>
                {category?.c_name}
              </p>
            </div>
          );
        })}

        <div
          className="filter_items_list"
          style={{ paddingLeft: selectedCategories.length ? "1.2rem" : "" }}
        >
          {showSubCategories &&
            categoryList
              ?.filter((item) =>
                item.c_name.toLowerCase().includes(searchVal.toLowerCase())
              )
              ?.slice(0, sliceVal)
              ?.map((item, index) => {
                return (
                  <div className="filter_item" key={index}>
                    <p onClick={() => selectCategory(item)}>{item.c_name}</p>
                  </div>
                );
              })}

          {categoryList?.length > sliceVal ? (
            <p
              className="plus_more_btn"
              onClick={() => setSliceVal(categoryList.length)}
            >
              + {categoryList.length - sliceVal} more
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const Price = () => {
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100);

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(20000);

  const [range, setRange] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const location = useLocation();

  const handleMinValue = (e) => {
    if (e.target.value < maxValue - 19) {
      if (e.target.value == 0) {
        setMinValue(0);
        setMinPrice(0);
      } else if (e.target.value > 0 && e.target.value <= 20) {
        setMinValue(20);
        setMinPrice(2000);
      } else if (e.target.value > 20 && e.target.value <= 40) {
        setMinValue(40);
        setMinPrice(5000);
      } else if (e.target.value > 40 && e.target.value <= 60) {
        setMinValue(60);
        setMinPrice(10000);
      } else if (e.target.value > 60 && e.target.value <= 80) {
        setMinValue(80);
        setMinPrice(15000);
      }
    }
  };

  const handleMaxValue = (e) => {
    if (e.target.value > minValue) {
      if (e.target.value > 0 && e.target.value <= 20) {
        setMaxValue(20);
        setMaxPrice(2000);
      } else if (e.target.value > 20 && e.target.value <= 40) {
        setMaxValue(40);
        setMaxPrice(5000);
      } else if (e.target.value > 40 && e.target.value <= 60) {
        setMaxValue(60);
        setMaxPrice(10000);
      } else if (e.target.value > 60 && e.target.value <= 80) {
        setMaxValue(80);
        setMaxPrice(15000);
      } else if (e.target.value > 80 && e.target.value <= 100) {
        setMaxValue(100);
        setMaxPrice(20000);
      }
    }
  };

  useEffect(() => {
    const check = parseInt(minPrice) !== 0 || parseInt(maxPrice) !== 20000;

    const value = {
      min: minPrice,
      max: maxPrice,
    };

    if (range) {
      navigate(
        setUrl(location.pathname, searchParams, "priceRange", value, check)
      );
    }
  }, [minPrice, maxValue]);

  useEffect(() => {
    if (searchParams.get("min") || searchParams.get("max")) {
      setMinPrice(searchParams.get("min"));
      setMaxPrice(searchParams.get("max"));

      if (searchParams.get("min") == 0) {
        setMinValue(0);
      } else if (searchParams.get("min") == 2000) {
        setMinValue(20);
      } else if (searchParams.get("min") == 5000) {
        setMinValue(40);
      } else if (searchParams.get("min") == 10000) {
        setMinValue(60);
      } else if (searchParams.get("min") == 15000) {
        setMinValue(80);
      }

      if (searchParams.get("max") == 20000) {
        setMaxValue(100);
      } else if (searchParams.get("max") == 2000) {
        setMaxValue(20);
      } else if (searchParams.get("max") == 5000) {
        setMaxValue(40);
      } else if (searchParams.get("max") == 10000) {
        setMaxValue(60);
      } else if (searchParams.get("max") == 15000) {
        setMaxValue(80);
      }
    } else {
      setMinPrice(0);
      setMinValue(0);
      setMaxPrice(20000);
      setMaxValue(100);
    }
  }, [searchParams.get("min"), searchParams.get("max")]);

  const activePriceRange = () => {
    setRange(true);
  };

  return (
    <div className="product_filteration_section">
      <div className="price_filteration" onClick={() => activePriceRange()}>
        <h1 className="filter_heading">PRICE</h1>
        <div className="slider">
          <div
            className="price_selected_range"
            style={{
              left: `${minValue}%`,
              right: `${maxValue}%`,
              width: `${maxValue - minValue}%`,
            }}
          ></div>

          <input
            type="range"
            id="myRange"
            value={minValue}
            onChange={handleMinValue}
          />
          <input
            type="range"
            id="myRange"
            value={maxValue}
            onChange={handleMaxValue}
          />
        </div>

        <div className="price_range_value_container">
          <select value={minValue} onChange={handleMinValue}>
            <option value="0">0</option>
            <option value="20">2000</option>
            <option value="40">5000</option>
            <option value="60">10000</option>
            <option value="80">15000</option>
          </select>

          <p>to</p>

          <select value={maxValue} onChange={handleMaxValue}>
            <option value="20">2000</option>
            <option value="40">5000</option>
            <option value="60">10000</option>
            <option value="80">15000</option>
            <option value="100">20000</option>
          </select>
        </div>
      </div>
    </div>
  );
};

const DeliveryDays = () => {
  const [shippingTime, setShippingTime] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const location = useLocation();

  const weekRef = useRef(null);
  const daysRef = useRef(null);

  const handleShippingTime = () => {
    if (daysRef.current.checked) {
      setShippingTime(15);
    } else if (weekRef.current.checked) {
      setShippingTime(7);
    } else {
      setShippingTime("");
    }
  };

  useEffect(() => {
    if (shippingTime !== null) {
      navigate(
        setUrl(location.pathname, searchParams, "shippingTime", shippingTime)
      );
    }
  }, [shippingTime]);

  useEffect(() => {
    if (searchParams.get("shippingTime")) {
      setShippingTime(searchParams.get("shippingTime"));
    } else {
      setShippingTime("");
    }
  }, [location]);

  return (
    <div className="product_filteration_section">
      <div className="price_filteration">
        <h1 className="filter_heading">DELIVERY DAYS</h1>

        <div className="filter_items_list days_filter_list">
          <div className="filter_item">
            <input
              type="checkbox"
              id="week"
              value={7}
              name="week"
              onClick={() => handleShippingTime()}
              ref={weekRef}
              checked={parseInt(shippingTime) <= 15}
            />
            <label for="week">Get it in a Week</label>
          </div>

          <div className="filter_item">
            <input
              type="checkbox"
              id="15Days"
              value={15}
              name="days"
              onClick={() => handleShippingTime()}
              ref={daysRef}
              checked={shippingTime == 15}
            />
            <label for="15Days">Get it in 15 Days</label>
          </div>
        </div>
      </div>
    </div>
  );
};

const AmazonFriendly = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [check, setCheck] = useState({
    amazon: null,
    shopify: null,
  });

  const handleAmazon = (e) => {
    setCheck({ ...check, amazon: e.target.checked });
  };

  const handleShopify = (e) => {
    setCheck({ ...check, shopify: e.target.checked });
  };

  useEffect(() => {
    if (check.amazon !== null) {
      navigate(
        setUrl(location.pathname, searchParams, "amazonFriendly", check.amazon)
      );
    }
  }, [check.amazon]);

  useEffect(() => {
    if (check.shopify !== null) {
      navigate(
        setUrl(
          location.pathname,
          searchParams,
          "shopifyFriendly",
          check.shopify
        )
      );
    }
  }, [check.shopify]);

  useEffect(() => {
    if (searchParams.get("amazonFriendly")) {
      setCheck({ ...check, amazon: searchParams.get("amazonFriendly") });
    } else {
      setCheck({
        ...check,
        amazon: false,
      });
    }

    if (searchParams.get("shopifyFriendly")) {
      setCheck({ ...check, shopify: searchParams.get("shopifyFriendly") });
    } else {
      setCheck({
        ...check,
        shopify: false,
      });
    }
  }, [location]);

  return (
    <div className="product_filteration_section">
      <div className="amazon_friendly">
        <input
          type="checkbox"
          id="amazon"
          onChange={handleAmazon}
          checked={check.amazon}
        />
        <label for="amazon">
          <img src={AmazonIcon} /> <p>Friendly</p>
        </label>
      </div>

      <div className=" amazon_friendly shopify_friendly">
        <input
          type="checkbox"
          id="shopify"
          onChange={handleShopify}
          checked={check.shopify}
        />
        <label for="shopify">
          <img src={ShopifyIcon} /> <p>Friendly</p>
        </label>
      </div>
    </div>
  );
};

const ShippingCountry = () => {
  const [shippingCountry, setShippingCountry] = useState({
    india: false,
    china: false,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const location = useLocation();

  const handleShippingCountry = (e) => {
    if (e.target.name === "china") {
      setShippingCountry({ ...shippingCountry, china: !shippingCountry.china });
    } else if (e.target.name === "india") {
      setShippingCountry({ ...shippingCountry, india: !shippingCountry.india });
    }
  };

  useEffect(() => {
    let activeCountries = null;

    if (shippingCountry.india && shippingCountry.china) {
      activeCountries = "india,china";
    } else if (shippingCountry.india) {
      activeCountries = "india";
    } else if (shippingCountry.china) {
      activeCountries = "china";
    } else {
      activeCountries = null;
    }

    navigate(
      setUrl(
        location.pathname,
        searchParams,
        "shippingCountry",
        activeCountries
      )
    );

    console.log("shippingCountry", shippingCountry, activeCountries);
  }, [shippingCountry.india, shippingCountry.china]);

  useEffect(() => {
    let countryArray = [];
    countryArray = searchParams.get("shippingCountry")?.split(",");
    if (
      countryArray?.some((item) => item === "china") &&
      countryArray?.some((item) => item === "india")
    ) {
      setShippingCountry({ india: true, china: true });
    } else if (countryArray?.some((item) => item === "india")) {
      setShippingCountry({ india: true, china: false });
    } else if (countryArray?.some((item) => item === "china")) {
      setShippingCountry({ india: false, china: true });
    } else {
      setShippingCountry({ india: false, china: false });
    }
  }, [location]);

  return (
    <div className="product_filteration_section">
      <div className="price_filteration">
        <h1 className="filter_heading">SHIPPING COUNTRY</h1>

        <div className="filter_items_list days_filter_list">
          <div className="filter_item">
            <input
              type="checkbox"
              id="india"
              value={shippingCountry.india}
              name="india"
              onChange={handleShippingCountry}
              checked={shippingCountry.india}
            />
            <label for="india">
              <span>India</span> <img src={indiaImg} />
            </label>
          </div>

          <div className="filter_item">
            <input
              type="checkbox"
              id="china"
              value={shippingCountry.india}
              name="china"
              onChange={handleShippingCountry}
              checked={shippingCountry.china}
            />
            <label for="china">
              <span>China</span> <img src={chinaImg} />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
