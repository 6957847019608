// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.relative {
    position: relative;
}


.image_cross {
    display: block;

}

.remove-icon {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.remove-icon:hover {
    background-color: darkred;
}

.file-input-label {
    width: 60px;
    background-color: #3b82f6;
    font-size: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    cursor: pointer;
}

.file-input {
    display: none;

}

.bg-blue-500 {
    background-color: #3b82f6;
}

.text-white {
    color: white;
}

.p-2 {
    padding: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/views/admin/products/edit/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;;AAGA;IACI,cAAc;;AAElB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,eAAe;IACf,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,aAAa;;AAEjB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".relative {\n    position: relative;\n}\n\n\n.image_cross {\n    display: block;\n\n}\n\n.remove-icon {\n    position: absolute;\n    top: 0;\n    right: 0;\n    background-color: red;\n    color: white;\n    border: none;\n    border-radius: 50%;\n    width: 1rem;\n    height: 1rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n}\n\n.remove-icon:hover {\n    background-color: darkred;\n}\n\n.file-input-label {\n    width: 60px;\n    background-color: #3b82f6;\n    font-size: 30px;\n    color: white;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border: 1px solid #ccc;\n    cursor: pointer;\n}\n\n.file-input {\n    display: none;\n\n}\n\n.bg-blue-500 {\n    background-color: #3b82f6;\n}\n\n.text-white {\n    color: white;\n}\n\n.p-2 {\n    padding: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
