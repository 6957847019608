import { callAPI } from "../../network/networkConnection";
import { NetworkConfiguration } from "../../network/networkConfriguation";

const getAllProductsList = (queryParams) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.ADMIN_PRODUCTS +
        queryParams,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getProductById = (id) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.PRODUCT_BY_ID + id,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getVariationProductById = (id) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.PRODUCT_VARIATION_BY_PARENT_ID +
        id,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const addProducts = (formData) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.ADD_PRODUCTS,
      "POST",
      {},
      formData
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const editProducts = (productPayload, id) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.EDIT_PRODUCTS +
        `/${id}`,
      "PUT",
      {
        "Content-Type": "application/json",
      },
      productPayload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const uploadImage = (productPayload, id) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.UPLOAD_IMAGE +
        `/${id}/images`,
      "PUT",
      {},
      productPayload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const deleteProducts = (id) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.DELETE_PRODUCT +
        `/${id}`,
      "DELETE",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const addExcelProduct = (formData) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.UPLOAD_EXCEL_PRODUCT,
      "POST",
      {},
      formData
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getProductBySku = (sku) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.PRODUCT_BY_SKU + sku,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const productListApiService = {
  getAllProductsList,
  getProductById,
  editProducts,
  deleteProducts,
  uploadImage,
  addProducts,
  addExcelProduct,
  getVariationProductById,
  getProductBySku,
};
