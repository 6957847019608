import { NetworkConfiguration } from "../../network/networkConfriguation";
import { callAPI } from "../../network/networkConnection";

const getProductSubscription = (queryParams) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.SUBSCRIBED_PRODUCTS +
        queryParams,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const addExcelProductUnsubscription = (formData) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.EXCEL_UNSUBSCRIBED_PRODUCTS,
      "POST",
      {},
      formData
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const addExcelProductSubscription = (formData) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.EXCEL_SUBSCRIBED_PRODUCTS,
      "POST",
      {},
      formData
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const unsubscribeProducts = (productsPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.MULTIPLE_PRODUCTS_UNSUBSCRIBE,
      "POST",
      {
        "Content-Type": "application/json",
      },
      productsPayload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const exportSubscribeProduct = (productPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.EXPORT_SUBSCRIBE_PRODUCT,
      "POST",
      {
        "Content-Type": "application/json",
      },
      productPayload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const exportAmazonProduct = (productPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.EXPORT_AMAZON_TEMPLATE,
      "POST",
      {
        "Content-Type": "application/json",
      },
      productPayload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const exportSubscribeProductInImage = (productPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.EXPORT_SUBSCRIBE_PRODUCT_IN_IMAGE,
      "POST",
      {
        "Content-Type": "application/json",
      },
      productPayload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const syncAllProducts = () => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.SYNC_ALL_PRODUCTS,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const syncSelectedProducts = (productPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.SYNC_SELECTED_PRODUCTS,
      "POST",
      {
        "Content-Type": "application/json",
      },
      productPayload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ProductSubscriptionApiService = {
  getProductSubscription,
  addExcelProductUnsubscription,
  addExcelProductSubscription,
  unsubscribeProducts,
  exportSubscribeProduct,
  exportSubscribeProductInImage,
  syncAllProducts,
  syncSelectedProducts,
  exportAmazonProduct,
};
