// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wallet_chart_filter_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wallet_chart_bg {
    margin: 1rem;
    background-color: white;
    border-radius: 1rem;
}

.wallet_chart_bg_dark {
    padding: 1rem;
}


.chart_filter_date{
    display: flex;
    justify-content: space-between;
}

.chart_filter_date div input{
    width: 15px;
    height: 15px;
    border: 1px solid var(--outline-color);
    border-radius: 2px;
}

.date_filter_col{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.date_filter_col label{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/component/views/seller/wallet/chart/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;;AAGA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sCAAsC;IACtC,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".wallet_chart_filter_row{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.wallet_chart_bg {\n    margin: 1rem;\n    background-color: white;\n    border-radius: 1rem;\n}\n\n.wallet_chart_bg_dark {\n    padding: 1rem;\n}\n\n\n.chart_filter_date{\n    display: flex;\n    justify-content: space-between;\n}\n\n.chart_filter_date div input{\n    width: 15px;\n    height: 15px;\n    border: 1px solid var(--outline-color);\n    border-radius: 2px;\n}\n\n.date_filter_col{\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n    cursor: pointer;\n}\n\n.date_filter_col label{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
