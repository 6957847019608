// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order_status_row {
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    background-color: white;    
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
}

.order_row {
    width: 100%;
    display: flex;
    align-items: center;
}

.order_line {
    width: 100%;
    border-bottom: 2px solid #e5e7eb;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/component/views/admin/orderDetails/orderTracking/style.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,2CAA2C;IAC3C,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,mBAAmB;IACnB,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,gCAAgC;IAChC,kBAAkB;AACtB","sourcesContent":[".order_status_row {\n    border: 1px solid rgba(0, 0, 0, 0.25);\n    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);\n    border-radius: 1rem;\n    padding: 1rem;\n    display: flex;\n    background-color: white;    \n    width: 100%;\n    align-items: center;\n    justify-content: space-between;\n    margin: 1rem 0;\n}\n\n.order_row {\n    width: 100%;\n    display: flex;\n    align-items: center;\n}\n\n.order_line {\n    width: 100%;\n    border-bottom: 2px solid #e5e7eb;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
