import React, { useEffect, useState } from "react";
import { useLoader } from "../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";
import { transactionsApiService } from "../../../../service/admin/transactionsApiService";
import WebButton from "../../../../component/library/webButton";
import { useNavigate, useParams } from "react-router-dom";

const TransactionDetails = () => {
  const [transactionsData, setTransactionsData] = useState({});
  const loader = useLoader();
  const message = useMessage();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    handleOrderTransaction();
  }, []);

  const handleOrderTransaction = () => {
    loader.showLoading(true);
    transactionsApiService
      .fetchOrderDetails(id)
      .then((res) => {
        if (res.status.code === 200) {
          loader.showLoading(false);
          setTransactionsData(res.data);
        } else if (res.status.code === 401) {
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };
  return (
    <div>
      <div className="flex flex-col flex-wrap gap-4 justify-center ">
        <span className="flex justify-between items-center">
          <span className="text-2xl font-bold underline">Order Details</span>{" "}
          {transactionsData.status === "ticket" ? (
            <WebButton
              text="Ticket"
              className="bg-blue-500 w-40  py-2 rounded-lg text-lg"
            />
          ) : (
            ""
          )}
        </span>
        <div className="flex gap-4">
          <p className="text-xl font-semibold">Order Id </p>{" "}
          <span className="text-lg"> {transactionsData?.orderId} </span>
        </div>

        <div className="flex gap-4">
          <p className="text-xl font-semibold">order Item Id </p>{" "}
          <span className="text-xl">
            {" "}
            {transactionsData?.orderDetails?.map((item) => item?.orderItemId)}
          </span>
        </div>
        <div className="flex gap-4">
          <p className="text-xl font-semibold">SKU </p>{" "}
          <span className="text-xl">
            {transactionsData?.orderDetails?.map((item) => item?.sku)}{" "}
          </span>
        </div>
        <div className="flex gap-4">
          <p className="text-xl font-semibold">Quantity</p>{" "}
          <span className="text-xl">
            {" "}
            {transactionsData?.orderDetails?.map((item) => item?.quantity)}{" "}
          </span>
        </div>
        <div className="flex gap-4">
          <p className="text-xl font-semibold">Consumer Price </p>{" "}
          <span className="text-xl">
            {transactionsData?.orderDetails?.map((item) => item?.consumerPrice)}
          </span>
        </div>
        <div className="flex gap-4">
          <p className="text-xl font-semibold">shippingPhoneNumber</p>{" "}
          <span className="text-xl">
            {transactionsData?.shippingPhoneNumber}
          </span>
        </div>
        <div className="flex gap-4">
          <p className="text-xl font-semibold">recipientName</p>{" "}
          <span className="text-xl">{transactionsData?.recipientName}</span>
        </div>
        <div className="flex gap-4">
          <p className="text-xl font-semibold">shippingAddress1 </p>{" "}
          <span className="text-xl">{transactionsData?.shippingAddress1}</span>
        </div>
        <div className="flex gap-4">
          <p className="text-xl font-semibold">shippingAddress2 </p>{" "}
          <span className="text-xl">{transactionsData?.shippingAddress2}</span>
        </div>
        <div className="flex gap-4">
          <p className="text-xl font-semibold">shippingAddress3 </p>{" "}
          <span className="text-xl">{transactionsData?.shippingAddress3} </span>
        </div>
        <div className="flex gap-4">
          <p className="text-xl font-semibold">shippingCity </p>{" "}
          <span className="text-xl">{transactionsData?.shippingCity}</span>
        </div>
        <div className="flex gap-4">
          <p className="text-xl font-semibold">shippingState </p>{" "}
          <span className="text-xl">{transactionsData?.shippingState}</span>
        </div>
        <div className="flex gap-4">
          <p className="text-xl font-semibold">shippingPostalCode</p>{" "}
          <span className="text-xl">
            {transactionsData?.shippingPostalCode}
          </span>
        </div>
        <div className="flex gap-4">
          <p className="text-xl font-semibold">Status </p>{" "}
          <span className="text-xl">{transactionsData?.status} </span>
        </div>
        <div className="flex gap-4">
          <p className="text-xl font-semibold">orderDate</p>{" "}
          <span className="text-xl">{transactionsData?.orderDate}</span>
        </div>
        <div className="flex gap-4">
          <p className="text-xl font-semibold">upsaleoOrderId </p>{" "}
          <span className="text-xl">{transactionsData?.upsaleoOrderId}</span>
        </div>
      </div>
      {transactionsData?.orderDetails?.map((item, index) => {
        return (
          <div className="flex flex-col flex-wrap ">
            <p className="text-2xl font-bold m-4 underline ">
              Product Details {index + 1}{" "}
            </p>
            <div className="flex gap-4">
              <p className="text-xl font-semibold"> Vendor Id </p>{" "}
              <span className="text-xl"> {item?.product?.vendor_id} </span>
            </div>
            <div className="flex gap-4">
              <p className="text-xl font-semibold">Category ID</p>{" "}
              <span className="text-xl">{item?.product?.categoryId} </span>
            </div>
            <div className="flex gap-4">
              <p className="text-xl font-semibold"> SKU </p>{" "}
              <span className="text-xl">{item?.product?.sku} </span>
            </div>
            <div className="flex gap-4">
              <p className="text-xl font-semibold"> Quantity </p>
              <span className="text-xl"> {item?.product?.quantity} </span>
            </div>
            <div className="flex gap-4">
              <p className="text-xl font-semibold"> Consumer Price </p>{" "}
              <span className="text-xl"> {item?.product?.sp} </span>
            </div>
            <div className="flex gap-4">
              <p className="text-xl font-semibold"> Title </p>{" "}
              <span className="text-xl">{item?.product?.title} </span>
            </div>
            <div className="flex gap-4">
              <p className="text-xl font-semibold"> NodeId </p>{" "}
              <span className="text-xl"> {item?.product?.nodeId}</span>
            </div>
            <div className="flex gap-4">
              <p className="text-xl font-semibold">Length </p>{" "}
              <span className="text-xl"> {item?.product?.length} </span>
            </div>
            <div className="flex gap-4">
              <p className="text-xl font-semibold"> Width </p>{" "}
              <span className="text-xl">{item?.product?.width} </span>
            </div>
            <div className="flex gap-4">
              <p className="text-xl font-semibold"> Height </p>{" "}
              <span className="text-xl"> {item?.product?.height} </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TransactionDetails;
