import React, { useEffect, useState } from "react";
import WebButton from "../../../component/library/webButton";
import { transactionsApiService } from "../../../service/admin/transactionsApiService";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import DatePicker from "../../../component/library/datePicker";
import { exportToCSV } from "react-easy-export/dist/react-easy-export";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../component/library/pagination/index";
const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [allTransactionData, setAllTransactionData] = useState("");
  const [startDate, setStartDate] = useState("");
  // const [transactionsData, setTransactionsData] = useState({});
  const [type, setType] = useState("");
  const [endDate, setEndDate] = useState("");
  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [pages, setPages] = useState({
    currentPage: "",
    totalPages: "",
    totalItems: "",
  });

  const handleExportCsv = () => {
    let array = [];
    transactions?.map((item, index) => {
      let obj = {
        "S.No.": index + 1,
        "Full Name": item?.userId?.firstName + " " + item?.userId?.lastName,
        Email: item?.userId?.email,
        Type: item?.type,
        Amount: item?.amount,
        Balance: item?.balance,
        Description: item?.description,
      };
      array.push(obj);
    });
    exportToCSV(array);
  };

  useEffect(() => {
    fetchTransactions();
  }, [page, limit]);

  const query = `?startDate=${startDate}&endDate=${endDate}&type=${type}&page=${page}&limit=${limit}`;

  const fetchTransactions = () => {
    loader.showLoading(true);
    transactionsApiService
      .getTransactions(query)
      .then((res) => {
        if (res.status.code === 200) {
          loader.showLoading(false);
          setTransactions(res.data.transactions);
          setPages({
            ...pages,
            currentPage: res.data.currentPage,
            totalPages: res.data.totalPages,
            totalItems: res.data.totalTransaction,
          });
          setPage(res.data.currentPage);
          setAllTransactionData(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  const handleOrderById = async (item) => {
    const array = await item.description.split(" ");
    const id = await array[array.length - 1];
    await transactionsApiService
      .fetchOrderDetails(id)
      .then(async (res) => {
        if (res.status.code === 200) {
          navigate(`/admin/order-management/details/${res?.data?._id}`);
        } else if (res.status.code === 402) {
          message.showToast(res.error.message, ToastTypes.ERROR);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleType = (e) => {
    setType(e.target.value);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };
  useEffect(() => {
    if (startDate && endDate) {
      fetchTransactions();
      //   handleCalculate();
    } else if (!startDate && !endDate) {
      const today = new Date();
      const sevenDaysAgo = new Date(today.setDate(today.getDate() - 7));
      setStartDate(sevenDaysAgo);
      setEndDate(today);
      // fetchTransactions();
      //   handleCalculate();
    }
  }, [type, startDate, endDate]);
  console.log("startDate" + startDate + "          end Date" + endDate);
  const divStyle = {
    paddingBottom: "20px",
  };
  return (
    <div style={divStyle}>
      <div className="w-full flex-col bg-white border flex items-center px-6 py-6 justify-between">
        <div className="w-full flex justify-between  px-6 py-6">
          <div className="flex flex-col items-center">
            <p className="text-slate-400 text-lg">Wallet Amount</p>
            <p className="text-green-500 text-xl font-medium mt-2">
              &#8377;{allTransactionData?.walletAmount}
            </p>
          </div>

          <div className="flex flex-col items-center">
            <p className="text-slate-400 text-lg">Total Deposit</p>
            <p className="text-green-500 text-xl font-medium mt-2">
              &#8377;{allTransactionData?.totalAmountsByType?.Deposit}
            </p>
          </div>

          <div className="flex flex-col items-center">
            <p className="text-slate-400 text-lg">Total Order</p>
            <p className="text-green-500 text-xl font-medium mt-2">
              &#8377;{allTransactionData?.totalAmountsByType?.Order}
            </p>
          </div>

          <div className="flex flex-col items-center">
            <p className="text-slate-400 text-lg">Total Membership</p>
            <p className="text-green-500 text-xl font-medium mt-2">
              &#8377;{allTransactionData?.totalAmountsByType?.Membership}
            </p>
          </div>

          <div className="flex flex-col items-center">
            <p className="text-slate-400 text-lg">Refund Balance</p>
            <p className="text-green-500 text-xl font-medium mt-2">
              &#8377;{allTransactionData?.totalAmountsByType?.Refund}
            </p>
          </div>
        </div>
        <div className="w-full bg-white border px-6 py-6 mt-2">
          <div className="flex gap-5 items-center">
            <div className="border py-2 px-5 rounded inline-block">
              <select
                className="text-slate-700 focus:outline-none"
                onChange={handleType}
              >
                <option value="">All Types</option>
                <option value="Deposit">Total Deposit</option>
                <option value="Order">Total Order</option>
                <option value="Membership">Total Membership</option>
                <option value="Refund">Refund Balance</option>
              </select>
            </div>

            <DatePicker setStartDate={setStartDate} setEndDate={setEndDate} />

            <button
              className="bg-slate-500 py-2 px-5 text-white rounded"
              onClick={handleExportCsv}
            >
              Download
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <label htmlFor="limit">Items per page:</label>
        <select
          id="limit"
          value={limit}
          onChange={handleLimitChange}
          style={{ border: "1px solid #ccc", padding: "0.5rem" }}
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </select>
      </div>

      {/* <WebButton text="Search" className="bg-blue-500 px-2 py-1 text-white rounded-sm" /> */}
      <table className="w-full  mt-4">
        <thead className="bg-gray-300">
          <th>S.No.</th>
          <th>Full Name</th>
          <th>Email</th>
          <th>Type</th>
          <th>Amount</th>
          <th>Balance</th>
          <th>Description</th>
        </thead>
        <tbody>
          {transactions && transactions.length > 0
            ? transactions.map((data, index) => {
                return (
                  <tr key={data.id} className="text-center">
                    <td>{(page - 1) * limit + index + 1}</td>
                    <td>
                      {data?.userId?.firstName} {data?.userId?.lastName}
                    </td>
                    <td>{data?.userId?.email}</td>
                    <td>{data?.type}</td>
                    <td>{data?.amount}</td>
                    <td>{data?.balance}</td>
                    {data?.type === "Order" || data?.type === "Refund" ? (
                      <td
                        className="cursor-pointer text-blue-600"
                        onClick={() => {
                          handleOrderById(data);
                        }}
                      >
                        {data?.description}
                      </td>
                    ) : (
                      <td className="">{data?.description}</td>
                    )}
                  </tr>
                );
              })
            : "No Data Found"}
        </tbody>
      </table>
      {transactions.length ? (
        <Pagination pages={pages} page={page} setPage={setPage} />
      ) : null}
    </div>
  );
};

export default Transactions;
