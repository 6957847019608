// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sync_popup_head{
    font-family: var(--sans-serif);
    font-family: var(--sans-serif-size-regular);
    font-weight: var(--sans-serif-bold);
    color:var(--web-secondary-color);
    border-bottom: 1px solid var(--outline-color);
    padding:0 1rem 0.5rem 1rem;
}

.sync_popup_option{
    font-family: var(--sans-serif);
    font-family: var(--sans-serif-size-regular);
    font-weight: var(--sans-serif-regular);
    padding:0.25rem 1rem;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/component/views/seller/subscription/syncProducts/style.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,2CAA2C;IAC3C,mCAAmC;IACnC,gCAAgC;IAChC,6CAA6C;IAC7C,0BAA0B;AAC9B;;AAEA;IACI,8BAA8B;IAC9B,2CAA2C;IAC3C,sCAAsC;IACtC,oBAAoB;IACpB,eAAe;AACnB","sourcesContent":[".sync_popup_head{\n    font-family: var(--sans-serif);\n    font-family: var(--sans-serif-size-regular);\n    font-weight: var(--sans-serif-bold);\n    color:var(--web-secondary-color);\n    border-bottom: 1px solid var(--outline-color);\n    padding:0 1rem 0.5rem 1rem;\n}\n\n.sync_popup_option{\n    font-family: var(--sans-serif);\n    font-family: var(--sans-serif-size-regular);\n    font-weight: var(--sans-serif-regular);\n    padding:0.25rem 1rem;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
