import React, { useEffect, useRef } from "react";
import { BsTriangle } from "react-icons/bs";
import WebButton from "../../library/webButton";
import { PiSignOut } from "react-icons/pi";
import {
  clearAuthLocalStorage,
  clearAuthVerifiedData,
} from "../../../storage/localStorage";
import { Link, useNavigate } from "react-router-dom";
import { useOutsideClick } from "../../../base/context/OutsideClick";
import { clearPersistedStorage } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { resetTabs } from "../../../redux/slices/tabsSlice";
import { sidebarData } from "../../../redux/slices/sidebarSlice";
import IconWrapper from "../../library/iconWrapper";
import { FaUser } from "react-icons/fa6";
import "./style.css";

export default function Profile({ onClickOutside, data }) {
  const navigate = useNavigate();
  const outsideClick = useOutsideClick();
  const dispatch = useDispatch();
  const sidebarArray = useSelector(sidebarData);

  const ref = useRef(null);

  const handleLogout = async () => {
    await clearAuthLocalStorage();
    await clearAuthVerifiedData();
    await clearPersistedStorage();
    await dispatch(resetTabs());
    navigate("/login");
  };

  useEffect(() => {
    outsideClick.getComponent(ref, onClickOutside);
  }, [onClickOutside]);

  return (
    <div ref={ref} id="popupModal" className="profile_popup_box">
      <BsTriangle className="profile_popup_upper_triangle_icon" />

      <div className="profile_popup_content_box">
        <p className="profile_popup_username">
          <span>Hi, </span>
          {" " + data?.firstName ? data?.firstName : ""}{" "}
          {data?.lastName ? data?.lastName : ""}!
        </p>

        <div className="profile_popup_pages_list">
          <div className="profile_popup_pages_item">
            <FaUser className="profile_popup_pages_item_icon" />
            <Link to="/seller/profile">
              <p className="text-base cursor-pointer">Profile</p>
            </Link>
          </div>
          {sidebarArray?.map((menu, index) => {
            return (
              <div className="profile_popup_pages_item" key={index}>
                <IconWrapper
                  name={menu?.icon}
                  className="profile_popup_pages_item_icon"
                />

                <Link to={menu?.link}>
                  <p>{menu.name}</p>
                </Link>
              </div>
            );
          })}
        </div>

        <div className="profile_popup_section_divide"></div>
        <WebButton
          className="signout_btn"
          text={[<PiSignOut className="text-xl" />, "sign out"]}
          onClick={handleLogout}
        />
      </div>
    </div>
  );
}
