import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  ToastTypes,
  useMessage,
} from "../../../../../../base/context/MessageProvider";
import { useLoader } from "../../../../../../base/context/LoaderProvider";
import { orderApiService } from "../../../../../../service/seller/orderApiService";
import WebButton from "../../../../../library/webButton";
import WebInput from "../../../../../library/webInput";
import { phoneFirstLetter } from "../../../../../../helper/regex";
import "./style.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "80vh",
  },
};

export default function EditOrderPopup({
  modalIsOpen,
  setIsOpen,
  id,
  refresh,
}) {
  const [data, setData] = useState({
    shippingPhoneNumber: "",
    recipientName: "",
    shippingAddress1: "",
    shippingAddress2: "",
    shippingAddress3: "",
    shippingState: "",
    shippingCity: "",
    shippingPostalCode: "",
  });

  const [error, setError] = useState({
    shippingPhoneNumber: "",
    recipientName: "",
    shippingAddress1: "",
    shippingAddress2: "",
    shippingAddress3: "",
    shippingState: "",
    shippingCity: "",
    shippingPostalCode: "",
  });

  const message = useMessage();
  const loader = useLoader();

  function closeModal() {
    setIsOpen(false);
  }

  const getOrderById = () => {
    loader.showLoading(true);
    orderApiService
      .getOrderById(id)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setData({
            ...data,
            shippingPhoneNumber: res?.data?.shippingPhoneNumber
              ? res.data.shippingPhoneNumber
              : "",
            recipientName: res?.data?.recipientName
              ? res.data.recipientName
              : "",
            shippingAddress1: res?.data?.shippingAddress1
              ? res.data.shippingAddress1
              : "",
            shippingAddress2: res?.data?.shippingAddress2
              ? res.data.shippingAddress2
              : "",
            shippingAddress3: res?.data?.shippingAddress3
              ? res.data.shippingAddress3
              : "",
            shippingState: res?.data?.shippingState
              ? res.data.shippingState
              : "",
            shippingCity: res?.data?.shippingCity ? res.data.shippingCity : "",
            shippingPostalCode: res?.data?.shippingPostalCode
              ? res.data.shippingPostalCode
              : "",
          });
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleOnChange = (e) => {
    if (e.target.name === "shippingPhoneNumber") {
      setData({ ...data, [e.target.name]: e.target.value.slice(0, 10) });
      setError({ ...error, [e.target.name]: "" });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
      setError({ ...error, [e.target.name]: "" });
    }
  };

  const validate = () => {
    let result = true;

    if (!data?.recipientName) {
      setError({ ...error, recipientName: "Name is required" });
      result = false;
    } else if (!data?.shippingAddress1) {
      setError({ ...error, shippingAddress1: "Address is required" });
      result = false;
    } else if (!data?.shippingAddress2) {
      setError({ ...error, shippingAddress2: "Address is required" });
      result = false;
    } else if (!data?.shippingAddress3) {
      setError({ ...error, shippingAddress3: "Address is required" });
      result = false;
    } else if (!data?.shippingPhoneNumber?.length) {
      setError({ ...error, shippingPhoneNumber: "Phone is required" });
      result = false;
    } else if (data?.shippingPhoneNumber?.length !== 10) {
      setError({ ...error, shippingPhoneNumber: "Not a valid phone number" });
      result = false;
    } else if (data?.shippingPhoneNumber?.length === 10) {
      let firstLetter = data.shippingPhoneNumber.slice(0, 1);
      if (!phoneFirstLetter.some((num) => num === firstLetter)) {
        setError({ ...error, shippingPhoneNumber: "Not a valid phone number" });
        result = false;
      }
    } else if (data?.shippingState) {
      setError({ ...error, shippingState: "State is required" });
      result = false;
    } else if (data?.shippingCity) {
      setError({ ...error, shippingCity: "City is required" });
      result = false;
    } else if (data?.shippingPostalCode) {
      setError({ ...error, shippingPostalCode: "Pincode is required" });
      result = false;
    }

    return result;
  };

  const handleEditOrder = (e) => {
    e.preventDefault();

    if (validate()) {
      loader.showLoading(true);
      orderApiService
        .updateOrderById(id, JSON.stringify(data))
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            message.showToast(res.status.message, ToastTypes.SUCCESS);
            closeModal();
            refresh();
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
        });
    }
  };

  useEffect(() => {
    getOrderById();
  }, []);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <form onSubmit={handleEditOrder} className="edit_order_form">
          <h1 className="edit_order_head">Edit Order</h1>

          <WebInput
            label="Name"
            name="recipientName"
            value={data.recipientName}
            error={error.recipientName}
            onChange={handleOnChange}
          />
          <WebInput
            label="Mobile Number"
            type="number"
            name="shippingPhoneNumber"
            value={data.shippingPhoneNumber}
            error={error.shippingPhoneNumber}
            onChange={handleOnChange}
          />
          <WebInput
            label="Shipping Address1"
            name="shippingAddress1"
            value={data.shippingAddress1}
            error={error.shippingAddress1}
            onChange={handleOnChange}
          />
          <WebInput
            label="Shipping Address2"
            name="shippingAddress2"
            value={data.shippingAddress2}
            error={error.shippingAddress2}
            onChange={handleOnChange}
          />
          <WebInput
            label="Shipping Address3"
            name="shippingAddress3"
            value={data.shippingAddress3}
            error={error.shippingAddress3}
            onChange={handleOnChange}
          />

          <div className="edit_abnormal_order_scp_row">
            <WebInput
              label="State"
              name="shippingState"
              value={data.shippingState}
              error={error.shippingState}
              onChange={handleOnChange}
            />
            <WebInput
              label="City"
              name="shippingCity"
              value={data.shippingCity}
              error={error.shippingCity}
              onChange={handleOnChange}
            />
            <WebInput
              label="Pincode"
              name="shippingPostalCode"
              value={data.shippingPostalCode}
              error={error.shippingPostalCode}
              onChange={handleOnChange}
            />
          </div>

          <div className="edit_order_btn_row">
            <WebButton text="Submit" type="submit" className="modal_btn" />
          </div>
        </form>
      </Modal>
    </div>
  );
}
