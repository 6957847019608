import React, { useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import WebButton from "../../../../library/webButton";
import { exportToCSV } from "react-easy-export/dist/react-easy-export";
import { orderApiService } from "../../../../../service/admin/ordersApiService";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";
import "./style.css";
import { Link } from "react-router-dom";
const OrderDetailTable = ({ data, pages, limit }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [checkHeaderClick, setCheckHeaderClick] = useState(false);
  const navigate = useNavigate();
  const loader = useLoader();
  const message = useMessage();
  const [uploadFile, setUploadFile] = useState();

  const handleAllChecked = (e) => {
    if (e.target.checked) {
      setSelectedRows(data.map((item) => item));
      setCheckHeaderClick(true);
    } else {
      setSelectedRows([]);
      setCheckHeaderClick(false);
    }
  };
  const handleFile = (e) => {
    setUploadFile(e.target.files[0]);
  };
  const handleTracking = () => {
    if (!uploadFile) {
      message.showToast("Please choose a file to upload", ToastTypes.ERROR);
      return;
    }

    const formData = new FormData();
    formData.append("file", uploadFile);

    loader.showLoading(true);
    orderApiService
      .addExcelTracking(formData)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          message.showToast(res.status.message, ToastTypes.SUCCESS);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  const handleExportCsv = () => {
    const exportData =
      selectedRows.length > 0
        ? selectedRows.map((item, index) => ({
            "S.No.": index + 1,
            "Upsaleo Order Id": item?.upsaleoOrderId,
            Buyer: item?.recipientName,
            "Order ID": item?.orderItemId,
            "Order Date": moment(item?.orderDate).format("DD/MM/YYYY"),
            Status: item?.status,
            Price: item?.totalPrice,
            State: item?.shippingState,
            "Updated Date": moment(item?.updatedAt).format("DD/MM/YYYY"),
          }))
        : data.map((item, index) => ({
            "S.No.": index + 1,
            "Upsaleo Order Id": item?.upsaleoOrderId,
            Buyer: item?.recipientName,
            "Order ID": item?.orderItemId,
            "Order Date": moment(item?.orderDate).format("DD/MM/YYYY"),
            Status: item?.status,
            Price: item?.totalPrice,
            State: item?.shippingState,
            "Updated Date": moment(item?.updatedAt).format("DD/MM/YYYY"),
          }));

    exportToCSV(exportData, `orders_${Date.now()}`);
  };

  const handleProcessExportCsv = () => {
    orderApiService.getprocessingOrder().then((res) => {
      if (res.status.code === 200) {
        const url = res.data.url;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "products.csv");
        link.click();
      } else {
        // Handle error case
      }
    });
  };

  const handleChange = (item) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some((row) => row._id === item._id);
      if (isSelected) {
        return prevSelectedRows.filter((row) => row._id !== item._id);
      } else {
        return [...prevSelectedRows, item];
      }
    });
  };

  return (
    <div className="order-detail-table-container">
      <div className="button-container">
        <WebButton
          onClick={handleExportCsv}
          text="Export"
          className="export-button"
        />
        <WebButton
          onClick={handleProcessExportCsv}
          text="Export processed Order"
          className="export-processed-button"
        />
        <input
          type="file"
          onChange={handleFile}
          className="bg-blue-400 w-30 text-white rounded-md p-2 outline-none"
        />
        <WebButton
          text="Upload tracking"
          className="upload-button"
          onClick={handleTracking}
        />
      </div>
      <table className="order-table">
        <thead className="table-head">
          <tr>
            <th>
              <input
                type="checkbox"
                className="checkbox-size"
                onChange={handleAllChecked}
              />
            </th>
            <th>S.No.</th>
            <th>Upsaleo Order Id</th>
            <th>Seller Name</th>
            <th>Order ID</th>
            <th>Order Date</th>
            <th>Status</th>
            <th>Price</th>
            <th>State</th>
            <th>Updated Date</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const isSelected = selectedRows.some((row) => row._id === item._id);
            return (
              <tr
                key={item._id}
                className={`table-row ${
                  index % 2 === 0 ? "even-row" : "odd-row"
                }`}
              >
                <td>
                  <input
                    type="checkbox"
                    className="checkbox-size"
                    onChange={() => handleChange(item)}
                    checked={isSelected}
                  />
                </td>
                <td>{(pages.currentPage - 1) * limit + index + 1}</td>
                <td className="clickable-cell">
                  <Link to={`/admin/order-management/details/${item._id}`}>
                    {item.upsaleoOrderId}
                  </Link>
                </td>
                <td className="clickable-cell">
                  <Link to={`/admin/order-management/details/${item._id}`}>
                    {item.recipientName}
                  </Link>
                </td>
                <td className="clickable-cell">
                  <Link to={`/admin/order-management/details/${item._id}`}>
                    {item.orderId}
                  </Link>
                </td>
                <td className="clickable-cell">
                  <Link to={`/admin/order-management/details/${item._id}`}>
                    {moment(item.orderDate).format("DD/MM/YYYY , LT")}
                  </Link>
                </td>
                <td className="clickable-cell">
                  <Link to={`/admin/order-management/details/${item._id}`}>
                    {item.status}
                  </Link>
                </td>
                <td className="clickable-cell">
                  <Link to={`/admin/order-management/details/${item._id}`}>
                    ₹{item.totalPrice}
                  </Link>
                </td>
                <td>{item.shippingState}</td>
                <td className="clickable-cell">
                  <Link to={`/admin/order-management/details/${item._id}`}>
                    {moment(item.updatedAt).format("DD/MM/YYYY , LT")}
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OrderDetailTable;
