// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../assets/images/home/banner_one.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_banner_img {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    width: calc(100% - 572px);
    height: 321px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.home_banner_carousel{
    width: calc(100% - 572px);
    height: 321px;
}

.home_banner_carousel_slide img{
    width: 100%;
    height: 321px;
    /* top: 100px; */
}


.home_banner_carousel .carousel .slider-wrapper{
    height: 321px;
}



.home_banner_carousel .carousel .slider-wrapper.axis-horizontal .slider{
    height: 321px;
}

.home_banner_carousel .carousel .slider-wrapper.axis-horizontal .slider .slide{
    z-index: 0 !important;
}

.control-dots{
    z-index: 1 !important;
}


@media (max-width:768px) {
    .home_banner_carousel{
        width: 100%;
        height: auto;
    }

    .home_banner_img, .home_banner_carousel_slide img, .home_banner_carousel .carousel .slider-wrapper ,.home_banner_carousel .carousel .slider-wrapper.axis-horizontal .slider{
        height: auto;
    }

}`, "",{"version":3,"sources":["webpack://./src/website/component/views/home/Banner/bannerCarousel/style.css"],"names":[],"mappings":"AAAA;IACI,yDAAyE;IACzE,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;AACpB;;;AAGA;IACI,aAAa;AACjB;;;;AAIA;IACI,aAAa;AACjB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;;AAGA;IACI;QACI,WAAW;QACX,YAAY;IAChB;;IAEA;QACI,YAAY;IAChB;;AAEJ","sourcesContent":[".home_banner_img {\n    background-image: url('../../../../../assets/images/home/banner_one.jpg');\n    width: calc(100% - 572px);\n    height: 321px;\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n}\n\n.home_banner_carousel{\n    width: calc(100% - 572px);\n    height: 321px;\n}\n\n.home_banner_carousel_slide img{\n    width: 100%;\n    height: 321px;\n    /* top: 100px; */\n}\n\n\n.home_banner_carousel .carousel .slider-wrapper{\n    height: 321px;\n}\n\n\n\n.home_banner_carousel .carousel .slider-wrapper.axis-horizontal .slider{\n    height: 321px;\n}\n\n.home_banner_carousel .carousel .slider-wrapper.axis-horizontal .slider .slide{\n    z-index: 0 !important;\n}\n\n.control-dots{\n    z-index: 1 !important;\n}\n\n\n@media (max-width:768px) {\n    .home_banner_carousel{\n        width: 100%;\n        height: auto;\n    }\n\n    .home_banner_img, .home_banner_carousel_slide img, .home_banner_carousel .carousel .slider-wrapper ,.home_banner_carousel .carousel .slider-wrapper.axis-horizontal .slider{\n        height: auto;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
