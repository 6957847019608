import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import WebInput from "../../../../library/webInput";
import { useSelector } from "react-redux";
import { selectIsNightMode } from "../../../../../redux/slices/modeSlice";
import { AuthApiService } from "../../../../../service/authApiService";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";
import { ImMobile } from "react-icons/im";
import Modal from "react-modal";
import WebButton from "../../../../library/webButton";
import { phoneFirstLetter } from "../../../../../helper/regex";
import { IoAlertCircle } from "react-icons/io5";
import WebOtpInput from "../../../../library/webOtpInput";
import { AiFillLock } from "react-icons/ai";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { clearAuthLocalStorage } from "../../../../../storage/localStorage";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function PasswordInput() {
  const isNightMode = useSelector(selectIsNightMode);
  const loader = useLoader();
  const navigate = useNavigate();
  const message = useMessage();

  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const [data, setData] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const [error, setError] = useState({
    oldPassword: "",
    newPassword: "",
  });

  const handleOnchange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const validate = () => {
    let result = true;
    if (!data.oldPassword) {
      setError({ ...error, oldPassword: "Password is required" });
      result = false;
    } else if (!data.newPassword) {
      setError({ ...error, newPassword: "Password is required" });
      result = false;
    }
    return result;
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    if (validate()) {
      loader.showLoading(true);
      AuthApiService.changePassword(JSON.stringify(data))
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            message.showToast(res.status.message, ToastTypes.SUCCESS);
            clearAuthLocalStorage();
            navigate("/login");
            closeModal();
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    }
  };

  return (
    <>
      <div
        className={`user_profile_input_row ${
          isNightMode
            ? "user_profile_input_row_dark"
            : "user_profile_input_row_light"
        }`}
      >
        <AiFillLock
          className={`user_profile_icon ${
            isNightMode ? "user_profile_icon_dark" : "user_profile_icon_light"
          }`}
        />

        <WebInput
          parentClassName="input_parent"
          className="input_transparent_hide"
          type="password"
          placeholder="Passord"
          id="phoneInput"
          error=""
          onChange={null}
          value="00000000"
          name="phone"
        />
        <MdEdit
          className={`user_profile_icon edit_icon ${
            isNightMode ? "user_profile_icon_dark" : "user_profile_icon_light"
          }`}
          onClick={() => setIsOpen(true)}
        />
      </div>

      {modalIsOpen ? (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Phone Modal"
        >
          <form onSubmit={handleChangePassword} className="phone_modal_form">
            <div className="edit_phone_head">Edit Password</div>

            <div className="password_modal_box">
              <WebInput
                parentClassName="input_parent"
                className="input_transparent"
                type="password"
                placeholder="Old Password"
                id="oldPasswordInput"
                value={data.oldPassword}
                onChange={handleOnchange}
                name="oldPassword"
              />
            </div>

            {error.oldPassword ? (
              <p className="profile_error">
                <IoAlertCircle
                  color="#ED1302"
                  size={18}
                  className="profile_error_icon"
                />
                {error.oldPassword}
              </p>
            ) : null}

            <div className="password_modal_box">
              <WebInput
                parentClassName="input_parent"
                className="input_transparent"
                type="password"
                placeholder="New Password"
                id="newPasswordInput"
                value={data.newPassword}
                onChange={handleOnchange}
                name="newPassword"
              />
            </div>

            {error.newPassword ? (
              <p className="profile_error">
                <IoAlertCircle
                  color="#ED1302"
                  size={18}
                  className="profile_error_icon"
                />
                {error.newPassword}
              </p>
            ) : null}

            <WebButton className="modal_btn" text="Update" type="submit" />
          </form>
        </Modal>
      ) : null}
    </>
  );
}
