import { getAuthLocalStorage } from "../storage/localStorage";

/**
 * @author Diksha Lalit
 * @since 08-04-2024
 * @param url API request url.
 * @param method request method.
 * @param body request body.
 * @param contentType request contentType headers.
 * @description network request manager.
 */

export const callAPI = async (url, method, requestHeaders, body) => {
  let loginHeader = await getAuthLocalStorage().loginHeader;

  if (loginHeader && loginHeader.length > 0) {
    requestHeaders["Authorization"] = `Bearer ${loginHeader}`;
  }

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: method,
      headers: requestHeaders,
      body: body,
    })
      .then((response) => {
        resolve(response.json());
      })
      .catch((error) => {
        reject(error);
      });
  });
};
