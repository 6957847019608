// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
    --sans-serif:"Inter",
        sans-serif;
    --sans-serif-light: 300;
    --sans-serif-regular: 400;
    --sans-serif-medium: 500;
    --sans-serif-semi-bold: 600;
    --sans-serif-bold: 700;

    --sans-serif-size-extra-small: 0.8rem;
    --sans-serif-size-small: 0.9rem;
    --sans-serif-size-regular: 1rem;
    --sans-serif-size-medium: 1.2rem;
    --sans-serif-size-large: 1.5rem;
    --sans-serif-size-extra-large: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/website/assets/fonts/index.css"],"names":[],"mappings":"AAEA;IACI;kBACc;IACd,uBAAuB;IACvB,yBAAyB;IACzB,wBAAwB;IACxB,2BAA2B;IAC3B,sBAAsB;;IAEtB,qCAAqC;IACrC,+BAA+B;IAC/B,+BAA+B;IAC/B,gCAAgC;IAChC,+BAA+B;IAC/B,mCAAmC;AACvC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');\n\n:root{\n    --sans-serif:\"Inter\",\n        sans-serif;\n    --sans-serif-light: 300;\n    --sans-serif-regular: 400;\n    --sans-serif-medium: 500;\n    --sans-serif-semi-bold: 600;\n    --sans-serif-bold: 700;\n\n    --sans-serif-size-extra-small: 0.8rem;\n    --sans-serif-size-small: 0.9rem;\n    --sans-serif-size-regular: 1rem;\n    --sans-serif-size-medium: 1.2rem;\n    --sans-serif-size-large: 1.5rem;\n    --sans-serif-size-extra-large: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
