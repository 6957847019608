// RefundDetails.js
import "./RefundDetails.css"; // Import CSS file for styling
import React, { useEffect, useState } from "react";
import { useLoader } from "../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";
import { orderApiService } from "../../../../service/admin/ordersApiService";
import { useNavigate, useParams } from "react-router-dom";

const RefundDetails = () => {
  const [query, setQuery] = useState({});
  const [data, setData] = useState({});
  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();
  const orderId = useParams();
  useEffect(() => {
    if (orderId) {
      setQuery((prevQuery) => ({ ...prevQuery, orderId }));
      fetchAllOrders();
    }
  }, [orderId]);
  const fetchAllOrders = () => {
    loader.showLoading(true);
    orderApiService
      .getRefundedByOrderId(orderId.id)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setData(res.data);
        } else if (res.status.code === 404) {
          setData([]);
        } else if (res.status.code === 402) {
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  if (!data) {
    return <p>No refund data available</p>; // Handle case where no data is passed
  }

  return (
    <div className="refund-details container">
      <header className="header">
        <h1>Admin Portal - Refund Details</h1>
      </header>
      <div className="main-content">
        <div className="detail-row">
          <div className="detail-label">Order ID:</div>
          <div className="detail-value">{data.orderId}</div>
        </div>
        <div className="detail-row">
          <div className="detail-label">Upsaleo Order ID:</div>
          <div className="detail-value">{data.upsaleoOrderId}</div>
        </div>
        <div className="detail-row">
          <div className="detail-label">Seller ID:</div>
          <div className="detail-value">{data.sellerId}</div>
        </div>
        <div className="detail-row">
          <div className="detail-label">Refunded By:</div>
          <div className="detail-value">{data.refundedBy}</div>
        </div>
        <div className="detail-row">
          <div className="detail-label">Amount:</div>
          <div className="detail-value">${data.amount}</div>
        </div>
        <div className="detail-row">
          <div className="detail-label">Reason:</div>
          <div className="detail-value">{data.reason}</div>
        </div>
        <div className="detail-row">
          <div className="detail-label">Refund Status:</div>
          <div className="detail-value">{data.refundStatus}</div>
        </div>
        {/* <div className="detail-row">
        <div className="detail-label">Images:</div>
        <div className="refund-images">
          {data.images.map((imageUrl, index) => (
            <img key={index} src={imageUrl} alt={`Image ${index}`} />
          ))}
        </div>
      </div> */}
      </div>
    </div>
  );
};

export default RefundDetails;
