import React, { useEffect, useState } from "react";
import WebButton from "../../../../component/library/webButton";
import { userApiService } from "../../../../service/admin/userApiService";
import { useLoader } from "../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";
import { useNavigate, useParams } from "react-router-dom";

const EditUser = () => {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    role: "",
    email: "",
    companyName: "",
    gst: "",
    phone: "",
    country: "",
    state: "",
    address: "",
    zipcode: "",
    language: "",
    pincode: "",
    address1: "",
    address2: "",
    city: "",
    bankAccountNumber: "",
    bankIfscCode: "",
    bankAddress: "",
    bankAccountHolderName: "",
    BusinessName: "",
    BusinessDescription: "",
    tan: "",
    GstinNumber: "",
    registerBusinessAddress: "",
    settings: {
      amazonSpPercent: 0,
      amazonMrpPercent: 0,
      shopifySpPercent: 0,
      shopifyMrpPercent: 0,
      inventorySync: {
        amazon: false,
        shopify: false,
      },
      priceSync: {
        amazon: false,
        shopify: false,
      },
      brandName: "",
      brandImage: "",
      companyName: "",
    },
  });

  const navigate = useNavigate();
  const loader = useLoader();
  const message = useMessage();
  const params = useParams();

  useEffect(() => {
    getUserById();
  }, []);

  const getUserById = () => {
    loader.showLoading(true);
    userApiService
      .getUserByID(params.id)
      .then((res) => {
        if (res.status.code === 200) {
          loader.showLoading(false);
          setUserData(res.data);
        } else if (res.status.code === 402) {
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEdit = (e) => {
    e.preventDefault();
    loader.showLoading(true);
    userApiService
      .updateUser(userData, params.id)
      .then((res) => {
        if (res.status.code === 200) {
          loader.showLoading(false);

          navigate("/admin/userManagement");
        } else if (res.status.code === 402) {
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const inputStyle = "w-80 h-10 border border-gray-400 px-2 mb-2";
  const labelStyle = "w-40 text-right pr-2";

  return (
    <div className="container mx-auto p-6">
      <header className="header">
        <h1>Admin Portal - Edit User Information</h1>
      </header>
      <div className="main-content">
        <form onSubmit={handleEdit} className="flex flex-wrap">
          <div className="w-full md:w-1/2 p-2">
            <div className="flex items-center mb-2">
              <label className={labelStyle}>First Name</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.firstName}
                name="firstName"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Last Name</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.lastName}
                name="lastName"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Gender</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.gender}
                name="gender"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Role</label>
              <select
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.role}
                name="role"
              >
                <option value="">--Select Role--</option>
                <option value="seller">Seller</option>
                <option value="admin">Admin</option>
                <option value="merchant">Merchant</option>
              </select>
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Email</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.email}
                name="email"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Company Name</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.companyName}
                name="companyName"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>GSTIN</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.gst}
                name="gst"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Phone</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.phone}
                name="phone"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Pincode</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.pincode}
                name="pincode"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Address 1</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.address1}
                name="address1"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Address 2</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.address2}
                name="address2"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>City</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.city}
                name="city"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>State</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.state}
                name="state"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Zipcode</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.zipcode}
                name="zipcode"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Language</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.language}
                name="language"
              />
            </div>
          </div>

          <div className="w-full md:w-1/2 p-2">
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Bank Account Number</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.bankAccountNumber}
                name="bankAccountNumber"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Bank IFSC Code</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.bankIfscCode}
                name="bankIfscCode"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Bank Address</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.bankAddress}
                name="bankAddress"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Bank Account Holder Name</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.bankAccountHolderName}
                name="bankAccountHolderName"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Business Name</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.BusinessName}
                name="BusinessName"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Business Description</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.BusinessDescription}
                name="BusinessDescription"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>TAN</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.tan}
                name="tan"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>GSTIN Number</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.GstinNumber}
                name="GstinNumber"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Registered Business Address</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.registerBusinessAddress}
                name="registerBusinessAddress"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Amazon SP %</label>
              <input
                className={inputStyle}
                type="number"
                onChange={handleOnChange}
                value={userData.settings.amazonSpPercent}
                name="settings.amazonSpPercent"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Amazon MRP %</label>
              <input
                className={inputStyle}
                type="number"
                onChange={handleOnChange}
                value={userData.settings.amazonMrpPercent}
                name="settings.amazonMrpPercent"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Shopify SP %</label>
              <input
                className={inputStyle}
                type="number"
                onChange={handleOnChange}
                value={userData.settings.shopifySpPercent}
                name="settings.shopifySpPercent"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Shopify MRP %</label>
              <input
                className={inputStyle}
                type="number"
                onChange={handleOnChange}
                value={userData.settings.shopifyMrpPercent}
                name="settings.shopifyMrpPercent"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Amazon Inventory Sync</label>
              <input
                type="checkbox"
                onChange={handleOnChange}
                checked={userData.settings.inventorySync.amazon}
                name="settings.inventorySync.amazon"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Shopify Inventory Sync</label>
              <input
                type="checkbox"
                onChange={handleOnChange}
                checked={userData.settings.inventorySync.shopify}
                name="settings.inventorySync.shopify"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Amazon Price Sync</label>
              <input
                type="checkbox"
                onChange={handleOnChange}
                checked={userData.settings.priceSync.amazon}
                name="settings.priceSync.amazon"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Shopify Price Sync</label>
              <input
                type="checkbox"
                onChange={handleOnChange}
                checked={userData.settings.priceSync.shopify}
                name="settings.priceSync.shopify"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Brand Name</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.settings.brandName}
                name="settings.brandName"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Brand Image</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.settings.brandImage}
                name="settings.brandImage"
              />
            </div>
            <div className="flex items-center mb-2">
              <label className={labelStyle}>Company Name</label>
              <input
                className={inputStyle}
                onChange={handleOnChange}
                value={userData.settings.companyName}
                name="settings.companyName"
              />
            </div>
          </div>
          <div className="w-full p-2 text-center">
            <WebButton
              type="submit"
              text="Update"
              className="px-6 py-2 bg-blue-500 text-white rounded-md"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUser;
