import { AiOutlineSearch } from "react-icons/ai";
import { GrFormNext } from "react-icons/gr";
import { IoCloseOutline } from "react-icons/io5";
import { LuMenu } from "react-icons/lu";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormDown } from "react-icons/gr";
import { AiOutlineMail } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { MdOutlineAddIcCall } from "react-icons/md";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { CgShoppingCart } from "react-icons/cg";
import { CgHeart } from "react-icons/cg";
import { MdKeyboardBackspace } from "react-icons/md";
import { GrPrevious } from "react-icons/gr";
import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";


export const searchIcon = <AiOutlineSearch />;
export const nextVIcon = <GrFormNext />;
export const prevVIcon = <GrFormPrevious />;
export const downVIcon = <GrFormDown />;
export const closeIcon = <IoCloseOutline />;
export const menuIcon = <LuMenu />;
export const mailIcon = <AiOutlineMail />;
export const locationIcon = <GrLocation />;
export const mobileIcon = <MdOutlineAddIcCall />;
export const linkedinIcon = <FaLinkedinIn />;
export const facebookIcon = <FaFacebookF />;
export const twitterIcon = <FaTwitter />;
export const instagramIcon = <FaInstagram />;
export const youtubeIcon = <FaYoutube />;
export const whatsappIcon = <FaWhatsapp />;
export const subscribeIcon = <FaHeart />;
export const cartIcon = <CgShoppingCart />;
export const profileIcon = <CgProfile />;
export const heartIcon = <CgHeart />;
export const backIcon = <GrPrevious />;
export const plusIcon=<FiPlus/>
export const minusIcon=<FiMinus/>


