import React from "react";
import Modal from "react-modal";
import WebButton from "../webButton";
import "./style.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth:'80%'
  },
};

export default function AlertPopup({
  message,
  modalIsOpen,
  setIsOpen,
  success,
  successText,
  cancel,
  cancelText,
}) {
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="alert_popup_msg">{message}</div>

      <div className="alert_popup_btn_row">
        <WebButton className="cancel_btn" text={cancelText} onClick={cancel} />

        <WebButton
          className="success_btn"
          text={successText}
          onClick={success}
        />
      </div>
    </Modal>
  );
}
