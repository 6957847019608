// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.refund-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.refund-form__group {
  margin-bottom: 15px;
  width: 100%;
}

.refund-form label {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.refund-form input[type="number"],
.refund-form input[type="file"],
.refund-form textarea,
.refund-form select {
  margin-left: 10px;
  flex-grow: 1;
}

.refund-form input[type="radio"] {
  margin-right: 5px;
}

.refund-form__button {
  align-self: center;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.refund-form__button:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/views/admin/ticketManagement/RefundForm/RefundForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,8BAA8B;EAC9B,WAAW;AACb;;AAEA;;;;EAIE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".refund-form {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  max-width: 400px;\n  margin: 0 auto;\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 8px;\n  background-color: #f9f9f9;\n}\n\n.refund-form__group {\n  margin-bottom: 15px;\n  width: 100%;\n}\n\n.refund-form label {\n  font-weight: bold;\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.refund-form input[type=\"number\"],\n.refund-form input[type=\"file\"],\n.refund-form textarea,\n.refund-form select {\n  margin-left: 10px;\n  flex-grow: 1;\n}\n\n.refund-form input[type=\"radio\"] {\n  margin-right: 5px;\n}\n\n.refund-form__button {\n  align-self: center;\n  padding: 10px 20px;\n  font-size: 16px;\n  color: #fff;\n  background-color: #007bff;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\n.refund-form__button:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
