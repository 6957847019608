import React, { useState } from "react";
import "./RefundForm.css"; // Import the CSS file
import { orderApiService } from "../../../../service/admin/ordersApiService";
import { useLoader } from "../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";
import { useNavigate } from "react-router-dom";

const RefundForm = ({ orderId }) => {
  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();
  const [inputType, setInputType] = useState("amount"); // 'amount' or 'percentage'
  const [amount, setAmount] = useState("");
  const [percentage, setPercentage] = useState("");
  const [reason, setReason] = useState("");
  const [refundStatus, setRefundStatus] = useState("");
  const [images, setImages] = useState([]);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      (inputType === "amount" && !amount) ||
      (inputType === "percentage" && !percentage)
    ) {
      alert("Please fill either amount or percentage.");
      return;
    }

    const formData = new FormData();
    if (inputType === "amount") {
      formData.append("refundedAmont", amount);
    } else {
      formData.append("refundPercent", percentage);
    }
    formData.append("reason", reason);
    formData.append("refundStatus", refundStatus);
    images.forEach((image, index) => {
      formData.append(`images`, image);
    });

    // const payload = {
    //   refundAmount: formData.amount,
    //   reason: formData.reason,
    //   id: formData.orderId,
    //   refundPercentage: formData.percentage,
    //   images: formData.images,
    // };
    loader.showLoading(true);
    orderApiService
      .addRefund(formData, orderId)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          navigate("/admin/ticketManagement");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  return (
    <form className="refund-form" onSubmit={handleSubmit}>
      <div className="refund-form__group">
        <label>
          <input
            type="radio"
            value="amount"
            checked={inputType === "amount"}
            onChange={() => setInputType("amount")}
          />
          Amount
        </label>
        <label>
          <input
            type="radio"
            value="percentage"
            checked={inputType === "percentage"}
            onChange={() => setInputType("percentage")}
          />
          Percentage
        </label>
      </div>
      {inputType === "amount" && (
        <div className="refund-form__group">
          <label>
            Amount:
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </label>
        </div>
      )}
      {inputType === "percentage" && (
        <div className="refund-form__group">
          <label>
            Percentage:
            <input
              type="number"
              value={percentage}
              onChange={(e) => setPercentage(e.target.value)}
              required
            />
          </label>
        </div>
      )}
      <div className="refund-form__group">
        <label>
          Reason:
          <textarea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
          />
        </label>
      </div>
      <div className="refund-form__group">
        <label>
          Refund Status:
          <select
            value={refundStatus}
            onChange={(e) => setRefundStatus(e.target.value)}
            required
          >
            <option value="" disabled>
              Select status
            </option>
            <option value="partially-refund">Partially Refund</option>
            <option value="full-refund">Full Refund</option>
          </select>
        </label>
      </div>
      <div className="refund-form__group">
        <label>
          Images:
          <input
            type="file"
            multiple
            onChange={handleImageUpload}
            accept="image/*"
          />
        </label>
      </div>
      <button className="refund-form__button" type="submit">
        Submit
      </button>
    </form>
  );
};

export default RefundForm;
