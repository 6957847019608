import React from "react";
import { useNavigate } from "react-router-dom";

export default function ProductOrderTable({ data }) {

  const navigate = useNavigate();

  const handleProduct = (id) => {
    navigate(`/product-details?id=${id}`);
  };

  return (
    <table className="seller_table">
      <thead>
        <tr>
          <td>Image</td>
          <td>Name</td>
          <td>SKU</td>
          <td>Tracking No.</td>
          <td>Shipping Method</td>
          <td>Consumer Price</td>
          <td>Order Item Id</td>
          <td>Order Quantity</td>
          <td>Upsaleo Price</td>
          <td>Upsaleo Quantity</td>
        </tr>
      </thead>

      <tbody>
        {data?.orderDetails?.map((product, index) => {
          return (
            <tr className="odd:bg-white even:bg-slate-100">
              <td onClick={() => handleProduct(product.product?._id)}>
                <img
                  src={product.product?.imgUrl[0]}
                  className="w-10 h-10 cursor-pointer"
                />
              </td>

              <td onClick={() => handleProduct(product.product?._id)}>
                <div className="truncate w-20 cursor-pointer table_overflow_text">
                  {product.product?.title}
                </div>
              </td>

              <td
                onClick={() => handleProduct(product.product?._id)}
                className="cursor-pointer"
              >
                {product.product?.sku}
              </td>

              <td
                onClick={() => handleProduct(product.product?._id)}
                className="cursor-pointer"
              >
                {product?.trackingId}
              </td>
              <td
                onClick={() => handleProduct(product.product?._id)}
                className="cursor-pointer"
              >
                {product?.shippingMethod}
              </td>
              <td
                onClick={() => handleProduct(product.product?._id)}
                className="cursor-pointer"
              >
                ₹{product?.consumerPrice}
              </td>

              <td
                onClick={() => handleProduct(product.product?._id)}
                className="cursor-pointer"
              >
                {product?.orderItemId}
              </td>

              <td>{product?.quantity}</td>
              <td>₹{product?.product?.sp}</td>
              <td>{product?.product?.quantity}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
