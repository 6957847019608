import React, { useEffect, useState } from "react";
import WebButton from "../../../component/library/webButton";
import { userApiService } from "../../../service/admin/userApiService";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import Pagination from "../../../component/library/pagination";
import moment from "moment";
import WebDropdown from "../../../component/library/webDropdown";
import { useNavigate } from "react-router-dom";
import { exportToCSV } from "react-easy-export/dist/react-easy-export";
import { TiTick } from "react-icons/ti";
import "./style.css";

const UserStatistics = () => {
  const [userStats, setUserStats] = useState([]);
  const [searchData, setSearchData] = useState({
    search: "",
    startDate: "",
    endDate: "",
  });
  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [checkHeaderClick, setCheckHeaderClick] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const [pages, setPages] = useState({
    currentPage: "",
    totalPages: "",
    totalItems: "",
  });

  useEffect(() => {
    fetchAllStatistics();
  }, [page, limit]);

  useEffect(() => {
    sortData();
  }, [sortField, sortOrder]);

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  const options = [
    { name: "--Please Select--", value: "" },
    { name: "Potential", value: "potential" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" },
  ];

  const query = `?search=${searchData?.search}&role=seller&startDate=${searchData?.startDate}&endDate=${searchData?.endDate}&page=${page}&limit=${limit}`;

  const fetchAllStatistics = () => {
    loader.showLoading(true);
    userApiService
      .getUserList(query ? query : "")
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          const usersWithStatus = res.data.users.map((user) => ({
            ...user,
            status: user.status || "",
          }));
          setUserStats(usersWithStatus);
          setPages({
            currentPage: res.data.currentPage,
            totalPages: res.data.totalPages,
            totalItems: res.data.totalUsers,
          });
          setPage(res.data.currentPage);
        } else if (res.status.code === 402) {
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleOnChange = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  };

  const handleSearch = () => {
    fetchAllStatistics();
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const handleDropdown = (index, value) => {
    setUserStats((prevUserStats) => {
      const updatedUserStats = [...prevUserStats];
      updatedUserStats[index].userType = value;
      return updatedUserStats;
    });
    const payload = {
      userType: value,
    };
    loader.showLoading(true);
    userApiService
      .updateUser(JSON.stringify(payload), userStats[index]._id)
      .then((res) => {
        loader.showLoading(false);
        console.log("User type updated successfully:", res);
      })
      .catch((err) => {
        loader.showLoading(false);
        console.error("Error updating user type:", err);
      });
  };

  const handleChange = (item) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some((row) => row._id === item._id);
      if (isSelected) {
        return prevSelectedRows.filter((row) => row._id !== item._id);
      } else {
        return [...prevSelectedRows, item];
      }
    });
  };

  const handleAllChecked = (e) => {
    if (e.target.checked) {
      const allSelectedRows = userStats.map((item) => item);
      setSelectedRows(allSelectedRows);
      setCheckHeaderClick(true);
    } else {
      setSelectedRows([]);
      setCheckHeaderClick(false);
    }
  };

  const handleExportCsv = () => {
    const exportData =
      selectedRows.length > 0
        ? selectedRows.map((item) => ({
            "User Name": item.firstName + item?.lastName,
            "Email ID": item?.email,
            "Registered Date": moment(item?.createdAt).format("DD/MM/YYYY"),
            "Total User Days": item?.userDays,
            "Total No. of Orders": item?.totalOrders,
            "Processed Orders": item?.processedOrders,
            "Wallet Balance": item?.walletBalance,
            "Deposit Balance": item?.depositBalance,
            "Refund Balance": item?.refundBalance,
            "Shopify Api Connect": item?.shopifyApiConnect,
            "Amazon Api Connect": item?.amazonApiConnect,
            "No. of products subscribed": item?.productSubscribe.length,
            "Login Counts per week": item?.weeklyLoginCount,
            "Login Counts": item?.totalLoginCount,
          }))
        : userStats.map((item) => ({
            "User Name": item.firstName + item?.lastName,
            "Email ID": item?.email,
            "Registered Date": moment(item?.createdAt).format("DD/MM/YYYY"),
            "Total User Days": item?.userDays,
            "Total No. of Orders": item?.totalOrders,
            "Processed Orders": item?.processedOrders,
            "Wallet Balance": item?.walletBalance,
            "Deposit Balance": item?.depositBalance,
            "Refund Balance": item?.refundBalance,
            "Shopify Api Connect": item?.shopifyApiConnect,
            "Amazon Api Connect": item?.amazonApiConnect,
            "No. of products subscribed": item?.productSubscribe.length,
            "Login Counts per week": item?.weeklyLoginCount,
            "Login Counts": item?.totalLoginCount,
          }));

    exportToCSV(exportData, `user-statics ${Date.now()}`);
  };

  const handleUserType = (id, userType) => {
    const payload = {
      userType: userType,
    };
    userApiService
      .updateUser(JSON.stringify(payload), id)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sortData = () => {
    if (sortField) {
      const sortedData = [...userStats].sort((a, b) => {
        if (sortOrder === "asc") {
          return a[sortField] > b[sortField] ? 1 : -1;
        } else {
          return a[sortField] < b[sortField] ? 1 : -1;
        }
      });
      setUserStats(sortedData);
    }
  };

  return (
    <div className="container">
      <header className="header">
        <h1>Admin Portal - User statistics List</h1>
      </header>
      <div className="flex gap-10 main-content">
        <div>
          <label className="block mb-1">Log ID</label>
          <input
            className="w-40 h-8 border border-gray-400"
            onChange={handleOnChange}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                handleSearch();
              }
            }}
            value={searchData.search}
            name="search"
          />
        </div>
        <div>
          <label className="block mb-1">Registered Date From</label>
          <input
            type="date"
            className="w-40 h-8 border border-gray-400"
            onChange={handleOnChange}
            value={searchData.startDate}
            name="startDate"
          />
        </div>
        <div>
          <label className="block mb-1">To</label>
          <input
            type="date"
            onChange={handleOnChange}
            value={searchData.endDate}
            name="endDate"
            className="w-40 h-8 border border-gray-400"
          />
        </div>
      </div>
      <div className="flex justify-between gap-4 mt-4">
        <div className="flex gap-4">
          <WebButton
            onClick={handleSearch}
            text="Search"
            className="px-2 py-1 rounded-md bg-gray-300"
          />
          <WebButton
            onClick={handleExportCsv}
            text="Export as CSV"
            className="px-2 py-1 rounded-md bg-gray-300"
          />
        </div>
        <div className="flex items-center">
          <label className="mr-2">Show</label>
          <select
            id="limit"
            onChange={handleLimitChange}
            value={limit}
            className="w-20 h-8 border border-gray-400"
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>

      <div className="mt-4 overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-2 border border-gray-300">
                <input
                  type="checkbox"
                  checked={checkHeaderClick}
                  onChange={handleAllChecked}
                />
              </th>
              <th className="p-2 border border-gray-300">
                <button onClick={() => handleSort("firstName")}>
                  User Name
                  {sortField === "firstName" &&
                    (sortOrder === "asc" ? " ▲" : " ▼")}
                </button>
              </th>
              <th className="p-2 border border-gray-300">
                <button onClick={() => handleSort("email")}>
                  Email ID
                  {sortField === "email" && (sortOrder === "asc" ? " ▲" : " ▼")}
                </button>
              </th>
              <th className="p-2 border border-gray-300">
                <button onClick={() => handleSort("createdAt")}>
                  Registered Date
                  {sortField === "createdAt" &&
                    (sortOrder === "asc" ? " ▲" : " ▼")}
                </button>
              </th>
              <th className="p-2 border border-gray-300">
                <button onClick={() => handleSort("userDays")}>
                  Total Days
                  {sortField === "userDays" &&
                    (sortOrder === "asc" ? " ▲" : " ▼")}
                </button>
              </th>
              <th className="p-2 border border-gray-300">
                <button onClick={() => handleSort("totalOrders")}>
                  Total No. of Orders
                  {sortField === "totalOrders" &&
                    (sortOrder === "asc" ? " ▲" : " ▼")}
                </button>
              </th>
              <th className="p-2 border border-gray-300">
                <button onClick={() => handleSort("processedOrders")}>
                  Processed Orders
                  {sortField === "processedOrders" &&
                    (sortOrder === "asc" ? " ▲" : " ▼")}
                </button>
              </th>
              <th className="p-2 border border-gray-300">
                <button onClick={() => handleSort("walletBalance")}>
                  Wallet Balance
                  {sortField === "walletBalance" &&
                    (sortOrder === "asc" ? " ▲" : " ▼")}
                </button>
              </th>
              <th className="p-2 border border-gray-300">
                <button onClick={() => handleSort("depositBalance")}>
                  Deposit Balance
                  {sortField === "depositBalance" &&
                    (sortOrder === "asc" ? " ▲" : " ▼")}
                </button>
              </th>
              <th className="p-2 border border-gray-300">
                <button onClick={() => handleSort("refundBalance")}>
                  Refund Balance
                  {sortField === "refundBalance" &&
                    (sortOrder === "asc" ? " ▲" : " ▼")}
                </button>
              </th>
              <th className="p-2 border border-gray-300">
                Shopify Api Connect
              </th>
              <th className="p-2 border border-gray-300">Amazon Api Connect</th>
              <th className="p-2 border border-gray-300">
                No. of products subscribed
              </th>
              <th className="p-2 border border-gray-300">
                <button onClick={() => handleSort("totalLoginCount")}>
                  Login Counts
                  {sortField === "totalLoginCount" &&
                    (sortOrder === "asc" ? " ▲" : " ▼")}
                </button>
              </th>
              <th className="p-2 border border-gray-300">
                <button onClick={() => handleSort("weeklyLoginCount")}>
                  Login Counts per week
                  {sortField === "weeklyLoginCount" &&
                    (sortOrder === "asc" ? " ▲" : " ▼")}
                </button>
              </th>
              <th className="p-2 border border-gray-300">User Type</th>
            </tr>
          </thead>
          <tbody>
            {userStats.length > 0 ? (
              userStats.map((item, index) => (
                <tr key={item._id} className="even:bg-gray-100">
                  <td className="p-2 border border-gray-300 text-center">
                    <input
                      type="checkbox"
                      onChange={() => handleChange(item)}
                      checked={selectedRows.some((row) => row._id === item._id)}
                    />
                  </td>
                  <td className="p-2 border border-gray-300 text-center">
                    {item.firstName} {item.lastName}
                  </td>
                  <td className="p-2 border border-gray-300 text-center">
                    {item.email}
                  </td>
                  <td className="p-2 border border-gray-300 text-center">
                    {moment(item.createdAt).format("DD/MM/YYYY")}
                  </td>
                  <td className="p-2 border border-gray-300 text-center">
                    {item.joiningDays}
                  </td>
                  <td className="p-2 border border-gray-300 text-center">
                    {item.totalOrders}
                  </td>
                  <td className="p-2 border border-gray-300 text-center">
                    {item.processOrders}
                  </td>
                  <td className="p-2 border border-gray-300 text-center">
                    {item.walletBalance}
                  </td>
                  <td className="p-2 border border-gray-300 text-center">
                    {item.depositBalance}
                  </td>
                  <td className="p-2 border border-gray-300 text-center">
                    {item.refundBalance}
                  </td>
                  <td className="p-2 border border-gray-300 text-center">
                    {item.shopifyApiConnect ? <TiTick /> : "No"}
                  </td>
                  <td className="p-2 border border-gray-300 text-center">
                    {item.amazonApiConnect ? <TiTick /> : "No"}
                  </td>
                  <td className="p-2 border border-gray-300 text-center">
                    {item.productSubscribe.length}
                  </td>
                  <td className="p-2 border border-gray-300 text-center">
                    {item.totalLoginCount}
                  </td>
                  <td className="p-2 border border-gray-300 text-center">
                    {item.weeklyLoginCount}
                  </td>
                  <td className="p-2 border border-gray-300 text-center">
                    <WebDropdown
                      className="dropdownUserStatics"
                      options={options}
                      name="userType"
                      selected={item.userType || ""}
                      onChange={(e) => handleDropdown(index, e.target.value)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="16"
                  className="p-2 border border-gray-300 text-center"
                >
                  No user statistics found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <Pagination pages={pages} page={page} setPage={setPage} />
      </div>
    </div>
  );
};

export default UserStatistics;
