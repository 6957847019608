import React, { useEffect, useState } from "react";
import { nextVIcon } from "../../../assets/icons";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { queryToUrl, urlToQuery } from "../../../helper/utils";
import "./style.css";

export default function BreadCrumbs({ product }) {
  const [pages, setPages] = useState();
  const [searchParams, SetSearchParams] = useSearchParams();
  const [path, setPath] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const arr = product?.c_name?.split(" > ");

    if (searchParams.get("category")) {
      setPages(
        urlToQuery(searchParams.get("category"))
          .split("/")
          .filter((item) => item !== "")
      );
    } else if (arr?.length) {
      setPages(arr.filter((item) => item !== ""));
    } else {
      setPages([]);
    }

    setPath(location.pathname.split("/").filter((val) => val !== ""));

    if (path.some((val) => val === "product-details")) {
      path[0] = "collection";

      setPath(path);
    }
  }, [location, product]);

  const selectPage = (item) => {
    const activeIndex = pages.findIndex((val) => val === item);

    const sliceArray = pages.slice(0, activeIndex + 1);

    return `/collection/?category=${queryToUrl(sliceArray.join("/"))}`;
  };

  const pagePath = (link, index) => {
    return `/${path.slice(0, index + 1).join("/")}`;
  };

  return (
    <div>
      {searchParams.get("search") ? (
        <div className="breadcrumbs_row">
          <Link to={`/collection?search=${searchParams.get("search")}`}>
            <p>results for "{searchParams.get("search")}"</p>
          </Link>
        </div>
      ) : (
        <div className="breadcrumbs_row">
          <Link to={"/"}>
            <p>Home</p>
          </Link>
          {nextVIcon}
          {path?.map((page, index) => {
            return (
              <>
                <Link to={pagePath(page, index)} key={index}>
                  <p>{page.replaceAll("-", " ")}</p>
                </Link>
                {index !== path.length ? nextVIcon : null}
              </>
            );
          })}
          {pages?.map((page, index) => {
            return (
              <>
                <Link to={selectPage(page)} key={index}>
                  <p>{page}</p>
                </Link>
                {index !== pages.length - 1 ? nextVIcon : null}
              </>
            );
          })}
        </div>
      )}
    </div>
  );
}
