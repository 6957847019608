import React, { useEffect, useState } from "react";
import { SubscriptionApiService } from "../../../service/website/subscriptionApiService";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { useLoader } from "../../../base/context/LoaderProvider";
import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { SellerApiService } from "../../../service/seller/sellerApiService";
import moment from "moment";

export default function Subscription() {
  const loader = useLoader();
  const message = useMessage();

  const [subscription, setSubscription] = useState([]);
  const [membership, setMembership] = useState({});

  const handleSubscriptions = () => {
    loader.showLoading(true);

    SubscriptionApiService.getSubscription()
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setSubscription(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  const queryParams = '?status="active"';

  const handleMembership = () => {
    SellerApiService.getMembership(queryParams)
      .then((res) => {
        if (res.status.code === 200) {
          setMembership(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  useEffect(() => {
    handleMembership();
    handleSubscriptions();
  }, []);

  const handleUpgrade = (id) => {
    loader.showLoading(true);

    const data = {
      subscriptionId: id,
    };

    SellerApiService.upgradeMembership(JSON.stringify(data))
      .then((res) => {
        loader.showLoading(false);

        if (res.status.code === 200) {
          message.showToast(res.status.message, ToastTypes.SUCCESS);
          handleMembership();
          handleSubscriptions();
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  return (
    <div>
      <div className="w-full bg-white border flex items-center px-6 py-6 gap-10 flex-wrap">
        {subscription.map((item, index) => {
          return (
            <div className="w-72 h-full" key={index}>
              <div
                className={`${
                  item._id === membership.subscriptionId
                    ? " bg-amber-400 text-center h-36 w-full flex flex-col items-center justify-center"
                    : "bg-amber-200 text-center h-36 w-full flex flex-col items-center justify-center"
                }`}
              >
                <p className="text-white text-3xl">{item.name}</p>
                <p className="text-white text-xl">
                  &#8377;{item.price} / {item.timePeriod}
                </p>
                <p className="text-white text-lg">{item.description}</p>
                {item._id === membership.subscriptionId ? (
                  <p className="text-white text-lg">
                    expires on{" "}
                    {moment(membership.endDate).format("Do MMM YYYY")}
                  </p>
                ) : null}
              </div>
              <div className="border p-4">
                {item.features.map((item, index) => {
                  return (
                    <div className="flex items-start gap-3 py-1">
                      {item.enabled ? (
                        <FaCheck className="text-green-500" size={20} />
                      ) : (
                        <IoMdClose
                          className="text-red-500 font-bold"
                          size={22}
                        />
                      )}

                      <p className="w-11/12">{item.name}</p>
                    </div>
                  );
                })}

                {item._id === membership.subscriptionId ? (
                  <div className="flex justify-center">
                    <button
                      className="bg-amber-400 text-white text-base px-5 py-1 rounded mt-2"
                      onClick={() => handleUpgrade(item._id)}
                    >
                      Extend
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
