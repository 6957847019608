export const navbarTopMenu = [
  { name: "Be a supplier", link: "/be-a-supplier" },
  { name: "Integrations", link: "" },
  { name: "Pricing", link: "/pricing" },
  { name: "Help Center", link: "/help-center" },
];

export const FooterContent = {
  about:
    "Upsaleo offer dropshipping services that guarantee exceptional service for your company.",
  headOne: "Connect With Us",
  headTwo: "How It Works",
  headThree: "Get To Know Us",
  headFour: "Quick Links",
};

export const upsaleoContact = {
  address: "Sector 6, Gautam Buddha Nagar Noida, Uttar Pradesh",
  email: "contact@upsaleo.com",
  phone: "+91 8130596699",
};

export const FooterHowItWorks = [
  { name: "How Dropshipping Work ", link: "/how-dropshiping-work" },
  { name: "Why work with Upsaleo ", link: "/why-work-with-upsaleo" },
  { name: "Be a Supplier", link: "/be-a-supplier" },
  { name: "Upsaleo Process", link: "/" },
];
export const FooterGetToKnowUs = [
  { name: "About Us", link: "/about-us" },
  { name: "Contact Us", link: "/contact-us" },
  { name: "Privacy  Policy", link: "/privacy-policy" },
  { name: "Delivery and Shipping Guide", link: "/delivery-and-shipping-guide" },
  // { name: "Shipping Policy", link: "/shipping-policy" },
  { name: "Return & Refund Policy", link: "/return-refund-policy" },
  { name: "Terms Of Service", link: "/terms-and-conditions" },
];

export const FooterQuickLinks = [
  { name: "Seller Login", link: "/login" },
  { name: "Your Account", link: "/seller/dashboard" },
  { name: "Selling Guide", link: "/selling-guide" },
  { name: "Help", link: "/help-center" },
];

export const HowDropshippingWorkContent = {
  section_one_head: "How does Dropshipping work",
  section_one_para:
    "Dropshipping is a retail model where an online store sells products without holding inventory. When a customer places an order, the store purchases the item from a supplier, who then ships it directly to the customer. This eliminates the need for upfront inventory investment and warehouse management, allowing for a wide product selection. However, it also means lower profit margins and less control over product quality and shipping. Effective dropshipping requires careful supplier selection, strong customer service, and efficient order processing.",

  section_two_head: (
    <h1>
      Our knowledge <br /> your <span>success</span>
    </h1>
  ),
  section_two_para:
    "At Upsaleo, we are dedicated to sharing our extensive knowledge and expertise to propel your success forward. Through personalized solutions and unwavering support, we empower you to navigate and excel in the dynamic landscape of e-commerce. Partner with us to harness the full potential of your business, achieve sustained growth, and reach new heights of prosperity. Together, let's build a thriving future for your online venture.",
};
