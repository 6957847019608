import React, { useEffect, useState } from "react";
import WebButton from "../../../component/library/webButton";
import { ProductSubscriptionApiService } from "../../../service/seller/productSubscriptionApiService";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import ChooseSubscribedFile from "../../../component/views/seller/subscription/chooseSubscribedFile";
import Pagination from "../../../component/library/pagination";
import NoDataFound from "../../../component/common/noDataFound";
import { formatDate } from "../../../helper/utils";
import { useNavigate } from "react-router-dom";
import DataExport from "../../../component/views/seller/subscription/dataExport";
import SyncProducts from "../../../component/views/seller/subscription/syncProducts";
import "./style.css";

const ProductSubscription = () => {
  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();

  const [productData, setProductData] = useState([]);
  const [selectedSKUs, setSelectedSKUs] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [pages, setPages] = useState({
    currentPage: "",
    totalPages: "",
    totalItems: "",
  });

  const queryParams = `?limit=${limit}&page=${page}`;

  const fetchSubscribeData = () => {
    loader.showLoading(true);
    ProductSubscriptionApiService.getProductSubscription(queryParams)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setProductData(res.data.products);
          setPages({
            ...pages,
            currentPage: res.data.currentPage,
            totalPages: res.data.totalPages,
            totalItems: res.data.totalProducts,
          });
          setPage(res.data.currentPage);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  const selectAllSubscription = (e) => {
    const skus = [...selectedSKUs];
    if (e.target.checked) {
      productData.map((item) => {
        skus.push(item.sku);
      });
      setSelectedSKUs(skus);
    } else {
      setSelectedSKUs([]);
    }
  };

  const selectSubscription = (e, sku) => {
    const skus = [...selectedSKUs];
    if (e.target.checked) {
      skus.push(sku);
      setSelectedSKUs(skus);
    } else {
      const filterdSkus = selectedSKUs.filter((item) => {
        return item !== sku;
      });
      setSelectedSKUs(filterdSkus);
    }
  };

  const handleUnsubscribe = () => {
    if (selectedSKUs.length) {
      loader.showLoading(true);

      let skus = selectedSKUs;
      ProductSubscriptionApiService.unsubscribeProducts(
        JSON.stringify({ skus: skus })
      )
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            setSelectedSKUs([]);
            fetchSubscribeData();
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          loader.showLoading(false);
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
        });
    } else {
      message.showToast("Select at least one product", ToastTypes.ERROR);
    }
  };

  const handleProduct = (product) => {
    navigate(`/product-details?id=${product._id}`);
  };

  useEffect(() => {
    fetchSubscribeData();
  }, [page]);

  useEffect(() => {
    if (selectedSKUs.length) {
      if (selectedSKUs.length === productData.length) {
        setSelectAll(true);
      }
    } else {
      setSelectAll(false);
    }
  }, [selectedSKUs]);

  return (
    <div className="product_subscribe_bg">
      <div className="product_subscribe_filter_row">
        <ChooseSubscribedFile />

        <div className="product_subscribe_filter_col">
          <DataExport
            selectedSKUs={selectedSKUs}
            setSelectedSKUs={setSelectedSKUs}
            setSelectAll={setSelectAll}
          />

          <SyncProducts
            selectedSKUs={selectedSKUs}
            setSelectedSKUs={setSelectedSKUs}
            setSelectAll={setSelectAll}
          />

          <WebButton
            text="Unsubscribe"
            onClick={() => handleUnsubscribe()}
            className="active_btn_with_shadow"
          />
        </div>
      </div>

      <div className="seller_table_wrapper">
        <table className="seller_table">
          <thead>
            <tr>
              <td>
                <input
                  className="w-4 h-4"
                  type="checkbox"
                  checked={selectAll}
                  onChange={(e) => selectAllSubscription(e)}
                />
              </td>
              <td>Name</td>
              <td>Image</td>
              <td>Category Name</td>
              <td>Quantity</td>
              <td>SKU</td>
              <td>Price</td>
              <td>Shopify Sync</td>
              <td>Amazon Sync</td>
              <td>Subscribe On</td>
            </tr>
          </thead>

          <tbody>
            {productData.length &&
              productData.map((item, index) => {
                return (
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        className="w-4 h-4"
                        checked={selectedSKUs.some((sku) => sku === item.sku)}
                        onChange={(e) => {
                          selectSubscription(e, item.sku);
                        }}
                      />
                    </td>

                    <td>
                      <div
                        className="truncate w-20 cursor-pointer table_overflow_text"
                        onClick={() => handleProduct(item)}
                      >
                        {item?.title}
                      </div>
                    </td>

                    <td>
                      <img
                        src={item.imgUrl[0]}
                        className="w-10 h-10 cursor-pointer"
                        onClick={() => handleProduct(item)}
                      />
                    </td>

                    <td
                      onClick={() => handleProduct(item)}
                      className="cursor-pointer"
                    >
                      {item?.c_name}
                    </td>
                    <td>{item?.quantity}</td>
                    <td>{item?.sku}</td>
                    <td>₹{item?.mrp}</td>
                    <td>{item?.shopifySync ? "Yes" : "No"}</td>
                    <td>{item?.amazonSync ? "Yes" : "No"}</td>
                    <td>{formatDate(item.createdAt)}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {!productData.length ? <NoDataFound message="No Data Found" /> : null}

      {productData.length ? (
        <Pagination pages={pages} page={page} setPage={setPage} />
      ) : null}
    </div>
  );
};

export default ProductSubscription;
