export const queryToUrl = (params) => {
  return params.replaceAll("&", "AND").replaceAll(" ", "_");
};

export const urlToQuery = (url) => {
  return url.replaceAll("AND", "&").replaceAll("_", " ");
};

export const setUrl = (path, searchParams, query, value, check) => {
  return `${path}?${
    query === "category"
      ? value
        ? "category=" + value + "&"
        : ""
      : searchParams.get("category")
      ? "category=" + searchParams.get("category") + "&"
      : ""
  }${
    query === "priceRange"
      ? check
        ? "min=" + value.min + "&" + "max=" + value.max + "&"
        : ""
      : searchParams.get("min")
      ? "min=" +
        searchParams.get("min") +
        "&" +
        "max=" +
        searchParams.get("max") +
        "&"
      : ""
  }${
    query === "shippingTime"
      ? value
        ? "shippingTime=" + value + "&"
        : ""
      : searchParams.get("shippingTime")
      ? "shippingTime=" + searchParams.get("shippingTime") + "&"
      : ""
  }${
    query === "search"
      ? "search=" + value + "&"
      : searchParams.get("search")
      ? "search=" + searchParams.get("search") + "&"
      : ""
  }${
    query === "amazonFriendly"
      ? value
        ? "amazonFriendly=" + value + "&"
        : ""
      : searchParams.get("amazonFriendly")
      ? "amazonFriendly=" + searchParams.get("amazonFriendly") + "&"
      : ""
  }${
    query === "shopifyFriendly"
      ? value
        ? "shopifyFriendly=" + value + "&"
        : ""
      : searchParams.get("shopifyFriendly")
      ? "shopifyFriendly=" + searchParams.get("shopifyFriendly") + "&"
      : ""
  }${
    query === "shippingCountry"
      ? value
        ? "shippingCountry=" + value + "&"
        : ""
      : searchParams.get("shippingCountry")
      ? "shippingCountry=" + searchParams.get("shippingCountry") + "&"
      : ""
  }${
    query === "sortBy"
      ? value !== "recommended"
        ? "sortBy=" + value + "&"
        : ""
      : searchParams.get("sortBy")
      ? "sortBy=" + searchParams.get("sortBy") + "&"
      : ""
  }${searchParams.get("fcId") ? "fcId=" + searchParams.get("fcId") + "&" : ""}${
    searchParams.get("page") ? "page=" + searchParams.get("page") : ""
  }`;
};

export const setQueryParams = (searchParams) => {
  return `?${
    searchParams.get("category")
      ? "categoryName=" +
        encodeURIComponent(urlToQuery(searchParams.get("category")))
      : ""
  }${
    searchParams.get("search")
      ? "&search=" + encodeURIComponent(urlToQuery(searchParams.get("search")))
      : ""
  }${
    searchParams.get("min") && searchParams.get("max")
      ? "&min=" +
        encodeURIComponent(searchParams.get("min")) +
        "&max=" +
        encodeURIComponent(searchParams.get("max"))
      : ""
  }${
    searchParams.get("shippingTime")
      ? "&shippingTime=" + encodeURIComponent(searchParams.get("shippingTime"))
      : ""
  }${
    searchParams.get("amazonFriendly")
      ? "&amazonFriendly=" +
        encodeURIComponent(searchParams.get("amazonFriendly"))
      : ""
  }${
    searchParams.get("shopifyFriendly")
      ? "&shopifyFriendly=" +
        encodeURIComponent(searchParams.get("shopifyFriendly"))
      : ""
  }${
    searchParams.get("shippingCountry")
      ? "&shippingCountry=" +
        encodeURIComponent(searchParams.get("shippingCountry"))
      : ""
  }${
    searchParams.get("sortBy")
      ? "&sortBy=" + encodeURIComponent(searchParams.get("sortBy"))
      : ""
  }${
    searchParams.get("fcId")
      ? "&fcId=" + encodeURIComponent(searchParams.get("fcId"))
      : ""
  }${
    searchParams.get("page")
      ? "&page=" + encodeURIComponent(searchParams.get("page"))
      : "&page=" + 1
  }`;
};

export const createMarkup = (html) => {
  return { __html: html };
};
