import React, { useState, useEffect } from "react";
import "./dashboard.css";
import { userApiService } from "../../../service/admin/userApiService";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const [activeUsers, setActiveUsers] = useState(0);
  const [userReport, setUserReport] = useState([]);
  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();

  const fetchUserReport = () => {
    loader.showLoading(true);
    userApiService
      .getTodayRegisterUser()
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setUserReport(res.data);
        } else if (res.status.code === 402) {
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  useEffect(() => {
    fetchUserReport();
  }, []);

  return (
    <div className="unique-dashboard">
      <div className="unique-cards">
        <div className="unique-card">
          <h3>Active Users</h3>
          <p>{activeUsers}</p>
        </div>
        <div className="unique-card">
          <h3>Total Users</h3>
          <p>{userReport?.totalUser}</p>
        </div>
        <div className="unique-card">
          <h3>Today's Registered Users</h3>
          <p>{userReport?.totalUserToday}</p>
        </div>
      </div>
    </div>
  );
}

// import React, { useState, useEffect, useCallback } from "react";
// import "./dashboard.css";
// import { userApiService } from "../../../service/admin/userApiService";
// import { useLoader } from "../../../base/context/LoaderProvider";
// import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
// import { useNavigate } from "react-router-dom";

// export default function Dashboard() {
//   const [activeUsers, setActiveUsers] = useState(0);
//   const [isConnected, setIsConnected] = useState(false);
//   const [userReport, setUserReport] = useState([]);
//   const loader = useLoader();
//   const message = useMessage();
//   const navigate = useNavigate();
//   const fetchUserReport = () => {
//     loader.showLoading(true);
//     userApiService
//       .getTodayRegisterUser()
//       .then((res) => {
//         loader.showLoading(false);
//         if (res.status.code === 200) {
//
//           setUserReport(res.data);
//         } else if (res.status.code === 402) {
//           navigate("/login");
//         } else {
//           message.showToast(res.error.message, ToastTypes.ERROR);
//         }
//       })
//       .catch((err) => {
//         loader.showLoading(false);
//         message.showToast("Server error. Try again later", ToastTypes.ERROR);
//       });
//   };

//   const connectWebSocket = useCallback(() => {
//     const websocket = new WebSocket("ws://localhost:4002");

//     websocket.onopen = () => {
//       console.log("Dashboard connected to WebSocket");
//       setIsConnected(true);
//       websocket.send(JSON.stringify({ type: "registerDashboard" }));
//     };

//     websocket.onmessage = (event) => {
//       const data = JSON.parse(event.data);
//       if (data.type === "activeUsers") {
//         setActiveUsers(data.count);
//       }
//     };

//     websocket.onclose = () => {
//       console.log("Dashboard disconnected from WebSocket");
//       setIsConnected(false);
//       setTimeout(connectWebSocket, 5000);
//     };

//     websocket.onerror = (error) => {
//       console.error("WebSocket error:", error);
//       websocket.close();
//     };

//     return websocket;
//   }, []);

//   useEffect(() => {
//     const websocket = connectWebSocket();

//     return () => {
//       websocket.close();
//     };
//   }, [connectWebSocket]);
//   useEffect(() => {
//     fetchUserReport();
//   }, []);

//   return (
//     <div className="unique-dashboard">
//       <div className="unique-cards">
//         <div className="unique-card">
//           <h3>Active Users</h3>
//           <p>{activeUsers}</p>
//         </div>
//         <div className="unique-card">
//           <h3>Total Users</h3>
//           <p>{userReport?.totalUser}</p>
//         </div>
//         <div className="unique-card">
//           <h3>Today's Registered Users</h3>
//           <p>{userReport?.totalUserToday}</p>
//         </div>
//       </div>
//     </div>
//   );
// }
