// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting_btn_row{
    display: flex;
    gap: 1rem;
    padding: 0 1rem;
}

.add_price_input_row{
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
}

.add_price_input_row p{
    font-family: "Inter", sans-serif;
    font-size: 1rem;
}

@media (max-width:768px) {
    .add_price_input_row{
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        padding: 0.5rem 0;
    }

    .add_price_input_row .setting_input{
        width: 80px;
        text-align: center;
    }

    .setting_btn_row{
        padding: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/views/seller/settings/priceMarkup/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,aAAa;IACb,2CAA2C;IAC3C,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI;QACI,aAAa;QACb,eAAe;QACf,WAAW;QACX,iBAAiB;IACrB;;IAEA;QACI,WAAW;QACX,kBAAkB;IACtB;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":[".setting_btn_row{\n    display: flex;\n    gap: 1rem;\n    padding: 0 1rem;\n}\n\n.add_price_input_row{\n    display: flex;\n    gap: 1rem;\n    align-items: center;\n    padding: 1rem;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.2);\n    margin-bottom: 1rem;\n}\n\n.add_price_input_row p{\n    font-family: \"Inter\", sans-serif;\n    font-size: 1rem;\n}\n\n@media (max-width:768px) {\n    .add_price_input_row{\n        display: flex;\n        flex-wrap: wrap;\n        gap: 0.5rem;\n        padding: 0.5rem 0;\n    }\n\n    .add_price_input_row .setting_input{\n        width: 80px;\n        text-align: center;\n    }\n\n    .setting_btn_row{\n        padding: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
