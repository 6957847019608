import React from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";

export default function DashboardCard({ heading, unit, baseColor, link }) {
  const navigate=useNavigate()
  return (
    <div
      className={`dashboard_card_box`}
      style={{ backgroundColor: baseColor }}
      onClick={()=>navigate(link)}
    >
      <div className="dashboard_card_circle"></div>

      <div className="dashboard_card_circle_line_parent"></div>

      <div className="dashboard_card_content">
        <p>{unit}</p>
        <p>{heading}</p>
      </div>
    </div>
  );
}
