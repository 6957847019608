import React, { useEffect, useState } from "react";
import content from "../../../helper/content/signup.json";
import WebInput from "../../../component/library/webInput";
import WebButton from "../../../component/library/webButton";
import WebOtpInput from "../../../component/library/webOtpInput";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { useNavigate } from "react-router-dom";
import { emailRegex, gstRegex, phoneFirstLetter } from "../../../helper/regex";
import { AuthApiService } from "../../../service/authApiService";
import {
  getAuthVerifiedData,
  setAuthVerifiedData,
} from "../../../storage/localStorage";

import SignUpImage from "../../../assets/images/signup/signup.png";
import Navbar from "../../../component/common/authNavbar";
import "../login/style.css";

export default function Signup() {
  const navigate = useNavigate();
  const message = useMessage();
  const loader = useLoader();

  const [otpSend, setOtpSend] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [timer, setTimer] = useState();
  const [time, setTime] = useState(60);
  const [otp, setOtp] = useState("");

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    companyName: "",
    address1: "",
    address2: "",
    state: "",
    city: "",
    zipcode: "",
    gst: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    companyName: "",
    address1: "",
    address2: "",
    state: "",
    city: "",
    zipcode: "",
    gst: "",
    password: "",
    confirmPassword: "",
  });

  const handleOnchange = (e) => {
    if (e.target.name !== "phone") {
      setData({ ...data, [e.target.name]: e.target.value });
      setError({ ...error, [e.target.name]: "" });
    }
  };

  const validate = () => {
    let result = true;
    if (!data.firstName) {
      setError({ ...error, firstName: "First name is required" });
      result = false;
    } else if (!data.lastName) {
      setError({ ...error, lastName: "Last name is required" });
      result = false;
    } else if (!data.phone.length) {
      setError({ ...error, phone: "Phone is required" });
      result = false;
      // } else if (!phoneVerified) {
      //   setError({ ...error, phone: "Verify phone number" });
      //   result = false;
    } else if (!data.email) {
      setError({ ...error, email: "Email is required" });
      result = false;
    } else if (!data.email.match(emailRegex)) {
      setError({ ...error, email: "Not a valid email address" });
      result = false;
    } else if (data.gst && !data.gst.match(gstRegex)) {
      setError({ ...error, gst: "Not a valid gst number" });
      result = false;
    } else if (!data.password) {
      setError({ ...error, password: "Password is required" });
      result = false;
    } else if (!data.confirmPassword) {
      setError({ ...error, confirmPassword: "Password is required" });
      result = false;
    } else if (data.password !== data.confirmPassword) {
      setError({ ...error, confirmPassword: "Password not matched" });
      result = false;
    }
    return result;
  };

  const handleSignup = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (validate()) {
      loader.showLoading(true);
      AuthApiService.registration(JSON.stringify(data))
        .then(async (res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            message.showToast(res.status.message, ToastTypes.SUCCESS);
            await setEmailToLocal();
            await SendVerificationLinktoEmail();
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    }
  };

  const setEmailToLocal = () => {
    let obj = {
      email: data.email,
      phone: data.phone,
    };
    setAuthVerifiedData(obj);
  };

  const SendVerificationLinktoEmail = () => {
    loader.showLoading(true);
    const data = {
      email: getAuthVerifiedData().verifiedEmail,
    };
    AuthApiService.verifyEmail(JSON.stringify(data))
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          message.showToast(res.status.message, ToastTypes.SUCCESS);
          navigate("/getUserDetails");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  //phone verifcation
  const handlePhone = (e) => {
    if (!phoneVerified) {
      setData({ ...data, phone: e.target.value.slice(0, 10) });
      setError({ ...error, phone: "" });
      if (otpSend) {
        setOtpSend(false);
        setTimer(false);
      }
    }
  };

  const validatePhone = () => {
    let result = true;
    if (!data.phone.length) {
      setError({ ...error, phone: "Phone is required" });
      result = false;
    } else if (data.phone.length !== 10) {
      setError({ ...error, phone: "Not a valid phone number" });
      result = false;
    } else if (data.phone.length === 10) {
      let firstLetter = data.phone.slice(0, 1);
      if (!phoneFirstLetter.some((num) => num === firstLetter)) {
        setError({ ...error, phone: "Not a valid phone number" });
        result = false;
      }
    }
    return result;
  };

  useEffect(() => {
    if (timer) {
      if (time > 0) {
        setTimeout(() => {
          setTime(time - 1);
        }, 1000);
      } else {
        setTimer(false);
        setTime(60);
      }
    } else {
      setTime(60);
    }
  }, [time, timer]);

  const sendOtptoPhone = (e) => {
    e.preventDefault();
    if (validatePhone()) {
      loader.showLoading(true);
      const payload = {
        phone: data.phone,
      };

      AuthApiService.sendOtp(JSON.stringify(payload))
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            message.showToast(res.status.message, ToastTypes.SUCCESS);
            setTimer(true);
            setOtpSend(true);
            setError({ ...error, phone: "" });
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    }
  };

  const handleVerifyOtp = () => {
    loader.showLoading(true);
    const otpPayload = {
      phone: data.phone,
      otp: otp,
    };

    AuthApiService.verifyOtp(JSON.stringify(otpPayload))
      .then(async (res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          message.showToast(res.status.message, ToastTypes.SUCCESS);
          await setPhoneToLocal();
          setPhoneVerified(true);
          setError({ ...error, phone: "" });
          setTimer(false);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  const setPhoneToLocal = () => {
    let localData = {
      phone: data.phone,
    };
    setAuthVerifiedData(localData);
  };

  useEffect(() => {
    if (otp.length === 6 && !phoneVerified) {
      handleVerifyOtp();
    }
  }, [otp]);

  return (
    <div>
      <Navbar />
      <div className="auth_container signup_auth_container">
        <div className="auth_row">
          <div className="auth_col_one">
            <h1 className="auth_heading">
              {content.upsaleoHeading}
              <br />
              {content.upsaleoSubHeading}
            </h1>
            <img src={SignUpImage} className="auth_image" />
          </div>

          <div className="auth_col_two signup_auth_col_two">
            <form className="auth_form" onSubmit={handleSignup}>
              <h1>{content.mainHeading}</h1>
              <WebInput
                parentClassName="input_parent"
                className="input_auth"
                id="firstNameInput"
                type="text"
                placeholder="First Name"
                name="firstName"
                value={data.firstName}
                error={error.firstName}
                onChange={handleOnchange}
              />

              <WebInput
                parentClassName="input_parent"
                className="input_auth"
                id="lastNameInput"
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={data.lastName}
                error={error.lastName}
                onChange={handleOnchange}
              />

              <WebInput
                parentClassName="input_parent"
                className="input_auth"
                id="phoneInput"
                type="number"
                placeholder="Contact Number"
                name="phone"
                value={data.phone}
                error={error.phone}
                verified={phoneVerified}
                onChange={handlePhone}
              />

              {/* {data.phone.length === 10 && !phoneVerified ? (
                <div className="otp_verified_container">
                  {otpSend ? <WebOtpInput setOtpVal={setOtp} /> : null}

                  <div className="timer_row">
                    {!timer ? (
                      <WebButton
                        className="otp_btn"
                        text={!otpSend ? "Request OTP" : "Resend OTP"}
                        onClick={sendOtptoPhone}
                      />
                    ) : (
                      <span>00:{time > 9 ? time : `0${time}`}</span>
                    )}
                  </div>
                </div>
              ) : null} */}

              <WebInput
                parentClassName="input_parent"
                className="input_auth"
                id="emailInput"
                type="email"
                placeholder="Email ID"
                name="email"
                value={data.email}
                error={error.email}
                onChange={handleOnchange}
              />

              <WebInput
                parentClassName="input_parent"
                className="input_auth"
                id="companyNameInput"
                type="text"
                placeholder="Company Name"
                name="companyName"
                value={data.companyName}
                error={error.companyName}
                onChange={handleOnchange}
              />

              {data.companyName ? (
                <>
                  <WebInput
                    parentClassName="input_parent"
                    className="input_auth"
                    id="address1Input"
                    type="text"
                    placeholder="Company Address Line 1"
                    name="address1"
                    value={data.address1}
                    error={error.address2}
                    onChange={handleOnchange}
                  />

                  <WebInput
                    parentClassName="input_parent"
                    className="input_auth"
                    id="address2Input"
                    type="text"
                    placeholder="Company Address Line 2"
                    name="address2"
                    value={data.address2}
                    error={error.address2}
                    onChange={handleOnchange}
                  />

                  <div className="state_city_pin_row">
                    <WebInput
                      parentClassName="input_parent"
                      className="input_auth"
                      id="stateInput"
                      type="text"
                      placeholder="State"
                      name="state"
                      value={data.state}
                      error={error.state}
                      onChange={handleOnchange}
                    />

                    <WebInput
                      parentClassName="input_parent"
                      className="input_auth"
                      id="cityInput"
                      type="text"
                      placeholder="City"
                      name="city"
                      value={data.city}
                      error={error.city}
                      onChange={handleOnchange}
                    />
                    <WebInput
                      parentClassName="input_parent"
                      className="input_auth"
                      id="zipcodeInput"
                      type="number"
                      placeholder="Zip"
                      name="zipcode"
                      value={data.zipcode}
                      error={error.zipcode}
                      onChange={handleOnchange}
                    />
                  </div>
                  <WebInput
                    parentClassName="input_parent"
                    className="input_auth"
                    id="gstInput"
                    type="text"
                    placeholder="GST Number"
                    name="gst"
                    value={data.gst}
                    error={error.gst}
                    onChange={handleOnchange}
                    maxLength="15"
                  />
                </>
              ) : null}

              <WebInput
                parentClassName="input_parent"
                className="input_auth"
                id="passwordInput"
                type="password"
                placeholder="Password"
                name="password"
                value={data.password}
                error={error.password}
                onChange={handleOnchange}
              />

              <WebInput
                parentClassName="input_parent"
                className="input_auth"
                id="confirmPasswordInput"
                type="password"
                placeholder="Confirm Password"
                name="confirmPassword"
                value={data.confirmPassword}
                error={error.confirmPassword}
                onChange={handleOnchange}
              />

              <WebButton
                className="auth_btn"
                text="Sign Up"
                onClick={(e) => handleSignup(e)}
              />
              <div className="auth_message" onClick={() => navigate("/login")}>
                <p></p>
                <p>Sign in into an existing account</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
