// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_box {
  width: 100%;
  border-collapse: collapse;
}

.table_box thead {
  background-color: #f3f4f6;
}

.table_box th,
.table_box td {
  padding: 10px;
  border: 1px solid #e5e7eb;
  text-align: left;
}

.line-through {
  text-decoration: line-through;
  background-color: #f87171; /* Light red */
}

.text-black {
  color: #000;
}

.text-blue-600 {
  color: #2563eb;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-blue-500 {
  background-color: #3b82f6;
}

.bg-green-500 {
  background-color: #10b981;
}

.text-white {
  color: #fff;
}

.p-2 {
  padding: 8px;
}

.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.flex {
  display: flex;
}

.gap-4 {
  gap: 16px;
}

.items-center {
  align-items: center;
}

.input_field {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/views/admin/category/existingCategory/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,aAAa;EACb,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,yBAAyB,EAAE,cAAc;AAC3C;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":[".table_box {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.table_box thead {\n  background-color: #f3f4f6;\n}\n\n.table_box th,\n.table_box td {\n  padding: 10px;\n  border: 1px solid #e5e7eb;\n  text-align: left;\n}\n\n.line-through {\n  text-decoration: line-through;\n  background-color: #f87171; /* Light red */\n}\n\n.text-black {\n  color: #000;\n}\n\n.text-blue-600 {\n  color: #2563eb;\n}\n\n.cursor-pointer {\n  cursor: pointer;\n}\n\n.bg-blue-500 {\n  background-color: #3b82f6;\n}\n\n.bg-green-500 {\n  background-color: #10b981;\n}\n\n.text-white {\n  color: #fff;\n}\n\n.p-2 {\n  padding: 8px;\n}\n\n.py-1 {\n  padding-top: 4px;\n  padding-bottom: 4px;\n}\n\n.flex {\n  display: flex;\n}\n\n.gap-4 {\n  gap: 16px;\n}\n\n.items-center {\n  align-items: center;\n}\n\n.input_field {\n  padding: 8px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
