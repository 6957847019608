// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product_subscribe_filter{
    display: flex;
    gap: 1rem;
}

.filter_radio{
    width: 15px;
    height: 15px;
    border-radius: 10px;
    cursor: pointer;
    background-color: aqua;
}

.filter_radio_row{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.filter_radio_row label{
    font-weight: 500;
    font-family: "Inter", sans-serif;
    font-size: 1rem;
}

.filter_file_row{
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/component/views/seller/subscription/chooseSubscribedFile/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".product_subscribe_filter{\n    display: flex;\n    gap: 1rem;\n}\n\n.filter_radio{\n    width: 15px;\n    height: 15px;\n    border-radius: 10px;\n    cursor: pointer;\n    background-color: aqua;\n}\n\n.filter_radio_row{\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n}\n\n.filter_radio_row label{\n    font-weight: 500;\n    font-family: \"Inter\", sans-serif;\n    font-size: 1rem;\n}\n\n.filter_file_row{\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
