import { Box, Modal } from '@mui/material'
import React from 'react'
 

const DeletePopUp=({ open, handleClose,submitText, handleSubmit ,handleCancel,cancelText ,heading , text})=> {
    const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="h-auto w-60">
                    <h1 className='text-xl font-semibold'>{heading}</h1>
                    <p className='text-md'>{text}</p>
                    <div className='flex gap-10 justify-end items-end mt-4'>
                    <button className="bg-red-600 text-white w-40 rounded-md" onClick={handleSubmit}> {submitText} </button>
                    <button className="bg-red-600 text-white w-40 rounded-md" onClick={handleCancel}>{cancelText}</button>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default DeletePopUp;