import React, { useEffect, useState } from "react";
import WebInput from "../../../../component/library/webInput";
import WebDropdown from "../../../../component/library/webDropdown";
import { useSelector } from "react-redux";
import { categoryData } from "../../../../redux/slices/categorySlice";
import WebButton from "../../../../component/library/webButton";
import { productListApiService } from "../../../../service/admin/productApiService";
import { useLoader } from "../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";
import { useNavigate } from "react-router-dom";

export default function AddProduct() {
  const [data, setData] = useState({
    c_name: "",
    categoryId: "",
    sku: "",
    title: "",
    nodeId: "",
    description: "",
    length: "",
    width: "",
    height: "",
    weight: "",
    pack_unit: "",
    vendor_price: "",
    mrp: "",
    sp: "",
    quantity: "",
    status: "",
    warranty: "",
    imgUrl: [],
    imgFromUrl: [],
    hsn: "",
    brand: "",
    location: "",
  });

  const [categories, setCategories] = useState();
  const [subCategory, setSubCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();
  const category = useSelector(categoryData);

  useEffect(() => {
    const array = [
      {
        name: "--Select Any Category--",
        value: "",
      },
    ];

    if (category?.length) {
      category?.map((item) => {
        const obj = {
          name: item.c_name,
          value: item.c_id,
        };
        array.push(obj);
      });
      setCategories(array);
    }
  }, [category]);

  console.log("23456", category);

  useEffect(() => {
    if (selectedCategory) {
      const selectedCategoryObj = category.find(
        (cat) => cat.c_id === selectedCategory
      );
      const arr = [
        {
          name: "--Select any one",
          value: "",
        },
      ];
      if (selectedCategoryObj?.children?.length) {
        selectedCategoryObj.children.map((item) => {
          const obj = {
            name: item.c_name,
            value: item?.c_id,
          };
          arr.push(obj);
        });
      }
      setSubCategory(arr);
    } else {
      setSubCategory([
        {
          name: "--Select any one",
          value: "",
        },
      ]);
    }
  }, [selectedCategory, category]);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setData({ ...data, categoryId: e.target.value, nodeId: "" });
  };

  const handleSubCategoryChange = (e) => {
    setData({ ...data, categoryId: e.target.value, nodeId: "" });
  };

  const [error, setError] = useState({
    categoryId: "",
    sku: "",
    title: "",
    nodeId: "",
    description: "",
    length: "",
    width: "",
    height: "",
    weight: "",
    pack_unit: "",
    vendor_price: "",
    mrp: "",
    sp: "",
    quantity: "",
    warranty: "",
    imgUrl: [],
    imgFromUrl: [],
    hsn: "",
    brand: "",
    location: "",
  });

  const handleOnchange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const handleImages = (e) => {
    const files = Array.from(e.target.files);
    setData({ ...data, imgUrl: files });
  };

  const handleProduct = () => {
    const payload = new FormData();
    payload.append("categoryId", data.categoryId);
    payload.append("sku", data.sku);
    payload.append("title", data.title);
    payload.append("nodeId", data.nodeId);
    payload.append("description", data.description);
    payload.append("length", data.length);
    payload.append("width", data.width);
    payload.append("height", data.height);
    payload.append("weight", data.weight);
    payload.append("pack_unit", data.pack_unit);
    payload.append("vendor_price", data.vendor_price);
    payload.append("mrp", data.mrp);
    payload.append("sp", data.sp);
    payload.append("quantity", data.quantity);
    payload.append("warranty", data.warranty);
    data.imgUrl.forEach((file) => {
      payload.append("images", file);
    });
    payload.append("gst", data.gst);
    payload.append("hsn", data.hsn);
    payload.append("status", data.status);
    payload.append("brand", data.brand);
    payload.append("location", data.location);
    loader.showLoading(true);
    productListApiService
      .addProducts(payload)
      .then((res) => {
        if (res.status.code === 200) {
          loader.showLoading(false);

          navigate("/admin/products/list");
          setData(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };
  const divStyle = {
    paddingBottom: "20px",
  };
  return (
    <div className="flex flex-col gap-x-10 gap-y-2" style={divStyle}>
      <label className="font-semibold ">Categories</label>
      <WebDropdown
        options={categories}
        className="border px-3  py-2 w-full rounded text-black"
        parentClassName="w-2/5"
        onChange={handleCategoryChange}
      />
      <label className="font-semibold ">Sub-Categories</label>
      <WebDropdown
        options={subCategory}
        className="border px-3  py-2 w-full rounded text-black"
        parentClassName="w-2/5"
        onChange={handleSubCategoryChange}
      />

      <div className="flex">
        <div className="flex flex-col w-10/12 gap-x-10">
          <label> SKU</label>
          <WebInput
            type="text"
            placeholder=""
            id="sku"
            onChange={handleOnchange}
            error={error.sku}
            value={data.sku}
            name="sku"
          />
        </div>
        <div className="flex flex-col w-10/12 gap-x-10">
          <label> TITLE</label>
          <WebInput
            type="text"
            placeholder=""
            id="title"
            onChange={handleOnchange}
            error={error.title}
            value={data.title}
            name="title"
          />
        </div>

        <div className="flex flex-col w-10/12 gap-x-10">
          <label> DESCRIPTION</label>
          <WebInput
            type="text"
            placeholder=""
            id="description"
            onChange={handleOnchange}
            error={error.description}
            value={data.description}
            name="description"
          />
        </div>
      </div>
      <div className="flex">
        <div className="flex flex-col w-10/12 gap-x-10">
          <label> MRP</label>
          <WebInput
            type="text"
            placeholder=""
            label=""
            id="mrp"
            onChange={handleOnchange}
            error={error.mrp}
            value={data.mrp}
            name="mrp"
          />
        </div>
        <div className="flex flex-col w-10/12 gap-x-10">
          <label> VENDOR PRICE</label>
          <WebInput
            type="text"
            placeholder=""
            id="vendor_price"
            onChange={handleOnchange}
            error={error.vendor_price}
            value={data.vendor_price}
            name="vendor_price"
          />
        </div>
        <div className="flex flex-col w-10/12 gap-x-10">
          <label>SELLING PRICE</label>
          <WebInput
            type="text"
            s
            id="sp"
            onChange={handleOnchange}
            error={error.sp}
            value={data.sp}
            name="sp"
          />
        </div>
      </div>
      <div className="flex">
        <div className="flex flex-col flex-wrap w-10/12 gap-x-10">
          <label>LENGTH</label>
          <WebInput
            type="text"
            id="length"
            onChange={handleOnchange}
            error={error.length}
            value={data.length}
            name="length"
          />
        </div>

        <div className="flex flex-col flex-wrap w-10/12 gap-x-10">
          <label>WIDTH</label>
          <WebInput
            type="text"
            placeholder=""
            id="width"
            onChange={handleOnchange}
            error={error.width}
            value={data.width}
            name="width"
          />
        </div>

        <div className="flex flex-col flex-wrap w-10/12 gap-x-10">
          <label>HEIGHT</label>
          <WebInput
            type="text"
            id="height"
            onChange={handleOnchange}
            error={error.height}
            value={data.height}
            name="height"
          />
        </div>
      </div>

      <div className="flex">
        <div className="flex flex-col flex-wrap w-10/12 gap-x-10">
          <label>WEIGHT</label>
          <WebInput
            type="text"
            id="weight"
            onChange={handleOnchange}
            error={error.weight}
            value={data.weight}
            name="weight"
          />
        </div>

        <div className="flex flex-col flex-wrap w-10/12 gap-x-10">
          <label>QUANTITY</label>
          <WebInput
            type="text"
            placeholder=""
            label=""
            id="quantity"
            onChange={handleOnchange}
            error={error.quantity}
            value={data.quantity}
            name="quantity"
          />
        </div>

        <div className="flex flex-col flex-wrap w-10/12 gap-x-10">
          <label>WARRANTY</label>
          <WebInput
            type="text"
            id="warranty"
            onChange={handleOnchange}
            error={error.warranty}
            value={data.warranty}
            name="warranty"
          />
        </div>
      </div>

      <div className="flex">
        <div className="flex flex-col flex-wrap w-10/12 gap-x-10">
          <label>PACKET UNIT</label>
          <WebInput
            type="text"
            placeholder=""
            id="pack_unit"
            onChange={handleOnchange}
            error={error.pack_unit}
            value={data.pack_unit}
            name="pack_unit"
          />
        </div>

        <div className="flex flex-col flex-wrap w-10/12 gap-x-10">
          <label>HSN</label>
          <WebInput
            type="text"
            id="hsn"
            onChange={handleOnchange}
            error={error.hsn}
            value={data.hsn}
            name="hsn"
          />
        </div>

        <div className="flex flex-col w-10/12 gap-x-10">
          <label>BRAND</label>
          <WebInput
            type="text"
            id="brand"
            onChange={handleOnchange}
            error={error.brand}
            value={data.brand}
            name="brand"
          />
        </div>
      </div>

      <div className="flex">
        <div className="flex flex-col w-1/2 ">
          <label>LOCATION</label>
          <WebInput
            type="text"
            placeholder=""
            id="location"
            onChange={handleOnchange}
            error={error.location}
            value={data.location}
            name="location"
          />
        </div>
        <div className="flex flex-col w-1/2 ">
          <label>IMAGES</label>
          <WebInput
            type="file"
            multiple
            onChange={handleImages}
            className="px-2 py-1 border border-black bg-white"
          />
        </div>
      </div>
      <WebButton
        onClick={handleProduct}
        text="Add Product"
        className=" bg-blue-500 w-36 py-2 rounded-lg text-white"
      />
    </div>
  );
}
