import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { selectIsNightMode } from "../../../../../redux/slices/modeSlice";
import "./style.css";

export default function DashboardChart({ data, heading }) {
  const isNightMode = useSelector(selectIsNightMode);

  const [series, setSeries] = useState([
    {
      name: "Orders",
      data: [],
    },
  ]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    setSeries([{ ...series[0], data: data.values }]);
    setLabels(data.labels);
  }, [data]);

  const options = {
    chart: {
      height: 350,
      type: "bar",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        borderRadius: 0,
        dataLabels: {
          position: "top",
        },
      },
    },

    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    xaxis: {
      categories: labels,
      position: "center",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val;
        },
      },
    },

    ///////////////////
  };

  return (
    <div
      className={`dashboard_chart_bg ${
        isNightMode ? "dashboard_chart_bg_dark" : ""
      }`}
    >
      <div className="dashboard_chart_filter_row ">
        <div className="dashboard_chart_heading">{heading}</div>
      </div>

      <Chart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height="350"
      />
    </div>
  );
}
