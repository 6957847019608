import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    addItems: (state, action) => {
      state.data = action.payload;
    },
    resetItems: (state, action) => {
      state.data = [];
    },
  },
});

export const { addItems } = sidebarSlice.actions;

export const sidebarData = (state) => state.sidebar.data;

export default sidebarSlice.reducer;
