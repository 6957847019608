import React, { useEffect } from "react";
import Lottie from "react-lottie";
import loader from "../../../assets/lotties/loader.json";
import "./style.css";

export default function Loader({ value }) {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  function disableScroll() {
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  }

  function enableScroll() {
    window.onscroll = function () {};
  }

  useEffect(() => {
    if (value) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [value]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return value ? (
    <div
      className="absolute  w-full h-screen bg-black bg-opacity-70 flex justify-center items-center loader_bg"
      style={{ top: scrollTop, left: scrollLeft }}
    >
      <div className="w-1/3 h-60 flex justify-center items-center">
        <Lottie options={defaultOptions} height={150} width={150} />
      </div>
    </div>
  ) : null;
}
