import React, { useState } from "react";
import WebButton from "../../../../library/webButton";
import { ProductSubscriptionApiService } from "../../../../../service/seller/productSubscriptionApiService";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";
import "./style.css";
import WebInput from "../../../../library/webInput";

const ChooseSubscribedFile = () => {
  const [uploadFile, setUploadFile] = useState();
  const [subscription, setSubscription] = useState();
  const [uploadFileErr, setUploadFileErr] = useState("");

  const loader = useLoader();
  const message = useMessage();

  const handleFile = (e) => {
    setUploadFile(e.target.files[0]);
    setUploadFileErr("");
  };

  const validateUploadFile = () => {
    let result;
    if (!uploadFile) {
      setUploadFileErr("File is required");
      result = false;
    } else {
      result = true;
    }
    return result;
  };

  const handleChangeButton = (e) => {
    setSubscription(e.target.value);
  };

  const handleSubscription = () => {
    if (validateUploadFile()) {
      loader.showLoading(true);
      const data = new FormData();
      data.append("file", uploadFile);
      if (subscription === "subscribe") {
        ProductSubscriptionApiService.addExcelProductSubscription(data)
          .then((res) => {
            loader.showLoading(false);
            if (res.status.code === 200) {
              message.showToast(res.status.message, ToastTypes.SUCCESS);
            } else {
              message.showToast(res.error.message, ToastTypes.ERROR);
            }
          })
          .catch((err) => {
            message.showToast(
              "Server error. Try again later",
              ToastTypes.ERROR
            );
            loader.showLoading(false);
          });
      } else {
        ProductSubscriptionApiService.addExcelProductUnsubscription(data)
          .then((res) => {
            loader.showLoading(false);
            if (res.status.code === 200) {
              message.showToast(res.status.message, ToastTypes.SUCCESS);
            } else {
              message.showToast(res.error.message, ToastTypes.ERROR);
            }
          })
          .catch((err) => {
            message.showToast(
              "Server error. Try again later",
              ToastTypes.ERROR
            );
            loader.showLoading(false);
          });
      }
    }
  };

  return (
    <div className="product_subscribe_filter">
      <div className="filter_radio_row">
        <input
          type="radio"
          id="subscribe"
          name="subscribe"
          value="subscribe"
          checked={subscription === "subscribe"}
          onChange={handleChangeButton}
          className="filter_radio"
        />
        <label htmlFor="subscribe">Subscribe</label>
      </div>

      <div className="filter_radio_row">
        <input
          type="radio"
          id="unsubscribe"
          name="subscribe"
          value="unsubscribe"
          checked={subscription === "unsubscribe"}
          onChange={handleChangeButton}
          className="filter_radio"
        />
        <label htmlFor="unsubscribe">Unsubscribe</label>
      </div>

      <div className="filter_file_row">
        <WebInput
          type="file"
          onChange={handleFile}
          className="order_file_input"
          error={uploadFileErr}
        />

        <WebButton
          text="Upload"
          className="active_btn_with_shadow filter_file_btn"
          onClick={handleSubscription}
        />
      </div>
    </div>
  );
};

export default ChooseSubscribedFile;
