// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collection_page{
    font-family: var(--sans-serif) !important;
    z-index: 0;
}

.product_listing_row{
    display: flex;
    gap: 1rem;
}

.product_listing_filteration_col{
    width: 240px;
    height: auto;
}

.product_listing_products_col{
    width: calc(100% - 244px);
    height: auto;   
}

.collection_product_row{
    display: flex;
    flex-wrap: wrap;
    gap: 0.96rem;
}

.collection_header{
    width: 100%;
    margin: 1rem 0;
}

.collection_footer{
    width: 100%;
    margin-top: 1rem;
}

.collection_pagination{
    margin-top: 1rem;
}

.apply_filters{
    display: none;
}


@media (max-width:768px) {
    .product_main_filter_wrapper{
        display: none;
    }

    .product_listing_row{
        display: flex;
        gap: 1rem;
    }
    
    .product_listing_filteration_col{
        display: none;
    }
    
    .product_listing_products_col{
        width: 100%;
        height: auto;   
    }
    .collection_product_row{
        justify-content: center;
    }

    .apply_filters{
        display: block;
        color: var(--web-secondary-color);
        font-weight: var(--sans-serif-medium);
        width: -moz-fit-content;
        width: fit-content;
        margin: 0.5rem 0;

    }
}`, "",{"version":3,"sources":["webpack://./src/website/views/products/style.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,UAAU;AACd;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;;AAGA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;QACb,SAAS;IACb;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,WAAW;QACX,YAAY;IAChB;IACA;QACI,uBAAuB;IAC3B;;IAEA;QACI,cAAc;QACd,iCAAiC;QACjC,qCAAqC;QACrC,uBAAkB;QAAlB,kBAAkB;QAClB,gBAAgB;;IAEpB;AACJ","sourcesContent":[".collection_page{\n    font-family: var(--sans-serif) !important;\n    z-index: 0;\n}\n\n.product_listing_row{\n    display: flex;\n    gap: 1rem;\n}\n\n.product_listing_filteration_col{\n    width: 240px;\n    height: auto;\n}\n\n.product_listing_products_col{\n    width: calc(100% - 244px);\n    height: auto;   \n}\n\n.collection_product_row{\n    display: flex;\n    flex-wrap: wrap;\n    gap: 0.96rem;\n}\n\n.collection_header{\n    width: 100%;\n    margin: 1rem 0;\n}\n\n.collection_footer{\n    width: 100%;\n    margin-top: 1rem;\n}\n\n.collection_pagination{\n    margin-top: 1rem;\n}\n\n.apply_filters{\n    display: none;\n}\n\n\n@media (max-width:768px) {\n    .product_main_filter_wrapper{\n        display: none;\n    }\n\n    .product_listing_row{\n        display: flex;\n        gap: 1rem;\n    }\n    \n    .product_listing_filteration_col{\n        display: none;\n    }\n    \n    .product_listing_products_col{\n        width: 100%;\n        height: auto;   \n    }\n    .collection_product_row{\n        justify-content: center;\n    }\n\n    .apply_filters{\n        display: block;\n        color: var(--web-secondary-color);\n        font-weight: var(--sans-serif-medium);\n        width: fit-content;\n        margin: 0.5rem 0;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
