// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.web_layout_bg{
    background-color: var(--web-layout-color);
    width: 100%;
    padding: 0;
    margin: 0;
    z-index: 1;
    margin-top: 7.5rem;
    position: relative;
}

.web_layout_children{
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 23.1rem);
}

.web_layout_children_content{
    width: 1280px;
    padding: 1.5rem 0;
}

@media (min-width:1600px) {
    .web_layout_children_content {
            width: 1538px;
            padding: 1.5rem 0;
        }
}


@media (max-width:768px) {
    .web_layout_children_content {
            width: 100%;
            padding:1rem;
        }
}`, "",{"version":3,"sources":["webpack://./src/website/component/layout/layout/style.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,WAAW;IACX,UAAU;IACV,SAAS;IACT,UAAU;IACV,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,iCAAiC;AACrC;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI;YACQ,aAAa;YACb,iBAAiB;QACrB;AACR;;;AAGA;IACI;YACQ,WAAW;YACX,YAAY;QAChB;AACR","sourcesContent":[".web_layout_bg{\n    background-color: var(--web-layout-color);\n    width: 100%;\n    padding: 0;\n    margin: 0;\n    z-index: 1;\n    margin-top: 7.5rem;\n    position: relative;\n}\n\n.web_layout_children{\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    min-height: calc(100vh - 23.1rem);\n}\n\n.web_layout_children_content{\n    width: 1280px;\n    padding: 1.5rem 0;\n}\n\n@media (min-width:1600px) {\n    .web_layout_children_content {\n            width: 1538px;\n            padding: 1.5rem 0;\n        }\n}\n\n\n@media (max-width:768px) {\n    .web_layout_children_content {\n            width: 100%;\n            padding:1rem;\n        }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
