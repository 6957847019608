import { createSlice } from "@reduxjs/toolkit";

export const modeSlice = createSlice({
  name: "mode",
  initialState: {
    isNightMode: false,
  },
  reducers: {
    toggleMode: (state) => {
      state.isNightMode = !state.isNightMode;
    },
  },
});

export const { toggleMode } = modeSlice.actions;

export const selectIsNightMode = (state) => state.mode.isNightMode;

export default modeSlice.reducer;
