import moment from "moment";
export const sidebarMenuHeight = (length) => {
  const hLength = 7 * length;
  const data = hLength.toString();
  return `h-${data}`;
};

export const sellerSidebarArray = [
  {
    name: "Dashboard",
    icon: "RiHome2Fill",
    link: "/seller/dashboard",
    items: [],
    opened: false,
    active: false,
    tabLink: "/seller/dashboard",
  },
  {
    name: "Orders",
    icon: "IoBag",
    link: "/seller/orders",
    items: [],
    opened: false,
    active: false,
    tabLink: "/seller/orders",
  },

  {
    name: "Marketplace Apis",
    icon: "IoCart",
    link: "/seller/marketplaceApis",
    items: [],
    opened: false,
    active: false,
    tabLink: "/seller/marketplaceApis",
  },
  {
    name: "Product Subscriptions",
    icon: "FaCubes",
    link: "/seller/productSubscription",
    items: [],
    opened: false,
    active: false,
    tabLink: "/seller/productSubscription",
  },
  {
    name: "Wallet",
    icon: "BsWalletFill",
    link: "/seller/wallet",
    items: [],
    opened: false,
    active: false,
    tabLink: "/seller/wallet",
  },
   {
    name: "Reports",
    icon: "FaFileAlt",
    link: "",
    items: [
      {
        name: "Invoice",
        link: "/seller/reports/invoice",
      },
    ],
    opened: false,
    active: false,
    tabLink: "/seller/reports/invoice",
  },
  {
    name: "Settings",
    icon: "IoMdSettings",
    link: "/seller/settings",
    items: [],
    opened: false,
    active: false,
    tabLink: "/seller/settings",
  },
];

export const adminSidebarArray = [
  {
    name: "Dashboard",
    icon: "FaTachometerAlt",
    link: "/admin/dashboard",
    items: [],
    opened: false,
    active: false,
    tabLink: "/admin/dashboard",
  },

  {
    name: "User Management",
    icon: "FaUsers",
    link: "/admin/user-management",
    items: [],
    opened: false,
    active: false,
    tabLink: "/admin/user-management",
  },

  {
    name: "Transactions",
    icon: "FaExchangeAlt",
    link: "/admin/transactions",
    items: [],
    opened: false,
    active: false,
    tabLink: "/admin/transactions",
  },
  {
    name: "User Statistics",
    icon: "FaChartBar",
    link: "/admin/user-statistics",
    items: [],
    opened: false,
    active: false,
    tabLink: "/admin/user-statistics",
  },

  {
    name: "Categories",
    icon: "FaListAlt",
    link: "/admin/categories",
    items: [],
    opened: false,
    active: false,
    tabLink: "/admin/categories",
  },
  {
    name: "Order Management",
    icon: "FaShoppingCart",
    link: "/admin/order-management",
    items: [],
    opened: false,
    active: false,
    tabLink: "/admin/order-management",
  },
  {
    name: "Ticket Management",
    icon: "FaTicketAlt",
    link: "/admin/ticket-management",
    items: [],
    opened: false,
    active: false,
    tabLink: "/admin/ticket-management",
  },
  {
    name: "Failed Orders",
    icon: "FaBan",
    link: "/admin/failed-orders",
    items: [],
    opened: false,
    active: false,
    tabLink: "/admin/failed-orders",
  },

  {
    name: "Subscription",
    icon: "FaLayerGroup",
    link: "",
    opened: false,
    active: false,
    tabLink: "/admin/subscription/list",
    items: [
      {
        name: "Add Subscription",
        link: "/admin/subscription/add",
      },
      {
        name: "Subscription List",
        link: "/admin/subscription/list",
      },
    ],
  },
  {
    name: "Products",
    icon: "FaBox",
    link: "",
    opened: false,
    active: false,
    tabLink: "/admin/products/list",
    items: [
      {
        name: "Add Products",
        link: "/admin/products/add",
      },
      {
        name: "Product List",
        link: "/admin/products/list",
      },
    ],
  },
  {
    name: "Membership Manage",
    icon: "FaIdBadge",
    link: "/admin/membership-management",
    opened: false,
    active: false,
    tabLink: "/admin/membership-management",
    items: [],
  },
];

export const marketPlaces = [
  { name: "Amazon" },
  { name: "⁠Flipkart" },
  { name: "Meesho" },
  { name: "⁠India Mart" },
  { name: "⁠First Cry" },
  { name: "⁠Myntra" },
  { name: "⁠Nykaa" },
  { name: "⁠Ajio" },
  { name: "⁠Tata Cliq" },
  { name: "⁠Udaan" },
];

export const socialMedia = [
  { name: "Facebook" },
  { name: "⁠Instagram" },
  { name: "Whatsapp" },
];

export const ownWebsite = [
  { name: "Shopify" },
  { name: "⁠Wordpress" },
  { name: "⁠Opencart" },
];

export const categories = [
  { name: "Home Improvement & Kitchen" },
  { name: "Lawn & Garden" },
  { name: "Electronics - PC" },
  { name: "Electronics - Accessories and Devices" },
  { name: "Electronics - Mobile Phones and Accessories" },
  { name: "BISS - Industrial" },
  { name: "BISS - Lab Supplies" },
  { name: "BISS - Mechanical Fasteners" },
  { name: "Shoes and Handbags" },
  { name: "Luggage" },
  { name: "Jewellery" },
  { name: "Watches" },
  { name: "Beauty" },
  { name: "Baby Product" },
  { name: "Toys" },
  { name: "Sports" },
  { name: "Office Products" },
  { name: "Musical Instruments" },
  { name: "Video Games - Console" },
  { name: "Gift Card" },
  { name: "Shopify" },
  { name: "Automotive" },
  { name: "Pet Products" },
  { name: "Personal Care Appliances" },
  { name: "BISS - Professional Health Care" },
];

export const formatDate = (date) => {
  return moment(date).format("Do MMM YYYY, h:mm a");
};

export const formatDateOnly = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const jsonToCsv = (jsonData, keys, csvContent) => {
  // Extracting keys
  const keysVal = keys;
  // Writing CSV headers
  let csvContentVal = csvContent + "\n";

  // Writing CSV data
  for (let item of jsonData) {
    const values = keysVal.map((key) => item[key]);
    csvContentVal += values.join(",") + "\n";
  }
  return csvContentVal;
};

export function downloadCsv(csvContent, fileName) {
  // Create a Blob object
  const blob = new Blob([csvContent], { type: "text/csv" });

  // Create a download URL
  const url = window.URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  // Append the link to the body
  document.body.appendChild(link);

  // Click the link to trigger download
  link.click();

  // Cleanup
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export const userApiTokenExpired=(status,navigate)=>{
  if(status===402){
    
    navigate('/login')
  }
}