import React, { useEffect, useState } from "react";
import PriceMarkup from "../../../component/views/seller/settings/priceMarkup";
import InventorySync from "../../../component/views/seller/settings/inventorySync";
import PriceSync from "../../../component/views/seller/settings/priceSync";
import WebInput from "../../../component/library/webInput";
import WebButton from "../../../component/library/webButton";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { useLoader } from "../../../base/context/LoaderProvider";
import { WalletApiService } from "../../../service/seller/walletApiService";
import { SettingApiService } from "../../../service/seller/settingApiService";
import "./style.css";

export default function Settings() {
  const message = useMessage();
  const loader = useLoader();

  const [wallet, setWallet] = useState({});
  const [autoPay, setAutoPay] = useState();
  const [autoRenewed, setAutoRenewed] = useState();
  const [stateUpdate, setStateUpdate] = useState(false);

  const [data, setData] = useState({
    amazonSpPercent: "",
    shopifySpPercent: "",
    amazonMrpPercent: "",
    shopifyMrpPercent: "",
    inventorySync: {
      amazon: false,
      shopify: false,
    },
    priceSync: {
      amazon: false,
      shopify: false,
    },
    brandName: "",
    companyName: "",
  });

  const [error, setError] = useState({
    amazonSpPercent: "",
    shopifySpPercent: "",
    amazonMrpPercent: "",
    shopifyMrpPercent: "",
    inventorySync: {
      amazon: false,
      shopify: false,
    },
    priceSync: {
      amazon: false,
      shopify: false,
    },
    brandName: "",
    companyName: "",
  });

  const handleOnChange = (e) => {
    if (e.target.name === "brandImage") {
      setData({ ...data, [e.target.name]: e.target.files[0] });
      setError({ ...error, [e.target.name]: "" });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
      setError({ ...error, [e.target.name]: "" });
    }
  };

  const handleOnClick = (key, name, value) => {
    setData({
      ...data,
      [key]: { ...data[key], [name]: value },
    });
    setStateUpdate(true);
  };

  const handleSettings = async () => {
    await SettingApiService.getSettings()
      .then(async (res) => {
        if (res.status.code === 200) {
          setData(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleWallet = async () => {
    await WalletApiService.getWalet()
      .then(async (res) => {
        if (res.status.code === 200) {
          setWallet(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleAutoRewel = async () => {
    loader.showLoading(true);
    await WalletApiService.getAutoRenewel()
      .then(async (res) => {
        loader.showLoading(false);

        if (res.status.code === 200) {
          setAutoRenewed(!autoRenewed);
          message.showToast(res.status.message, ToastTypes.SUCCESS);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleAutoPay = async () => {
    loader.showLoading(true);
    await WalletApiService.getAutoPay()
      .then(async (res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          message.showToast(res.status.message, ToastTypes.SUCCESS);
          setAutoPay(!autoPay);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleSave = () => {
    loader.showLoading(true);
    SettingApiService.updateSettings(JSON.stringify(data))
      .then(async (res) => {
        setStateUpdate(false);
        loader.showLoading(false);
        if (res.status.code === 200) {
          message.showToast(res.status.message, ToastTypes.SUCCESS);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        setStateUpdate(false);
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  useEffect(() => {
    handleWallet();
    handleSettings();
  }, []);

  useEffect(() => {
    setAutoPay(wallet?.wallet?.autoPay);
    setAutoRenewed(wallet?.wallet?.autoRenewed);
  }, [wallet]);

  useEffect(() => {
    if (stateUpdate) {
      handleSave();
    }
  }, [data]);

  return (
    <div className="setting_bg">
      <PriceMarkup
        data={data}
        error={error}
        handleOnChange={handleOnChange}
        onSubmit={handleSave}
        refresh={handleSettings}
      
      />

      <InventorySync
        data={data}
        handleOnClick={handleOnClick}
        onSubmit={handleSave}
      />
      <PriceSync
        data={data}
        handleOnClick={handleOnClick}
        onSubmit={handleSave}
      />

      <div className="order_section_row">
        <div>
          <p className="inventory_sync_head">Order Amount</p>

          <div className="inventory_sync_option_row">
            <div className="">
              <input
                type="checkbox"
                className="w-4 h-4"
                checked={autoPay}
                onClick={handleAutoPay}
              />
              <p className="text-lg">Enable Auto-Pay</p>
            </div>
          </div>
        </div>

        <div>
          <p className="inventory_sync_head">Member Fee</p>
          <div className="inventory_sync_option_row">
            <div className="">
              <input
                type="checkbox"
                className="w-4 h-4"
                checked={autoRenewed}
                onClick={handleAutoRewel}
              />
              <p className="text-lg">Enable Auto-Renewal</p>
            </div>
          </div>
        </div>
      </div>

      <div className="setting_brands_input_box" onSubmit={handleSave}>
        <p className="inventory_sync_head">Brand Name</p>
        <WebInput
          id="brandName"
          name="brandName"
          error={error.brandName}
          value={data.brandName}
          onChange={handleOnChange}
        />

        <p className="inventory_sync_head">Company Name</p>
        <WebInput
          id="companyName"
          name="companyName"
          error={error.companyName}
          value={data.companyName}
          onChange={handleOnChange}
        />

        <WebButton
          className="setting_submit_btn"
          text="Save"
          onClick={handleSave}
          type="submit"
        />
      </div>
    </div>
  );
}
