import React, { useEffect, useRef, useState } from "react";
import WebButton from "../../../../library/webButton";
import { ProductSubscriptionApiService } from "../../../../../service/seller/productSubscriptionApiService";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";
import { useOutsideClick } from "../../../../../base/context/OutsideClick";
import { RiArrowDropDownLine } from "react-icons/ri";
import "./style.css";

export default function DataExport({
  selectedSKUs,
  setSelectedSKUs,
  setSelectAll,
}) {
  const [showOptions, setShowOptions] = useState(false);

  const loader = useLoader();
  const message = useMessage();
  const outsideClick = useOutsideClick();
  const ref = useRef(null);

  const options = [
    { name: "Shopify Template", value: "shopify template" },
    { name: "Amazon Template", value: "amazon template" },
    { name: "Product Listing", value: "product listing" },
    { name: "Amazon Image", value: "amazon image" },
    { name: "Shopify Image", value: "shopify image" },
    { name: "Image Zip", value: "image zip" },
  ];

  const handleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleExport = (type) => {
    if (selectedSKUs.length) {
      loader.showLoading(true);

      if (type === "image zip") {
        const productPayload = {
          skus: selectedSKUs,
        };

        ProductSubscriptionApiService.exportSubscribeProductInImage(
          JSON.stringify(productPayload)
        )
          .then((res) => {
            loader.showLoading(false);
            if (res.status.code === 200) {
              setSelectedSKUs([]);
              setSelectAll(false);
              setShowOptions(false);

              const url = res.data.url;
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "products.csv");
              link.click();
            } else {
              message.showToast(res.error.message, ToastTypes.ERROR);
            }
          })
          .catch((err) => {
            setShowOptions(false);
            loader.showLoading(false);
            message.showToast(
              "Server error. Try again later",
              ToastTypes.ERROR
            );
          });
      } else if (type === "amazon template") {
        const productPayload = {
          skus: selectedSKUs,
        };

        ProductSubscriptionApiService.exportAmazonProduct(
          JSON.stringify(productPayload)
        )
          .then((res) => {
            loader.showLoading(false);
            if (res.status.code === 200) {
              setSelectedSKUs([]);
              setSelectAll(false);
              setShowOptions(false);

              const url = res.data.url;
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "AmazonTemplete.zip");
              link.click();
            } else {
              message.showToast(res.error.message, ToastTypes.ERROR);
            }
          })
          .catch((err) => {
            setShowOptions(false);
            loader.showLoading(false);
            message.showToast(
              "Server error. Try again later",
              ToastTypes.ERROR
            );
          });
      } else {
        const productPayload = {
          skus: selectedSKUs,
          type: type,
        };

        ProductSubscriptionApiService.exportSubscribeProduct(
          JSON.stringify(productPayload)
        )
          .then((res) => {
            loader.showLoading(false);
            if (res.status.code === 200) {
              setSelectedSKUs([]);
              setSelectAll(false);
              setShowOptions(false);

              const url = res.data.url;
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "products.csv");
              link.click();
            } else {
              message.showToast(res.error.message, ToastTypes.ERROR);
            }
          })
          .catch((err) => {
            setShowOptions(false);
            loader.showLoading(false);
            message.showToast(
              "Server error. Try again later",
              ToastTypes.ERROR
            );
          });
      }
    } else {
      setShowOptions(false);
      message.showToast("Select at least one product", ToastTypes.ERROR);
    }
  };

  const onClickOutside = () => {
    setShowOptions(false);
  };

  useEffect(() => {
    outsideClick.getComponent(ref, onClickOutside);
  }, [onClickOutside]);

  return (
    <div>
      <div
        onClick={() => handleOptions()}
        className="filter_dropdown export_dropdown_btn"
      >
        Data Export <RiArrowDropDownLine className="export_dropdown_icon" />
      </div>

      {showOptions ? (
        <div
          ref={ref}
          className={`${
            showOptions ? "w-60 h-auto" : null
          } h-0 bg-white border absolute shadow-xl flex flex-col gap-4 p-5`}
        >
          {options.map((item, key) => {
            return (
              <p
                key={key}
                className="cursor-pointer font-medium"
                onClick={() => handleExport(item.value)}
              >
                {item.name}
              </p>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
