// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order_steps{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    gap: 0.5rem;
}

.order_steps p{
    font-family: var(--sans-serif);
    font-size: var(--sans-serif-size-small);
    font-weight: var(--sans-serif-medium);
    padding: 0 0.2rem;
    cursor: pointer;
    color: rgb(42, 40, 40);
}

.active_order_step{
    color: var(--web-primary-color) !important;
    border-bottom: 2px solid var(--web-primary-color);
}

.order_step_line{
    width: 80px;
    height: 1px;
    background-color: transparent;
    border-bottom:0.1rem dashed rgb(42, 40, 40);
}`, "",{"version":3,"sources":["webpack://./src/website/component/views/checkout/orderSteps/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,8BAA8B;IAC9B,uCAAuC;IACvC,qCAAqC;IACrC,iBAAiB;IACjB,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,0CAA0C;IAC1C,iDAAiD;AACrD;;AAEA;IACI,WAAW;IACX,WAAW;IACX,6BAA6B;IAC7B,2CAA2C;AAC/C","sourcesContent":[".order_steps{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 1rem;\n    gap: 0.5rem;\n}\n\n.order_steps p{\n    font-family: var(--sans-serif);\n    font-size: var(--sans-serif-size-small);\n    font-weight: var(--sans-serif-medium);\n    padding: 0 0.2rem;\n    cursor: pointer;\n    color: rgb(42, 40, 40);\n}\n\n.active_order_step{\n    color: var(--web-primary-color) !important;\n    border-bottom: 2px solid var(--web-primary-color);\n}\n\n.order_step_line{\n    width: 80px;\n    height: 1px;\n    background-color: transparent;\n    border-bottom:0.1rem dashed rgb(42, 40, 40);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
