import React, { useEffect, useState } from "react";
import Sidebar from "../sidebar";
import Tabs from "../tabs";
import { useDispatch, useSelector } from "react-redux";
import { selectIsNightMode } from "../../../redux/slices/modeSlice";
import { nextVIcon, prevVIcon } from "../../../website/assets/icons";
import { useLocation } from "react-router-dom";
import { addItems, sidebarData } from "../../../redux/slices/sidebarSlice";
import "./style.css";

export default function Layout({ children }) {
  const [collapse, setCollapse] = useState(false);
  const [width, setWidth] = useState("85");
  const [menuDisplay,setMenuDisplay]=useState(false)
  const location = useLocation();


  const isNightMode = useSelector(selectIsNightMode);

  useEffect(() => {
    if (collapse) {
      setWidth("70");
    } else {
      setWidth("232");
    }
  }, [collapse]);

  const handleMenuCollapse = () => {
    setCollapse(!collapse);
  };

  //update the recent tabs on location change

  const dispatch = useDispatch();
  const tabs = useSelector(sidebarData);

  const updateTabs = async () => {
    const updatedTabs = tabs.map((tab) => {
      if (location.pathname.includes(tab?.tabLink)) {
        return { ...tab, active: true, opened: true };
      } else {
        return { ...tab, active: false };
      }
    });
    await dispatch(addItems(updatedTabs));
  };

  useEffect(() => {
    if (tabs && tabs?.length) {
      updateTabs();
    }
  }, [location.pathname]);



  useEffect(()=>{
    if(window.innerWidth < 768){
      setWidth(0)
      setCollapse(false)
    }

    console.log('window.innerWidth',window.innerWidth,collapse,width,menuDisplay)
  },[window.innerWidth])

  return (
    <div className="layout_box">
      <div
        className={`
          sidebar_collapse_btn ${
            collapse
              ? "sidebar_collapse_btn_open"
              : "sidebar_collapse_btn_close"
          }`}
        onClick={() => handleMenuCollapse()}
      >
        {collapse ? nextVIcon : prevVIcon}
      </div>

      <div className="layout_left">
        <Sidebar collapse={collapse} menuDisplay={menuDisplay} setMenuDisplay={setMenuDisplay} />
      </div>

      <div
        className={`layout_right  ${
          isNightMode ? "layout_right_dark" : "layout_right_light"
        }`}
        style={{ width: `calc(100vw - ${width}px)` }}
      >
        <Tabs setMenuDisplay={setMenuDisplay} menuDisplay={menuDisplay}/>
        <div className="layout_children">
          {children}
        </div>
      </div>
    </div>
  );
}
