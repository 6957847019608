import React from "react";
import One from "../../../../../assets/images/home/channel_partner/one.png";
import Two from "../../../../../assets/images/home/channel_partner/two.png";
import Three from "../../../../../assets/images/home/channel_partner/three.png";
import Four from "../../../../../assets/images/home/channel_partner/four.png";
import Five from "../../../../../assets/images/home/channel_partner/five.png";

import "./style.css";

export default function ChannelPartners() {
  return (
    <div className="section_box">
      <div className="why_us_bg">
        <p className="home_main_head">Our Channel Partners</p>

        <div className="channel_partner_img_row">
          <img src={One} />
          <img src={Two} />
          <img src={Three} />
          <img src={Four} />
          <img src={Five} />
        </div>
      </div>
    </div>
  );
}
