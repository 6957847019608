import HomePage from "./views/home";
import ReturnAndRefundPolicy from "./views/returnAndRefundPolicy";
import ShippingPolicy from "./views/shippingPolicy";
import Products from "./views/products";
import ProductDetails from "./views/productDetails";
import PrivacyPolicy from "./views/privacyPolicy";
import TermsCondition from "./views/termsCondition";
import AboutUs from "./views/aboutUs";
import UpsaleoDeliveryShippingGuide from "./views/upsaleoDeliveryShippingGuide";
import Pricing from "./views/pricing";
import BeASupplier from "./views/beAsupplier";
import HelpCenter from "./views/helpCenter";
import ContactUs from "./views/contactUs";
import SellingGuide from "./views/sellingGuide";
import HowDropshippingWork from "./views/howDropshippingWork";
import WhyDropshippingWithUs from "./views/whyDropshippingWithUs";
import FeaturedCategory from "./views/featuredCategory";
import Cart from "./views/checkout/cart";
import Address from "./views/checkout/address";


const routes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/shipping-policy",
    element: <ShippingPolicy />,
  },
  {
    path: "/return-refund-policy",
    element: <ReturnAndRefundPolicy />,
  },
  { path: "/selling-guide", element: <SellingGuide /> },
  {
    path: "/collection",
    element: <Products />,
  },
  {
    path: "/collection/:category",
    element: <Products />,
  },
  {
    path: "/collection/:category/:subCategory",
    element: <Products />,
  },
  {
    path: "/collection/:category/:subCategory/:subSubCategory",
    element: <Products />,
  },
  {
    path: "/product-details",
    element: <ProductDetails />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsCondition />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/delivery-and-shipping-guide",
    element: <UpsaleoDeliveryShippingGuide />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/be-a-supplier",
    element: <BeASupplier />,
  },
  {
    path: "/help-center",
    element: <HelpCenter />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/why-work-with-upsaleo",
    element: <WhyDropshippingWithUs />,
  },
  {
    path: "/how-dropshiping-work",
    element: <HowDropshippingWork />,
  },
  {
    path: "/fc/:category",
    element: <FeaturedCategory />,
  },
  {
    path: "/checkout/cart",
    element: <Cart />,
  },
  {
    path: "/checkout/address",
    element: <Address />,
  },
];

export default routes;
