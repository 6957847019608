// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date_picker{
    display: flex;
    gap: 1.2rem;
    z-index: 0;
}

.date_picker input{
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25) !important;
    /* height: 30px !important; */
    border-radius: 5px !important;
    padding-bottom: 0 !important;
    color: rgba(0, 0, 0, 0.6) !important;
    font-weight: 500 !important;
    outline: none !important;
    font-size: 1rem !important;
    display: flex;
    align-items: center;
    
}

.css-1xgnz6y-MuiStack-root{
    padding-top: 0 !important;
}

/* .date_picker input:hover{
    border: none !important;
    outline: none !important;
}

.date_picker input:focus{
    border: none !important;
}

.css-1xgnz6y-MuiStack-root{
    overflow: hidden !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    position: absolute !important;;
    top: -10px !important;
} */`, "",{"version":3,"sources":["webpack://./src/component/library/datePicker/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,UAAU;AACd;;AAEA;IACI,oDAAoD;IACpD,6BAA6B;IAC7B,6BAA6B;IAE7B,4BAA4B;IAE5B,oCAAoC;IACpC,2BAA2B;IAC3B,wBAAwB;IACxB,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;;AAEvB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;;;;;;;;;;;;;;;;GAgBG","sourcesContent":[".date_picker{\n    display: flex;\n    gap: 1.2rem;\n    z-index: 0;\n}\n\n.date_picker input{\n    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25) !important;\n    /* height: 30px !important; */\n    border-radius: 5px !important;\n    outline: none !important;\n    padding-bottom: 0 !important;\n    font-size: 1.2rem !important;\n    color: rgba(0, 0, 0, 0.6) !important;\n    font-weight: 500 !important;\n    outline: none !important;\n    font-size: 1rem !important;\n    display: flex;\n    align-items: center;\n    \n}\n\n.css-1xgnz6y-MuiStack-root{\n    padding-top: 0 !important;\n}\n\n/* .date_picker input:hover{\n    border: none !important;\n    outline: none !important;\n}\n\n.date_picker input:focus{\n    border: none !important;\n}\n\n.css-1xgnz6y-MuiStack-root{\n    overflow: hidden !important;\n}\n\n.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{\n    position: absolute !important;;\n    top: -10px !important;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
