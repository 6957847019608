// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.why_us_bg {
    width: 100%;
}

.why_us_content_row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.why_us_content_box{
    width: 320px;
    height: 200px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.why_us_content_box h2{
    width: 80%;
    font-size: 1.1rem;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: #04529F;
    text-align: center;
    height: 35px;
}

.why_us_content_box p{
    width: 80%;
    font-size: 0.9rem;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    text-align: center; 
    line-height: 1.2rem;  
    height: 120px;
}

.why_us_content_box img{
    width: 320px;
    height: 200px;
}


@media (max-width:768px) {

    .why_us_content_row .why_us_content_box:nth-child(5) {
        order: 6;
      }
      
      .why_us_content_row .why_us_content_box:nth-child(6) {
        order: 5;
      }

      .why_us_content_row .why_us_content_box:nth-child(7) {
        order: 8;
      }
      
      .why_us_content_row .why_us_content_box:nth-child(8) {
        order: 7;
      }
      
   
      
   
    
}`, "",{"version":3,"sources":["webpack://./src/website/component/views/home/whyUs/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,gCAAgC;IAChC,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,gCAAgC;IAChC,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;;AAGA;;IAEI;QACI,QAAQ;MACV;;MAEA;QACE,QAAQ;MACV;;MAEA;QACE,QAAQ;MACV;;MAEA;QACE,QAAQ;MACV;;;;;;AAMN","sourcesContent":[".why_us_bg {\n    width: 100%;\n}\n\n.why_us_content_row{\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n\n.why_us_content_box{\n    width: 320px;\n    height: 200px;\n    background-color: #ffffff;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.why_us_content_box h2{\n    width: 80%;\n    font-size: 1.1rem;\n    font-family: \"Inter\", sans-serif;\n    font-weight: 600;\n    color: #04529F;\n    text-align: center;\n    height: 35px;\n}\n\n.why_us_content_box p{\n    width: 80%;\n    font-size: 0.9rem;\n    font-family: \"Inter\", sans-serif;\n    font-weight: 500;\n    text-align: center; \n    line-height: 1.2rem;  \n    height: 120px;\n}\n\n.why_us_content_box img{\n    width: 320px;\n    height: 200px;\n}\n\n\n@media (max-width:768px) {\n\n    .why_us_content_row .why_us_content_box:nth-child(5) {\n        order: 6;\n      }\n      \n      .why_us_content_row .why_us_content_box:nth-child(6) {\n        order: 5;\n      }\n\n      .why_us_content_row .why_us_content_box:nth-child(7) {\n        order: 8;\n      }\n      \n      .why_us_content_row .why_us_content_box:nth-child(8) {\n        order: 7;\n      }\n      \n   \n      \n   \n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
