// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marketplace_box{
    display: flex;
    gap: 4rem;
    align-items:center;
}

.marketplace_box img:nth-child(1) {
    height: 3.5rem;
    cursor: pointer;
}

.marketplace_box div img{
    height: 3rem;
    margin-top: 1.5rem;
    cursor: pointer;
}

.market_place_head{
    font-size: 1.2rem;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    margin-bottom: 1rem;
}

.marketplace_bg{
    padding: 1rem;
}

.stores_table_head{
    font-size: 1.2rem;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    margin: 2rem 0 -0.5rem 0;
}


@media (max-width:768px) {
    .marketplace_box{
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }   
}`, "",{"version":3,"sources":["webpack://./src/views/seller/marketPlaceApi/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,gCAAgC;IAChC,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,gCAAgC;IAChC,wBAAwB;AAC5B;;;AAGA;IACI;QACI,aAAa;QACb,eAAe;QACf,SAAS;IACb;AACJ","sourcesContent":[".marketplace_box{\n    display: flex;\n    gap: 4rem;\n    align-items:center;\n}\n\n.marketplace_box img:nth-child(1) {\n    height: 3.5rem;\n    cursor: pointer;\n}\n\n.marketplace_box div img{\n    height: 3rem;\n    margin-top: 1.5rem;\n    cursor: pointer;\n}\n\n.market_place_head{\n    font-size: 1.2rem;\n    font-weight: 500;\n    font-family: \"Inter\", sans-serif;\n    margin-bottom: 1rem;\n}\n\n.marketplace_bg{\n    padding: 1rem;\n}\n\n.stores_table_head{\n    font-size: 1.2rem;\n    font-weight: 500;\n    font-family: \"Inter\", sans-serif;\n    margin: 2rem 0 -0.5rem 0;\n}\n\n\n@media (max-width:768px) {\n    .marketplace_box{\n        display: flex;\n        flex-wrap: wrap;\n        gap: 1rem;\n    }   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
