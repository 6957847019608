// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* RefundDetails.css */

.refund-details {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.refund-details h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.detail-row {
  display: flex;
  margin-bottom: 10px;
}

.detail-label {
  min-width: 150px;
  font-weight: bold;
  color: #666;
}

.detail-value {
  flex: 1 1;
}

.refund-images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.refund-images img {
  max-width: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/views/admin/ticketManagement/RefundDetails.js/RefundDetails.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAEtB;EACE,yBAAyB;EACzB,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,uCAAuC;AACzC;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,aAAa;EACb,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":["/* RefundDetails.css */\n\n.refund-details {\n  background-color: #f9f9f9;\n  border: 1px solid #ddd;\n  padding: 20px;\n  border-radius: 8px;\n  margin-bottom: 20px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.refund-details h2 {\n  margin-bottom: 20px;\n  font-size: 24px;\n  color: #333;\n}\n\n.detail-row {\n  display: flex;\n  margin-bottom: 10px;\n}\n\n.detail-label {\n  min-width: 150px;\n  font-weight: bold;\n  color: #666;\n}\n\n.detail-value {\n  flex: 1;\n}\n\n.refund-images {\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 10px;\n}\n\n.refund-images img {\n  max-width: 100px;\n  margin-right: 10px;\n  margin-bottom: 10px;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
