import React, { useEffect, useState } from "react";
import { categoryApiService } from "../../../../service/admin/categoryApiService";
import { useLoader } from "../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";
import { MdModeEdit } from "react-icons/md";
import { IoEye } from "react-icons/io5";
import { IoMdEyeOff } from "react-icons/io";
import { IoArrowBack } from "react-icons/io5";
import WebButton from "../../../../component/library/webButton";
import EditCategory from "../../../../component/views/admin/category/editCategory";
import { useNavigate } from "react-router-dom";
import "./style.css";

export default function ExistingCategory() {
  const [categories, setCategories] = useState([]);
  const [editingCategory, setEditingCategory] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [newProductShow, setNewProductShow] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [previousCategories, setPreviousCategories] = useState([]);
  const [categoryPath, setCategoryPath] = useState([]);
  const [open, setOpen] = useState(false);
  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    fetchExistingCategory();
  }, []);

  const fetchExistingCategory = () => {
    loader.showLoading(true);
    categoryApiService
      .getExistingCategory()
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setCategories(res.data);
        } else if (res.status.code === 402) {
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch(() => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleSubClick = (item) => {
    setPreviousCategories([...previousCategories, categories]);
    setCategories(item.children);
    setCategoryPath([...categoryPath, item.c_name]);
    setSelectedCategory(item.c_name);
    setEditingCategory(null); // Reset editing category when navigating
  };

  const handleBackClick = () => {
    if (previousCategories.length > 0) {
      setCategories(previousCategories.pop());
      setPreviousCategories([...previousCategories]);
      setCategoryPath(categoryPath.slice(0, -1));
      setSelectedCategory("");
      setEditingCategory(null); // Reset editing category when navigating back
    }
  };

  const startEditingCategory = (item) => {
    setEditingCategory(item._id);
    setNewCategoryName(item.c_name);
    setNewProductShow(item.shownProductLimit || 50);
  };

  const handleUpdateCategory = (clickedCategoryName, hide) => {
    const payload = {
      oldCategoryName: categoryPath.concat(clickedCategoryName).join("/"),
      newCategory: { hide: !hide },
    };
    loader.showLoading(true);
    categoryApiService
      .updateCategory(JSON.stringify(payload))
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          fetchExistingCategory();
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch(() => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleOnClick = (c_name) => {
    setOpen(true);
    setSelectedCategory(categoryPath.concat(c_name).join("/"));
  };

  const handleContentClick = (clickedCategoryName, contentId) => {
    const fullCategoryName = categoryPath.concat(clickedCategoryName).join("/");
    console.log("++++++++++++++++", fullCategoryName);
    console.log(contentId);
    console.log(clickedCategoryName);
    if (!contentId || contentId == undefined) {
      const payload = {
        oldCategoryName: fullCategoryName,
        newCategory: {},
      };
      categoryApiService
        .createBlankCategoryContent(JSON.stringify(payload))
        .then((res) => {
          if (res.status.code === 200) {
            let id = res.data._id;
            navigate(`/admin/categories/content?id=${id}`);
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch(() => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
        });
    } else {
      navigate(`/admin/categories/content?id=${contentId}`);
    }
  };

  const handleOnClickClose = () => {
    setOpen(false);
  };

  const saveCategoryName = (clickedCategoryName) => {
    const fullCategoryName = categoryPath.concat(clickedCategoryName).join("/");
    const payload = {
      oldCategoryName: fullCategoryName,
      newCategory: {
        c_name: newCategoryName,
        shownProductLimit: newProductShow,
      },
    };
    loader.showLoading(true);
    categoryApiService
      .updateCategory(JSON.stringify(payload))
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          fetchExistingCategory();
          setEditingCategory(null); // Reset editing category after saving
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch(() => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  return (
    <div style={{ paddingBottom: "20px" }} className="container">
      <header className="header">
        <h1>Admin Portal - Categories List</h1>
      </header>
      <div className="main-content">
        {previousCategories.length > 0 && (
          <div className="cursor-pointer mb-3" onClick={handleBackClick}>
            <IoArrowBack />
          </div>
        )}
        <table className="table_box text-black">
          <thead>
            <tr>
              <th>Categories</th>
              <th>Product Show</th>
              <th>Sub-Categories</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories.length > 0 &&
              categories.map((item) => {
                const rowClass = item.hide ? "line-through" : "";
                return (
                  <tr key={item._id} className={rowClass}>
                    <td>
                      {editingCategory === item._id ? (
                        <input
                          type="text"
                          value={newCategoryName}
                          onChange={(e) => setNewCategoryName(e.target.value)}
                          className="input_field"
                        />
                      ) : (
                        <span>{item.c_name}</span>
                      )}
                    </td>
                    <td>
                      {editingCategory === item._id ? (
                        <input
                          type="text"
                          value={newProductShow}
                          onChange={(e) => setNewProductShow(e.target.value)}
                          className="input_field"
                        />
                      ) : (
                        <span>
                          {item.shownProductLimit ? item.shownProductLimit : 50}
                        </span>
                      )}
                    </td>
                    <td>
                      {item.children?.length ? (
                        <span
                          className="text-blue-600 cursor-pointer"
                          onClick={() => handleSubClick(item)}
                        >
                          sub-items
                        </span>
                      ) : (
                        <WebButton
                          onClick={() => handleOnClick(item.c_name)}
                          text="Add New Sub-Category"
                          className="bg-blue-500 p-2 py-1 text-white"
                        />
                      )}
                    </td>
                    <td className="cursor-pointer flex gap-4 items-center">
                      {item.hide ? (
                        <IoMdEyeOff
                          onClick={() =>
                            handleUpdateCategory(item.c_name, item.hide)
                          }
                        />
                      ) : (
                        <IoEye
                          onClick={() =>
                            handleUpdateCategory(item.c_name, item.hide)
                          }
                        />
                      )}
                      {editingCategory === item._id ? (
                        <WebButton
                          text="Save"
                          onClick={() => saveCategoryName(item.c_name)}
                          className="bg-green-500 p-2 py-1 text-white"
                        />
                      ) : (
                        <MdModeEdit
                          onClick={() => startEditingCategory(item)}
                        />
                      )}
                      <WebButton
                        text="Html Content"
                        onClick={() =>
                          handleContentClick(item.children, item.contentId)
                        }
                        className="bg-green-500 p-2 py-1 text-white"
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <EditCategory
          open={open}
          handleClose={handleOnClickClose}
          category={selectedCategory}
          categories={categories}
          ExistingCategory={fetchExistingCategory}
        />
      </div>
    </div>
  );
}
