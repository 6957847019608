import React, { useEffect, useState } from "react";
import ShopifyImage from "../../../assets/images/marketPlace/shopify.png";
import AmazonImage from "../../../assets/images/marketPlace/amazon.png";
import ShopifyForm from "../../../component/views/seller/marketPlace/shopifyForm";
import { selectIsNightMode } from "../../../redux/slices/modeSlice";
import { useSelector } from "react-redux";
import { marketPlaceApiService } from "../../../service/seller/marketPlaceApiService";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { useLoader } from "../../../base/context/LoaderProvider";
import { Link } from "react-router-dom";
import "./style.css";

export default function MarketplaceApis() {
  const [shopifyModal, setShopifyModal] = useState(false);
  const isNightMode = useSelector(selectIsNightMode);
  const [stores, setStores] = useState([]);

  const message = useMessage();
  const loader = useLoader();

  useEffect(() => {
    handleStores();
  }, []);

  const handleStores = () => {
    loader.showLoading(true);
    marketPlaceApiService
      .getStoresList()
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setStores(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        if (err.status.code === 500) {
          message.showToast("Invalid credentials", ToastTypes.ERROR);
        } else {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
        }
      });
  };

  const handleShopify = () => {
    setShopifyModal(!shopifyModal);
  };

  return (
    <div className="marketplace_bg">
      <p className="market_place_head">
        Please select the marketplace you want to connect.
      </p>

      <div className="marketplace_box">
        <img src={ShopifyImage} onClick={() => handleShopify()} />

        <div>
          <Link to="/amazon/connect">
            <img src={AmazonImage} />
          </Link>
        </div>
      </div>

      {shopifyModal ? (
        <ShopifyForm
          modalIsOpen={shopifyModal}
          setIsOpen={setShopifyModal}
          handleStores={handleStores}
        />
      ) : null}

      <p className="stores_table_head">Amazon Connected Store</p>
      <div className="seller_table_wrapper">
        <table className="seller_table">
          <thead>
            <tr>
              <td>Selling Partner Id</td>
              <td>Sp Api Oauth Code</td>
            </tr>
          </thead>
          <tbody>
            {stores?.amazonStore?.map((store, index) => {
              return (
                <tr key={index}>
                  <td>{store.sellingPartnerId}</td>
                  <td>{store.spapi_oauth_code}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <p className="stores_table_head">Shopify Connected Stores</p>
      <div className="seller_table_wrapper">
      <table className="seller_table">
        <thead>
          <tr>
            <td>Store Domain</td>
            <td>Shopify Secret Key</td>
          </tr>
        </thead>
        <tbody>
          {stores?.shopifyStore?.map((store, index) => {
            return (
              <tr key={index}>
                <td>{store.storeDomain}</td>
                <td>{store.shopifySecretKey}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
    </div>
  );
}
