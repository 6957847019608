// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order_detail_info_box{
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
    padding: 1rem;
}

.order_detail_head{
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
}

.order_detail_info p{
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 0.9rem;
    margin: 1rem 0;
}

.order_detail_info span{
    font-family: "Inter", sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    margin-left: 1rem;
}

.order_detail_info_row{
    display: flex;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/component/views/admin/orderDetails/orderInfo/style.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,2CAA2C;IAC3C,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,gCAAgC;IAChC,gBAAgB;IAChB,iBAAiB;IACjB,4CAA4C;IAC5C,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,gCAAgC;IAChC,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".order_detail_info_box{\n    border: 1px solid rgba(0, 0, 0, 0.25);\n    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);\n    border-radius: 1rem;\n    padding: 1rem;\n}\n\n.order_detail_head{\n    text-align: center;\n    font-family: \"Inter\", sans-serif;\n    font-weight: 600;\n    font-size: 1.2rem;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.25);\n    margin-bottom: 1rem;\n    padding-bottom: 0.5rem;\n}\n\n.order_detail_info p{\n    font-family: \"Inter\", sans-serif;\n    font-weight: 500;\n    font-size: 0.9rem;\n    margin: 1rem 0;\n}\n\n.order_detail_info span{\n    font-family: \"Inter\", sans-serif;\n    font-size: 0.9rem;\n    font-weight: 400;\n    margin-left: 1rem;\n}\n\n.order_detail_info_row{\n    display: flex;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
