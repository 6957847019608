// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert_popup_msg{
    text-align: center;
    font-family: var(--sans-serif);
    font-size: var(--sans-serif-size-regular);
    font-weight: var(--sans-serif-regular);
    max-width: 400px;
}

.alert_popup_btn_row{
    display: flex;
    justify-content: space-between;
    gap: 5rem;
    margin-top: 2rem;
}


@media (max-width:768px) {
    .alert_popup_msg{
        max-width: 100%;
    }
    
    .alert_popup_btn_row{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        gap: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/library/alertPopup/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,8BAA8B;IAC9B,yCAAyC;IACzC,sCAAsC;IACtC,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,gBAAgB;AACpB;;;AAGA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,aAAa;QACb,8BAA8B;QAC9B,WAAW;QACX,SAAS;IACb;AACJ","sourcesContent":[".alert_popup_msg{\n    text-align: center;\n    font-family: var(--sans-serif);\n    font-size: var(--sans-serif-size-regular);\n    font-weight: var(--sans-serif-regular);\n    max-width: 400px;\n}\n\n.alert_popup_btn_row{\n    display: flex;\n    justify-content: space-between;\n    gap: 5rem;\n    margin-top: 2rem;\n}\n\n\n@media (max-width:768px) {\n    .alert_popup_msg{\n        max-width: 100%;\n    }\n    \n    .alert_popup_btn_row{\n        display: flex;\n        flex-direction: column-reverse;\n        width: 100%;\n        gap: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
