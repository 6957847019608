// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile_filter_bg{
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}



.mobile_filter_bg .navbar_category_menu_container_show{
    height:100dvh;
}

.close_btn{
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1.5rem;
    margin-bottom: -1rem;
}`, "",{"version":3,"sources":["webpack://./src/website/component/views/collection/mobileFilteration/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,MAAM;IACN,OAAO;IACP,UAAU;AACd;;;;AAIA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,uBAAkB;IAAlB,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":[".mobile_filter_bg{\n    width: 100vw;\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 2;\n}\n\n\n\n.mobile_filter_bg .navbar_category_menu_container_show{\n    height:100dvh;\n}\n\n.close_btn{\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n    width: fit-content;\n    font-size: 1.5rem;\n    margin-bottom: -1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
