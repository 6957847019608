import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Chat from "../../../../component/common/chat";
import WebNavbar from "../navbar";
import WebFooter from "../footer";
import "../../../assets/colors/index.css";
import "../../../assets/fonts/index.css";
import "./style.css";

export default function WebLayout({ children }) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="web_layout_bg">
      <WebNavbar />
      <div className="web_layout_children">
        <div className="web_layout_children_content">{children}</div>
        <Chat />
      </div>
      <WebFooter />
    </div>
  );
}
