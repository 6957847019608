// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password_modal_box{
    border-bottom: 1px solid #000000c7;
    width: 300px;
    margin:0.5rem 2rem;
}`, "",{"version":3,"sources":["webpack://./src/component/views/seller/profile/passwordUpdate/style.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".password_modal_box{\n    border-bottom: 1px solid #000000c7;\n    width: 300px;\n    margin:0.5rem 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
