// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.export_dropdown_btn{
    display: flex;
    align-items: center;
    gap: 2rem;
    cursor: pointer;
}

.export_dropdown_icon{
    font-size: 1rem;
    font-size: 30px;
    display:inline-flex;
}`, "",{"version":3,"sources":["webpack://./src/component/views/seller/subscription/dataExport/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".export_dropdown_btn{\n    display: flex;\n    align-items: center;\n    gap: 2rem;\n    cursor: pointer;\n}\n\n.export_dropdown_icon{\n    font-size: 1rem;\n    font-size: 30px;\n    display:inline-flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
