// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_banner_row{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

@media (max-width:768px) {
    .home_banner_row{
        flex-direction: column;
    }

    .home_banner_row .category_menu_bg:nth-child(1){
        display: none;
    }

    .banner_right_box_bg{
        width: 100%;
        margin-top: 1rem;
        height:-moz-fit-content;
        height:fit-content;
    }
}
`, "",{"version":3,"sources":["webpack://./src/website/component/views/home/Banner/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,aAAa;IACjB;;IAEA;QACI,WAAW;QACX,gBAAgB;QAChB,uBAAkB;QAAlB,kBAAkB;IACtB;AACJ","sourcesContent":[".home_banner_row{\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n}\n\n@media (max-width:768px) {\n    .home_banner_row{\n        flex-direction: column;\n    }\n\n    .home_banner_row .category_menu_bg:nth-child(1){\n        display: none;\n    }\n\n    .banner_right_box_bg{\n        width: 100%;\n        margin-top: 1rem;\n        height:fit-content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
