import { NetworkConfiguration } from "../../network/networkConfriguation";
import { callAPI } from "../../network/networkConnection";

const getSubscription = () => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.SUBSCRIPTION_PLANS,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const subscribeProduct = (id) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.SUBSCRIBE_PRODUCT +
        `/${id}`,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const unSubscribeProduct = (id) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.UNSUBSCRIBE_PRODUCT +
        `/${id}`,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const deleteSubscription = (id) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.DELETE_SUBSCRIPTION +
        `/${id}`,
      "DELETE",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const addSubscription = (payload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.ADD_SUBSCRIPTION,
      "POST",
      {
        "Content-Type": "application/json",
      },
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getSubscriptionById = (id) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.SUBSCRIPTION_BY_ID +
        id,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const editSubscription = (payload, id) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.EDIT_SUBSCRIPTION +
        id,
      "PUT",
      {
        "Content-Type": "application/json",
      },
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const SubscriptionApiService = {
  getSubscription,
  deleteSubscription,
  addSubscription,
  subscribeProduct,
  unSubscribeProduct,
  getSubscriptionById,
  editSubscription,
};
