import React, { useEffect, useState } from "react";
import WebInput from "../../../../component/library/webInput";
import WebDropdown from "../../../../component/library/webDropdown";
import { productListApiService } from "../../../../service/admin/productApiService";
import { useNavigate, useParams } from "react-router-dom";
import WebButton from "../../../../component/library/webButton";
import "./style.css";
import { RxCross2 } from "react-icons/rx";
import { useLoader } from "../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";

export default function EditProduct() {
  const [data, setData] = useState({});
  const loader = useLoader();
  const message = useMessage();
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [selectedImage, setSeletedImage] = useState(null);

  useEffect(() => {
    fetchDetails();
  }, []);

  const editProduct = () => {
    loader.showLoading(true);

    const payload = {
      categoryId: data.categoryId,
      sku: data?.sku,
      title: data?.title,
      nodeId: data?.nodeId,
      description: data?.description,
      quantity: data?.quantity,
      length: data?.length,
      width: data?.width,
      height: data?.height,
      weight: data?.weight,
      pack_unit: data?.pack_unit,
      vendor_price: data?.vendor_price,
      mrp: data?.mrp,
      sp: data?.sp,
      warranty: data?.warranty,
      hsn: data?.hsn,
      brand: data?.brand,
      location: data?.location,
    };
    productListApiService
      .editProducts(JSON.stringify(payload), params.id)
      .then((res) => {
        if (res.status.code === 200) {
          loader.showLoading(false);
          navigate("/admin/products/list");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  const fetchDetails = () => {
    loader.showLoading(true);
    productListApiService
      .getProductById(params.id)
      .then((res) => {
        if (res.status.code === 200) {
          loader.showLoading(false);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
        setData(res.data);
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  const handleOnchange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const handleImage = (e) => {
    setSeletedImage(e.target.files[0]);
  };

  const handleImageUpload = (id) => {
    loader.showLoading(true);
    const payload = new FormData();
    payload.append("images", selectedImage);
    console.log("payload", payload);
    productListApiService
      .uploadImage(payload, id)
      .then((res) => {
        if (res.status.code === 200) {
          loader.showLoading(false);
        } else {
          loader.showLoading(false);
          console.log("123");
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        console.log(err);
      });
  };

  const handleRemoveImage = (index) => {
    const updatedImages = data.imgUrl.filter((_, i) => i !== index);
    setData({ ...data, imgUrl: updatedImages });
  };

  useEffect(() => {
    console.log("selectedImage", selectedImage);
  }, [selectedImage]);
  const divStyle = {
    paddingBottom: "20px",
  };
  return (
    <div className="flex flex-col gap-x-10 gap-y-2" style={divStyle}>
      {/* <WebDropdown
        options={options}
        className="border px-3  py-2 w-full rounded"
        parentClassName="w-2/5"
      /> */}

      <WebInput
        type="text"
        placeholder="enter category"
        label="Category Name"
        id="categoryName"
        onChange={handleOnchange}
        error={error.c_name}
        value={data?.c_name}
        name="c_name"
        parentClassName="w-2/5"
      />

      <WebInput
        type="text"
        placeholder=""
        label="SKU"
        id="sku"
        onChange={handleOnchange}
        error={error.sku}
        value={data?.sku}
        name="sku"
      />

      <WebInput
        type="text"
        placeholder=""
        label="TITLE"
        id="title"
        onChange={handleOnchange}
        error={error.title}
        value={data?.title}
        name="title"
      />

      <WebInput
        type="text"
        placeholder=""
        label="DESCRIPTION"
        id="description"
        onChange={handleOnchange}
        error={error.description}
        value={data?.description}
        name="description"
      />

      <div className="flex w-10/12 gap-x-10">
        <WebInput
          type="text"
          placeholder=""
          label="MRP"
          id="mrp"
          onChange={handleOnchange}
          error={error.mrp}
          value={data?.mrp}
          name="mrp"
        />

        <WebInput
          type="text"
          placeholder=""
          label="VENDOR PRICE"
          id="vendor_price"
          onChange={handleOnchange}
          error={error.vendor_price}
          value={data?.vendor_price}
          name="vendor_price"
        />

        <WebInput
          type="text"
          placeholder=""
          label="SELLING PRICE"
          id="sp"
          onChange={handleOnchange}
          error={error.sp}
          value={data?.sp}
          name="sp"
        />
      </div>

      <div className="flex flex-wrap w-10/12 gap-x-10">
        <WebInput
          type="text"
          placeholder=""
          label="LENGTH"
          id="length"
          onChange={handleOnchange}
          error={error.length}
          value={data?.length}
          name="length"
        />

        <WebInput
          type="text"
          placeholder=""
          label="WIDTH"
          id="width"
          onChange={handleOnchange}
          error={error.width}
          value={data?.width}
          name="width"
        />

        <WebInput
          type="text"
          placeholder=""
          label="HEIGHT"
          id="height"
          onChange={handleOnchange}
          error={error.height}
          value={data?.height}
          name="height"
        />

        <WebInput
          type="text"
          placeholder=""
          label="WEIGHT"
          id="weight"
          onChange={handleOnchange}
          error={error.weight}
          value={data?.weight}
          name="weight"
        />
      </div>

      <div className="flex flex-wrap w-10/12 gap-x-10">
        <WebInput
          type="text"
          placeholder=""
          label="PACKET UNIT"
          id="pack_unit"
          onChange={handleOnchange}
          error={error.pack_unit}
          value={data?.pack_unit}
          name="pack_unit"
        />

        <WebInput
          type="text"
          placeholder=""
          label="QUANTITY"
          id="quantity"
          onChange={handleOnchange}
          error={error.quantity}
          value={data?.quantity}
          name="quantity"
        />

        <WebInput
          type="text"
          placeholder=""
          label="WARRANTY"
          id="warranty"
          onChange={handleOnchange}
          error={error.warranty}
          value={data?.warranty}
          name="warranty"
        />

        <WebInput
          type="text"
          placeholder=""
          label="HSN"
          id="hsn"
          onChange={handleOnchange}
          error={error.hsn}
          value={data?.hsn}
          name="hsn"
        />
      </div>

      <div className="flex w-10/12 gap-x-10">
        <WebInput
          type="text"
          placeholder=""
          label="BRAND"
          id="brand"
          onChange={handleOnchange}
          error={error.brand}
          value={data?.brand}
          name="brand"
        />

        <WebInput
          type="text"
          placeholder=""
          label="LOCATION"
          id="location"
          onChange={handleOnchange}
          error={error.location}
          value={data?.location}
          name="location"
        />
      </div>

      <div className="flex gap-2 ">
        {data?.imgUrl?.map((img, index) => (
          <div key={index} className="relative">
            <img src={img} alt="img" className="w-24 image_cross" />
            <button
              className="remove-icon"
              onClick={() => handleRemoveImage(index)}
            >
              <RxCross2 />
            </button>
          </div>
        ))}

        {selectedImage && (
          <img
            src={URL.createObjectURL(selectedImage)}
            alt="selected"
            className="w-24"
          />
        )}

        <label htmlFor="file-input" className="file-input-label">
          +
        </label>

        <input
          type="file"
          id="file-input"
          className="file-input"
          onChange={handleImage}
        />

        <WebButton
          onClick={() => handleImageUpload(data._id)}
          text="Upload"
          className="bg-blue-500 text-white p-2"
        />
      </div>

      <WebButton
        text="Save"
        className="bg-blue-500 text-white w-48 text-xl py-2 rounded-md mt-3 m-auto"
        onClick={editProduct}
      />
    </div>
  );
}
