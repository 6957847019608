import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import WebInput from "../../../../library/webInput";
import { useSelector } from "react-redux";
import { selectIsNightMode } from "../../../../../redux/slices/modeSlice";
import { AuthApiService } from "../../../../../service/authApiService";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";
import { ImMobile } from "react-icons/im";
import Modal from "react-modal";
import "./style.css";
import WebButton from "../../../../library/webButton";
import { phoneFirstLetter } from "../../../../../helper/regex";
import { IoAlertCircle } from "react-icons/io5";
import WebOtpInput from "../../../../library/webOtpInput";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function PhoneInput({ data, handleUpdate }) {
  const loader = useLoader();
  const message = useMessage();
  const isNightMode = useSelector(selectIsNightMode);
  const [error, setError] = useState("");

  const [modalIsOpen, setIsOpen] = useState(false);
  const [phone, setPhone] = useState("");

  const [otpSend, setOtpSend] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [timer, setTimer] = useState();
  const [time, setTime] = useState(60);
  const [otp, setOtp] = useState("");

  function closeModal() {
    setIsOpen(false);
  }

  //phone verifcation
  const handlePhone = (e) => {
    if (!phoneVerified) {
      setPhone(e.target.value.slice(0, 10));
      setError("");
      if (otpSend) {
        setOtpSend(false);
        setTimer(false);
      }
    }
  };

  const validatePhone = () => {
    let result = true;
    if (!phone.length) {
      setError("Phone is required");
      result = false;
    } else if (phone.length !== 10) {
      setError("Not a valid phone number");
      result = false;
    } else if (phone.length === 10) {
      let firstLetter = phone.slice(0, 1);
      if (!phoneFirstLetter.some((num) => num === firstLetter)) {
        setError("Not a valid phone number");
        result = false;
      }
    }
    return result;
  };

  useEffect(() => {
    if (timer) {
      if (time > 0) {
        setTimeout(() => {
          setTime(time - 1);
        }, 1000);
      } else {
        setTimer(false);
        setTime(60);
      }
    } else {
      setTime(60);
    }
  }, [time, timer]);

  const sendOtptoPhone = (e) => {
    e.preventDefault();
    if (validatePhone()) {
      loader.showLoading(true);
      const payload = {
        phone: phone,
      };

      AuthApiService.sendOtp(JSON.stringify(payload))
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            message.showToast(res.status.message, ToastTypes.SUCCESS);
            setTimer(true);
            setOtpSend(true);
            setError("");
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    }
  };

  const handleVerifyOtp = () => {
    loader.showLoading(true);
    const otpPayload = {
      phone: phone,
      otp: otp,
    };

    AuthApiService.verifyOtp(JSON.stringify(otpPayload))
      .then(async (res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          message.showToast(res.status.message, ToastTypes.SUCCESS);
          closeModal();
          setPhoneVerified(true);
          setError("");
          setTimer(false);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  useEffect(() => {
    if (otp.length === 6 && !phoneVerified) {
      handleVerifyOtp();
    }
  }, [otp]);

  const handleUpdatePhone = () => {
    loader.showLoading(true);

    const authPayload = {
      phone: phone,
    };

    AuthApiService.updateProfile(JSON.stringify(authPayload))
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          message.showToast("Phone updated successfully", ToastTypes.SUCCESS);
          handleUpdate();
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  useEffect(() => {
    if (phoneVerified) {
      handleUpdatePhone();
    }
  }, [phoneVerified]);

  return (
    <>
      <div
        className={`user_profile_input_row ${
          isNightMode
            ? "user_profile_input_row_dark"
            : "user_profile_input_row_light"
        }`}
      >
        <ImMobile
          className={`user_profile_icon ${
            isNightMode ? "user_profile_icon_dark" : "user_profile_icon_light"
          }`}
        />

        <WebInput
          parentClassName="input_parent"
          className="input_transparent_hide"
          type="number"
          placeholder="Contact Number"
          id="phoneInput"
          error=""
          onChange={null}
          value={data.phone}
          name="phone"
        />
        <MdEdit
          className={`user_profile_icon edit_icon ${
            isNightMode ? "user_profile_icon_dark" : "user_profile_icon_light"
          }`}
          onClick={() => setIsOpen(true)}
        />
      </div>

      {modalIsOpen ? (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Phone Modal"
        >
          <form onSubmit={sendOtptoPhone} className="phone_modal_form">
            <div className="edit_phone_head">Edit Contact Number</div>

            <div className="phone_modal_row">
              <p>+91</p>

              <div className="phone_modal_box">
                <WebInput
                  parentClassName="input_parent"
                  className="input_transparent"
                  type="number"
                  placeholder="Contact Number"
                  id="phoneInput"
                  error=""
                  onChange={handlePhone}
                  value={phone}
                />
              </div>
            </div>

            {error ? (
              <p className="profile_error">
                <IoAlertCircle
                  color="#ED1302"
                  size={18}
                  className="profile_error_icon"
                />
                {error}
              </p>
            ) : null}

            {phone.length === 10 && !phoneVerified ? (
              <div className="otp_verified_container">
                {otpSend ? <WebOtpInput setOtpVal={setOtp} /> : null}
              </div>
            ) : null}

            <div className="timer_row">
              {!timer ? (
                <WebButton
                  className="modal_btn"
                  text={!otpSend ? "Request OTP" : "Resend OTP"}
                  onClick={sendOtptoPhone}
                  type="submit"
                />
              ) : (
                <span>00:{time > 9 ? time : `0${time}`}</span>
              )}
            </div>
          </form>
        </Modal>
      ) : null}
    </>
  );
}
