import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import WebButton from "../../../../library/webButton";
import WebInput from "../../../../library/webInput";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";
import { orderApiService } from "../../../../../service/seller/orderApiService";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function RaiseTicket({ modalIsOpen, setIsOpen, id, handleRefresh }) {
  const [data, setData] = useState({
    issue: "",
  });
  const [error, setError] = useState("");

  const message = useMessage();
  const loader = useLoader();

  function closeModal() {
    setIsOpen(false);
  }

  const handleOnChange = (e) => {
    setData({ ...data, issue: e.target.value });
    setError("");
  };

  const validate = () => {
    let result = true;
    if (!data.issue) {
      setError("Issue is required");
      result = false;
    }
    return result;
  };

  const handleRaiseTicket = (e) => {
    e.preventDefault();

    if (validate()) {
      loader.showLoading(true);
      orderApiService
        .raiseTicket(id, JSON.stringify(data))
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            message.showToast(res.status.message, ToastTypes.SUCCESS);
            closeModal();
            handleRefresh();
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
        });
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <form onSubmit={handleRaiseTicket} className="edit_order_form">
          <h1 className="edit_order_head">Raise a Ticket</h1>

          <WebInput
            label="Enter Your Issue"
            value={data.issue}
            error={error}
            onChange={handleOnChange}
          />

          <WebButton text="Submit" type="submit" className="modal_btn" />
        </form>
      </Modal>
    </div>
  );
}
