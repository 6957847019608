import React, { useEffect, useRef, useState } from "react";
import { MdEdit } from "react-icons/md";
import { PiUserFill } from "react-icons/pi";
import WebInput from "../../../../library/webInput";
import { useSelector } from "react-redux";
import { selectIsNightMode } from "../../../../../redux/slices/modeSlice";
import { IoAlertCircle } from "react-icons/io5";
import { AuthApiService } from "../../../../../service/authApiService";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";

export default function NameInput({ data, setData }) {
  const [nameValue, setNameValue] = useState("");
  const [activeInput, setActiveInput] = useState(false);
  const [error, setError] = useState("");

  const loader = useLoader();
  const message = useMessage();
  const nameRef = useRef();
  const isNightMode = useSelector(selectIsNightMode);

  const handleName = (e) => {
    if (activeInput) {
      setNameValue(e.target.value);
      setError("");
    }
  };

  const activeNameInput = () => {
    setActiveInput(true);
    nameRef.current.focus();
  };

  const updateName = () => {
    const value = nameValue.split(" ");
    const firstValue = value[0];
    const lastValue = value.shift();

    if (lastValue.length > 1) {
      setData({
        ...data,
        firstName: firstValue,
        lastName: value.join(" "),
      });
    } else {
      setData({
        ...data,
        firstName: firstValue,
        lastName: lastValue,
      });
    }
  };

  useEffect(() => {
    if (!nameValue && data.firstName && data.lastName) {
      const firstValue = data.firstName ? data.firstName : "";
      const lastValue = data.lastName ? data.lastName : "";
      setNameValue(firstValue + " " + lastValue);
    }

    handleUpdateName();
  }, [data]);

  const validate = () => {
    let result = true;
    if (!data.firstName && !data.lastName) {
      setError("Name is required");
      result = false;
    } else if (!data.lastName) {
      setError("Last name is required");
      result = false;
    } else if (data.firstName && data.lastName) {
      setError("");
      result = true;
    }

    return result;
  };

  const handleUpdateName = () => {
    if (validate() && activeInput) {
      loader.showLoading(true);

      const authPayload = {
        firstName: data.firstName,
        lastName: data.lastName,
      };

      AuthApiService.updateProfile(JSON.stringify(authPayload))
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            message.showToast(
              "User name updated successfully",
              ToastTypes.SUCCESS
            );
            setActiveInput(false);
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    }
  };

  return (
    <>
      <div
        className={`user_profile_input_row ${
          isNightMode
            ? "user_profile_input_row_dark"
            : "user_profile_input_row_light"
        } 
         ${activeInput ? "user_active_row" : "user_inactive_row"}
        `}
      >
        <PiUserFill
          className={`user_profile_icon ${
            isNightMode ? "user_profile_icon_dark" : "user_profile_icon_light"
          }`}
        />

        <WebInput
          parentClassName="input_parent"
          className={
            activeInput ? "input_transparent" : "input_transparent_hide"
          }
          type="text"
          placeholder="Name"
          id="nameInput"
          error=""
          onChange={handleName}
          value={nameValue}
          name="name"
          onBlur={updateName}
          ref={nameRef}
        />
        <MdEdit
          className={`user_profile_icon edit_icon ${
            isNightMode ? "user_profile_icon_dark" : "user_profile_icon_light"
          }`}
          onClick={() => activeNameInput()}
        />
      </div>

      {error ? (
        <p className="profile_error">
          <IoAlertCircle
            color="#ED1302"
            size={18}
            className="profile_error_icon"
          />
          {error}
        </p>
      ) : null}
    </>
  );
}
