// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox_size {
  width: 20px;
  height: 20px;
}

.products td {
  font-weight: 500;
}

.input_field {
  width: 10rem;
  height: 2rem;
  border: 1px solid grey;
}
.top {
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/views/admin/products/list/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,sBAAsB;AACxB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".checkbox_size {\n  width: 20px;\n  height: 20px;\n}\n\n.products td {\n  font-weight: 500;\n}\n\n.input_field {\n  width: 10rem;\n  height: 2rem;\n  border: 1px solid grey;\n}\n.top {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
