import React from "react";
import Blog1 from "../../../../../assets/images/home/blogs/blog1.png";
import Blog2 from "../../../../../assets/images/home/blogs/blog2.png";
import Blog3 from "../../../../../assets/images/home/blogs/blog3.png";
import { BsArrowUpRightCircle } from "react-icons/bs";

import "./style.css";

export default function Blogs() {
  const blogs = [
    {
      head: "What are the future trends in Dropshipping?",
      para: "Discuss emerging trends and innovations in the Dropshipping industry, such as automation, personalization, and sustainability.",
      img: Blog1,
    },
    {
      head: "How can I market my Dropshipping business?",
      para: "Explore effective marketing tactics and channels for promoting a Dropshipping business, such as social media marketing, influencer partnerships, and search engine optimization (SEO)..",
      img: Blog2,
    },
    {
      head: "How can I find reliable Dropshipping suppliers?",
      para: "‘Share strategies for finding reputable suppliers, conducting due diligence, and building strong relationships with suppliers.",
      img: Blog3,
    },
  ];

  return (
    <div className="section_box">
      <div className="blogs_box">
        <div className="home_main_head">Discover Our Blogs</div>

        <div className="blogs_row">
          {blogs.map((blog, index) => {
            return <BlogItem blog={blog} index={index} />;
          })}
        </div>
      </div>
    </div>
  );
}
 
const BlogItem = ({ blog, index }) => {
  const check = (index + 2) % 3;

  return (
    <div className={`blog_item ${check === 0 ? "blog_outline" : ""}`}>
      <img src={blog.img} className="blog_img" />
      <div className="head_arrow_row">
        <p className="blog_head">{blog.head}</p>
        <BsArrowUpRightCircle className="blog_arrow" />
      </div>
      <p className="blog_para">{blog.para}</p>
    </div>
  );
};
