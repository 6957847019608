import React, { useState } from "react";
import WebInput from "../../../../../component/library/webInput";
import WebButton from "../../../../../component/library/webButton";
import { emailRegex, phoneFirstLetter } from "../../../../../helper/regex";
import { ContactApiService } from "../../../../../service/website/contactApiService";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import "./style.css";

export default function ContactUs() {
  const [data, setData] = useState({
    name: "",
    email: "",
    contactNo: "",
    service: "",
  });

  const [error, setError] = useState({
    name: "",
    email: "",
    contactNo: "",
    service: "",
  });

  const message = useMessage();
  const loader = useLoader();

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const validate = () => {
    let result = true;
    if (!data.name) {
      setError({ ...error, name: "Name is required" });
      result = false;
    } else if (!data.email) {
      setError({ ...error, email: "Email is required" });
      result = false;
    } else if (!data.email.match(emailRegex)) {
      setError({ ...error, email: "Not a valid email address" });
      result = false;
    } else if (!data.contactNo.length) {
      setError({ ...error, contactNo: "Phone is required" });
      result = false;
    } else if (data.contactNo.length !== 10) {
      setError({ ...error, contactNo: "Not a valid phone number" });
      result = false;
    } else if (data.contactNo.length === 10) {
      let firstLetter = data.contactNo.slice(0, 1);
      if (!phoneFirstLetter.some((num) => num === firstLetter)) {
        setError({ ...error, contactNo: "Not a valid phone number" });
        result = false;
      } else if (!data.service) {
        setError({ ...error, service: "Service is required" });
        result = false;
      }
    }
    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      loader.showLoading(true);
      ContactApiService.contactToAdmin(JSON.stringify(data))
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            message.showToast(res.status.message, ToastTypes.SUCCESS);
            setData({
              name: "",
              email: "",
              contactNo: "",
              service: "",
            });
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          loader.showLoading(false);
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
        });
    }
  };

  return (
    <div className="section_box">
      <form className="contact_us_bg" onSubmit={handleSubmit}>
        <h1 className="contact_us_head">CONTACT US</h1>

        <div className="contact_input_row">
          <WebInput
            type="text"
            placeholder="Your Name*"
            className="home_contact_input"
            error={error.name}
            onChange={handleOnChange}
            value={data.name}
            name="name"
          />

          <WebInput
            type="email"
            placeholder="Your Email*"
            className="home_contact_input"
            error={error.email}
            onChange={handleOnChange}
            value={data.email}
            name="email"
          />

          <WebInput
            type="text"
            placeholder="Your Contact Number*"
            className="home_contact_input"
            error={error.contactNo}
            onChange={handleOnChange}
            value={data.contactNo}
            name="contactNo"
            maxLength="10"
          />

          <WebInput
            type="text"
            placeholder="All Services*"
            className="home_contact_input"
            error={error.service}
            onChange={handleOnChange}
            value={data.service}
            name="service"
          />
        </div>

        <WebButton
          className="active_btn_without_shadow"
          text="SEND"
          type="submit"
        />
      </form>
    </div>
  );
}
