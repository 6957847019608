import { NetworkConfiguration } from "../../network/networkConfriguation";
import { callAPI } from "../../network/networkConnection";

const addNewCategory = (payload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.ADD_CATEGORY,
      "POST",
      {
        "Content-Type": "application/json",
      },
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getExistingCategory = (query) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.EXISTING_CATEGORY,
      "GET",
      {
        "Content-Type": "application/json",
      },
      query
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const updateCategory = (payload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.UPDATE_CATEGORY,
      "PUT",
      {
        "Content-Type": "application/json",
      },
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const createBlankCategoryContent = (payload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.BLANK_CATEGORY_CONTENT,
      "POST",
      {
        "Content-Type": "application/json",
      },
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const categoryApiService = {
  addNewCategory,
  getExistingCategory,
  updateCategory,
  createBlankCategoryContent,
};
