// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.recharge_popup_form{

    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width:768px) {
    .recharge_popup_form{
        gap: 1rem;
        width: 80dvw;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/component/views/seller/wallet/paymentPopup/style.css"],"names":[],"mappings":";AACA;;IAEI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI;QACI,SAAS;QACT,YAAY;IAChB;;AAEJ","sourcesContent":["\n.recharge_popup_form{\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n@media (max-width:768px) {\n    .recharge_popup_form{\n        gap: 1rem;\n        width: 80dvw;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
