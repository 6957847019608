import React, { useEffect, useState } from "react";
import { amazonApiService } from "../../../service/seller/amazonApiService";
import { jwtDecode } from "jwt-decode";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import Lottie from "react-lottie";
import pleaseWait from "../../../assets/lotties/please_wait.json";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./style.css";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: pleaseWait,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function AmazonConnection() {
  const [sellerId, setSellerId] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const message = useMessage();
  const navigate = useNavigate();

  //get data from localstroage
  const token = localStorage.getItem("LOGIN_HEADER");
  const sptokenData = localStorage.getItem("sptoken");

  //get data from search params
  const amazon_state = searchParams.get("amazon_state");
  const amazon_callback_uri = searchParams.get("amazon_callback_uri");
  const spapi_oauth_code = searchParams.get("spapi_oauth_code");
  const selling_partner_id = searchParams.get("selling_partner_id");
  let redirect_url = searchParams.get("redirect_url");

  useEffect(() => {
    //set decode id
    if (sptokenData) {
      setSellerId(jwtDecode(token).id);
      redirect_url = jwtDecode(token).redirect_url;
    } else if (token) {
      setSellerId(jwtDecode(token).id);
    }
  }, []);

  //connect to amazon api call
  useEffect(() => {
    if (
      sellerId &&
      !amazon_callback_uri &&
      !amazon_state &&
      !spapi_oauth_code &&
      !selling_partner_id
    ) {
      connectToAmazon();
    }
  }, [sellerId]);

  //get seller app id api call
  useEffect(() => {
    if (amazon_callback_uri && amazon_state) {
      getSellerAppId();
    }
  }, [amazon_callback_uri, amazon_state]);

  // save data on amazon api call
  useEffect(() => {
    if (spapi_oauth_code && selling_partner_id && sellerId) {
      saveAmazonData();
    }
  }, [spapi_oauth_code, sellerId, selling_partner_id]);

  //get seller app id function
  const getSellerAppId = () => {
    amazonApiService
      .getSellerAppId()
      .then((res) => {
        window.location.href = `https://sellercentral.amazon.in/apps/authorize/consent?application_id=${res.sp_app_id}&state=${amazon_state}&version=beta&sellerId=${sellerId}`;
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  //connect to amazon store function
  const connectToAmazonPayload = {
    sellerId: sellerId,
  };
  const connectToAmazon = () => {
    amazonApiService
      .connectToAmazon(JSON.stringify(connectToAmazonPayload))
      .then((res) => {
        if (!res.error) {
          if (!res.isconnected) {
            localStorage.setItem("sptoken", res.token);
            window.location.href = res.location;
          } else {
            message.showToast(res.message, ToastTypes.INFO);
            navigate("/seller/marketplaceApis");
          }

          // else {
          //   window.location.href = redirect_url;
          // }
        }

        // else {
        //   window.location.href = redirect_url;
        // }
      })
      .catch((err) => {
        console.log("connectToAmazon err", err);
      });
  };

  // save amazon data on database function
  const amazonPayload = {
    spapi_oauth_code: spapi_oauth_code,
    sellerId: sellerId,
    selling_partner_id: selling_partner_id,
  };

  const saveAmazonData = () => {
    amazonApiService
      .saveAmazonData(JSON.stringify(amazonPayload))
      .then((res) => {
        message.showToast(
          "Your store connected successfully",
          ToastTypes.SUCCESS
        );
        navigate("/seller/marketplaceApis");
      })
      .catch((err) => {
        console.log("save to database err", err);
      });
  };

  return (
    <div className="amazon_connect_bg">
      <h1>Connecting to Amazon</h1>
      <div className="amazon_connect_loading">
        <Lottie options={defaultOptions} height={350} width={350} />
      </div>
    </div>
  );
}
