import React from "react";
import "./style.css";

export default function UpsaleoDeliveryShippingGuide() {
  return (
    <div className="delivery_shipping_bg">
      <div className="delivery_shipping_banner">
        {/* <h1>Delivery & Shipping Guide</h1> */}
      </div>

      <div className="delivery_shipping_content">
        <h1>Upsaleo Delivery & Shipping Guide</h1>

        <p>
          The product page and shopping cart clearly indicate the real-time
          price, which includes the product’s wholesale price, shipping fee, and
          GST. There are no additional fees. You simply decide the price markup,
          and the difference is your profit! Stay updated with Upsaleo's product
          prices as this could affect your profit margin.
        </p>

        <p>
          If you have set up through API integration, you have the option to
          adjust your Selling Price automatically to maintain the same
          percentage markup.
        </p>

        <p>
          Products packages and shipping labels do not display Upsaleo's name.
          We ship orders under your store’s name.
        </p>

        <p>
          Upsaleo reserves the right to restrict shipments within India. All
          orders must be placed with a residential ship-to address. Orders with
          P.O. Box, APO, or FPO shipping addresses will not be processed. Any
          orders with shipping addresses outside India will not be processed.
          Individual suppliers may have additional shipping restrictions.
          However, the absence of a specific shipping policy by a supplier does
          not imply that the supplier offers any of the above-mentioned
          services.
        </p>

        <h2>Accept:</h2>
        <p>
          Upsaleo.com reserves the right to update the rules when necessary, and
          the updated version will be published on Upsaleo.com with immediate
          effect. If you refuse to accept the policy, you are deemed to have
          waived the use of the services provided by Upsaleo.com immediately.
        </p>

        <p>
          For after-sales issues, sellers should file a return & refund
          application within 15 days from the delivery day. We reserve the right
          not to provide any after-sales service for issues received after 15
          days from delivery.
        </p>

        <p>
          Once the seller submits the after-sales ticket, he/she needs to
          provide evidence (as specified below) to Upsaleo.com. If the seller
          cannot provide compelling evidence in time, Upsaleo.com has the right
          to reject the refund or close the ticket.
        </p>

        <p>
          All items must be returned in good condition with box/packaging,
          including tags, parts, and accessories.
        </p>

        <p>
          Whether the evidence is compelling or not will ultimately be
          determined by Upsaleo.com.
        </p>

        <h2>Under Various Circumstances:</h2>

        <p>1. Wrong Receipt Information</p>

        <p>
          If the seller fills in wrong receipt information, the seller should
          promptly contact contact@upsaleo.com or contact us through the Upsaleo
          account by raising a ticket promptly to confirm whether the goods have
          been shipped out.
        </p>
        <p>
          If we have not shipped it, then we can update the correct information.
        </p>

        <p>
          If the goods have already been shipped out, then Upsaleo will contact
          the Indian Warehouse to stop the package.
        </p>

        <p>
          In case if the order is already dispatched from the Indian warehouse
          then, after we receive the product back without any damage, a portion
          of the order amount will be deducted as the repacking & restocking fee
          (20%) and the rest of the money will be refunded to your wallet.
        </p>

        <p>
          2. Defective Products (package breakage/significantly not as
          described/missing or wrong goods/quality problems) not as
          described/missing or wrong goods/quality problems)
        </p>

        <p>
          If the seller detects any problem upon receipt (damaged
          package/significantly not as described/missing or wrong goods or other
          quality problems), he/she shall contact contact@upsaleo.com or contact
          us through the Upsaleo account by raising a ticket for settlement
          within 5 business days from the delivery day and provide evidence to
          prove the problem (pictures of broken products and package outer box
          with a shipping label on it). We can refuse the refund application if
          the seller fails to provide compelling evidence in time.
        </p>

        <p>
          If Upsaleo agrees to refund, the buyer shall return the goods in time
          and upload the tracking number. The supplier will refund in full upon
          receipt.
        </p>

        <p>
          If the buyer agrees to resend, the buyer shall return and upload the
          tracking number in time. The supplier will reship the product upon
          receipt.
        </p>

        <h2>Return Policy</h2>

        <p>
          We are committed to quality service, so we carefully package each
          order for safe delivery. We also have a transparent return policy in
          place to provide your customers value for their money.
        </p>

        <p>
          A customer is eligible for return or refund under the following
          circumstances:
        </p>

        <p>
          Item missing delivery: We provide a refund if items are missing from
          the delivery. For example, if a customer ordered ten items but was
          delivered 6, they can get a refund for the missing 4 items within 5-7
          working days.
        </p>

        <p>Replacement: There is no policy for the replacement of items.</p>

        <p>
          RTO: Courier returns take place when the order is undelivered and
          marked as ‘Returning to Origin’ (RTO). Following are the potential
          reasons for the order being marked as RTO.
        </p>

        <h2>Reasons for undelivered shipment:</h2>

        <p>
          Incomplete address: Customers are required to fill in their address
          accurately. The shipment cannot be delivered in case of an incomplete
          address.
        </p>

        <p>
          Customer refused delivery: If the customer refuses the delivery, the
          order can be marked as ‘Returning to Origin’ (RTO).
        </p>

        <p>
          Door/premises/office closed: The order can be undelivered if the
          delivery agent finds the door/premises/office closed.
        </p>

        <p>
          Pin code not serviceable: The shipment can be undelivered if the pin
          code is not serviceable.
        </p>

        <p>
          In all of the cases mentioned above, we will provide a partial refund
          of 80% of the item price. The rest, 20%, will be retained as logistics
          and packing fees.
        </p>

        <h2>*NOTE:</h2>

        <p>
          Delivery time only starts after the buyer’s payments have been
          cleared, and the item has been dispatched from our Indian warehouse
          (usually 5-7 working days after the product reaches Indian Warehouse).
          We cannot guarantee a delivery on any specific date or time. This is
          because it is not something our delivery partners can guarantee
          either.
        </p>

        <p>
          Our company is currently serving PAN India with more than 22000 Pin
          codes. Any pin code which falls under the unserviceable category
          (remote or rural locations) will lead to extra delivery time. Extra
          delivery time will be taken when courier partners cannot provide
          services due to circumstances beyond their control, e.g., lockdown,
          curfew, riots, server failure, events by nature (earthquake, Tsunami,
          volcano-no eruption, etc.), government policies, etc.
        </p>

        <p>
          Customers are requested to read the return policy thoroughly. We
          always strive to provide the best products to our customers. Our
          packaging services help to ensure that the product suffers no damage
          during transportation. In the odd case of customers receiving a faulty
          product or an item that does not match their expectations, our lucid
          return policy will help us deliver a good customer experience.
        </p>

        <h2>Cancellation policy</h2>
        <p>
          We process orders very quickly. Thus it may not be possible to
          intercept an order for cancellation. So once a customer makes the
          payment of the order, we will deny the cancellation and proceed with
          shipping and no refund.
        </p>

        <h2>Cancel membership</h2>

        <p>
          Customers may downgrade to the free “starter plan” at any time, and we
          will not charge them subsequently. We don’t have a provision of a
          partial refund for the unused portion of the monthly plan. The balance
          in the customer’s e-wallet can be returned to them after settling all
          orders.
        </p>
      </div>
    </div>
  );
}
