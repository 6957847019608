import React from "react";
import "./style.css";
export default function SellingGuide() {
  return (
    <div className="selling_guide_bg">
      <div className="selling_guide_banner">
        {/* <h1>Selling Guide</h1> */}
      </div>
      <section className="selling_guide_delevery">
        <p>
          To create a great trading environment and improve the services of
          upsaleo.com, upsaleo hereby issues this general return and refund
          policy. This Policy applies to all users on upsaleo.com.
        </p>
      </section>
      <section className="selling_guide_content">
        <p>
          <span>Return and Refund Policy : </span>
          To enhance the trading environment and improve services on
          Upsaleo.com, we are pleased to introduce our comprehensive Return and
          Refund Policy, applicable to all users.
          <br />
          <span>Upholding Your Experience : </span>
          At Upsaleo, we prioritize transparency and customer satisfaction in
          every transaction. Our Return and Refund Policy is designed to ensure
          that your interactions with us are fair, reliable, and supportive.
          <br />
          <span>Policy Overview : </span>
          We strive to make your return and refund process as seamless as
          possible:
          <br />
          <span className="selling_guide_subpoints">
            &nbsp; 1. Eligibility :
          </span>{" "}
          Products eligible for return must meet specific criteria outlined in
          our policy, ensuring clarity and fairness.
          <br />
          <span className="selling_guide_subpoints">
            &nbsp; 2. Initiating Returns :
          </span>{" "}
          Users can initiate a return request through their Upsaleo account
          within a specified timeframe.
          <br />
          <span className="selling_guide_subpoints">
            &nbsp; 3. Refund Process :
          </span>{" "}
          Upon approval, refunds are processed promptly, with the amount
          credited back to the original payment method used.
        </p>
      </section>
      <section className="selling_guide_content">
        <h2>Our Commitment:</h2>
        <p>
          Our dedicated support team is available to assist you at every step of
          the process, providing guidance and addressing any concerns you may
          have. This policy underscores our commitment to maintaining a positive
          and trustworthy shopping experience for all Upsaleo users.
          <br />
          For more information on our Return and Refund Policy, please visit our
          website or contact our customer support team directly.
        </p>
      </section>
    </div>
  );
}
