import React, { forwardRef } from "react";
import { IoAlertCircle } from "react-icons/io5";
import "./style.css";

const WebTextArea = forwardRef(
  (
    {
      placeholder,
      className,
      label,
      id,
      parentClassName,
      error,
      onChange,
      value,
      name,
      maxLength,
      onBlur,
      onClick,
      textareaStyle,
      labelStyle,
    },
    ref
  ) => {
    return (
      <div
        className={
          parentClassName ? parentClassName : "default_textarea_parent"
        }
      >
        {label ? (
          <label for={id} style={labelStyle}>
            {label}
          </label>
        ) : null}

        <div className="textarea_wrapper">
          <textarea
            style={textareaStyle}
            ref={ref}
            id={id}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            maxLength={maxLength}
            onBlur={onBlur}
            className={`
              ${className ? className : "default_textarea"}
                ${error ? "error_textarea" : ""}
              `}
            onClick={onClick}
          ></textarea>
          {error ? (
            <IoAlertCircle color="#ED1302" size={20} className="alert_icon" />
          ) : null}
        </div>

        {error ? <p className="alert_message">{error}</p> : null}
      </div>
    );
  }
);

export default WebTextArea;
