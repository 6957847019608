// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shopify_marketplace_form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
}

.shopify_marketplace_form_head{
    font-size: 1.1rem;
    font-weight: 500;
    font-family: "Inter", sans-serif;
}

.shopify_marketplace_img{
    width: 200px;
    height: 200px;
}

.shopify_marketplace_form_box{
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-right: 2rem;
}

@media (max-width:768px) {
    .shopify_marketplace_img{
       display: none;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/component/views/seller/marketPlace/shopifyForm/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,gCAAgC;AACpC;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI;OACG,aAAa;IAChB;;AAEJ","sourcesContent":[".shopify_marketplace_form{\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    margin: 1rem 0;\n}\n\n.shopify_marketplace_form_head{\n    font-size: 1.1rem;\n    font-weight: 500;\n    font-family: \"Inter\", sans-serif;\n}\n\n.shopify_marketplace_img{\n    width: 200px;\n    height: 200px;\n}\n\n.shopify_marketplace_form_box{\n    display: flex;\n    align-items: center;\n    gap: 2rem;\n    margin-right: 2rem;\n}\n\n@media (max-width:768px) {\n    .shopify_marketplace_img{\n       display: none;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
