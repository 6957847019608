// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wallet_membership_box{
    border-top: 1px solid var(--outline-color);
    padding: 0.5rem;
}

.wallet_membership_box h2{
    font-size: 1.3rem;
    color: rgba(0,0,0,0.5);
}

.wallet_membership_box_dark h2{
    color: rgba(255, 255, 255, 0.5);
}


.wallet_membership_box p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.wallet_membership_box_dark p {
    color: rgba(255, 255, 255);
}

.wallet_membership_row{
    display: flex;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/component/views/seller/wallet/membership/style.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,+BAA+B;AACnC;;;AAGA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".wallet_membership_box{\n    border-top: 1px solid var(--outline-color);\n    padding: 0.5rem;\n}\n\n.wallet_membership_box h2{\n    font-size: 1.3rem;\n    color: rgba(0,0,0,0.5);\n}\n\n.wallet_membership_box_dark h2{\n    color: rgba(255, 255, 255, 0.5);\n}\n\n\n.wallet_membership_box p {\n    font-size: 1.2rem;\n    margin-bottom: 1rem;\n}\n\n.wallet_membership_box_dark p {\n    color: rgba(255, 255, 255);\n}\n\n.wallet_membership_row{\n    display: flex;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
