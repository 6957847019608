import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { WalletApiService } from "../../../../../service/seller/walletApiService";
import { useSelector } from "react-redux";
import { selectIsNightMode } from "../../../../../redux/slices/modeSlice";
import "./style.css";

export default function WalletChart() {
  const [chartData, setChartData] = useState();
  const [year, setYear] = useState("2024");

  const isNightMode = useSelector(selectIsNightMode);

  const [series, setSeries] = useState([
    {
      name: "Orders",
      data: [],
    },
    {
      name: "Deposit",
      data: [],
    },
    {
      name: "Refund",
      data: [],
    },
  ]);

  useEffect(() => {
    setSeries([
      { ...series[0], data: chartData?.order },
      { ...series[1], data: chartData?.deposit },
      { ...series[2], data: chartData?.refund },
    ]);
  }, [chartData]);

  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#C4F0FF", "#FFB4B2", "#ABFFB4"],

    stroke: {
      width: "2",
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
    },

    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      floating: true,
      // offsetY: -25,
      offsetX: -5,
    },

    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
  };

  const handleChartData = () => {
    WalletApiService.getChartData(year)
      .then((res) => {
        setChartData(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleYear = (e) => {
    setYear(e.target.value);
  };

  useEffect(() => {
    handleChartData();
  }, [year]);

  return (
    <div
      className={`wallet_chart_bg ${isNightMode ? "wallet_chart_bg_dark" : ""}`}
    >
      <div className="wallet_chart_filter_row ">
        <div className="wallet_transaction_heading">Analysis</div>
        <select className="wallet_dropdown" onChange={handleYear}>
          <option value="2024">2024</option>
        </select>
      </div>

      <Chart
        options={options}
        series={series}
        type="line"
        width="100%"
        height="350"
      />

      {/* <div className="chart_filter_date">
        <div className="date_filter_col">
          <input type="checkbox" id="today"></input>
          <label for="today">Today so Far</label>
        </div>

        <div className="date_filter_col">
          <input type="checkbox" id="yesterday"></input>
          <label for="yesterday">Yesterday</label>
        </div>

        <div className="date_filter_col">
          <input type="checkbox" id="week"></input>
          <label for="week">Same day last week</label>
        </div>

        <div className="date_filter_col">
          <input type="checkbox" id="year"></input>
          <label for="year">Same day last year</label>
        </div>
      </div> */}
    </div>
  );
}
