import React, { useState } from "react";
import { RiDownload2Fill } from "react-icons/ri";
import {
  downloadCsv,
  formatDate,
  jsonToCsv,
} from "../../../../../helper/utils";
import "./style.css";
import DatePicker from "../../../../../component/library/datePicker";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";
import { orderApiService } from "../../../../../service/seller/orderApiService";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsNightMode } from "../../../../../redux/slices/modeSlice";
import Pagination from "../../../../library/pagination";

export default function WalletTransaction({
  transaction,
  setType,
  setStartDate,
  setEndDate,
  numberOfDays,
  pages,
  page,
  setPage,
}) {
  const handleType = (e) => {
    setType(e.target.value);
    setStartDate('')
    setEndDate('')
    setPage(1)
  };

  const message = useMessage();
  const navigate = useNavigate();

  const isNightMode = useSelector(selectIsNightMode);

  const keys = ["_id", "type", "amount", "balance", "description", "date"];
  let csvContent = "Id,Type,Amount,Final Balance,Description,Date";

  const handleDownloadCsv = () => {
    if (transaction.length) {
      if (numberOfDays <= 30) {
        downloadCsv(jsonToCsv(transaction, keys, csvContent), "data.csv");
      } else {
        message.showToast("Maximum limit 30 days", ToastTypes.ERROR);
      }
    } else {
      message.showToast("Data not available to download", ToastTypes.ERROR);
    }
  };

  const handleOrderById = async (item) => {
    const array = await item.description.split(" ");
    const id = await array[array.length - 1];

    await orderApiService
      .getOrderDetailsByUpsaleo(id)
      .then(async (res) => {
        if (res.status.code === 200) {
          navigate(`/seller/orders/orderDetails/${res.data._id}`);
        } else if (res.status.code === 402) {
          message.showToast(res.error.message, ToastTypes.ERROR);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  return (
    <div
      className={`wallet_transaction_bg ${
        isNightMode ? "wallet_transaction_bg_dark" : ""
      }`}
    >
      <div className="wallet_transaction_filter_row">
        <div className="wallet_transaction_heading">Wallet Transaction</div>
        <div className="wallet_filter_col">
          <DatePicker setStartDate={setStartDate} setEndDate={setEndDate} />

          <select className="wallet_dropdown" onChange={handleType}>
            <option selected disabled>
              Transaction Type
            </option>
            <option value="">All Transactions</option>
            <option value="Deposit">Deposit</option>
            <option value="Order">Order</option>
            <option value="Refund">Refund</option>
          </select>

          <RiDownload2Fill
            className="download_icon"
            onClick={() => handleDownloadCsv()}
          />
        </div>
      </div>

      <div className="seller_table_wrapper">
      <table className="seller_table">
        <thead>
          <tr>
            <td>Date</td>
            <td>Transaction Id</td>
            <td>Type</td>
            <td>Amount</td>
            <td>Balance</td>
            <td>Description</td>
          </tr>
        </thead>

        <tbody>
          {transaction &&
            transaction.length &&
            transaction.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{formatDate(item.date)}</td>
                  <td>{item.transactionId ? item.transactionId : item._id}</td>
                  <td>{item.type}</td>
                  <td>{item.amount}</td>
                  <td>{item.balance}</td>
                  <td
                    className={
                      item.type === "Order" || item.type === "Refund"
                        ? "td_pointer"
                        : null
                    }
                    onClick={
                      item.type === "Order" || item.type === "Refund"
                        ? () => handleOrderById(item)
                        : null
                    }
                  >
                    {item.description}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      </div>

      {transaction.length ? (
        <Pagination pages={pages} page={page} setPage={setPage} />
      ) : null}
    </div>
  );
}
