import React from "react";
import { formatDate } from "../../../../../helper/utils";
import { useNavigate } from "react-router-dom";

export default function ProductOrderTable({ data }) {
  const navigate = useNavigate();

  // Step 1: Group by name
  const grouped =  data?.reduce((acc, item) => {
    if (!acc[item.trackingId]) {
        acc[item.trackingId] = [];
    }
    acc[item.trackingId].push(item);
    return acc;
  }, {});

// Step 2: Transform to desired format
  let result;

  if(grouped){
     result = Object.entries(grouped).map(([trackingId, data]) => ({
      trackingId: trackingId,
      data: data
    }));
  }
 
  const handleProduct = (id) => {
    navigate(`/product-details?id=${id}`);
  };

  return (
    <div className="seller_table_wrapper">
    <table className="seller_table">
      <thead>
        <tr>
          <td>Image</td>
          <td>Name</td>
          <td>SKU</td>
          <td>Tracking No.</td>
          <td>Shipping Method</td>
          <td>Carrier Name</td>
          <td>Consumer Price</td>
          <td>Order Item Id</td>
          <td>Quantity</td>
          <td>Upsaleo Price</td>
        </tr>
      </thead>

      <tbody>
        {result?.map((item, index) => {
          return item.data?.map((product,indexx)=>{
            return (
              <tr className="odd:bg-white even:bg-slate-100" key={index}>
                <td onClick={() => handleProduct(product.product?._id)}>
                  <img
                    src={product.product?.imgUrl[0]}
                    className="w-10 h-10 cursor-pointer"
                  />
                </td>

                <td onClick={() => handleProduct(product.product?._id)}>
                  <div className="truncate w-20 cursor-pointer table_overflow_text">
                    {product.product?.title}
                  </div>
                </td>

                <td onClick={() => handleProduct(product.product?._id)} className="cursor-pointer">
                  {product.product?.sku}
                </td>


                {indexx===0?<td rowSpan={item.data.length}>{product?.trackingId}</td>:null}
                {indexx===0?<td rowSpan={item.data.length}>{product?.shippingMethod}</td>:null}
                {indexx===0?<td rowSpan={item.data.length}>{product?.carrierName}</td>:null}
                
                <td>₹{product?.consumerPrice}</td>

                <td>{product?.orderItemId}</td>

                <td>{product?.quantity}</td>
                <td>₹{product?.product?.sp}</td>
              </tr>
            );
          })          
        })}
      </tbody>
    </table>
    </div>
  );
}
