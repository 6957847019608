import React, { useEffect, useState } from "react";
import ProductCard from "../../component/common/productCard";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { FCApiService } from "../../service/fCApiService";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { createMarkup } from "../../helper/utils";
import "./style.css";
import { nextVIcon } from "../../assets/icons";

export default function FeaturedCategory() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const params = useParams();
  const message = useMessage();

  useEffect(() => {
    if (searchParams.get("id")) {
      handleCategoryById();
    }
  }, [searchParams.get("id")]);

  const handleCategoryById = () => {
    FCApiService.getCategoryById(searchParams.get("id"))
      .then((res) => {
        if (res.status.code === 200) {
          setData(res.data);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  return (
    <div>
      {data.map((item) => {
        if (item.type === "html") {
          return (
            <div
              className="featured_category_section"
              dangerouslySetInnerHTML={createMarkup(item.data)}
            ></div>
          );
        } else if (item.type === "list") {
          return (
            <div>
              <div className="fc_product_listing_head_row">
                <h1>{item.heading}</h1>
                <Link
                  to={`/collection/${params.category}?fcId=${searchParams.get(
                    "id"
                  )}`}
                >
                  <p>
                    View More <span>{nextVIcon}</span>
                  </p>
                </Link>
              </div>
              <div className="fc_product_listing">
                {item.products?.map((item) => {
                  return <ProductCard product={item} />;
                })}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}
