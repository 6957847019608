import React from "react";
import "./style.css";

export default function TermsCondition() {
  return (
    <div className="privacy_bg">
      <div className="terms_condition_banner"></div>

      <div className="privacy_content">
        <h1>Upsaleo Terms of Use</h1>

        <p>
          This document constitutes an electronic record under the Information
          Technology Act, 2000 and its amended provisions regarding electronic
          records in various statutes. It is generated by a computer system and
          does not require physical or digital signatures.
        </p>

        <p>
          This document is published in compliance with Rule 3(1) of the
          Information Technology (Intermediaries guidelines) Rules, 2011, which
          mandates the publication of rules, regulations, privacy policy, and
          Terms of Use for access or usage of the website www.upsaleo.com.
        </p>

        <p>
          The domain name www.upsaleo.com (hereinafter referred to as “Website”)
          is owned by Boundless Trends Pvt. Ltd., a limited liability company
          with its registered office at B-71, Sector 6, Gautam Buddha Nagar
          Noida, Uttar Pradesh Your use of the Website, services, and tools is
          governed by the following terms and conditions (“Terms of Use”),
          including applicable policies incorporated herein by reference. If you
          engage in transactions on the Website, you are subject to the policies
          applicable to such transactions. By using the Website, you agree to
          these terms and conditions, constituting binding obligations with
          Upsaleo
        </p>

        <p>
          Please read these Terms & Conditions carefully before using this
          website. For the purposes of these Terms of Use, the terms “you”,
          “your”, or “user” refer to any natural or legal person who becomes a
          user of the Website by providing Registration Data while registering
          on the Website as a Registered User using computer systems. Upsaleo
          allows users to browse the Website or register on the Website.
        </p>

        <p>
          When you utilize any services provided by Upsaleo through the Website,
          such as blogs or reviews, you are bound by the rules, guidelines,
          policies, terms, and conditions specific to those services. These are
          considered part and parcel of these Terms of Use. Upsaleo reserves the
          right, at its sole discretion, to modify, change, add, or remove
          portions of these Terms of Use at any time without prior written
          notice. It is your responsibility to periodically review these Terms
          of Use for updates or changes. Your continued use of the Website after
          posting changes constitutes acceptance and agreement to the revisions.
          As long as you comply with these Terms of Use, Upsaleo grants you a
          personal, non-exclusive, non-transferable, limited privilege to access
          and use the Website.
        </p>
      </div>
    </div>
  );
}
