import React from "react";
import SectionOneImg from "../../assets/images/why-us/section_one.png";
import StepOneImg from "../../assets/images/why-us/step_one.png";
import StepTwoImg from "../../assets/images/why-us/step_two.png";
import StepThreeImg from "../../assets/images/why-us/step_three.png";

import { profileIcon } from "../../assets/icons";
import "./style.css";

export default function WhyDropshippingWithUs() {
  const matix = [
    { name: "Years Of Experience", value: "3" },
    { name: "Objects Protected", value: "384" },
    { name: "Professionals", value: "35" },
    { name: "Happy Customers", value: "474" },
  ];

  return (
    <div>
      <div className="dropshipping_with_us_banner">
        <h1>Why work with <span>Upsaleo?</span></h1>
      </div>

      <div className="dropshipping_with_us_matrix">
        {matix.map((item, index) => {
          return (
            <div key={index}>
              <h1>{item.value}</h1>
              <p>{item.name}</p>
            </div>
          );
        })}
      </div>

      <div className="dropshipping_with_us_section_one">
        <div className="column_one">
          <img src={SectionOneImg} />
        </div>
        <div className="column_two">
          <p>
            Working with Upsaleo offers a range of benefits that make it an excellent choice for entrepreneurs in the dropshipping industry. Upsaleo provides access to a diverse product range, allowing you to offer unique and trending items that help differentiate your store in a competitive market. The platform is designed to be user-friendly, simplifying the process of sourcing, listing, and managing products so you can focus on growing your business. Upsaleo's cost-effective solutions minimize financial risks by eliminating the need for upfront inventory investment, making it accessible for both new and experienced business owners. Additionally, the platform offers expert support, guiding you through product selection and store optimization to enhance your business performance. With valuable market insights and data-driven analysis, Upsaleo helps you stay informed about trends and customer preferences, enabling you to make strategic decisions. Comprehensive tools, including inventory management and automated order processing, streamline your operations and improve efficiency, ensuring a seamless experience as you scale your online store. Partnering with Upsaleo means having the resources and support needed to thrive in the dynamic e-commerce landscape.
          </p>
        </div>
      </div>

      <div className="dropshipping_with_us_mission">
        <h1>Mission</h1>

        <div className="mission_row">
          <div className="mission_column">
            <div>{profileIcon}</div>
            <h2>People</h2>
            <p>
              At Upsaleo, our mission revolves around empowering people—both our
              customers and team members. We are committed to fostering a
              culture of growth, innovation, and collaboration, where everyone
              has the opportunity to thrive and succeed.
            </p>
          </div>
          <div className="mission_column">
            <div>{profileIcon}</div>
            <h2>Customer Service</h2>
            <p>
              Our dedication to exceptional customer service sets us apart. We
              prioritize your satisfaction and aim to exceed your expectations
              at every interaction. With a focus on responsiveness, reliability,
              and personalized support, we ensure a seamless experience for
              every customer.
            </p>
          </div>
          <div className="mission_column">
            <div>{profileIcon}</div>
            <h2>Support</h2>
            <p>
              At Upsaleo, support means more than just assistance; it's about
              being your trusted partner. Whether you need guidance with our
              platform, help selecting products, or advice on scaling your
              business, our knowledgeable support team is here to provide expert
              guidance and help you achieve your goals.
            </p>
          </div>
        </div>
      </div>

      <div className="dropshipping_with_us_section_two">
        <div className="dropshipping_with_us_section_two_heading">
          Dropshipping has become popular for several reasons
        </div>

        <div className="dropshipping_with_us_section_two_row">
          <div className="dropshipping_with_us_section_two_col">
            <h1>Low Initial Investment</h1>
            <p>
              Dropshipping requires minimal upfront capital since you don't need
              to purchase inventory upfront. This makes it accessible for
              entrepreneurs with limited funds.
            </p>
          </div>
          <div className="dropshipping_with_us_section_two_col">
            <h1>Easy to Get Started</h1>
            <p>
              It's straightforward to launch a dropshipping business. You can
              start selling products online without managing physical stock or
              worrying about shipping logistics, allowing for quick entry into
              the e-commerce market.
            </p>
          </div>
        </div>
      </div>

      <div className="dropshipping_with_us_section_three">
        <div className="dropshipping_with_us_section_three_col">
          <img src={StepOneImg} />
          <h1>Customer</h1>
          <p>
            At Upsaleo, you are our priority. We are committed to providing you
            with an exceptional shopping experience, offering a wide range of
            quality products and reliable service.
          </p>
        </div>
        <div className="dropshipping_with_us_section_three_col">
          <img src={StepTwoImg} />
          <h1>Your Store</h1>
          <p>
            Create and manage your online store effortlessly with Upsaleo’s
            intuitive platform. Showcase your products, manage inventory, and
            fulfill orders with ease.
          </p>
        </div>
        <div className="dropshipping_with_us_section_three_col">
          <img src={StepThreeImg} />
          <h1>Market Insights</h1>
          <p>
           Upsaleo provides valuable insights and data-driven analysis to help you identify trends, understand customer preferences, and make informed decisions, enhancing your ability to succeed in the dropshipping business.
          </p>
        </div>
      </div>
    </div>
  );
}
