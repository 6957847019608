import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import WebInput from "../../../../library/webInput";
import WebButton from "../../../../library/webButton";
import { WalletApiService } from "../../../../../service/seller/walletApiService";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";
import Logo from "../../../../../assets/images/common/logo.png";
import './style.css'

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

export default function PaymentPopup({
  modalIsOpen,
  setIsOpen,
  handleRefresh,
}) {
  const [data, setData] = useState("");
  const [error, setError] = useState("");
  const [orderDetails, setOrderDetails] = useState({});

  const loader = useLoader();
  const message = useMessage();

  function closeModal() {
    setIsOpen(false);
  }

  const handleOnchange = (e) => {
    setData(e.target.value);
    setError("");
  };

  const validate = () => {
    let result = true;
    if (!data.length) {
      setError("Amount is required");
      result = false;
    } else if (data < 5000) {
      setError("Minimum Amount 5000");
      result = false;
    }
    return result;
  };

  const handleProceedToPay = async (e) => {
    e.preventDefault();

    if (validate()) {
      const rpScript = "https://checkout.razorpay.com/v1/checkout.js";
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = rpScript;
        script.onload = () => {
          openRazorpay();
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    }
  };

  const openRazorpay = async () => {
    loader.showLoading(true);
    const amountPayload = {
      amount: data * 100,
    };
    await WalletApiService.createOrder(JSON.stringify(amountPayload))
      .then(async (res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setOrderDetails(res.data);
          closeModal();
          setData("");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleRedirect = () => {
    const options = {
      key: process.env.REACT_APP_RAZOR_PAY_KEY,
      amount: orderDetails.amount,
      order_id: orderDetails.id,
      currency: orderDetails.currency,
      image: Logo,
      name: "Upsaleo",
      prefill: {
        email: "abcdef@gmailll.com",
        contact: "202-555-0183",
      },
      handler: function (response) {
        handleVerifyPayment(response.razorpay_payment_id);
      },
    };
    const paymentWindow = new window.Razorpay(options);
    paymentWindow.open();
  };

  const handleVerifyPayment = async (id) => {
    await WalletApiService.verifyOrder(id)
      .then(async (res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          handleRefresh();
          message.showToast(res.status.message, ToastTypes.SUCCESS);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  useEffect(() => {
    if (orderDetails.amount) {
      handleRedirect();
    }
  }, [orderDetails]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <form
        className="recharge_popup_form"
        onSubmit={handleProceedToPay}
      >
        <div className="text-lg font-medium ">Add money on your wallet</div>
        <WebInput
          type="number"
          placeholder="Ex-5000"
          label="Amount"
          id="wallet"
          error={error}
          onChange={handleOnchange}
          value={data}
          name="amount"
        />

        <WebButton
          className="bg-slate-500 py-2 px-5 text-white rounded mt-2"
          text="Proceed to Pay"
          type="submit"
        />
      </form>
    </Modal>
  );
}
