import LandingPage from "./views/landingPage";

const routes = [
  {
    path: "admin/landing-page",
    element: <LandingPage />,
  },
];

export default routes;
