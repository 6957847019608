import React from "react";
import BannerCategories from "./bannerCategories";
import BannerCarousel from "./bannerCarousel";
import BannerDescription from "./bannerDescription";
import "./style.css";

export default function HomeBanner() {
  return (
    <div className="home_banner_row">
      <BannerCategories />
      <BannerCarousel />
      <BannerDescription />
    </div>
  );
}
