import React from "react";
import { Route, Routes } from "react-router-dom";

import Login from "../views/common/login";
import Signup from "../views/common/signup";
import GetUserDetails from "../views/common/getUserDetails";
import ChangePassword from "../views/common/changePassword";
import ForgotPassword from "../views/common/login/forgetPassword/verifyEmail";
import ResetPassword from "../views/common/login/forgetPassword/resetPassword";
import ErrorPage from "../views/common/errorPage";

import WebLayout from "../website/component/layout/layout";
import Layout from "../component/layout/layout";
import ProtectRoute from "../component/layout/protectRoute";

import websiteRoutes from "../website/router";
import adminRoutes from "./admin/routes";
import sellerRoutes from "./seller/routes";
import routes from "../admin/routes";
import AmazonConnection from "../views/seller/amazon";

export default function Router() {
  const adminRoute = adminRoutes.map(({ path, title, element }) => {
    return (
      <Route
        key={title}
        path={`/${path}`}
        element={
          <Layout>
            <ProtectRoute>{element}</ProtectRoute>
          </Layout>
        }
      />
    );
  });

  const sellerRoute = sellerRoutes.map(({ path, title, element }) => {
    return (
      <>
        <Route
          key={title}
          path={`/${path}`}
          element={
            <Layout>
              <ProtectRoute>{element}</ProtectRoute>
            </Layout>
          }
        />
      </>
    );
  });

  const webRoutes = websiteRoutes.map(({ path, element }) => {
    return (
      <Route path={`/${path}`} element={<WebLayout>{element}</WebLayout>} />
    );
  });

  const newAdminRoutes = routes.map(({ path, element }) => {
    return (
      <Route
        path={`/${path}`}
        element={
          <Layout>
            <ProtectRoute>{element}</ProtectRoute>
          </Layout>
        }
      />
    );
  });

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/getUserDetails" element={<GetUserDetails />} />
      <Route
        path="/login/forgetPassword/verifyEmail"
        element={<ForgotPassword />}
      />
      <Route
        path="/login/forgetPassword/ResetPassword"
        element={<ResetPassword />}
      />
      <Route
        path="/changePassword"
        element={
          <Layout>
            <ProtectRoute>
              <ChangePassword />
            </ProtectRoute>
          </Layout>
        }
      />

      <Route path="/amazon/connect" element={<AmazonConnection />} />

      {webRoutes}
      {adminRoute}
      {sellerRoute}
      {newAdminRoutes}

      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}
