import React, { useState } from "react";
import WebInput from "../../../../../component/library/webInput";
import { useNavigate } from "react-router-dom";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import content from "../../../../../helper/content/forgot.json";
import WebButton from "../../../../../component/library/webButton";
import { emailRegex } from "../../../../../helper/regex";
import { AuthApiService } from "../../../../../service/authApiService";
import ForgotImage from "../../../../../assets/images/forgot/forgot.png";
import Navbar from "../../../../../component/common/authNavbar";
import "../../style.css";

export default function ForgetPassword() {
  const [emailSend, setEmailSend] = useState(false);

  return (
    <div>
      <Navbar />
      <div className="auth_container">
        <div className="auth_row">
          <div className="auth_col_one">
            <h1 className="auth_heading">
              {content.upsaleoMainHeading} <br />
              {content.upsaleoSubHeading}
            </h1>
            <img src={ForgotImage} className="auth_image" />
          </div>

          <div className="auth_col_two">
            {!emailSend ? (
              <VerifyEmail setEmailSend={setEmailSend} />
            ) : (
              <EmailSend />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const VerifyEmail = ({ setEmailSend }) => {
  const navigate = useNavigate();
  const message = useMessage();
  const loader = useLoader();

  const [email, setEmail] = useState("");
  const [emailErr, setEmailError] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  const validate = () => {
    let result = true;
    if (!email.length) {
      setEmailError("Email is required");
      result = false;
    } else if (!email.match(emailRegex)) {
      setEmailError("Not a valid email address");
      result = false;
    }
    return result;
  };

  const handleVerify = (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        email: email,
      };
      loader.showLoading(true);

      AuthApiService.verifyEmailtoResetPassword(JSON.stringify(data))
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            message.showToast(res.status.message, ToastTypes.SUCCESS);
            setEmailSend(true);
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    }
  };

  const handleSignIn = () => {
    navigate("/login");
  };

  return (
    <form className="auth_form" onSubmit={handleVerify}>
      <h1>{content.mainHeading}</h1>
      <h3>{content.subHeading}</h3>
      <WebInput
        parentClassName="input_parent"
        className="input_auth"
        id="emailInput"
        type="email"
        placeholder="Enter your email"
        name="email"
        value={email}
        error={emailErr}
        onChange={handleEmail}
      />

      <WebButton
        className="auth_btn"
        text={content.continueBtn}
        onClick={(e) => handleVerify(e)}
      />

      <div className="auth_message">
        <p>{content.signUpMessage}</p>
        <p onClick={() => handleSignIn()}>{content.signUpText}</p>
      </div>
    </form>
  );
};

const EmailSend = () => {
  const navigate = useNavigate();
  return (
    <div className="auth_form">
      <h1>{content.mainHeading}</h1>
      <h3 className="auth_form_success_msg">{content.emailMessage}</h3>

      <div className="continue_msg">
        <span></span>or<span></span>
      </div>
      <WebButton
        className="auth_btn"
        text="Back to Login"
        onClick={() => navigate("/login")}
      />

      <div className="auth_message">
        <p>Don’t have an account?</p>
        <p onClick={() => navigate("/signup")}>Sign up for free!</p>
      </div>
    </div>
  );
};
