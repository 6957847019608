// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featured_category_section{
   margin-bottom:4rem;
}
.fc_product_listing{
    display: flex;
    column-gap: 1rem;
    row-gap: 2rem;
    flex-wrap: wrap;
}
.fc_product_listing_head_row{
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
 }
 .fc_product_listing_head_row h1{
   font-size: var(--sans-serif-size-medium);
   font-family: var(--sans-serif);
   font-weight: var(--sans-serif-semi-bold);
   text-transform: capitalize;
 }

 .fc_product_listing_head_row p{
   font-size: var(--sans-serif-size-small);
   font-family: var(--sans-serif);
   font-weight: var(--sans-serif-medium);
   display: flex;
   gap: 0.3rem;
   align-items: center;
 }

  .fc_product_listing_head_row p span{
   font-size: var(--sans-serif-size-medium);
  }`, "",{"version":3,"sources":["webpack://./src/website/views/featuredCategory/style.css"],"names":[],"mappings":"AAAA;GACG,kBAAkB;AACrB;AACA;IACI,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,eAAe;AACnB;AACA;IACI,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;CACtB;CACA;GACE,wCAAwC;GACxC,8BAA8B;GAC9B,wCAAwC;GACxC,0BAA0B;CAC5B;;CAEA;GACE,uCAAuC;GACvC,8BAA8B;GAC9B,qCAAqC;GACrC,aAAa;GACb,WAAW;GACX,mBAAmB;CACrB;;EAEC;GACC,wCAAwC;EACzC","sourcesContent":[".featured_category_section{\n   margin-bottom:4rem;\n}\n.fc_product_listing{\n    display: flex;\n    column-gap: 1rem;\n    row-gap: 2rem;\n    flex-wrap: wrap;\n}\n.fc_product_listing_head_row{\n    margin-bottom: 1rem;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n }\n .fc_product_listing_head_row h1{\n   font-size: var(--sans-serif-size-medium);\n   font-family: var(--sans-serif);\n   font-weight: var(--sans-serif-semi-bold);\n   text-transform: capitalize;\n }\n\n .fc_product_listing_head_row p{\n   font-size: var(--sans-serif-size-small);\n   font-family: var(--sans-serif);\n   font-weight: var(--sans-serif-medium);\n   display: flex;\n   gap: 0.3rem;\n   align-items: center;\n }\n\n  .fc_product_listing_head_row p span{\n   font-size: var(--sans-serif-size-medium);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
