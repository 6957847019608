import React, { useEffect, useRef, useState } from "react";
import WebInput from "../../../component/library/webInput";
import { FiMail } from "react-icons/fi";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { IoAlertCircle, IoLocationSharp } from "react-icons/io5";
import { GrMoney } from "react-icons/gr";
import { AiFillLock } from "react-icons/ai";
import { selectIsNightMode } from "../../../redux/slices/modeSlice";
import { useSelector } from "react-redux";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { AuthApiService } from "../../../service/authApiService";
import NameInput from "../../../component/views/seller/profile/nameInput";
import PhoneInput from "../../../component/views/seller/profile/phoneInput";
import { gstRegex } from "../../../helper/regex";
import WebButton from "../../../component/library/webButton";
import PasswordUpdate from "../../../component/views/seller/profile/passwordUpdate";
import "./style.css";

export default function UserProfile() {
  const isNightMode = useSelector(selectIsNightMode);
  const loader = useLoader();
  const message = useMessage();

  const [gstError, setGstError] = useState("");

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    address1: "",
    address2: "",
    gst: "",
  });

  const handleUserProfile = () => {
    loader.showLoading(true);

    AuthApiService.getProfile()
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setData({
            ...data,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email,
            phone: res.data.phone,
            companyName: res.data.companyName,
            address1: res.data.address1,
            address2: res.data.address2,
            gst: res.data.gst,
            password: res.data.password,
          });
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    if (e.target.name === "gst") {
      setGstError("");
    }
  };

  useEffect(() => {
    handleUserProfile();
  }, []);

  const validate = () => {
    let result = true;
    if (data.gst && !data.gst.match(gstRegex)) {
      setGstError("Not a valid gst number");
      result = false;
    }
    return result;
  };

  const updateProfile = () => {
    if ((data.gst && validate()) || !data.gst) {
      loader.showLoading(true);

      AuthApiService.updateProfile(JSON.stringify(data))
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            message.showToast(res.status.message, ToastTypes.SUCCESS);
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    }
  };

  return (
    <div className={`profile_bg`}>
      <div
        className={`user_profile_head ${
          isNightMode ? "user_profile_head_dark" : "user_profile_head_light"
        }`}
      >
        User Detail
      </div>
      <NameInput data={data} setData={setData} />
      <PhoneInput data={data} handleUpdate={handleUserProfile} />
      {/* email input */}
      <div
        className={`user_profile_input_row ${
          isNightMode
            ? "user_profile_input_row_dark"
            : "user_profile_input_row_light"
        }`}
      >
        <FiMail
          className={`user_profile_icon ${
            isNightMode ? "user_profile_icon_dark" : "user_profile_icon_light"
          }`}
        />
        <WebInput
          parentClassName="input_parent"
          className="input_transparent_hide"
          type="email"
          placeholder="Email Address"
          id="emailInput"
          value={data.email}
          name="email"
        />
      </div>
      {/* Company Heading */}
      <div
        className={`user_profile_head ${
          isNightMode ? "user_profile_head_dark" : "user_profile_head_light"
        }`}
      >
        Company Detail
      </div>
      <div
        className={`user_profile_input_row ${
          isNightMode
            ? "user_profile_input_row_dark"
            : "user_profile_input_row_light"
        }`}
      >
        <HiOutlineBuildingOffice2
          className={`user_profile_icon ${
            isNightMode ? "user_profile_icon_dark" : "user_profile_icon_light"
          }`}
        />
        <WebInput
          parentClassName="input_parent"
          className="input_transparent"
          type="text"
          placeholder="Company Name"
          id="comapnyNameInput"
          onChange={handleOnChange}
          value={data.companyName}
          name="companyName"
        />
      </div>
      <div
        className={`user_profile_input_row ${
          isNightMode
            ? "user_profile_input_row_dark"
            : "user_profile_input_row_light"
        }`}
      >
        <IoLocationSharp
          className={`user_profile_icon ${
            isNightMode ? "user_profile_icon_dark" : "user_profile_icon_light"
          }`}
        />
        <WebInput
          parentClassName="input_parent"
          className="input_transparent"
          type="text"
          placeholder="Company Address"
          id="address1Input"
          onChange={handleOnChange}
          value={data.address1}
          name="address1"
        />
      </div>
      <div
        className={`user_profile_input_row ${
          isNightMode
            ? "user_profile_input_row_dark"
            : "user_profile_input_row_light"
        }`}
      >
        <IoLocationSharp
          className={`user_profile_icon ${
            isNightMode ? "user_profile_icon_dark" : "user_profile_icon_light"
          }`}
        />
        <WebInput
          parentClassName="input_parent"
          className="input_transparent"
          type="text"
          placeholder="Company Address"
          id="address2Input"
          onChange={handleOnChange}
          value={data.address2}
          name="address2"
        />
      </div>

      <div>
        <div
          className={`user_profile_input_row ${
            isNightMode
              ? "user_profile_input_row_dark"
              : "user_profile_input_row_light"
          }`}
        >
          <GrMoney
            className={`user_profile_icon ${
              isNightMode ? "user_profile_icon_dark" : "user_profile_icon_light"
            }`}
          />
          <WebInput
            parentClassName="input_parent"
            className="input_transparent"
            value={data.gst}
            name="gst"
            id="gstInput"
            type="text"
            placeholder="GST Number"
            maxLength="15"
            onChange={handleOnChange}
          />
        </div>

        {gstError ? (
          <p className="profile_error">
            <IoAlertCircle
              color="#ED1302"
              size={18}
              className="profile_error_icon"
            />
            {gstError}
          </p>
        ) : null}
      </div>

      {/* password change */}

      <div
        className={`user_profile_head ${
          isNightMode ? "user_profile_head_dark" : "user_profile_head_light"
        }`}
      >
        Password Reset
      </div>

      <PasswordUpdate />

      <WebButton
        className="profile_submit_btn"
        text="Done"
        onClick={() => updateProfile()}
      />
    </div>
  );
}
