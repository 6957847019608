import React from "react";
import One from "../../../../../assets/images/home/whyus/one.png";
import Two from "../../../../../assets/images/home/whyus/two.png";
import Three from "../../../../../assets/images/home/whyus/three.png";
import Four from "../../../../../assets/images/home/whyus/four.png";

import "./style.css";

export default function WhyUs() {
  return (
    <div className="section_box">
      <div className="why_us_bg">
        <p className="home_main_head">Why Dropshipping with us? </p>

        <div className="why_us_content_row">
          <div className="why_us_content_box">
            <img src={Three} />
          </div>

          <div className="why_us_content_box">
            <h2>Effortless Order Processing</h2>
            <p>
              Enjoy 24/7 order placement on our automated platform. Orders are
              swiftly fulfilled upon payment confirmation for seamless
              transactions.
            </p>
          </div>

          <div className="why_us_content_box">
            <img src={Two} />
          </div>

          <div className="why_us_content_box">
            <h2>Advanced API Integration</h2>
            <p>
              Accelerate product uploads and maintain real-time inventory
              synchronization with our cutting-edge API integration.
            </p>
          </div>

          <div className="why_us_content_box">
            <h2>Superior Customer Care</h2>
            <p>
              Our expert team is committed to delivering exceptional customer
              service. Access personalized support anytime via our online
              helpdesk.
            </p>
          </div>
          <div className="why_us_content_box">
            <img src={One} />
          </div>

          <div className="why_us_content_box">
            <h2>Extensive Product Range</h2>
            <p>
              Explore a wide array of popular products and categories tailored
              for Dropshipping needs. Discover over 50,000+ SKUs at your
              fingertips for unparalleled selection.
            </p>
          </div>

          <div className="why_us_content_box">
            <img src={Four} />
          </div>
        </div>
      </div>
    </div>
  );
}
