import React, { useRef, useState } from "react";
import WebInput from "../../../../library/webInput";
import WebButton from "../../../../library/webButton";
import "./style.css";

export default function PriceMarkup({ data, error, handleOnChange, onSubmit,refresh }) {
  const [active, setActive] = useState("amazon");
  const [mrpActive, setMrpActive] = useState("amazon");

  const ref=useRef()
  const mrpRef=useRef()

  const handleType = (type) => {
    setActive(type);
    ref.current.value='';
    refresh()
  };

  const handleMrpType = (type) => {
    setMrpActive(type);
    mrpRef.current.value='';
    refresh()
  };


  return (
    <div className="price_markup_bg">
      <div className="setting_btn_row">
        <WebButton
          className={
            active === "amazon"
              ? "active_btn_with_shadow"
              : "inactive_btn_with_shadow"
          }
          text="Amazon"
          onClick={() => handleType("amazon")}
          type="submit"
        />

        <WebButton
          className={
            active === "shopify"
              ? "active_btn_with_shadow"
              : "inactive_btn_with_shadow"
          }
          text="Shopify"
          onClick={() => handleType("shopify")}
          type="submit"
        />
      </div>

      <div className="add_price_input_row">
        <p>Seller Price</p>
        <p>=</p>
        <p>Upsaleo Price</p>
        <p>+</p>

        <WebInput
          className="setting_input"
          type="number"
          placeholder="margin percentage"
          id="spPercent"
          name={active === "amazon" ? "amazonSpPercent" : "shopifySpPercent"}
          error={
            active === "amazon" ? error.amazonSpPercent : error.shopifySpPercent
          }
          value={
            active === "amazon" ? data.amazonSpPercent : data.shopifySpPercent
          }
          onChange={handleOnChange}
          ref={ref}
        />
        <p>%</p>
        <WebButton
          className="setting_submit_btn"
          text="Add Seller Price"
          onClick={onSubmit}
          type="submit"
        />
      </div>

      {/* ///////////////////////////////////////////////////////////////////////////////////////////////// */}

      <div className="setting_btn_row">
        <WebButton
          className={
            mrpActive === "amazon"
              ? "active_btn_with_shadow"
              : "inactive_btn_with_shadow"
          }
          text="Amazon"
          onClick={() => handleMrpType("amazon")}
          type="submit"
        />

        <WebButton
          className={
            mrpActive === "shopify"
              ? "active_btn_with_shadow"
              : "inactive_btn_with_shadow"
          }
          text="Shopify"
          onClick={() => handleMrpType("shopify")}
          type="submit"
        />
      </div>

      <div className="add_price_input_row">
        <p>MRP</p>
        <p>=</p>
        <p>Selling Price</p>
        <p>x</p>

        <WebInput
          className="setting_input"
          type="number"
          placeholder="1.2"
          id="mrpPercent"
          name={
            mrpActive === "amazon" ? "amazonMrpPercent" : "shopifyMrpPercent"
          }
          error={
            mrpActive === "amazon"
              ? error.amazonMrpPercent
              : error.shopifyMrpPercent
          }
          value={
            mrpActive === "amazon"
              ? data.amazonMrpPercent
              : data.shopifyMrpPercent
          }
          onChange={handleOnChange}
          ref={mrpRef}
        />

        <WebButton
          className="setting_submit_btn"
          text="Add MRP"
          onClick={onSubmit}
        />
      </div>
    </div>
  );
}
