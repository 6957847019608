import React from "react";
import HeadDesign from "../../../../../assets/images/home/headDesign.png";
import { AiOutlineCheck } from "react-icons/ai";
import { RxDotFilled } from "react-icons/rx";
import WebButton from "../../../../../../component/library/webButton";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";

export default function BannerDescription() {
  const navigate = useNavigate();

  const dropshipList = [
    { name: "How does dropshipping work", link: "/how-dropshiping-work" },
    { name: "Why work with upsaleo", link: "/why-work-with-upsaleo" },
    { name: "Integrate your store", link: "/seller/settings" },
  ];

  const helpCentreList = [
    { name: "Contact us", link: "/contact-us" },
    { name: "FAQ", link: "/help-center" },
    { name: "Seller Dashboard", link: "/seller/dashboard" },
  ];

  return (
    <div className="banner_right_box_bg">
      <img src={HeadDesign} />

      <div className="banner_right_box_content">
        <p className="banner_right_box_head">Dropshipping Simplified</p>

        <div className="dropship_list">
          {dropshipList.map((item, index) => {
            return (
              <Link to={item.link}>
                <p key={index}>
                  <AiOutlineCheck />
                  {item.name}
                </p>
              </Link>
            );
          })}
        </div>

        <WebButton
          text="Sign Up"
          className="web_navbar_btn active_btn_without_shadow signup_btn_mobile"
          onClick={() => navigate("/signup")}
        />

        <div className="help_centre_head_row">
          <p className="banner_right_box_head">Help Centre</p>
          {/* <p className="view_more_btn">View more</p> */}
        </div>

        <div className="dropship_list">
          {helpCentreList.map((item, index) => {
            return (
              <Link to={item.link}>
                <p key={index}>
                  <RxDotFilled />
                  {item.name}
                </p>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
