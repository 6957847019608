import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {  useSelector } from "react-redux";
import { categoryData } from "../../../../../../redux/slices/categorySlice";
import { nextVIcon } from "../../../../../assets/icons";
import { queryToUrl } from "../../../../../helper/utils";
import "./style.css";

export default function BannerCategories() {
  const [subMenus, setSubMenus] = useState([]);
  const categories = useSelector(categoryData);

  const [menuActive, setMenuActive] = useState(false);
  const [activeMenuIndex, setActiveMenuIndex] = useState("");
  const [allCategory, setAllCategory] = useState(false);

  const [categoryName, setCategoryName] = useState("");

  const handleSubMenus = (category, menus, index) => {
    setCategoryName(category);
    setSubMenus(menus);
    setMenuActive(true);
    setActiveMenuIndex(index);
  };

  const handleOnMouseLeave = () => {
    setMenuActive(false);
    setActiveMenuIndex("");
    setAllCategory(false);
  };

  const handleAllCategories = () => {
    setAllCategory(true);
    setActiveMenuIndex("20");
    setMenuActive(true);
    setSubMenus(categories[9]?.children);
  };

  const handleRestSubMenus = (category, menus, index) => {
    setCategoryName(category);
    setSubMenus(menus);
    setMenuActive(true);
    setActiveMenuIndex(index);
  };

  useEffect(() => {
    const index = parseInt(activeMenuIndex);
    if (index < 20) {
      setAllCategory(false);
    }
  }, [activeMenuIndex]);

  return (
    <div className="category_menu_bg" onMouseLeave={() => handleOnMouseLeave()}>
      {categories?.slice(0, 9).map((item, index) => {
        return (
          <Link
            to={`/collection/?category=${queryToUrl(item.c_name)}`}
            key={index}
            className={
              activeMenuIndex === `1${index}`
                ? "active_menu category_menu_item"
                : "category_menu_item"
            }
            onMouseOver={() =>
              handleSubMenus(item.c_name, item.children, `1${index}`)
            }
          >
            <p>{item.c_name}</p>
            {nextVIcon}
          </Link>
        );
      })}

      <div
        className={
          allCategory ? "active_menu category_menu_item" : "category_menu_item"
        }
        onMouseOver={() => handleAllCategories()}
      >
        <p>All Categories</p>
        {nextVIcon}
      </div>

      {allCategory ? (
        <div className="rest_categories_box">
          {categories?.slice(9, categories.length)?.map((item, index) => {
            return (
              <Link
                to={`/collection/?category=${queryToUrl(item.c_name)}`}
                key={index}
                className={
                  activeMenuIndex === `2${index}`
                    ? "active_menu category_menu_item"
                    : "category_menu_item"
                }
                onMouseOver={() =>
                  handleRestSubMenus(item.c_name, item.children, `2${index}`)
                }
              >
                <p>{item.c_name}</p>
              </Link>
            );
          })}
        </div>
      ) : null}

      {menuActive ? (
        <div
          className={
            allCategory
              ? "submenus_box submenus_box_position_all"
              : "submenus_box submenus_box_position"
          }
        >
          {subMenus?.map((subCategory, index) => {
            return (
              <div className="submenu_item" key={index}>
                <Link
                  className="submenu_item_head"
                  to={`/collection/?category=${
                    queryToUrl(categoryName) +
                    "/" +
                    queryToUrl(subCategory.c_name)
                  }`}
                >
                  {subCategory.c_name}
                </Link>
                <div className="submenus_items_box">
                  {subCategory?.children?.map((subSubCategory, index) => {
                    return (
                      <Link
                        to={`/collection/?category=${
                          queryToUrl(categoryName) +
                          "/" +
                          queryToUrl(subCategory.c_name) +
                          "/" +
                          queryToUrl(subSubCategory.c_name)
                        }`}
                      >
                        <p key={index}>{subSubCategory.c_name}</p>
                      </Link>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
