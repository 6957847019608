// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unique-dashboard {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.unique-cards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.unique-card {
  flex: 1 1;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.unique-card h3 {
  margin-bottom: 10px;
}

.unique-card p {
  font-size: 1.5em;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/views/admin/dashboard/dashboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,wCAAwC;EACxC,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,SAAS;AACX","sourcesContent":[".unique-dashboard {\n  padding: 20px;\n  max-width: 800px;\n  margin: 0 auto;\n}\n\n.unique-cards {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n}\n\n.unique-card {\n  flex: 1;\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  text-align: center;\n}\n\n.unique-card h3 {\n  margin-bottom: 10px;\n}\n\n.unique-card p {\n  font-size: 1.5em;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
