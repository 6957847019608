import React, { useEffect, useState } from "react";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { ProductsApiService } from "../../../service/website/productsApiService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import WebButton from "../../../component/library/webButton";
import { SubscriptionApiService } from "../../../service/website/subscriptionApiService";
import { getAuthLocalStorage } from "../../../storage/localStorage";
import BreadCrumbs from "../../component/common/breadCrumbs";
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./style.css";
import { CartApiService } from "../../service/cartApiService";
import { useDispatch } from "react-redux";
import { fetchCartItems } from "../../../redux/slices/cartSlice";


const ProductDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [product, setProduct] = useState({});
  const [sku, setSku] = useState(searchParams.get("sku"));

  const loader = useLoader();
  const message = useMessage();

  useEffect(() => {
    if (sku) {
      setSearchParams({ sku: sku });
    }
  }, [sku]);

  const handleProduct = () => {
    loader.showLoading(true);
    ProductsApiService.getProductById(searchParams.get("id"))
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setProduct(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      handleProduct();
    }
  }, []);

  const handleProductBySku = () => {
    loader.showLoading(true);
    ProductsApiService.getProductBySku(sku)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setProduct(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  useEffect(() => {
    if (sku) {
      handleProductBySku();
    }
  }, [sku]);

  return (
    <div>
      <BreadCrumbs product={product} />
      <div className="product_detail_box">

        <div className="pd_sm_screen"><ProductCarousel product={product}/> <SubscribeButton product={product}/></div>
        <div className="pd_lg_screen"><ProductDetailLeft product={product} /></div>
        
        
        <ProductDetailRight product={product} setSku={setSku} />
      </div>
    </div>
  );
};

export default ProductDetails;

const ProductDetailLeft = ({ product }) => {
  const [times, setTimes] = useState(0);
  const [activeImage, setActiveImage] = useState();

  const handlePrev = () => {
    if (times !== 0) {
      setTimes(times - 1);
    }
  };

  const handleNext = () => {
    if (product?.imgUrl?.length > times + 1) {
      setTimes(times + 1);
    }
  };

  const handleImage = (url) => {
    setActiveImage(url);
  };


  useEffect(() => {
    if (product?.imgUrl) {
      setActiveImage(product?.imgUrl[0]);
    }
  }, [product]);

  return (
    <div className="product_detail_left_section">
      <div className="product_detail_image_box">
        <div className="product_detail_image_gallery">
          {times !== 0 ? (
            <div
              className="image_prev image_next_prev_btn"
              onClick={() => handlePrev()}
            >
              <IoIosArrowUp />
            </div>
          ) : null}

          <div
            className="image_gallery_column"
            style={{ position: "relative", top: `-${times * 90}px` }}
          >
            {product?.imgUrl?.map((img, index) => {
              return (
                <img
                  className="gallery_image"
                  src={img}
                  onClick={() => handleImage(img)}
                  onMouseOver={() => handleImage(img)}
                />
              );
            })}
          </div>

          {product?.imgUrl?.length - 5 > times &&
          product?.imgUrl?.length > 5 ? (
            <div
              className="image_next image_next_prev_btn"
              onClick={() => handleNext()}
            >
              <IoIosArrowDown />
            </div>
          ) : null}
        </div>

        <div>
          <img src={activeImage} className="product_detail_active_image" />

          <SubscribeButton product={product}/>
          
        </div>
      </div>
    </div>
  );
};



const ProductCarousel=({product})=>{

    return <div className="pd_carousel">
            <Carousel
              animationHandler="base"
              showArrows={false}
              showStatus={false}
              showIndicators={true}
              infiniteLoop={true}
              showThumbs={false}
              useKeyboardArrows={true}
              autoPlay={false}
              stopOnHover={true}
              swipeable={true}
              dynamicHeight={true}
              emulateTouch={true}
              autoFocus={false}
            >


              {product?.imgUrl?.map((img,index)=>{
                return <div className="pd_carousel_slide" key={index}>
                        <img src={img} />
                      </div>
              })}
             
            </Carousel>
          </div>
}


const ProductDetailRight = ({ product, setSku }) => {
  const [filteredColors, setFilteredColors] = useState([]);
  const [filteredSizes, setFilteredSizes] = useState([]);

  const products = product?.variations;

  useEffect(() => {
    const colors = [];

    products?.map((item) => {
      if (colors.length === 0) {
        colors.push(item);
      } else if (colors.every((val) => val.color !== item.color)) {
        colors.push(item);
      }
    });

    setFilteredColors(colors);

    const sizes = products?.filter((item) => {
      return product.color_map === item.color;
    });

    setFilteredSizes(sizes);
  }, [product]);

  const updateProduct = (e) => {
    setSku(e.target.value);
  };

  const createMarkup = (html) => {
    return { __html: html };
  };


  const dispatch=useDispatch()
  const message=useMessage();

  const addToCart=()=>{

    const userLogin= getAuthLocalStorage().isLoggedIn && getAuthLocalStorage().loginHeader && getAuthLocalStorage().userType ==='seller'

    if(userLogin){
      const productPayload={
        productId:product._id,
        quantity:1
      }
  
      CartApiService.AddUpdateCart(JSON.stringify(productPayload)).then((res)=>{
        if(res.status){
          dispatch(fetchCartItems())
        }
      }).catch((error)=>{
        console.log('error',error)
      })
    }else{
      message.showToast("To add product please login", ToastTypes.WARNING);
    }   
  }

  return (
    <div className="product_detail_right_section">
      <p className="pd_product_name">{product.title}</p>
      <p className="pd_product_sp"> &#8377;{product.sp}</p>

      <WebButton
        className="add_to_cart_btn"
        type="button"
        text="Add To Cart"
        onClick={()=>addToCart()}
      />

      <WebButton
        className="buy_now_btn"
        type="button"
        text="Buy Now"
        onClick={null}
      />

      {filteredColors?.length > 1 ? (
        <div className="size_color_dropdown">
          <p>Color</p>
          <select onChange={updateProduct}>
            {filteredColors?.map((item, index) => {
              return (
                <option
                  key={index}
                  selected={product.color_map === item.color}
                  value={item.sku}
                >
                  {item.color}
                </option>
              );
            })}
          </select>
        </div>
      ) : null}

      {filteredSizes?.length ? (
        <div>
          <p className="size_head">Size</p>

          <div className="size_row">
            {filteredSizes?.map((item, index) => {
              return (
                <div key={index} active={product.size_map === item.size}>
                  {item.size}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      <h1 className="pd_head">Product Details</h1>

      <table className="pd_table">
        {product?.c_name ? (
          <tr>
            <td>Category</td>
            <td>
              {product.c_name.split(">")[product.c_name.split(">").length - 1]}
            </td>
          </tr>
        ) : null}

        {product?.hsn ? (
          <tr>
            <td>Hsn</td>
            <td>{product.hsn}</td>
          </tr>
        ) : null}

        {product?.sku ? (
          <tr>
            <td>Sku</td>
            <td>{product.sku}</td>
          </tr>
        ) : null}

        {product?.warranty ? (
          <tr>
            <td>Warranty</td>
            <td>{product.warranty}</td>
          </tr>
        ) : null}

        {product?.location ? (
          <tr>
            <td>Country of Origin</td>
            <td>{product.location}</td>
          </tr>
        ) : null}

        {product?.handling_days ? (
          <tr>
            <td>Handling Days</td>
            <td>{product.handling_days}</td>
          </tr>
        ) : null}
      </table>

      <div className="pd_table_division"></div>

      <h1 className="pd_head">Additional Information</h1>

      <table className="pd_table">
        {product.quantity ? (
          <tr>
            <td>Net Quantity</td>
            <td>{product.quantity} Count</td>
          </tr>
        ) : null}

        {product?.length || product?.width || product?.height ? (
          <tr>
            <td>
              Item Dimensions ({product?.length ? "L x " : null}
              {product?.width ? "W x " : null}
              {product?.height ? "H" : null})
            </td>
            <td>
              {product?.length ? product?.length?.split(" ")[0] + "x" : null}
              {product?.width ? product?.width?.split(" ")[0] + "x" : null}
              {product?.height ? product?.height?.split(" ")[0] + "x" : null}
              Centimeters
            </td>
          </tr>
        ) : null}

        {product.weight ? (
          <tr>
            <td>Item Weight</td>
            <td>{product.weight} g</td>
          </tr>
        ) : null}
      </table>

      <div className="pd_table_division"></div>

      {product.description ? (
        <>
          <h1 className="pd_head">Product Description</h1>
          <div
            className="pd_para"
            dangerouslySetInnerHTML={createMarkup(product.description)}
          />
        </>
      ) : null}
    </div>
  );
};


const SubscribeButton=({product})=>{

  const message=useMessage()
  const navigate=useNavigate()
  const [isSubscribed, setIsSubscribed] = useState(product.subscribe);

  const check =
    getAuthLocalStorage().isLoggedIn &&
    getAuthLocalStorage().loginHeader &&
    getAuthLocalStorage().userType === "seller";

  const handleSubscribe = () => {
    if (check) {
      if (isSubscribed) {
        SubscriptionApiService.unSubscribeProduct(product.sku)
          .then((res) => {
            if (res.status.code === 200) {
              setIsSubscribed(false);
              message.showToast(res.status.message, ToastTypes.SUCCESS);
            } else {
              message.showToast(res.error.message, ToastTypes.ERROR);
            }
          })
          .catch((err) => {
            message.showToast(
              "Server error. Try again later",
              ToastTypes.ERROR
            );
          });
      } else {
        SubscriptionApiService.subscribeProduct(product.sku)
          .then((res) => {
            if (res.status.code === 200) {
              setIsSubscribed(true);
              message.showToast(res.status.message, ToastTypes.SUCCESS);
            } else {
              message.showToast(res.error.message, ToastTypes.ERROR);
            }
          })
          .catch((err) => {
            message.showToast(
              "Server error. Try again later",
              ToastTypes.ERROR
            );
          });
      }
    } else {
      navigate("/login");
      message.showToast(
        "To subscribe product please login",
        ToastTypes.WARNING
      );
    }
  };

  useEffect(() => {
    setIsSubscribed(product.subscribe);
  }, [product]);

  return <div className="subscribe_btn_row">
  <WebButton
    className={isSubscribed ? "subscribed_btn" : "subscribe_btn"}
    type="button"
    text={isSubscribed ? "Subscribed" : "Subscribe Now"}
    onClick={handleSubscribe}
  />
</div>
}