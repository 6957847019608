import React, { useEffect, useRef, useState } from "react";
import { navbarTopMenu } from "../../../helper/constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  cartIcon,
  closeIcon,
  heartIcon,
  menuIcon,
  nextVIcon,
  profileIcon,
} from "../../../assets/icons";
import Logo from "../../../assets/images/common/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthLocalStorage, getAuthLocalStorage } from "../../../../storage/localStorage";
import WebButton from "../../../../component/library/webButton";
import Profile from "../../../../component/layout/profile";
import SearchBar from "./searchBar";
import amazon from "../../../assets/images/integrations/amazon.png";
import shopify from "../../../assets/images/integrations/shopify.png";
import wooCommerce from "../../../assets/images/integrations/woo-commerce.png";
import wordpress from "../../../assets/images/integrations/wordpress.png";
import { useOutsideClick } from "../../../../base/context/OutsideClick";
import { AuthApiService } from "../../../../service/authApiService";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";
import { FCApiService } from "../../../service/fCApiService";
import MenuBar from "./menuBar";
import {  fetchCartItems, selectCartItems } from '../../../../redux/slices/cartSlice';
import { CartApiService } from "../../../service/cartApiService";
import "./style.css";

export default function WebNavbar() {
  const [menu, setMenu] = useState(false);
  const [userProfile, setUserProfile] = useState(false);
  const [showIntegrations, setShowIntgrations] = useState(false);
  const [data, setData] = useState({});
  const [featuredCategories, setFeaturedCategories] = useState([]);
  const message = useMessage();
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenu = () => {
    setMenu(!menu);
  };

  const check =
    getAuthLocalStorage().isLoggedIn && getAuthLocalStorage().loginHeader;

  const handleUserProfile = () => {
    setUserProfile(true);
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleRegister = () => {
    navigate("/signup");
  };

  const handleIntegration = (name) => {
    if (name === "Integrations") {
      setShowIntgrations(!showIntegrations);
    } else {
      setShowIntgrations(false);
    }
  };

  const handleSubscriptions = () => {
    if (check) {
      navigate("/seller/productSubscription");
    } else {
      navigate("/login");
    }
  };

  //get user profile
  const handleUserProfileApi = () => {
    AuthApiService.getProfile()
      .then((res) => {
        if (res.status.code === 200) {
          setData(res.data);
        } else if (res.status.code === 402) {
          message.showToast(res.error.message, ToastTypes.ERROR);
          clearAuthLocalStorage()
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  useEffect(() => {
    if (getAuthLocalStorage().isLoggedIn && getAuthLocalStorage().loginHeader) {
      handleUserProfileApi();
    }
  }, [getAuthLocalStorage().isLoggedIn]);

  //get featured categories

  const handleFeaturedCategories = () => {
    FCApiService.getCategories()
      .then((res) => {
        if (res.status.code === 200) {
          setFeaturedCategories(res.data);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  useEffect(() => {
    handleFeaturedCategories();
  }, []);

  //get cart
  const dispatch = useDispatch();

  const cart=useSelector(selectCartItems);

  useEffect(() => {
    dispatch(fetchCartItems())
  }, []);

  return (
    <div className="web_navbar_container">
      <div className="web_navbar_row_one">
        {navbarTopMenu.map((menu, index) => {
          return (
            <div key={index}>
              <Link
                to={menu.link}
                className={
                  location.pathname === menu.link
                    ? "web_navbar_menu_active web_navbar_menu"
                    : "web_navbar_menu"
                }
              >
                <p onClick={() => handleIntegration(menu.name)}>{menu.name}</p>
              </Link>
            </div>
          );
        })}

        {showIntegrations ? (
          <IntegrationPopup
            check={check}
            onClickOutside={() => {
              setShowIntgrations(false);
            }}
          />
        ) : null}
      </div>

      <div className="web_navbar_row_two">
          <div className="navbar_menu_logo_col">
          <div className="navbar_menu_btn" onClick={handleMenu}>
            {menuIcon}
          </div>

          <Link className="navbar_logo_col" to="/">
            <img src={Logo} />
          </Link>
        </div>

        <SearchBar />

        <div className="navbar_auth_col">
          {!check ? (
            <div className="web_navbar_auth_btn">
             <WebButton
                className="web_navbar_btn web_login_btn"
                text="Login"
                onClick={() => handleLogin()}
                type="button"
              />

              <WebButton
                className="web_navbar_btn active_btn_without_shadow"
                text="Register Now"
                onClick={() => handleRegister()}
                type="button"
              />
            </div>
          ) : (
            <div className="web_navbar_auth_icons">
              {getAuthLocalStorage().userType === "seller" ? (
                <>
                  <div
                    className="navbar_icon"
                    onClick={() => handleSubscriptions()}
                  >
                    {heartIcon}
                  </div>
                  <div
                    className="navbar_icon"
                    onClick={() => navigate('/checkout/cart')}
                  >
                    {cartIcon} 
                    <div className="cart_count">{cart.length}</div>
                  </div>
                </>
              ) : null}

              <div className="navbar_icon" onClick={() => handleUserProfile()}>
                {profileIcon}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="web_navbar_row_three">
        <div className="navbar_menu_btn" onClick={handleMenu}>
          {menuIcon}
        </div>

        <div>
          <Link
            to="/collection/all-products"
            className={
              location.pathname === "/collection/all-products"
                ? "web_navbar_menu_active web_navbar_menu"
                : "web_navbar_menu"
            }
          >
            All Products
          </Link>
        </div>

        {featuredCategories?.map((menu, index) => {
          return (
            <div key={index}>
              <Link
                to={menu.pageUrl + `?id=${menu._id}`}
                className={
                  location.pathname === menu.pageUrl
                    ? "web_navbar_menu_active web_navbar_menu"
                    : "web_navbar_menu"
                }
              >
                {menu.tagName}
              </Link>
            </div>
          );
        })}
      </div>


      <div  className="web_navbar_search_row">
        <SearchBar />
      </div>

      <MenuBar menu={menu} setMenu={setMenu} featuredCategories={featuredCategories}/>

      <div className="web_navbar_popup_wrapper">
        {userProfile ? (
          <Profile
            data={data}
            onClickOutside={() => {
              setUserProfile(false);
            }}
          />
        ) : null}
      </div>

    </div>
  );
}

const IntegrationPopup = ({ check, onClickOutside }) => {
  const ref = useRef();

  const outsideClick = useOutsideClick();

  useEffect(() => {
    outsideClick.getComponent(ref, onClickOutside);
  }, [onClickOutside]);

  const platforms = [
    { name: "Amazon", image: amazon },
    { name: "Shopify", image: shopify },
    { name: "Woocommerce", image: wooCommerce },
    { name: "Wordpress", image: wordpress },
  ];

  const handlePage = () => {
    if (check) {
      return "/seller/marketplaceApis";
    } else {
      return "/login";
    }
  };

  return (
    <div className="integration_popup" ref={ref}>
      {platforms.map((platform, index) => {
        return (
          <Link
            key={index}
            className="integration_popup_column"
            to={handlePage()}
          >
            <img src={platform.image} />
            <p>{platform.name}</p>
          </Link>
        );
      })}
    </div>
  );
};
