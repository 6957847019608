import React, { useState, useEffect } from "react";
import axios from "axios";
import "./style.css";

const MembershipManagement = () => {
  const [users, setUsers] = useState([]);
  const [membershipDetails, setMembeshipDetails] = useState([]);
  const [editMode, setEditMode] = useState(null);

  useEffect(() => {
    // Fetch user data from the server
    const token = localStorage.getItem("LOGIN_HEADER");
    axios
      .get(`${process.env.REACT_APP_APIUrl}admin/viewMembership`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setUsers(response.data.data);
      })
      .catch((error) => console.error("Error fetching user data:", error));
  }, []);

  useEffect(() => {
    // Fetch membership details from the server
    axios
      .get(`${process.env.REACT_APP_APIUrl}user/subscription?status=active`)
      .then((response) => {
        console.log(response.data.data);
        setMembeshipDetails(response.data.data);
      })
      .catch((error) =>
        console.error("Error fetching membership details:", error)
      );
  }, []);

  const handlePlanChange = (userId, newPlan) => {
    const membershipData = membershipDetails.find(
      (plan) => plan.name === newPlan
    );
    const updatedUsers = users.map((user) =>
      user._id === userId
        ? {
            ...user,
            activeMembership: {
              ...user.activeMembership,
              subscriptionId: membershipData._id,
            },
          }
        : user
    );
    setUsers(updatedUsers);
  };

  const handleDateChange = (userId, startDate, endDate) => {
    const updatedUsers = users.map((user) =>
      user._id === userId
        ? {
            ...user,
            activeMembership: {
              ...user.activeMembership,
              startDate,
              endDate,
            },
          }
        : user
    );
    setUsers(updatedUsers);
  };

  const handleSave = (userId) => {
    const user = users.find((user) => user._id === userId);
    const token = localStorage.getItem("LOGIN_HEADER");
    axios
      .put(`${process.env.REACT_APP_APIUrl}admin/updateMembership`, user, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setEditMode(null);
        // Optional: fetch updated user data from the server to ensure consistency
      })
      .catch((error) => {
        console.error("Error updating user data:", error);
      });
  };

  const handleEdit = (userId) => {
    setEditMode(userId);
  };

  return (
    <div className="container">
      <header className="header">
        <h1>Admin Portal - Membership User List</h1>
      </header>
      <main className="main-content">
        <table className="user-table">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Plan</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user._id}>
                <td>{`${user.firstName} ${user.lastName}`}</td>
                <td>{user.email}</td>
                <td>
                  {editMode === user._id ? (
                    <select
                      value={user.activeMembership?.id}
                      onChange={(e) =>
                        handlePlanChange(user._id, e.target.value)
                      }
                    >
                      {membershipDetails.map((membership) => (
                        <option key={membership.id} value={membership.id}>
                          {membership.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    user.activeMembership?.subscriptionName
                  )}
                </td>

                <td>
                  {editMode === user._id ? (
                    <input
                      type="date"
                      value={user.activeMembership?.startDate?.substr(0, 10)}
                      onChange={(e) =>
                        handleDateChange(
                          user._id,
                          e.target.value,
                          user.activeMembership?.endDate?.substr(0, 10)
                        )
                      }
                    />
                  ) : (
                    user.activeMembership?.startDate?.substr(0, 10)
                  )}
                </td>
                <td>
                  {editMode === user._id ? (
                    <input
                      type="date"
                      value={user.activeMembership?.endDate?.substr(0, 10)}
                      onChange={(e) =>
                        handleDateChange(
                          user._id,
                          user.activeMembership?.startDate?.substr(0, 10),
                          e.target.value
                        )
                      }
                    />
                  ) : (
                    user.activeMembership?.endDate?.substr(0, 10)
                  )}
                </td>
                <td>
                  {editMode === user._id ? (
                    <button
                      className="save-btn"
                      onClick={() => handleSave(user._id)}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className="edit-btn"
                      onClick={() => handleEdit(user._id)}
                    >
                      Edit
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    </div>
  );
};

export default MembershipManagement;
