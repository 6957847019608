import React, { useEffect, useState } from "react";
import axios from "axios";
import "./InstagramVideos.css";

const InstagramVideo = () => {
  const [posts, setPosts] = useState([]);
  const accessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN;
  useEffect(() => {
    const fetchInstagramVideos = async () => {
      try {
        const response = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink&access_token=${accessToken}`
        );
        const allMedia = response.data.data;
        const imageMedia = allMedia
          .filter((media) => media.media_type === "IMAGE")
          .slice(0, 4);
        setPosts(imageMedia);
      } catch (error) {
        console.error("Error fetching Instagram videos", error);
      }
    };

    fetchInstagramVideos();
  }, [accessToken]);

  return (
    <div className="section_box">
      <div className="home_main_head">Instagram Posts</div>
      <div className="instagram-posts-container">
        {posts.map((post) => (
          <div key={post.id} className="instagram-post">
            <div className="post-media">
              {post.media_type === "VIDEO" ? (
                <video
                  controls
                  onEnded={(e) => {
                    e.target.play();
                  }}
                >
                  <source src={post.media_url} type="video/mp4" />
                  Video Not Supported
                </video>
              ) : (
                <img src={post.media_url} alt="Instagram Post" />
              )}
            </div>
            <a
              href={post.permalink}
              target="_blank"
              rel="noopener noreferrer"
              className="post-link"
            >
              View on Instagram
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InstagramVideo;
