import React, { useEffect, useState } from "react";
import { downVIcon } from "../../../../assets/icons";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { setUrl } from "../../../../helper/utils";
import "./style.css";

export default function Sorting() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showOptions, setShowOptions] = useState(false);
  const [activeOption, setActiveOption] = useState({
    name: "Recommended",
    value: "recommended",
  });

  const location = useLocation();
  const navigate = useNavigate();

  const options = [
    { name: "Recommended", value: "recommended" },
    { name: "Price: High to Low", value: "highToLow" },
    { name: "Price: Low to High", value: "lowToHigh" },
  ];

  const handleOnClick = (item) => {
    setActiveOption({ name: item.name, value: item.value });
    setShowOptions(false);
  };

  useEffect(() => {
    if (activeOption.value) {
      navigate(
        setUrl(location.pathname, searchParams, "sortBy", activeOption.value)
      );
    }
  }, [activeOption.value, activeOption.name]);

  useEffect(() => {
    if (searchParams.get("sortBy")) {
      options.filter((item) => {
        if (item.value === searchParams.get("sortBy")) {
          setActiveOption({
            name: item.name,
            value: item.value,
          });
        }
      });
    }
  }, [location]);

  return (
    <div className="collection_sorting_container">
      <div
        className="collection_sorting_box"
        onMouseOver={() => setShowOptions(true)}
        onMouseOut={() => setShowOptions(false)}
      >
        <div className="sorting_selected_option">
          <p>
            <span>Sort by :</span> {activeOption.name}
          </p>
          <p className="sorting_dropdown_icon">{downVIcon}</p>
        </div>


          {showOptions ? (
            <div className="collection_sorting_options_box">
              {options.map((item, index) => {
                return (
                  <p
                    key={index}
                    onClick={() => handleOnClick(item)}
                    className={
                      activeOption.name === item.name ? "sort_active_option" : ""
                    }
                  >
                    {item.name}
                  </p>
                );
              })}
            </div>
          ) : null}

          <div className="collection_sorting_options_box_sm_screen">
              {options.map((item, index) => {
                return (
                  <p
                    key={index}
                    onClick={() => handleOnClick(item)}
                    className={
                      activeOption.name === item.name ? "sort_active_option" : ""
                    }
                  >
                    {item.name}
                  </p>
                );
              })}
          </div>

      </div>
    </div>
  );
}
