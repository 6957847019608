import React, { useEffect } from "react";
import { getAuthLocalStorage } from "../../../storage/localStorage";
import { useNavigate } from "react-router-dom";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";

export default function ProtectRoute({ children }) {
  const navigate = useNavigate();
  const message = useMessage();

  const condition =
    getAuthLocalStorage().loginHeader && getAuthLocalStorage().isLoggedIn;

  const handleMessage = () => {
    message.showToast("First login to your account", ToastTypes.WARNING);
    navigate("/login");
  };

  useEffect(() => {
    if (!condition) {
      handleMessage();
    }
  }, []);

  return <div>{condition ? children : null}</div>;
}
