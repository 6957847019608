import React from "react";
import step1 from "../../../../../assets/images/home/steps/image 61.png";
import step2 from "../../../../../assets/images/home/steps/image 63.png";
import step3 from "../../../../../assets/images/home/steps/image 64.png";
import step4 from "../../../../../assets/images/home/steps/image 65.png";
import step5 from "../../../../../assets/images/home/steps/image 66.png";
import step6 from "../../../../../assets/images/home/steps/image 67.png";

import "./style.css";

export default function DropShipSteps({ headShow }) {
  // const steps = [
  //   {
  //     head: "Setting Up Your Store",
  //     para: "Easily launch your online presence with Upsaleo’s intuitive platform",
  //     img: step1,
  //   },
  //   {
  //     head: "Finding Reliable Products",
  //     para: "Partner with Upsaleo to access a network of reliable suppliers, ensuring high-quality products and satisfied customers",
  //     img: step2,
  //   },
  //   {
  //     head: "Listing Your Products",
  //     para: "Quickly add and showcase products using seamless integration tools",
  //     img: step3,
  //   },
  //   {
  //     head: "Processing Orders Efficiently",
  //     para: "Automate order fulfillment for quick and reliable customer delivery",
  //     img: step4,
  //   },
  //   {
  //     head: "Maximizing Profitability",
  //     para: "Optimize earnings with competitive pricing and strategic insights",
  //     img: step5,
  //   },
  //   {
  //     head: "Providing Excellent Customer Service",
  //     para: "Ensure satisfaction with dedicated support from Upsaleo’s team",
  //     img: step6,
  //   },
  // ];

  const steps = [
    {
      head: "Setting Up Your Store",
      para: "Easily launch your online presence with a platform like Shopify",
      img: step1,
    },
    {
      head: "Reliable product search",
      para: "Join Upsaleo for trending products and guaranteed satisfaction",
      img: step2,
    },
    {
      head: "Listing Your Products",
      para: "Quickly add and showcase products using seamless integration tools",
      img: step3,
    },
    {
      head: "Fast Order Processing",
      para: "Automate order fulfillment for quick and reliable customer delivery",
      img: step4,
    },
    {
      head: "Maximizing Profitability",
      para: "Optimize earnings with competitive pricing and strategic insights",
      img: step5,
    },
    {
      head: "Top-Notch Service",
      para: "Ensure satisfaction with dedicated support from Upsaleo's team",
      img: step6,
    },
  ];

  return (
    <div className="section_box">
      <div className="step_bg">
        {headShow ? (
          <p className="home_main_head">How does Dropshipping work? </p>
        ) : null}

        <div className="steps_row">
          {steps.map((item) => {
            return <Step image={item.img} head={item.head} para={item.para} />;
          })}
        </div>
      </div>
    </div>
  );
}

const Step = ({ image, head, para }) => {
  return (
    <div className="step_box">
      <img src={image} className="step_img" />
      <div className="step_head">{head}</div>
      <div className="step_para">{para}</div>
    </div>
  );
};
