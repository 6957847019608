// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_blow_out_sales {
    background-color: var(--web-white-color);
    box-shadow: 0 3px 3px 0 var(--web-shadow-color);
    width: 100%;
    padding: 1rem;
    margin: 1.5rem 0;
}

.home_blow_out_sales_product_row {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    gap: 0.5rem;
}

.home_blow_out_sale_head_row {
    display: flex;
    justify-content: space-between;
}

.home_blow_out_sale_head_row h1 {
    font-size: var(--sans-serif-size-medium);
    font-family: var(--sans-serif);
    font-weight: var(--sans-serif-semi-bold);
}

.home_blow_out_sale_head_row p {
    font-size: var(--sans-serif-size-small);
    font-family: var(--sans-serif);
    font-weight: var(--sans-serif-medium);
}

@media (max-width:768px) {
    .home_blow_out_sales_product_row{
        overflow-x:auto ;
    }

    .home_blow_out_sales ::-webkit-scrollbar {
       display: none;
    }
}


`, "",{"version":3,"sources":["webpack://./src/website/component/views/home/featuredCategoris/style.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,+CAA+C;IAC/C,WAAW;IACX,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,wCAAwC;IACxC,8BAA8B;IAC9B,wCAAwC;AAC5C;;AAEA;IACI,uCAAuC;IACvC,8BAA8B;IAC9B,qCAAqC;AACzC;;AAEA;IACI;QACI,gBAAgB;IACpB;;IAEA;OACG,aAAa;IAChB;AACJ","sourcesContent":[".home_blow_out_sales {\n    background-color: var(--web-white-color);\n    box-shadow: 0 3px 3px 0 var(--web-shadow-color);\n    width: 100%;\n    padding: 1rem;\n    margin: 1.5rem 0;\n}\n\n.home_blow_out_sales_product_row {\n    display: flex;\n    flex-wrap: nowrap;\n    overflow: hidden;\n    gap: 0.5rem;\n}\n\n.home_blow_out_sale_head_row {\n    display: flex;\n    justify-content: space-between;\n}\n\n.home_blow_out_sale_head_row h1 {\n    font-size: var(--sans-serif-size-medium);\n    font-family: var(--sans-serif);\n    font-weight: var(--sans-serif-semi-bold);\n}\n\n.home_blow_out_sale_head_row p {\n    font-size: var(--sans-serif-size-small);\n    font-family: var(--sans-serif);\n    font-weight: var(--sans-serif-medium);\n}\n\n@media (max-width:768px) {\n    .home_blow_out_sales_product_row{\n        overflow-x:auto ;\n    }\n\n    .home_blow_out_sales ::-webkit-scrollbar {\n       display: none;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
