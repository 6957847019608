// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textarea_wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.default_textarea{
    width: 350px;
    height: 100px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 0 0.5rem;
    outline: none;
}

.default_textarea_parent{
    width: 100%;
}


.auth_textarea{
    height: 150px;
    width: 100%;
    border-radius: 5px;
    outline: none;
    padding: 0 25px 0 20px;
    font-size: 1.2rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/component/library/webTextArea/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;;AAGA;IACI,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,oCAAoC;IACpC,0CAA0C;AAC9C","sourcesContent":[".textarea_wrapper {\n    display: flex;\n    align-items: center;\n    position: relative;\n}\n\n.default_textarea{\n    width: 350px;\n    height: 100px;\n    border: 1px solid rgba(0, 0, 0, 0.3);\n    border-radius: 4px;\n    padding: 0 0.5rem;\n    outline: none;\n}\n\n.default_textarea_parent{\n    width: 100%;\n}\n\n\n.auth_textarea{\n    height: 150px;\n    width: 100%;\n    border-radius: 5px;\n    outline: none;\n    padding: 0 25px 0 20px;\n    font-size: 1.2rem;\n    border: 1px solid rgba(0, 0, 0, 0.2);\n    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
