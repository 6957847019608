import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { getAuthVerifiedData } from "../../../storage/localStorage";
import { AuthApiService } from "../../../service/authApiService";
import WebInput from "../../../component/library/webInput";
import WebButton from "../../../component/library/webButton";
import { marketPlaces, ownWebsite, socialMedia } from "../../../helper/utils";
import MultiSelectDropdown from "../../../component/library/multiSelectDropdown";
import Image from "../../../assets/images/login/login.png";
import Navbar from "../../../component/common/authNavbar";
import { FaCircleCheck } from "react-icons/fa6";
import { addItems, categoryData } from "../../../redux/slices/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import "../login/style.css";
import { CategoryApiService } from "../../../service/website/categoryApiService";

export default function GetUserDetails() {
  const [seller, setSeller] = useState("");
  const [selected, setSelected] = useState("");

  const [error, setError] = useState("");

  const handleSeller = (val) => {
    setSelected(val);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selected === "") {
      setError("Please select one option");
    } else {
      setSeller(selected);
    }
  };

  return (
    <>
      <Navbar />
      {seller === "" ? (
        <QuesComponent
          handleSeller={handleSeller}
          selected={selected}
          handleSubmit={handleSubmit}
          error={error}
        />
      ) : null}

      {seller === true ? <FormComponent /> : null}

      {seller === false ? <EmailSend /> : null}
    </>
  );
}

function QuesComponent({ handleSeller, selected, handleSubmit, error }) {
  return (
    <div>
      <div className="auth_container">
        <div className="auth_row">
          <div className="auth_col_one">
            <h1 className="auth_heading">
              Unlock limitless opportunities.
              <br />
              Join us today!
            </h1>
            <img src={Image} className="auth_image" />
          </div>

          <div className="auth_col_two">
            <form className="auth_form" onSubmit={handleSubmit}>
              <h1 className="seller_ques_head">Are you a seller/Reseller?</h1>

              {error ? (
                <h3 style={{ color: "red" }}>{error}</h3>
              ) : (
                <h3>Please select</h3>
              )}

              <div
                className={`get_user_yes_no_btn ${
                  selected === true ? "focused_input_auth" : ""
                }`}
                value="Yes"
                onClick={() => handleSeller(true)}
              >
                Yes {selected === true ? <FaCircleCheck color="#4CAF50" /> : ""}
              </div>

              <div
                className={`get_user_yes_no_btn ${
                  selected === false ? "focused_input_auth" : ""
                }`}
                value="No"
                onClick={() => handleSeller(false)}
              >
                No {selected === false ? <FaCircleCheck color="#4CAF50" /> : ""}
              </div>

              <button className="auth_btn" onClick={handleSubmit} type="submit">
                Continue
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

function FormComponent() {
  const loader = useLoader();
  const message = useMessage();

  const [activeMarketPlaces, setActiveMarketPlaces] = useState([]);
  const [activeOtherMarketplaces, setActiveOtherMarketplaces] = useState([]);
  const [activeSellingCategories, setActiveSellingCategories] = useState([]);
  const [activeSocialMedia, setActiveSocialMedia] = useState([]);
  const [revenue, setRevenue] = useState("");
  const [monthlyOrder, setMonthlyOrder] = useState("");
  const [email, setEmail] = useState(getAuthVerifiedData().verifiedEmail);
  const [reseller, setReseller] = useState(false);

  const categories = useSelector(categoryData);

  const [category, setCategory] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    handleCategories();
  }, []);

  const handleCategories = () => {
    CategoryApiService.getCategories()
      .then((res) => {
        if (res.status.code === 200) {
          dispatch(addItems(res.data));
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  useEffect(() => {
    const arr = [];
    categories?.map((item) => arr.push({ name: item.c_name }));
    setCategory(arr);
  }, [categories]);

  const handleMarketPlaces = (options) => {
    const array = [];
    options.map((option) => {
      if (option.selected) {
        array.push(option.name);
      }
    });
    setActiveMarketPlaces(array);
  };

  const handleOtherMarketPlaces = (options) => {
    const array = [];
    options.map((option) => {
      if (option.selected) {
        array.push(option.name);
      }
    });
    setActiveOtherMarketplaces(array);
  };

  const handleSellingCategories = (options) => {
    const array = [];
    options.map((option) => {
      if (option.selected) {
        array.push(option.name);
      }
    });

    setActiveSellingCategories(array);
  };

  const handleSocialMedia = (options) => {
    const array = [];
    options.map((option) => {
      if (option.selected) {
        array.push(option.name);
      }
    });
    setActiveSocialMedia(array);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      loader.showLoading(true);

      const data = {
        email: email,
        marketPlaces: activeMarketPlaces,
        sellingCategories: activeSellingCategories,
        socialMedia: activeSocialMedia,
        otherMarketPlaces: activeOtherMarketplaces,
        revenue: revenue,
        monthlyOrder: monthlyOrder,
      };

      AuthApiService.userDetails(JSON.stringify(data))
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            setReseller(true);
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    }
  };
  return (
    <div>
      {reseller ? (
        <EmailSend />
      ) : (
        <div className="user_detail_form_bg">
          <div className="user_detail_form">
            <h2 className="get_user_form_label">Select Market Places?</h2>
            <MultiSelectDropdown
              options={marketPlaces}
              placeholder="Select Market Places"
              setValues={handleMarketPlaces}
              className="input_auth"
            />

            <h2 className="get_user_form_label">
              Select Social Media Platforms?
            </h2>
            <MultiSelectDropdown
              options={socialMedia}
              placeholder="Select Social Media"
              setValues={handleSocialMedia}
              className="input_auth"
            />

            <h2 className="get_user_form_label">Select Own Website?</h2>
            <MultiSelectDropdown
              options={ownWebsite}
              placeholder="Select Own Website"
              setValues={handleOtherMarketPlaces}
              className="input_auth"
            />

            <h2 className="get_user_form_label">
              Which category are you selling?
            </h2>
            <MultiSelectDropdown
              options={category}
              placeholder="Select Categories"
              setValues={handleSellingCategories}
              className="input_auth"
            />

            <h2 className="get_user_form_label">Your Monthly Revenue?</h2>
            <div style={{ width: "100%" }}>
              <WebInput
                id="revenueInput"
                type="number"
                placeholder="ex-30,000"
                onChange={(e) => setRevenue(e.target.value)}
                className="input_auth"
              />
            </div>

            <h2 className="get_user_form_label">Your Monthly Orders?</h2>
            <div style={{ width: "100%" }}>
              <WebInput
                id="ordersInput"
                type="number"
                placeholder="ex-10"
                onChange={(e) => setMonthlyOrder(e.target.value)}
                className="input_auth"
              />
            </div>

            <WebButton
              className="auth_btn"
              style={{ width: "200px" }}
              text="Submit"
              onClick={(e) => handleSubmit(e)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function EmailSend() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="auth_container">
        <div className="auth_row">
          <div className="auth_col_one">
            <h1 className="auth_heading">
              Unlock limitless opportunities.
              <br />
              Join us today!
            </h1>
            <img src={Image} className="auth_image" />
          </div>
          <div className="auth_col_two">
            <div className="auth_form">
              <h1>Verify Email</h1>
              <h3 className="auth_form_success_msg">
                Please verify email address to activate your account
              </h3>

              <div className="continue_msg">
                <span></span>or<span></span>
              </div>
              <WebButton
                className="auth_btn"
                text="Back to Login"
                onClick={() => navigate("/login")}
              />

              <div className="auth_message">
                <p>Don’t have an account?</p>
                <p onClick={() => navigate("/signup")}>Sign up for free!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
