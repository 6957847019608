import React, { useEffect, useState } from "react";
import WebButton from "../../../../library/webButton";
import { selectIsNightMode } from "../../../../../redux/slices/modeSlice";
import { useSelector } from "react-redux";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";
import { WalletApiService } from "../../../../../service/seller/walletApiService";
import ToolTip from "../../../../library/tooltip";
import "./style.css";

export default function MyCard({ wallet, setAddAmount }) {
  const isNightMode = useSelector(selectIsNightMode);
  const [autoPay, setAutoPay] = useState(false);
  const loader = useLoader();
  const message = useMessage();

  useEffect(() => {
    setAutoPay(wallet?.wallet?.autoPay);
  }, [wallet]);

  const handleRecharge = () => {
    setAddAmount(true);
  };

  const handleAutopay = async () => {
    loader.showLoading(true);
    await WalletApiService.getAutoPay()
      .then(async (res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          message.showToast(res.status.message, ToastTypes.SUCCESS);
          setAutoPay(!autoPay);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  return (
    <div className="my_card_bg">
      <div
        className={`mycard_head_row ${
          isNightMode ? "mycard_head_row_dark" : ""
        }`}
      >
        <p>My Card</p>
        <WebButton
          className="active_btn_with_shadow"
          text="Recharge"
          onClick={() => handleRecharge()}
          type="submit"
        />
      </div>

      <div className={`my_card_box ${isNightMode ? "my_card_box_dark" : ""}`}>
        <div className="mycard_shadow_two"></div>
        <div className="mycard_shadow_one"></div>
        <div className="mycard">
          <p>
            {wallet?.wallet?.userId?.firstName
              ? wallet?.wallet?.userId?.firstName
              : ""}{" "}
            {wallet?.wallet?.userId?.lastName
              ? wallet?.wallet?.userId?.lastName
              : ""}
          </p>

          <div>
            <p>{wallet?.wallet?.cardNumber}</p>

            <div className="toogle_price_row">
              <p>&#8377; {wallet?.wallet?.balance}</p>

              <div className="toggle_btn_row">
                <div className="toggle_bg"></div>
                <div
                  className={`${
                    autoPay
                      ? "toogle_active_btn"
                      : isNightMode
                      ? "toogle_inactive_btn_dark"
                      : "toogle_inactive_btn"
                  }`}
                  onClick={() => handleAutopay()}
                ></div>
                <ToolTip
                  className="wallet_tool_tip"
                  text={autoPay ? "Disable autopay" : "Enable autopay"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
