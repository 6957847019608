import React from "react";
import { callAPI } from "../../network/networkConnection";
import { NetworkConfiguration } from "../../network/networkConfriguation";

const getTransactions = (queryParams) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.TRANSACTION +
        queryParams,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchOrderDetails = (id) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.TRANSACTION_DETAILS +
        `/${id}`,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getOrderDetailsByUpsaleo = (id) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.GET_ORDER_DETAILS_UPID +
        `/${id}`,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const transactionsApiService = {
  getTransactions,
  fetchOrderDetails,
  getOrderDetailsByUpsaleo,
};
