import React, { useEffect, useState } from "react";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { ProductsApiService } from "../../../service/website/productsApiService";

import HomeBanner from "../../component/views/home/Banner";
import SelectedProducts from "../../component/views/home/selectedProducts";
import DropShipSteps from "../../component/views/home/dropshipWorkSteps";
import WhyUs from "../../component/views/home/whyUs";
import ChannelPartners from "../../component/views/home/channelPartners";
import ContactUs from "../../component/views/home/contactUs";
import Blogs from "../../component/views/home/blogs";
import InstagramVideo from "../../component/views/home/instagramVideo";
import FeaturedCategoris from "../../component/views/home/featuredCategoris";
import "./style.css";

export default function HomePage() {
  const [featuredCategoris, setFeaturedCategories] = useState([]);
  const [products, setProducts] = useState([]);

  const message = useMessage();

  const handleProducts = () => {
    ProductsApiService.getHomeProducts()
      .then((res) => {
        if (res.status.code === 200) {
          setFeaturedCategories(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleAllProducts = () => {
    ProductsApiService.getProducts("?limit=20&homeProduct=true")

      .then((res) => {
        if (res.status.code === 200) {
          setProducts(res.data.products);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  useEffect(() => {
    handleProducts();
    handleAllProducts();
  }, []);

  return (
    <div>
      <HomeBanner />

      {featuredCategoris.length &&
        featuredCategoris?.map((category) => {
          return <FeaturedCategoris category={category} />;
        })}

      <SelectedProducts products={products} />
      <DropShipSteps headShow={true} />
      <WhyUs />
      <ChannelPartners />
      <ContactUs />
      <Blogs />
      <InstagramVideo />
    </div>
  );
}
