import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store, { persistor } from "./redux/store";
import { Provider } from "react-redux";
import LoadingProvider from "./base/context/LoaderProvider";
import MessageProvider from "./base/context/MessageProvider";
import OutsideClickProvider from "./base/context/OutsideClick";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId="895117288681-nrupi2cihncofgq5t7l5ue1hnno4amtu.apps.googleusercontent.com">
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <MessageProvider>
          <LoadingProvider>
            <OutsideClickProvider>
              <App />
            </OutsideClickProvider>
          </LoadingProvider>
        </MessageProvider>
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>
);
