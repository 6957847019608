// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected_products_bg{
    width: 100%;
}

.selected_product_items_row{
    display: flex;
    column-gap: 1rem;
    row-gap: 2rem;
    flex-wrap: wrap;
}

@media (max-width:768px) {
    .selected_product_items_row {
        display: flex;
        justify-content: center;
        column-gap: 1rem;
        row-gap: 1rem;
        flex-wrap: wrap;
    }
    .home_section_head{
        text-align: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/website/component/views/home/selectedProducts/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI;QACI,aAAa;QACb,uBAAuB;QACvB,gBAAgB;QAChB,aAAa;QACb,eAAe;IACnB;IACA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".selected_products_bg{\n    width: 100%;\n}\n\n.selected_product_items_row{\n    display: flex;\n    column-gap: 1rem;\n    row-gap: 2rem;\n    flex-wrap: wrap;\n}\n\n@media (max-width:768px) {\n    .selected_product_items_row {\n        display: flex;\n        justify-content: center;\n        column-gap: 1rem;\n        row-gap: 1rem;\n        flex-wrap: wrap;\n    }\n    .home_section_head{\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
