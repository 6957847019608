import React, { useState } from "react";
import Modal from "react-modal";
import WebButton from "../../../../library/webButton";
import WebInput from "../../../../library/webInput";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";
import { marketPlaceApiService } from "../../../../../service/seller/marketPlaceApiService";
import Image from "../../../../../assets/images/marketPlace/form_image.png";
import "./style.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

export default function ShopifyForm({ modalIsOpen, setIsOpen, handleStores }) {
  const loader = useLoader();
  const message = useMessage();

  const [data, setData] = useState({
    storeDomain: "",
    shopifySecretKey: "",
  });

  const [error, setError] = useState({
    storeDomain: "",
    shopifySecretKey: "",
  });

  const handleOnchange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  function closeModal() {
    setIsOpen(false);
  }

  const validate = () => {
    let result = true;
    if (!data.storeDomain.length) {
      setError({ ...error, storeDomain: "Domain is required" });
      result = false;
    } else if (!data.shopifySecretKey.length) {
      setError({ ...error, shopifySecretKey: "Secret key is required" });
      result = false;
    }
    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      loader.showLoading(true);
      marketPlaceApiService
        .connectWithShopify(JSON.stringify(data))
        .then((res) => {
          closeModal();
          loader.showLoading(false);
          if (res.status.code === 200) {
            handleStores();
            message.showToast(res.status.message, ToastTypes.SUCCESS);
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          closeModal();
          loader.showLoading(false);
          if (err.status.code === 500) {
            message.showToast("Invalid credentials", ToastTypes.ERROR);
          } else {
            message.showToast(
              "Server error. Try again later",
              ToastTypes.ERROR
            );
          }
        });
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Shopify Form Modal"
    >
      <div className="shopify_marketplace_form_box">
        <img src={Image} className="shopify_marketplace_img" />

        <form className="shopify_marketplace_form" onSubmit={handleSubmit}>
          <p className="shopify_marketplace_form_head">
            Shopify Seller API Integration
          </p>

          <WebInput
            id="domainInput"
            type="text"
            label="STORE DOMAIN"
            name="storeDomain"
            value={data.storeDomain}
            error={error.storeDomain}
            onChange={handleOnchange}
          />

          <WebInput
            id="keyInput"
            type="text"
            label="ADMIN API SECRET"
            name="shopifySecretKey"
            value={data.shopifySecretKey}
            error={error.shopifySecretKey}
            onChange={handleOnchange}
          />

          <WebButton
            className="active_btn_without_shadow"
            text="Connect To Shopify"
            onClick={(e) => handleSubmit(e)}
          />
        </form>
      </div>
    </Modal>
  );
}
