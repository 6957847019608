import React from "react";
import { HowDropshippingWorkContent } from "../../helper/constants";
import SectionOneImg from "../../assets/images/how-dropshipping/section_one.png";
import SectionTwoImg from "../../assets/images/how-dropshipping/section_two.png";
import WebButton from "../../../component/library/webButton";
import DropShipSteps from "../../component/views/home/dropshipWorkSteps";
import "./style.css";

export default function HowDropshippingWork() {
  const handleWhatsapp = () => {
    window.open("https://wa.me/message/MKQJBLTLR5GAM1", "_blank");
  };
  return (
    <div>
      <div className="how_dropshipping_banner"></div>
      <div className="how_dropshipping_work_row">
        <div className="how_dropshipping_work_column">
          <h1>{HowDropshippingWorkContent.section_one_head}</h1>
          <p>{HowDropshippingWorkContent.section_one_para}</p>
          <div className="how_dropshipping_work_column_btn">
            <WebButton
              className="active_btn_with_shadow"
              text=" &nbsp;  &nbsp;  Speak to an expert  &nbsp;  &nbsp; "
              onClick={() => handleWhatsapp()}
            />
          </div>
        </div>
        <div className="how_dropshipping_work_column">
          <img
            src={SectionOneImg}
            className="how_dropshipping_work_section_img"
          />
        </div>
      </div>

      <DropShipSteps headShow={false} />

      <div className="how_dropshipping_work_row ">
        <div className="how_dropshipping_work_column">
          <div className="our_knowledge_box">
            <div className="border_box_one"></div>
            {HowDropshippingWorkContent.section_two_head}
            <p>{HowDropshippingWorkContent.section_two_para} </p>
            <div className="border_box_two"></div>
          </div>
        </div>
        <div className="how_dropshipping_work_column">
          <img
            src={SectionTwoImg}
            className="how_dropshipping_work_section_img"
          />
        </div>
      </div>
    </div>
  );
}
