import React, { useEffect, useState } from "react";
import WebInput from "../../../../component/library/webInput";
import FeatureList from "../featureList";
import WebButton from "../../../../component/library/webButton";
import { SubscriptionApiService } from "../../../../service/website/subscriptionApiService";
import { useLoader } from "../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";
import WebDropdown from "../../../../component/library/webDropdown";
import WebTextArea from "../../../../component/library/webTextArea";
import { useNavigate } from "react-router-dom";

const AddSubscription = () => {
  const loader = useLoader();
  const message = useMessage();

  const [features, setFeatures] = useState([]);
  const [timePeriod, setTimePeriod] = useState("days");
  const [time, setTime] = useState("");
  const navigate = useNavigate();

  const options = [
    {
      name: "Days",
      value: "days",
    },
    {
      name: "Months",
      value: "months",
    },
    {
      name: "Year",
      value: "year",
    },
  ];

  const [data, setData] = useState({
    name: "",
    timePeriod: "",
    feature: {
      name: "",
      enable: "",
    },
    description: "",
    price: "",
  });

  const [error, setError] = useState({
    name: "",
    timePeriod: "",
    features: "",
    description: "",
    price: "",
  });

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const handleFeature = (e) => {
    setData({
      ...data,
      feature: {
        name: e.target.value,
        enable: true,
      },
    });
  };

  const handleAddFeature = (e) => {
    e.preventDefault();
    if (data.feature.name) {
      setFeatures([...features, data.feature]);
      setData({
        ...data,
        feature: {
          name: "",
          enable: "",
        },
      });
      setError({ ...error, features: "" });
    }
  };

  const handleTimePeriod = (e) => {
    setTimePeriod(e.target.value);
  };

  const validate = () => {
    let result = true;

    if (!data.name) {
      setError({ ...error, name: "Name is required" });
      result = false;
    } else if (!data.timePeriod) {
      setError({ ...error, timePeriod: "Time period is required" });
      result = false;
    } else if (!features.length) {
      setError({ ...error, features: "Features is required" });
      result = false;
    } else if (!data.description) {
      setError({ ...error, description: "Description is required" });
      result = false;
    } else if (!data.price) {
      setError({ ...error, price: "Price is required" });
      result = false;
    }

    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      const payload = {
        name: data.name,
        timePeriod: time,
        features: features,
        description: data.description,
        price: data.price,
      };
      SubscriptionApiService.addSubscription(JSON.stringify(payload))
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            navigate("/admin/subscription/list");
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    }
  };

  useEffect(() => {
    setTime(data.timePeriod + " " + timePeriod);
    setError({ ...error, timePeriod: "" });
  }, [timePeriod, data.timePeriod]);

  return (
    <form className="flex justify-between" onSubmit={handleSubmit}>
      <div className="w-2/5 ">
        <label className="text-white">Name</label>
        <WebInput
          // label="Name"
          name="name"
          value={data.name}
          error={error.name}
          onChange={handleOnChange}
        />
        <label className="text-white">Time Period</label>
        <div className="flex gap-2 items-center">
          <WebInput
            name="timePeriod"
            type="number"
            value={data.timePeriod}
            error={error.timePeriod}
            onChange={handleOnChange}
          />
          <WebDropdown options={options} onChange={handleTimePeriod} />
        </div>

        <label className="text-white">Feature Name</label>
        <div className="flex gap-2 items-center">
          <WebInput
            name="feature"
            error={error.features}
            value={data.feature.name}
            onChange={handleFeature}
          />
          <WebButton
            onClick={handleAddFeature}
            text="Add"
            className="border px-6 py-2 bg-slate-500 rounded my-1 text-white "
          />
        </div>

        <WebTextArea
          label="Description"
          name="description"
          type="text"
          value={data.description}
          error={error.description}
          onChange={handleOnChange}
        />
        <label className="text-white">Price</label>

        <WebInput
          name="price"
          type="number"
          value={data.value}
          error={error.price}
          onChange={handleOnChange}
        />

        <WebButton
          text="Submit"
          className="bg-blue-600 w-72 h-10 rounded-md text-white mt-4"
        />
      </div>

      <div className="w-2/5">
        {features.length ? (
          <FeatureList features={features} setFeatures={setFeatures} />
        ) : null}
      </div>
    </form>
  );
};

export default AddSubscription;
