import React, { useEffect, useState } from "react";
import WalletCard from "../../../component/views/seller/wallet/card";
import WalletChart from "../../../component/views/seller/wallet/chart";
import WalletTransaction from "../../../component/views/seller/wallet/transaction";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { useLoader } from "../../../base/context/LoaderProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { WalletApiService } from "../../../service/seller/walletApiService";
import { clearAuthLocalStorage } from "../../../storage/localStorage";
import MyCard from "../../../component/views/seller/wallet/myCard";
import Membership from "../../../component/views/seller/wallet/membership";
import PaymentPopup from "../../../component/views/seller/wallet/paymentPopup";
import "./style.css";

export default function Wallet() {
  const message = useMessage();
  const loader = useLoader();
  const navigate = useNavigate();
  const location=useLocation();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [pages, setPages] = useState({
    currentPage: "",
    totalPages: "",
    totalItems: "",
  });

  const [transaction, setTransaction] = useState([]);
  const [type, setType] = useState("");
  const [wallet, setWallet] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [addAmount, setAddAmount] = useState(false);

  useEffect(()=>{
    const search=new URLSearchParams(location.search)
    const date= new Date()
    let nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);

    if(search.get('startDate')){
      setStartDate(date)
      setEndDate(nextDay)
    }

    handleWallet();
  },[])

  useEffect(() => {
    if (startDate && endDate) {
      handleTransactions();
      handleCalculate();
    } else if (!startDate && !endDate) {
      handleTransactions();
      handleCalculate();
    }

    const search=new URLSearchParams(location.search);
    if(search.size){
      navigate('/seller/wallet')
    }

  }, [type, startDate, endDate, page]);

  let query = `?type=${type}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${page}`;

  const handleTransactions = async () => {
    loader.showLoading(true);
    await WalletApiService.getTransaction(query)
      .then(async (res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setTransaction(res.data.transactions);
          setPages({
            ...pages,
            currentPage: res.data.currentPage,
            totalPages: res.data.totalPages,
            totalItems: res.data.totalTransactions,
          });

          setPage(res.data.currentPage);
        } else if (res.status.code === 402) {
          message.showToast(res.error.message, ToastTypes.ERROR);
          clearAuthLocalStorage();
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleWallet = async () => {
    await WalletApiService.getWalet()
      .then(async (res) => {
        if (res.status.code === 200) {
          setWallet(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleCalculate = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in milliseconds
    const difference = end - start;

    // Convert milliseconds to days
    const daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));

    setNumberOfDays(daysDifference);
  };

  const handleRefresh = () => {
    handleTransactions();
    handleWallet();
  };

  return (
    <div className="wallet_bg">
      <div className="wallet_upper_row">
        <div className="wallet_upper_col_one">
          <div className="wallet_cards_row">
            <WalletCard
              heading="Amount consumed"
              amount={wallet?.totalConsume}
              boxClassName="amount_consumed"
            />
            <WalletCard
              heading="Amount deposit"
              amount={wallet?.totalDeposit}
              boxClassName="amount_deposit"
            />
            <WalletCard
              heading="Amount refund"
              amount={wallet?.totalRefund}
              boxClassName="amount_refund"
            />
          </div>

          <WalletChart />
        </div>

        <div className="wallet_upper_col_two">
          <MyCard wallet={wallet} setAddAmount={setAddAmount} />

          <Membership membership={wallet?.membership} wallet={wallet?.wallet} />
        </div>
      </div>

      <WalletTransaction
        transaction={transaction}
        setType={setType}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        numberOfDays={numberOfDays}
        pages={pages}
        page={page}
        setPage={setPage}
      />

      <PaymentPopup
        modalIsOpen={addAmount}
        setIsOpen={setAddAmount}
        handleRefresh={handleRefresh}
      />
    </div>
  );
}
