// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit_order_form{
    width: 400px;
}

.edit_order_head{
    text-align: center;
    font-size: 1.5rem;
}
.edit_order_btn_row{
    display: flex;
    justify-content: center;
}

.edit_abnormal_order_scp_row{
    display: flex;
    width: 100%;
    gap: 1.56rem;
}

.edit_abnormal_order_scp_row .default_input{
    width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/component/views/seller/orders/list/editOrderPopup/style.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".edit_order_form{\n    width: 400px;\n}\n\n.edit_order_head{\n    text-align: center;\n    font-size: 1.5rem;\n}\n.edit_order_btn_row{\n    display: flex;\n    justify-content: center;\n}\n\n.edit_abnormal_order_scp_row{\n    display: flex;\n    width: 100%;\n    gap: 1.56rem;\n}\n\n.edit_abnormal_order_scp_row .default_input{\n    width: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
