import React from "react";
import WebInput from "../../../component/library/webInput";
import WebTextArea from "../../../component/library/webTextArea";
import WebButton from "../../../component/library/webButton";
import "./style.css";

export default function LandingPage() {
  return (
    <div className="landing_page_container">
      <div className="landing_page_column">
        <WebInput
          type="text"
          label="Page Name"
          id="page_name"
          error=""
          onChange={null}
          value=""
          name="productName"
        />

        <WebInput
          type="text"
          label="Page Url"
          id="page_url"
          error=""
          onChange={null}
          value=""
          name="productUrl"
        />

        <WebTextArea
          type="text"
          label="Add Html"
          id="html"
          error=""
          onChange={null}
          value=""
          name="html"
        />
      </div>

      <div className="landing_page_column">
        <WebInput
          type="text"
          label="Products"
          id="page_url"
          error=""
          onChange={null}
          value=""
          name="productUrl"
          placeholder="ALLBR-622419838885,ALLBR-622419838889"
        />
        <div>Or</div>

        <WebInput
          type="file"
          id="file"
          error=""
          onChange={null}
          value=""
          name="file"
        />
      </div>

      <div className="landing_page_column">
        <WebInput
          type="text"
          label="Carousel"
          id="carousel"
          error=""
          onChange={null}
          value=""
          name="carousel"
          placeholder="ALLBR-622419838885,ALLBR-622419838889"
        />
        <div>Or</div>

        <WebInput
          type="file"
          id="file"
          error=""
          onChange={null}
          value=""
          name="file"
        />
      </div>
    </div>
  );
}
