// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter_dropdown{
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    height: 40px;
    border-radius: 5px;
    outline: none;
    padding: 0 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/component/library/webDropdown/style.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,iBAAiB;IACjB,qCAAqC;IACrC,iBAAiB;IACjB,yBAAyB;IACzB,gBAAgB;AACpB","sourcesContent":[".filter_dropdown{\n    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);\n    height: 40px;\n    border-radius: 5px;\n    outline: none;\n    padding: 0 0.5rem;\n    border: 1px solid rgba(0, 0, 0, 0.25);\n    font-size: 1.2rem;\n    color: rgba(0, 0, 0, 0.6);\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
