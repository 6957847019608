import React, { useEffect, useRef, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { useOutsideClick } from "../../../base/context/OutsideClick";
import "./style.css";

export default function MultiSelectDropdown({
  options,
  placeholder,
  setValues,
  className,
}) {
  const [dropdownOpt, setDropdownOpt] = useState(options);
  const [optionVisibility, setOptionVisibility] = useState(false);
  const [addOthers, setAddOthers] = useState(false);
  const [otherOption, setOtherOption] = useState("");
  const outsideClick = useOutsideClick();
  const ref = useRef();

  useEffect(() => {
    setDropdownOpt(options);
  }, [options]);

  const handleValues = async (option) => {
    const data = JSON.parse(JSON.stringify(dropdownOpt));

    await data.map((item) => {
      if (item.name === option.name) {
        item.selected = !item.selected;
      }
    });
    setDropdownOpt(data);
    setValues(data);
  };

  const handleDropdown = () => {
    setOptionVisibility(true);
  };

  const handleClose = () => {
    setOptionVisibility(false);
  };

  useEffect(() => {
    outsideClick.getComponent(ref, handleClose);
  }, []);

  const handleOther = () => {
    setAddOthers(!addOthers);
  };

  const handleOtherOption = (e) => {
    setOtherOption(e.target.value);
  };

  const addOtherOption = (e) => {
    e.preventDefault();
    const data = JSON.parse(JSON.stringify(dropdownOpt));
    const obj = {
      name: otherOption,
      selected: true,
    };

    data.push(obj);
    setDropdownOpt(data);
    setValues(data);
    setOtherOption("");
  };

  useEffect(() => {
    if (addOthers) {
      document.getElementById("otherInput").focus();
    }
  }, [addOthers]);

  return (
    <div className="multi_select_dropdown w-full relative my-1">
      <div
        className={`${
          className
            ? className
            : "border h-12 px-3 py-2 w-full rounded-full flex"
        }`}
        onClick={handleDropdown}
      >
        {dropdownOpt.some((item) => item.selected) ? (
          <div className="flex justify-between items-center w-full">
            <div className="w-11/12 flex overflow-x-auto overflow-y-hidden gap-2">
              {dropdownOpt.map((item) => {
                return item.selected ? (
                  <div className="text-nowrap inline">{item.name},</div>
                ) : null;
              })}
            </div>

            {optionVisibility ? (
              <IoMdArrowDropdown
                size={20}
                className="origin-center rotate-180 "
              />
            ) : (
              <IoMdArrowDropdown size={20} />
            )}
          </div>
        ) : (
          <div className="flex justify-between w-full items-center ">
            <div>{placeholder}</div>
            {optionVisibility ? (
              <IoMdArrowDropdown
                size={20}
                className="origin-center rotate-180 "
              />
            ) : (
              <IoMdArrowDropdown size={20} />
            )}
          </div>
        )}
      </div>

      {optionVisibility ? (
        <div
          ref={ref}
          className="absolute w-full max-h-52 bg-white border p-2 rounded z-50 top-12 overflow-y-auto"
        >
          {dropdownOpt.map((item) => {
            return (
              <div className="flex gap-2" onClick={() => handleValues(item)}>
                <input type="checkbox" checked={item?.selected} />
                <span>{item?.name}</span>
              </div>
            );
          })}
          <div className="flex gap-2" onClick={() => handleOther()}>
            <input type="checkbox" checked={addOthers} />
            <span>Other</span>
          </div>

          {addOthers ? (
            <div>
              <input
                className="w-full h-10 border px-2"
                onChange={handleOtherOption}
                id="otherInput"
              />
              <button
                className="bg-slate-500 py-1 px-4 my-2 text-white rounded"
                onClick={addOtherOption}
              >
                Add
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
