import { NetworkConfiguration } from "../../network/networkConfriguation";
import { callAPI } from "../../network/networkConnection";

const getSellerAppId = () => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.GET_SELLER_APP_ID,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const connectToAmazon = (payload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.CONNECT_TO_AMAZON,
      "POST",
      {
        "Content-Type": "application/json",
      },
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const saveAmazonData = (payload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.SAVE_AMAZON_DATA,
      "POST",
      {
        "Content-Type": "application/json",
      },
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const amazonApiService = {
  getSellerAppId,
  connectToAmazon,
  saveAmazonData,
};
