// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wallet_bg{
    border-top: 1px solid var(--outline-color);
    padding-bottom: 1rem;
}

.wallet_cards_row{
    width: 100%;
    display: flex;
    gap: 2rem;
    padding: 1rem;
}

.wallet_upper_row{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    border-bottom: 1px solid var(--outline-color);
}

.wallet_upper_col_one{
   width: calc(100% - 335px);
}

.wallet_upper_col_two{
    width: 316px;
    border-left:1px solid var(--outline-color);
}


@media (max-width:768px) {
    .wallet_upper_row{
        display: flex;
        flex-direction: column-reverse;

    }

    .wallet_upper_col_one{
        width: 100%;
    }

    .wallet_cards_row{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;
        padding: 1rem;
    }

    .wallet_upper_col_two{
        width: 100%;
        border-left:1px solid var(--outline-color);
    }

    .wallet_chart_bg .wallet_dropdown{
        width: 100px;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/seller/wallet/style.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,SAAS;IACT,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,WAAW;IACX,6CAA6C;AACjD;;AAEA;GACG,yBAAyB;AAC5B;;AAEA;IACI,YAAY;IACZ,0CAA0C;AAC9C;;;AAGA;IACI;QACI,aAAa;QACb,8BAA8B;;IAElC;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,WAAW;QACX,aAAa;QACb,uBAAuB;QACvB,eAAe;QACf,SAAS;QACT,aAAa;IACjB;;IAEA;QACI,WAAW;QACX,0CAA0C;IAC9C;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".wallet_bg{\n    border-top: 1px solid var(--outline-color);\n    padding-bottom: 1rem;\n}\n\n.wallet_cards_row{\n    width: 100%;\n    display: flex;\n    gap: 2rem;\n    padding: 1rem;\n}\n\n.wallet_upper_row{\n    display: flex;\n    justify-content: space-between;\n    gap: 1rem;\n    width: 100%;\n    border-bottom: 1px solid var(--outline-color);\n}\n\n.wallet_upper_col_one{\n   width: calc(100% - 335px);\n}\n\n.wallet_upper_col_two{\n    width: 316px;\n    border-left:1px solid var(--outline-color);\n}\n\n\n@media (max-width:768px) {\n    .wallet_upper_row{\n        display: flex;\n        flex-direction: column-reverse;\n\n    }\n\n    .wallet_upper_col_one{\n        width: 100%;\n    }\n\n    .wallet_cards_row{\n        width: 100%;\n        display: flex;\n        justify-content: center;\n        flex-wrap: wrap;\n        gap: 1rem;\n        padding: 1rem;\n    }\n\n    .wallet_upper_col_two{\n        width: 100%;\n        border-left:1px solid var(--outline-color);\n    }\n\n    .wallet_chart_bg .wallet_dropdown{\n        width: 100px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
