// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --web-layout-color: #F7F7F7;
    --web-outline-color: #f2f2f2;



    --web-primary-color: #3DD0E5;
    --web-secondary-color: #004C81;
    --web-tertiary-color: #D8F1F9;

    --web-grey-color:#949494;
    --web-dark-grey-color: #6d6d6d;
    --web-light-grey-color: #e6e6e6;
    --web-white-color:#ffffff;
    --web-black-color: #000000;
    --web-shadow-color: #d3d3d3;


}`, "",{"version":3,"sources":["webpack://./src/website/assets/colors/index.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,4BAA4B;;;;IAI5B,4BAA4B;IAC5B,8BAA8B;IAC9B,6BAA6B;;IAE7B,wBAAwB;IACxB,8BAA8B;IAC9B,+BAA+B;IAC/B,yBAAyB;IACzB,0BAA0B;IAC1B,2BAA2B;;;AAG/B","sourcesContent":[":root {\n    --web-layout-color: #F7F7F7;\n    --web-outline-color: #f2f2f2;\n\n\n\n    --web-primary-color: #3DD0E5;\n    --web-secondary-color: #004C81;\n    --web-tertiary-color: #D8F1F9;\n\n    --web-grey-color:#949494;\n    --web-dark-grey-color: #6d6d6d;\n    --web-light-grey-color: #e6e6e6;\n    --web-white-color:#ffffff;\n    --web-black-color: #000000;\n    --web-shadow-color: #d3d3d3;\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
