import { NetworkConfiguration } from "../../network/networkConfriguation";
import { callAPI } from "../../network/networkConnection";

const getMembership = (queryParams) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.MEMBERSHIP +
        queryParams,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const upgradeMembership = (subscriptionPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.UPGRADE_MEMBERSHIP,
      "PUT",
      {
        "Content-Type": "application/json",
      },
      subscriptionPayload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const SellerApiService = {
  getMembership,
  upgradeMembership,
};
