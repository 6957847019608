import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/common/logo_no_image.png";
import tinycolor from "tinycolor2";
import { subscribeIcon } from "../../../assets/icons";
import chinaImg from "../../../assets/images/product/china.png";
import indiaImg from "../../../assets/images/product/india.png";
import { getAuthLocalStorage } from "../../../../storage/localStorage";
import { SubscriptionApiService } from "../../../../service/website/subscriptionApiService";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";
import "./style.css";

export default function ProductCard({ product }) {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState("");

  const message = useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    setIsSubscribed(product.subscribe);
  }, [product]);

  const handleImageError = (event) => {
    event.target.src = Logo;
  };

  const customColorMap = {
    Cocoa: "#6F4E37",
    "Rose Red": "#FF033E",
    "Nut Brown": "#80461B",
    Mint: "#3EB489",
    Celery: "#BFFF00",
    "Big Red": "#D1001C",
    "Azure Blue": "#007FFF",
    "Tide Blue": "#2E5894",
    "Heather Gray": "#B7BFC6",
    "Light Green": "#90EE90",
    "Light Pink": "#FFB6C1",
    "Reddish Brown": "#A52A2A",
    "Gray Blue": "#6D7B8D",
    "Off-White": "#FAF0E6",
    "Navy Blue": "#000080",
    "Dark Gray": "#A9A9A9",
    "Light Gray": "#D3D3D3",
    "Milk White": "#FEFCFF",
    "Mo Qing": "#005F69",
    "Dark Green": "#006400",
    "Dusty Pink": "#D5B9B2",
    "Dark Coffee": "#704241",
    "Military Green": "#4B5320",
    "Deep Blue": "#220878",
    "Black Blue": "#3A4E5F",
    "Black Gray": "#848482",
    "Black Lake Green": "#54626F",
    "Blue Pink": "#FF66FF",
    "Blue White": "#E0E8FF",
    "Dark Burgundy": "#770F05",
    "Light Green Yellow": "#ADFF2F",
    "Light Purple Pink": "#FFC3C0",
    "Purple Blue": "#4E5180",
    "Rose Yellow": "#FFF600",
    Burgundy: "#800020",
    "Fluorescent Green": "#08FF08",
    "Peach Red": "#FFDAB9",
    "Sapphire Blue": "#0F52BA",
    "Sky Blue": "#87CEEB",
    "Lotus Root Powder": "#C76080",
    "Pomegranate Purple": "#C0392B",
    "Till Blue": "#3D4674",
    Brown: "#a52a2a",
    "Dark Blue": "#00008B",
    "Medium Gray": "#808080",
    "Pure Black": "#000000",
    "Hole Green": "#4c5866",
    "Off White": "#FAF0E6",
    "Grey and Blue": "#808080",
    "Grey and Pink": "#D3D3D3",
    "Brick Red": "#8B0000",
    "Dark Brown": "#654321",
    "Light Purple": "#E6E6FA",
  };

  useEffect(() => {
    const arr = [];
    product.variations.forEach((item) => {
      if (arr.length === 0 || !arr.some((val) => val.color === item.color)) {
        const color = tinycolor(item.color);
        let colorCode = "";
        if (color.isValid()) {
          colorCode = color.toHexString();
        } else {
          colorCode = customColorMap[capitalizeColor(item.color)];
        }
        arr.push({
          ...item,
          colorCode: colorCode,
        });
      }
    });
    setFilteredProducts(arr);
  }, [product]);

  const capitalizeColor = (colorString) => {
    return colorString
      .toLowerCase()
      .replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase());
  };

  const check =
    getAuthLocalStorage().isLoggedIn &&
    getAuthLocalStorage().loginHeader &&
    getAuthLocalStorage().userType === "seller";

  const handleSubscribe = () => {
    if (check) {
      if (isSubscribed) {
        SubscriptionApiService.unSubscribeProduct(product.sku)
          .then((res) => {
            if (res.status.code === 200) {
              setIsSubscribed(false);
            } else {
              message.showToast(res.error.message, ToastTypes.ERROR);
            }
          })
          .catch((err) => {
            message.showToast(
              "Server error. Try again later",
              ToastTypes.ERROR
            );
          });
      } else {
        SubscriptionApiService.subscribeProduct(product.sku)
          .then((res) => {
            if (res.status.code === 200) {
              setIsSubscribed(true);
            } else {
              message.showToast(res.error.message, ToastTypes.ERROR);
            }
          })
          .catch((err) => {
            message.showToast(
              "Server error. Try again later",
              ToastTypes.ERROR
            );
          });
      }
    } else {
      navigate("/login");
      message.showToast(
        "To subscribe product please login",
        ToastTypes.WARNING
      );
    }
  };

  return (
    <div className="product_card_box">
      <div
        className={`${
          isSubscribed ? "subscribe_icon_active" : "subscribe_icon"
        }`}
        onClick={() => handleSubscribe()}
      >
        {isSubscribed ? subscribeIcon : subscribeIcon}
      </div>
      <Link to={`/product-details/?id=${product._id}`}>
        <img
          className="product_card_img"
          src={product?.imgUrl[0]}
          onError={handleImageError}
        />
      </Link>
      <div className="product_card_color_row">
        <div className="product_card_color_col">
          {filteredProducts?.map((variation, index) => {
            return (
              <Link
                to={`/product-details/?sku=${variation?.sku}`}
                className={
                  product.color_map === variation.color
                    ? "product_card_color_active"
                    : "product_card_color"
                }
                style={{
                  backgroundColor: `${variation.colorCode}`,
                }}
              ></Link>
            );
          })}
        </div>

        {filteredProducts?.length > 6 ? (
          <Link
            to={`/product-details/?id=${product._id}`}
            className="product_card_color_view_more"
          >
            + more
          </Link>
        ) : null}
      </div>
      <Link to={`/product-details/?id=${product._id}`}>
        <p className="product_card_name">{product?.title}</p>
      </Link>
      <Link to={`/product-details/?id=${product._id}`}>
        <p className="product_card_price">₹ {product?.sp}</p>
      </Link>

      <p className="product_card_country">
        shipped in {product.handling_days} days from{" "}
        {product.location === "China" ? (
          <img src={chinaImg} />
        ) : (
          <img src={indiaImg} />
        )}
      </p>
    </div>
  );
}
