// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.channel_partner_img_row {
    display: flex;
    gap: 4rem;
    justify-content: center;
}

.channel_partner_img_row img{
    width: 150px;
}



@media (max-width:768px) {

    .channel_partner_img_row{
        width: 100%;
        overflow-x:auto ;
        display: flex;
        justify-content: start;
        gap: 1rem;
    }

    .channel_partner_img_row img{
        width: 100px;
    }

    .channel_partner_img_row::-webkit-scrollbar {
        display: none;
     }
    
}`, "",{"version":3,"sources":["webpack://./src/website/component/views/home/channelPartners/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;;;AAIA;;IAEI;QACI,WAAW;QACX,gBAAgB;QAChB,aAAa;QACb,sBAAsB;QACtB,SAAS;IACb;;IAEA;QACI,YAAY;IAChB;;IAEA;QACI,aAAa;KAChB;;AAEL","sourcesContent":[".channel_partner_img_row {\n    display: flex;\n    gap: 4rem;\n    justify-content: center;\n}\n\n.channel_partner_img_row img{\n    width: 150px;\n}\n\n\n\n@media (max-width:768px) {\n\n    .channel_partner_img_row{\n        width: 100%;\n        overflow-x:auto ;\n        display: flex;\n        justify-content: start;\n        gap: 1rem;\n    }\n\n    .channel_partner_img_row img{\n        width: 100px;\n    }\n\n    .channel_partner_img_row::-webkit-scrollbar {\n        display: none;\n     }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
