// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp_form{
    display: flex;
    gap: 10px;
}

.otp_form input{
    width: 40px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.2rem;
    text-align: center;
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/component/library/webOtpInput/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,0CAA0C;IAC1C,qBAAqB;IACrB,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".otp_form{\n    display: flex;\n    gap: 10px;\n}\n\n.otp_form input{\n    width: 40px;\n    height: 40px;\n    border: 1px solid rgba(0, 0, 0, 0.2);\n    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);\n    border-radius: 0.2rem;\n    text-align: center;\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
