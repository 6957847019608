import { NetworkConfiguration } from "../network/networkConfriguation";
import { callAPI } from "../network/networkConnection";

/**
 * @author Diksha Lalit.
 * @since 25-04-2024.
 * @description to login user.
 */

const login = (loginPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.LOGIN,
      "POST",
      {
        "Content-Type": "application/json",
      },
      loginPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 25-04-2024.
 * @description to get user profile.
 */

const getProfile = () => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.PROFILE,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 25-04-2024.
 * @description to send otp to phone number.
 */

const sendOtp = (otpPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.SEND_OTP,
      "POST",
      {
        "Content-Type": "application/json",
      },
      otpPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 26-04-2024.
 * @description to verify otp.
 */

const verifyOtp = (otpPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.VERIFY_OTP,
      "POST",
      {
        "Content-Type": "application/json",
      },
      otpPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 26-04-2024.
 * @description to user registeration.
 */

const registration = (userPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.REGISTRATION,
      "POST",
      {
        "Content-Type": "application/json",
      },
      userPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 26-04-2024.
 * @description to get user details by user.
 */

const userDetails = (userPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.USER_DETAILS,
      "POST",
      {
        "Content-Type": "application/json",
      },
      userPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 26-04-2024.
 * @description to verify user email address.
 */

const verifyEmail = (emailPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.EMAIL_VERIFICATION,
      "POST",
      {
        "Content-Type": "application/json",
      },
      emailPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 26-04-2024.
 * @description to verify user email address when user forget password.
 */

const verifyEmailtoResetPassword = (emailPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.FORGET_PASSWORD,
      "PUT",
      {
        "Content-Type": "application/json",
      },
      emailPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 26-04-2024.
 * @description to verify user email address when user forget password.
 */

const resetPassword = (passwordPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.RESET_PASSWORD,
      "PUT",
      {
        "Content-Type": "application/json",
      },
      passwordPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 08-05-2024.
 * @description to change the password.
 */

const changePassword = (passwordPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.CHANGE_PASSWORD,
      "PUT",
      {
        "Content-Type": "application/json",
      },
      passwordPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 08-05-2024.
 * @description to login with google.
 */
const googleLogin = (authPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.GOOGLE_LOGIN,
      "POST",
      {
        "Content-Type": "application/json",
      },
      authPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 06-06-2024.
 * @description to update user profile.
 */
const updateProfile = (authPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.UPDATE_PROFILE,
      "PUT",
      {
        "Content-Type": "application/json",
      },
      authPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch(async (err) => {
        reject(err);
      });
  });
};

export const AuthApiService = {
  login,
  getProfile,
  sendOtp,
  verifyOtp,
  registration,
  userDetails,
  verifyEmail,
  verifyEmailtoResetPassword,
  resetPassword,
  changePassword,
  googleLogin,
  updateProfile,
};
