import React from "react";

export default function PriceSync({ data, handleOnClick }) {
  return (
    <div className="inventory_sync_bg">
      <p className="inventory_sync_head">Price Synchronization</p>

      <div className="inventory_sync_option_row">
        <div className="">
          <input
            type="checkbox"
            className="w-4 h-4"
            checked={data.priceSync.amazon}
            onClick={() =>
              handleOnClick("priceSync", "amazon", !data.priceSync.amazon)
            }
          />
          <p className="text-lg">Amazon</p>
        </div>

        <div className="">
          <input
            type="checkbox"
            className="w-4 h-4"
            checked={data.priceSync.shopify}
            onClick={() =>
              handleOnClick("priceSync", "shopify", !data.priceSync.shopify)
            }
          />
          <p className="text-lg">Shopify</p>
        </div>
      </div>
    </div>
  );
}
