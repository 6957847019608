import React from "react";
import "./style.css";

export default function PrivacyPolicy() {
  return (
    <div className="privacy_bg">
      <div className="privacy_banner"></div>

      <div className="privacy_content">
        <h1>Privacy Policy</h1>

        <p>
          We deeply value your privacy, which is why we offer this notice to
          detail our online information practices and provide you with control
          over how your information is collected and used.
        </p>

        <p>
          At Upsaleo, we collect personal information online solely to fulfil
          our legal obligations and deliver our services to you. This typically
          includes your name, email address, mailing address, and phone number,
          obtained when you register on our site, place orders, subscribe to our
          newsletter, or participate in surveys.
        </p>

        <p>
          Our use of your information is strictly in accordance with what is
          disclosed at the time of collection and permitted by law. We may
          utilize your information for the following purposes:
        </p>

        <div>
          - Conducting marketing analysis and customizing your shopping
          experience.
        </div>

        <div>
          - Sending newsletters and gathering feedback on our products,
          services, marketing, and website.
        </div>

        <div>
          - Processing transactions, including payments and product deliveries.
        </div>

        <div>- Sending emails for promotional offers and order processing.</div>

        <p>
          Please be aware that while we make every effort to secure your
          personal information during internet transmission, we cannot guarantee
          100% security.
        </p>

        <p>
          Regarding cookies, these digital objects sent to your device by our
          web server enable us to monitor and remember certain information about
          you. Cookie log files help us track your site usage, aiding us in
          managing and optimizing our site’s performance. We may share cookies
          with contracted third-party service providers solely to improve our
          understanding of site visitors; these providers are not permitted to
          use this information for any other purpose.
        </p>

        <p>
          You have the option to disable or decline cookies through your browser
          settings, though this may impact your access to our site.
        </p>

        <p>
          Occasionally, our website may feature links to third-party products
          and services, each governed by its own independent privacy terms for
          which Upsaleo assumes no liability.
        </p>

        <p>
          As a visitor, you have the right to access, modify, or delete any
          information we collect about you. You may also request restrictions on
          further processing of your personal information at any time. To
          protect your privacy, we may require specific information to verify
          your identity before granting access to the information we maintain.
          However, legal or regulatory requirements may restrict our ability to
          provide or delete certain data.
        </p>

        <p>
          Upsaleo reserves the right to modify this privacy policy at any time,
          with updated versions available on our website. By providing
          information or continuing to use our website after such updates, you
          indicate your acceptance of the changes.
        </p>
      </div>
    </div>
  );
}
