import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import sidebarReducer from "./slices/sidebarSlice";
import tabsReducer from "./slices/tabsSlice";
import categoryReducer from "./slices/categorySlice";
import modeReducer from "./slices/modeSlice";
import cartReducer from "./slices/cartSlice";


// Import other reducers as needed

const rootReducer = combineReducers({
  user: userReducer,
  sidebar: sidebarReducer,
  tabs: tabsReducer,
  category: categoryReducer,
  mode: modeReducer,
  cart: cartReducer

  // Add other reducers here
});

export default rootReducer;
