import React from "react";
import { formatDate } from "../../../../../helper/utils";
import "./style.css";

export default function OrderInfo({ data }) {


    // Step 1: Group by name
    const grouped =  data?.orderDetails?.reduce((acc, item) => {
      if (!acc[item.trackingId]) {
          acc[item.trackingId] = [];
      }
      acc[item.trackingId].push(item);
      return acc;
    }, {});
  
  // Step 2: Transform to desired format
    let result;
  
    if(grouped){
       result = Object.entries(grouped).map(([trackingId, data]) => ({
        trackingId: trackingId,
        data: data
      }));
    } 

  return (
    <div className="order_detail_info_box">
      <p className="order_detail_head">Orders Information</p>

      <div className="order_detail_info_row">
        <div className="order_detail_info">
          <p>
            Upsaleo Order ID : <span>{data?.upsaleoOrderId}</span>
          </p>
          <p>
            Marketplace Order ID :<span>{data?.orderId}</span>
          </p>
          <p>
            Marketplace:<span>{data?.markeplace}</span>
          </p>
          <p>
            Recipient : <span>{data?.recipientName}</span>
          </p>
          <p>
            Telephone : <span>{data?.shippingPhoneNumber}</span>
          </p>
          <p>
            Address :
            <span>
              {data?.shippingAddress1 +
                ", " +
                data?.shippingAddress2 +
                ", " +
                data?.shippingAddress3 +
                ", " +
                data?.shippingCity +
                ", " +
                data?.shippingState +
                ", " +
                data?.shippingPostalCode}
            </span>
          </p>
        </div>

        <div className="order_detail_info">
          <p className="text-lg">
            Order Date : <span>{formatDate(data.createdAt)}</span>
          </p>
          <p className="text-lg">
            Order Status : <span>{data.status}</span>
          </p>

          <p>
            Shipping Method :
            <span>
            {result?.map((item, index) => {
              return item.data?.map((product,indexx)=>{
                return indexx===0?product?.shippingMethod:null
                })
              })}
            </span>
          </p>

          <p>
            Carrier Name :
            <span>
              {result?.map((item, index) => {
                return item.data?.map((product,indexx)=>{
                  return indexx===0?product?.carrierName:null
                  })
              })}
            </span>
          </p>
          
          <p>
            Tracking Number :
            <span>
              {result?.map((item, index) => {
                return item.data?.map((product,indexx)=>{
                  return indexx===0?product?.trackingId:null
                  })
              })}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
