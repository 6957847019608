import React from "react";
import { BiSupport } from "react-icons/bi";
// import { BsPinMapFill } from "react-icons/bs";
import { GrDeliver } from "react-icons/gr";
// import { GoPackageDependencies } from "react-icons/go";
import { MdOutlineInsights } from "react-icons/md";
import { GrIntegration } from "react-icons/gr";
import OfferImage from "../../../assets/images/about/offer.png";
import MissionVissionImg from "../../../assets/images/about/mission-vission.png";
import ProductImg from "../../../assets/images/about/product.png";
import WebButton from "../../../component/library/webButton";
import "./style.css";

export default function AboutUs() {
  const offers = [
    {
      icon: <GrDeliver />,
      name: "Fast Delivery",
      para: "Speedy shipping, every order",
    },
    {
      icon: <MdOutlineInsights />,
      name: "Trend Updates",
      para: "Stay current with insights",
    },
    {
      icon: <BiSupport />,
      name: "24h Support",
      para: "Help anytime, around the clock",
    },
    {
      icon: <GrIntegration />,
      name: "Seamless Integration",
      para: "Connect with major platforms",
    },
  ];

  const moments = [
    {
      head: "The Company Established",
      para: "The standard chunk of Lorem Ipsum used since the below. Sections 1.10.32 and 1.10.3 de Finibus Bonorum.",
    },
    {
      head: "Expand All Over The World",
      para: "The standard chunk of Lorem Ipsum used since the below. Sections 1.10.32 and 1.10.3 de Finibus Bonorum.",
    },
    {
      head: "Benchmark In Industry",
      para: "The standard chunk of Lorem Ipsum used since the below. Sections 1.10.32 and 1.10.3 de Finibus Bonorum.",
    },
  ];

  const handleInstagram = () => {
    window.open("https://www.instagram.com/upsaleoofficial/", "_blank");
  };

  const handleFacebook = () => {
    window.open(
      "https://www.facebook.com/people/Upsaleo/61558625604511/",
      "_blank"
    );
  };

  return (
    <div className="about_us_bg">
      <div className="about_us_banner">
        {/* <h1>About Us</h1> */}
      </div>
      <div className="about_us_what_we_offer_container">
      <h1>What We Offer?</h1>
      <div className="about_us_what_we_offer">
        <div className="col_one">
          <img src={OfferImage} />
        </div>
        <div className="col_two">
          <p className="offer_content">
            Owned and operated by dedicated professionals, Boundless Trends
            Private Ltd. is a leading provider known for offering high-quality
            products at affordable prices. Upsaleo is celebrated for its
            technical expertise, user-friendly interface, and extensive product
            range. We are committed to supporting you in setting up and growing
            your business.
            <br />
            <br />
            We offer dropshipping services that guarantee exceptional service
            for your company. Our primary focus is on helping businesses achieve
            global impact. We are highly praised for our robust technical
            systems, easy-to-use features, and wide product selection. We
            promise to provide comprehensive services to help you start and grow
            your business to new heights. Our users commend our automated
            dropship solutions, unlimited order processing, and excellent
            customer service. We use an advanced warehouse system to ensure
            efficient order handling, meeting the demands of the fast-paced
            e-commerce market. You can also benefit from our outstanding
            after-sales support, with our customer service team always ready to
            assist you.
          </p>

          <div className="about_us_what_we_offer_list">
            {offers.map((offer, index) => {
              return (
                <div key={index} className="about_us_what_we_offer_list_col">
                  <div className="icon">{offer.icon}</div>
                  <div>
                    <h2>{offer.name}</h2>
                    <p>{offer.para}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      </div>
     
      <div className="about_matrix">
        <div className="about_matrix_col">
          <h1>956+</h1>
          <p>Employee Empowered</p>
        </div>
        <div className="about_matix_col_divide"></div>
        <div className="about_matrix_col">
          <h1>100</h1>
          <p>Worldwide Distributors</p>
        </div>
        <div className="about_matix_col_divide"></div>
        <div className="about_matrix_col">
          <h1>750+</h1>
          <p>Receive Daily Ordered</p>
        </div>
        <div className="about_matix_col_divide"></div>
        <div className="about_matrix_col">
          <h1>900</h1>
          <p>Get Growth Per Years</p>
        </div>
      </div>

      <div className="upsaleo_moments">
        <h1 className="upsaleo_moments_head">Upsaleo Moments: Take A Look</h1>

        {moments.map((moment, index) => {
          return (
            <div className="upsaleo_moments_step" key={index}>
              <div className="upsaleo_moments_step_left">
                <div>{index + 1}</div>
                <div className="upsaleo_moments_step_line"></div>
              </div>
              <div className="upsaleo_moments_step_right">
                <p>{moment.head}</p>
                <p>{moment.para}</p>
              </div>
            </div>
          );
        })}
      </div>

      <div className="mission_vission">
        <h1 className="mission_vission_head">Our Mission & Vision</h1>
        <p className="mission_vission_para">
          Upsaleo's Mission is to facilitate seamless connections and enable dropshipping businesses.
        </p>

        <div className="mission_vission_row">
          <div className="mission_vission_col_one">
            <img src={MissionVissionImg} />
          </div>
          <div className="mission_vission_col_two">
            <h1>Our Mission and Vision at Upsaleo</h1>
            <p>
            At Upsaleo, our mission is to provide entrepreneurs with top-tier products and advanced tools for exceptional dropshipping success. We strive to be the leading partner in the e-commerce sector, where our expertise and support turn business aspirations into tangible achievements. Our vision is to empower global entrepreneurs with the resources they need to thrive and excel.
            </p>
            <h2>Connect with us on:</h2>
            <div className="mission_vission_col_btns">
              <WebButton
                className="active_btn_without_shadow"
                text="Follow us on Instagram"
                onClick={() => handleInstagram()}
              />
              <WebButton
                className="active_btn_without_shadow"
                text="Follow us on Facebook"
                onClick={() => handleFacebook()}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="buyers_review">
        <div className="buyers_review_section">
        <h1>Latest Sellers Review</h1>
        <p className="buyers_review_para">
          See what sellers are saying about their experience with our products and services!
        </p>
        </div>

        <div className="buyers_review_row">
          <div className="buyers_review_col">
            <div className="buyers_review_product">
              <img src={ProductImg} />
              <h2>Aakash Sharma</h2>
            </div>
            <p className="buyers_review_descp">
              Excellent selection of products! The quality is top-notch, and the prices are unbeatable. My customers are thrilled with their purchases.
            </p>
            <p className="buyers_name">15 Feb  2024</p>
          </div>

          <div className="buyers_review_col">
            <div className="buyers_review_product">
              <img src={ProductImg} />
              <h2>Kiran</h2>
            </div>
            <p className="buyers_review_descp">
              The support team is fantastic! They helped me set up quickly and answered all my questions. Great product range and my sales have gone up a lot since I started using them.
            </p>
            <p className="buyers_name">23 April 2024</p>
          </div>

          <div className="buyers_review_col">
            <div className="buyers_review_product">
              <img src={ProductImg} />
              <h2>Piyush Garg</h2>
            </div>
            <p className="buyers_review_descp">
              Seriously impressed. Best supplier for dropshipping! Easy to connect with my store and shipping is super fast.
            </p>
            <p className="buyers_name">01 June 2024</p>
          </div>
        </div>
      </div>

      {/* <div className="privacy_content">
        <p>
          Owned and operated by dedicated professionals, Boundless Trends
          Private Ltd. is a leading provider known for offering high-quality
          products at affordable prices. Upsaleo is celebrated for its technical
          expertise, user-friendly interface, and extensive product range. We
          are committed to supporting you in setting up and growing your
          business.
        </p>

        <p>
          Dropshipping platform Upsaleo simplifies operations for both emerging
          and established e-commerce businesses. Our main goal is to bridge the
          communication gap between retailers and suppliers, creating the
          essential connections needed for dropshipping success. As a dedicated
          provider, Upsaleo ensures superior products at competitive rates.
        </p>

        <p>
          We offer dropshipping services that guarantee exceptional service for
          your company. Our primary focus is on helping businesses achieve
          global impact. We are highly praised for our robust technical systems,
          easy-to-use features, and wide product selection. We promise to
          provide comprehensive services to help you start and grow your
          business to new heights. Our users commend our automated dropship
          solutions, unlimited order processing, and excellent customer service.
          We use an advanced warehouse system to ensure efficient order
          handling, meeting the demands of the fast-paced e-commerce market. You
          can also benefit from our outstanding after-sales support, with our
          customer service team always ready to assist you.
        </p>

        <p>
          We aim to boost your online dropshipping business' revenue with
          minimal effort and technical expertise.
        </p>

        <p>
          Our philosophy of dropshipping with a "zero inventory system"
          eliminates upfront inventory costs and simplifies shipping.
        </p>
      </div> */}
    </div>
  );
}
