// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.amazon_connect_bg{
    border-top: 1px solid var(--outline-color);
    padding: 1rem;
}

.amazon_connect_bg h1{
    font-family: var(--sans-serif);
    font-size: var(--sans-serif-size-regular);
    font-weight: var(--sans-serif-medium);
}

.amazon_connect_loading{
    display: flex;
    justify-content: start;
    width: 210px;
    margin-top: -150px;
}`, "",{"version":3,"sources":["webpack://./src/views/seller/amazon/style.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,aAAa;AACjB;;AAEA;IACI,8BAA8B;IAC9B,yCAAyC;IACzC,qCAAqC;AACzC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".amazon_connect_bg{\n    border-top: 1px solid var(--outline-color);\n    padding: 1rem;\n}\n\n.amazon_connect_bg h1{\n    font-family: var(--sans-serif);\n    font-size: var(--sans-serif-size-regular);\n    font-weight: var(--sans-serif-medium);\n}\n\n.amazon_connect_loading{\n    display: flex;\n    justify-content: start;\n    width: 210px;\n    margin-top: -150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
