// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --layout-color: #565656;
    --outline-color: #7a7a7a;



    --dark-primary-color: #181822;
    --dark-secondary-color: #22222E;
    --dark-tertiary-color: #ABDDD9;
    --dark-text-color: #FFFFFF;
 


    --light-primary-color: #FFFFFF;
    --light-secondary-color: #dddddd;
    --light-tertiary-color: #181822;
    --light-text-color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/assets/colors/color.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,wBAAwB;;;;IAIxB,6BAA6B;IAC7B,+BAA+B;IAC/B,8BAA8B;IAC9B,0BAA0B;;;;IAI1B,8BAA8B;IAC9B,gCAAgC;IAChC,+BAA+B;IAC/B,2BAA2B;AAC/B","sourcesContent":[":root {\n    --layout-color: #565656;\n    --outline-color: #7a7a7a;\n\n\n\n    --dark-primary-color: #181822;\n    --dark-secondary-color: #22222E;\n    --dark-tertiary-color: #ABDDD9;\n    --dark-text-color: #FFFFFF;\n \n\n\n    --light-primary-color: #FFFFFF;\n    --light-secondary-color: #dddddd;\n    --light-tertiary-color: #181822;\n    --light-text-color: #000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
