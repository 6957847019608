// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.header {
  text-align: center;
}

.header h1 {
  color: #0056b3;
  margin-bottom: 1rem;
}

.main-content {
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th,
.user-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.user-table th {
  background-color: #007bff;
  color: white;
}

.user-table td select,
.user-table td input {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.save-btn {
  padding: 5px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-btn:hover {
  background-color: #218838;
}
`, "",{"version":3,"sources":["webpack://./src/views/admin/membershipManagement/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,uCAAuC;AACzC;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;;EAEE,aAAa;EACb,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;;EAEE,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".container {\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 20px;\n  font-family: Arial, sans-serif;\n}\n\n.header {\n  text-align: center;\n}\n\n.header h1 {\n  color: #0056b3;\n  margin-bottom: 1rem;\n}\n\n.main-content {\n  background-color: #f8f9fa;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.user-table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.user-table th,\n.user-table td {\n  padding: 10px;\n  text-align: left;\n  border-bottom: 1px solid #ddd;\n}\n\n.user-table th {\n  background-color: #007bff;\n  color: white;\n}\n\n.user-table td select,\n.user-table td input {\n  padding: 5px;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n}\n\n.save-btn {\n  padding: 5px 10px;\n  background-color: #28a745;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n}\n\n.save-btn:hover {\n  background-color: #218838;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
