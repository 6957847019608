// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order_detail_btn_row{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    gap: 1rem;
}

@media (max-width:768px) {
    .order_detail_btn_row{
        justify-content: center;
        flex-wrap: wrap;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/views/seller/orderDetails/orderStatus/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI;QACI,uBAAuB;QACvB,eAAe;IACnB;AACJ","sourcesContent":[".order_detail_btn_row{\n    display: flex;\n    justify-content: flex-end;\n    margin-bottom: 1rem;\n    gap: 1rem;\n}\n\n@media (max-width:768px) {\n    .order_detail_btn_row{\n        justify-content: center;\n        flex-wrap: wrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
