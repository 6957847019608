// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting_bg{
    border-top: 1px solid var(--outline-color);
    padding: 1rem;
}

.order_section_row{
    display: flex;
    gap: 2rem;
    padding: 0 1rem;
}

.setting_brands_input_box{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
}

`, "",{"version":3,"sources":["webpack://./src/views/seller/settings/style.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,eAAe;AACnB","sourcesContent":[".setting_bg{\n    border-top: 1px solid var(--outline-color);\n    padding: 1rem;\n}\n\n.order_section_row{\n    display: flex;\n    gap: 2rem;\n    padding: 0 1rem;\n}\n\n.setting_brands_input_box{\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    padding: 0 1rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
