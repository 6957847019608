import React, { useEffect, useState } from "react";
import { orderApiService } from "../../../service/admin/ordersApiService";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../helper/utils";
import Pagination from "../../../component/library/pagination";
import './style.css'

const FailedOrders = () => {
  const [failedOrders, setFailedOrders] = useState([]);
  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [pages, setPages] = useState({
    currentPage: "",
    totalPages: "",
    totalItems: "",
  });

  useEffect(() => {
    fetchFailedOrders();
  }, [page]);

  const fetchFailedOrders = () => {
    const queryParams = `?limit=${limit}&page=${page}`;

    loader.showLoading(true);
    orderApiService
      .getFailedOrders(queryParams)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setFailedOrders(res.data.orders);
          setPages({
            ...pages,
            currentPage: res.data.currentPage,
            totalPages: res.data.totalPages,
            totalItems: res.data.totalOrders,
          });
          setPage(res.data.currentPage);

        } else if (res.status.code === 402) {
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };


  return (
    <div className="p-4 container">
      <header className="header">
        <h1>Admin Portal - Failed Orders</h1>
      </header>
      <div className="main-content">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 failed_order_table ">
            <thead className="bg-gray-300">
              <tr>
                <th className="p-2 border">S.No.</th>
                <th className="p-2 border">User Name</th>
                <th className="p-2 border">Email</th>
                <th className="p-2 border">Order ID</th>
                <th className="p-2 border">Order Item ID</th>
                <th className="p-2 border">Sku</th>
                <th className="p-2 border">Quantity</th>
                <th className="p-2 border">Consumer Price</th>
                <th className="p-2 border">Product Name</th>
                <th className="p-2 border">Created At</th>
              </tr>
            </thead>
            <tbody >
              {failedOrders && failedOrders.length > 0 ? (
                failedOrders.map((data, index) => (
                  <tr className="text-center even:bg-gray-100" key={index}>
                    <td className="p-2 border">{(page-1) * limit+  index + 1}</td>
                    <td className="p-2 border">
                      {data.sellerId?.firstName} {data.sellerId?.lastName}
                    </td>
                    <td className="p-2 border">
                      {data?.sellerId?.email}
                    </td>
                    <td className="p-2 border">{data.orderId}</td>
                    <td className="p-2 border">
                      {data?.orderDetails?.map((item, idx) => (
                        <p key={idx}>{item?.orderItemId}</p>
                      ))}
                    </td>
                    <td className="p-2 border">
                      {data?.orderDetails?.map((item, idx) => (
                        <p key={idx}>{item?.sku}</p>
                      ))}
                    </td>
                    <td className="p-2 border">
                      {data?.orderDetails?.map((item, idx) => (
                        <p key={idx}>{item?.quantity}</p>
                      ))}
                    </td>
                    <td className="p-2 border">
                      {data?.orderDetails?.map((item, idx) => (
                        <p key={idx}>{item?.consumerPrice}</p>
                      ))}
                    </td>
                    <td className="p-2 border">
                      {data?.orderDetails?.map((item, idx) => (
                        <p key={idx}>{item?.productName}</p>
                      ))}
                    </td>
                    <td className="p-2 border">
                      {formatDate(data?.createdAt)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="p-2 text-center border">
                    No Failed Orders Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>

         
        </div>
      </div>

      {failedOrders.length ? (
        <Pagination pages={pages} page={page} setPage={setPage} />
      ) : null}
    </div>
  );
};

export default FailedOrders;
