// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip{
    background-color: rgb(61, 61, 61);
    padding: 0.5rem 0.5rem;
    border-radius: 0.2rem;
    color: white;
    line-height: 15px;
  
}

.wallet_tool_tip{
    position: absolute;
    z-index: 22;
    top: 30px;
    right: 10px;
    width: 130px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/component/library/tooltip/style.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,sBAAsB;IACtB,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;;AAErB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".tooltip{\n    background-color: rgb(61, 61, 61);\n    padding: 0.5rem 0.5rem;\n    border-radius: 0.2rem;\n    color: white;\n    line-height: 15px;\n  \n}\n\n.wallet_tool_tip{\n    position: absolute;\n    z-index: 22;\n    top: 30px;\n    right: 10px;\n    width: 130px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
