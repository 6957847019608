import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    addItems: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { addItems } = categorySlice.actions;

export const categoryData = (state) => state.category.data;

export default categorySlice.reducer;
