import React from "react";
import "./style.css";

export default function WebDropdown({
  options,
  className,
  parentClassName,
  onChange,
  disable,
  selected,
}) {
  return (
    <div className={parentClassName}>
      <select
        className={
          className ? className : "my-1 border px-3 py-2 w-full rounded"
        }
        onChange={onChange}
      >
        {options?.map((item) => {
          return (
            <option
              value={item.value}
              selected={selected === item.value}
              disable={disable}
            >
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}
