import React from "react";
import ProductCard from "../../../common/productCard";
import { Link } from "react-router-dom";
import "./style.css";

export default function FeaturedCategoris({ category }) {
  const path = category.pageUrl.replace("fc/", "");

  return (
    <div className="home_blow_out_sales">
      <div className="home_blow_out_sale_head_row">
        <Link to={path}>
          <h1>{category?.name}</h1>
        </Link>
        <Link to={`/collection${path}?fcId=${category?.fcId}`}>
          <p>View More</p>
        </Link>
      </div>

      <div className="home_blow_out_sales_product_row">
        {category?.products?.map((product) => {
          return <ProductCard product={product} />;
        })}
      </div>
    </div>
  );
}
