import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

function ActiveUser({ children }) {
  // useEffect(() => {
  //   function getOrCreateClientId() {
  //     let clientId = sessionStorage.getItem("clientId");
  //     const lastUpdated = sessionStorage.getItem("clientIdLastUpdated");
  //     const now = new Date().getTime();

  //     // Refresh clientId more than 24 hours
  //     if (
  //       !clientId ||
  //       !lastUpdated ||
  //       now - parseInt(lastUpdated) > 24 * 60 * 60 * 1000
  //     ) {
  //       clientId = uuidv4();
  //       sessionStorage.setItem("clientId", clientId);
  //       sessionStorage.setItem("clientIdLastUpdated", now.toString());
  //     }

  //     ReactGA.set({ userId: clientId });
  //     ReactGA.event({
  //       category: "User",
  //       action: "Login",
  //       label: "ActiveUser Component Mounted",
  //     });

  //     return clientId;
  //   }

  //   const clientId = getOrCreateClientId();

  //   return () => {
  //     ReactGA.event({
  //       category: "User",
  //       action: "Logout",
  //       label: "ActiveUser Component Unmounted",
  //     });
  //   };
  // }, []);

  return (
    <>
      {children}
      {/* <Chat /> */}
    </>
  );
}

export default ActiveUser;

// import React, { useEffect } from "react";
// import { v4 as uuidv4 } from "uuid";
// import Chat from "../chat/index";

// function ActiveUser({ children }) {
//   useEffect(() => {
//     function getOrCreateClientId() {
//       let clientId = sessionStorage.getItem("clientId");
//       const lastUpdated = sessionStorage.getItem("clientIdLastUpdated");
//       const now = new Date().getTime();

//       // Refresh clientId more than 24 hours
//       if (
//         !clientId ||
//         !lastUpdated ||
//         now - parseInt(lastUpdated) > 24 * 60 * 60 * 1000
//       ) {
//         clientId = uuidv4();
//         sessionStorage.setItem("clientId", clientId);
//         sessionStorage.setItem("clientIdLastUpdated", now.toString());
//       }

//       return clientId;
//     }

//     const clientId = getOrCreateClientId();
//     // const websocket = new WebSocket("ws://localhost:4002");

//     // websocket.onopen = () => {
//     //   console.log("Connected to WebSocket");
//     //   websocket.send(JSON.stringify({ type: "identify", clientId }));
//     // };

//     return () => {
//       // websocket.close();
//     };
//   }, []);

//   return (
//     <>
//       {children}
//       {/* <Chat /> */}
//     </>
//   );
// }

// export default ActiveUser;
