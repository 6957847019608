// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-detail-table-container {
  margin-top: 20px;
  font-family: Arial, sans-serif;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}

.export-button,
.export-processed-button,
.upload-button {
  background-color: #007bff;
  color: #ffffff;
  padding: 8px 12px;
  margin-right: 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.upload-button {
  background-color: #1e7e34;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.table-head {
  background-color: #f0f0f0;
}

.table-head th,
.table-row td {
  padding: 10px;
  text-align: left;
}

.checkbox-size {
  transform: scale(1.5);
}

.table-row:hover {
  background-color: #f5f5f5;
}

.clickable-cell {
  cursor: pointer;
}

.even-row {
  background-color: #ffffff;
}

.odd-row {
  background-color: #f8f9fa;
}
`, "",{"version":3,"sources":["webpack://./src/component/views/admin/orderManagement/orderDetailsTable/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;;;EAGE,yBAAyB;EACzB,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".order-detail-table-container {\n  margin-top: 20px;\n  font-family: Arial, sans-serif;\n}\n\n.button-container {\n  display: flex;\n  justify-content: flex-start;\n  margin-right: 20px;\n}\n\n.export-button,\n.export-processed-button,\n.upload-button {\n  background-color: #007bff;\n  color: #ffffff;\n  padding: 8px 12px;\n  margin-right: 10px;\n  border: none;\n  cursor: pointer;\n  border-radius: 4px;\n}\n\n.upload-button {\n  background-color: #1e7e34;\n}\n\n.order-table {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 10px;\n}\n\n.table-head {\n  background-color: #f0f0f0;\n}\n\n.table-head th,\n.table-row td {\n  padding: 10px;\n  text-align: left;\n}\n\n.checkbox-size {\n  transform: scale(1.5);\n}\n\n.table-row:hover {\n  background-color: #f5f5f5;\n}\n\n.clickable-cell {\n  cursor: pointer;\n}\n\n.even-row {\n  background-color: #ffffff;\n}\n\n.odd-row {\n  background-color: #f8f9fa;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
