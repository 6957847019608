import { callAPI } from "../../network/networkConnection";
import { NetworkConfiguration } from "../../network/networkConfriguation";

const getUserList = (queryParams) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.GET_USERS +
        queryParams,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getUserByID = (id) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.GET_USER_BY_ID + id,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const updateUser = (userPayload, id) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.UPDATE_USER + id,
      "PUT",
      {
        "Content-Type": "application/json",
      },
      userPayload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getTodayRegisterUser = () => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl +
        NetworkConfiguration.GET_USERS_DAILY_REPORT,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const userApiService = {
  getUserList,
  getUserByID,
  updateUser,
  getTodayRegisterUser,
};
