// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order_detail_bg{
    border-top: 1px solid var(--outline-color);
    padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/views/seller/orders/details/style.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,aAAa;AACjB","sourcesContent":[".order_detail_bg{\n    border-top: 1px solid var(--outline-color);\n    padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
