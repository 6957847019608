import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { downVIcon, searchIcon } from "../../../../assets/icons";
import { useSelector } from "react-redux";
import { categoryData } from "../../../../../redux/slices/categorySlice";
import { queryToUrl, urlToQuery } from "../../../../helper/utils";
import "./style.css";

export default function SearchBar() {
  const [searchVal, setSearchVal] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState("All Categories");
  const [query, setQuery] = useState("");

  const inputRef = useRef("");
  const navigate = useNavigate();
  const categories = useSelector(categoryData);
  const location = useLocation();

  useEffect(() => {
    if (searchParams.get("category")) {
      setSelectedCategory(
        urlToQuery(searchParams.get("category").split("/")[0])
      );
    } else {
      setSelectedCategory("All Categories");
    }

    if (searchParams.get("search")) {
      setSearchVal(urlToQuery(searchParams.get("search")));
    } else {
      setSearchVal("");
    }
  }, [location]);

  const handleActiveInput = () => {
    inputRef.current.focus();
  };
  const handleOnChange = (e) => {
    setSearchVal(e.target.value);
  };

  const selectCategory = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleProducts = (e) => {
    e.preventDefault();
    if (searchVal && selectedCategory !== "All Categories") {
      setQuery(
        `?category=${queryToUrl(selectedCategory)}&search=${queryToUrl(
          searchVal
        )}`
      );
    } else if (searchVal && selectedCategory === "All Categories") {
      setQuery(`?search=${queryToUrl(searchVal)}`);
    } else if (!searchVal && selectedCategory !== "All Categories") {
      setQuery(`?category=${queryToUrl(selectedCategory)}`);
    }
  };

  useEffect(() => {
    if (query) {
      navigate(`/collection${query}`);
    }
  }, [query]);

  return (
    <div className="web_navbar_search">
      <div className="select_wrapper">
        <div className="selected_category_col">
          &nbsp; {selectedCategory} &nbsp;
        </div>
        <select onChange={selectCategory}>
          <option
            value="All Categories"
            selected={selectedCategory === "All Categories" ? true : false}
          >
            &nbsp; All Categories &nbsp;
          </option>
          {categories?.map((category, index) => {
            return (
              <option
                key={index}
                value={category.c_name}
                selected={selectedCategory === category.c_name ? true : false}
              >
                &nbsp; {category.c_name} &nbsp;
              </option>
            );
          })}
        </select>

        <div>{downVIcon}</div>
      </div>

      <form onClick={() => handleActiveInput()} onSubmit={handleProducts}>
        <input
          type="text"
          value={searchVal}
          onChange={handleOnChange}
          ref={inputRef}
          placeholder="Search Upsaleo.com"
        />
        <button type="submit" className="web_navbar_search_icon">
          {searchIcon}
        </button>
      </form>
    </div>
  );
}
