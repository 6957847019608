import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

export const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    addTabs: (state, action) => {
      state.data = action.payload;
    },
    resetTabs: (state, action) => {
      state.data = [];
    },
  },
});

export const { addTabs, resetTabs } = tabsSlice.actions;

export const tabsData = (state) => state.tabs.data;

export default tabsSlice.reducer;
