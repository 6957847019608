import { NetworkConfiguration } from "../../network/networkConfriguation";
import { callAPI } from "../../network/networkConnection";

const AddUpdateCart = (productPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.ADD_TO_CART,
      "POST",
      {
        "Content-Type": "application/json",
      },
      productPayload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const RemoveFromCart = (productPayload) => {
    return new Promise((resolve, reject) => {
      callAPI(
        process.env.REACT_APP_APIUrl + NetworkConfiguration.REMOVE_FROM_CART,
        "POST",
        {
          "Content-Type": "application/json",
        },
        productPayload
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
};

const RemoveAllCart = () => {
    return new Promise((resolve, reject) => {
      callAPI(
        process.env.REACT_APP_APIUrl + NetworkConfiguration.REMOVE_ALL_CART,
        "GET",
        {
          "Content-Type": "application/json",
        },
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
};

const getCart = () => {
    return new Promise((resolve, reject) => {
      callAPI(
        process.env.REACT_APP_APIUrl + NetworkConfiguration.GET_CART,
        "GET",
        {
          "Content-Type": "application/json",
        },
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
};

export const CartApiService = {
    AddUpdateCart,
    RemoveFromCart,
    RemoveAllCart,
    getCart
};
