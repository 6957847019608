// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing_page_container{
    border-top: 1px solid var(--outline-color);
    display: flex;
    padding: 1rem;
    justify-content: space-between;
}

.landing_page_column{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
    


`, "",{"version":3,"sources":["webpack://./src/admin/views/landingPage/style.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,aAAa;IACb,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf","sourcesContent":[".landing_page_container{\n    border-top: 1px solid var(--outline-color);\n    display: flex;\n    padding: 1rem;\n    justify-content: space-between;\n}\n\n.landing_page_column{\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n}\n    \n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
