// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_chart_bg {
    width: 50%;
    background-color: rgb(255, 255, 255);
    border-radius: 1rem;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.dashboard_chart_bg_dark {
    padding: 1rem;
}

.dashboard_chart_heading{
    font-family: var(--sans-serif);
    font-size: var(--sans-serif-size-medium);
    font-weight: var(--sans-serif-medium);
    padding:1rem;
    text-align: center;
}


@media (max-width:768px) {
    .dashboard_chart_bg{
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/views/seller/dashboard/chart/style.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,oCAAoC;IACpC,mBAAmB;IACnB,0CAA0C;IAC1C,oCAAoC;AACxC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,8BAA8B;IAC9B,wCAAwC;IACxC,qCAAqC;IACrC,YAAY;IACZ,kBAAkB;AACtB;;;AAGA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".dashboard_chart_bg {\n    width: 50%;\n    background-color: rgb(255, 255, 255);\n    border-radius: 1rem;\n    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);\n    border: 1px solid rgba(0, 0, 0, 0.1);\n}\n\n.dashboard_chart_bg_dark {\n    padding: 1rem;\n}\n\n.dashboard_chart_heading{\n    font-family: var(--sans-serif);\n    font-size: var(--sans-serif-size-medium);\n    font-weight: var(--sans-serif-medium);\n    padding:1rem;\n    text-align: center;\n}\n\n\n@media (max-width:768px) {\n    .dashboard_chart_bg{\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
