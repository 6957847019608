import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../component/common/breadCrumbs";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { ProductsApiService } from "../../../service/website/productsApiService";
import ProductCard from "../../component/common/productCard";
import ProductFilteration from "../../component/views/collection/productFilteration";
import { createMarkup, setUrl } from "../../helper/utils";
import Pagination from "../../../component/library/pagination";
import Sorting from "../../component/views/collection/sorting";
import queryString from "query-string";
import MobileFilteration from "../../component/views/collection/mobileFilteration";
import "./style.css";

export default function Products() {
  const message = useMessage();
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(null);
  const location = useLocation();

  const [queryParam, setQueryParam] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const [contentId, setContentId] = useState("");
  const [content, setContent] = useState();

  const [pages, setPages] = useState({
    currentPage: "",
    totalPages: "",
    totalItems: "",
  });

  const navigate = useNavigate();

  const handleProducts = async (query) => {
    await ProductsApiService.getProducts(query)
      .then(async (res) => {
        if (res.status.code === 200) {
          if (res.data) {
            await setProducts(res?.data?.products);
            setPages({
              ...pages,
              currentPage: res.data.currentPage,
              totalPages: res.data.totalPages,
              totalItems: res.data.totalProducts,
            });
          } else {
            await setProducts([]);
          }

          if (res.data.searchOutsideCategory) {
            navigate(`/collection?search=${searchParams.get("search")}`);
          }
        } else {
          await setProducts([]);
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  useEffect(() => {
    if (queryParam) {
      handleProducts(queryParam);
    } else if (!queryParam) {
      handleProducts("");
    }
    window.scrollTo(0, 0);
  }, [queryParam]);

  useEffect(() => {
    if (page === 1) {
      handleRemovePageParam();
    } else if (page > 1) {
      handleAddPageParam();
    }
  }, [page]);

  const handleRemovePageParam = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("page");
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleAddPageParam = () => {
    const currentQueries = queryString.parse(location.search);
    const newQueryParams = {
      ...currentQueries,
      page: page,
    };
    const newQueryString = queryString.stringify(newQueryParams);

    navigate(`${location.pathname}?${newQueryString}`);
  };

  const handleCategoryContent = () => {
    ProductsApiService.getCategoryContent(contentId)
      .then((res) => {
        if (res.status.code === 200) {
          setContent(res.data);
        } else {
          setContent();
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  useEffect(() => {
    if (contentId) {
      handleCategoryContent();
    } else {
      setContent();
    }
  }, [contentId]);

  useEffect(() => {
    let pageNo = parseInt(searchParams.get("page"));
    if (pageNo && pageNo !== 1) {
      setPage(pageNo);
    } else {
      setPage(1);
    }
  }, [searchParams.get("page")]);


  const [filter,setFilter]=useState(false);

  return (
    <div className="collection_page">
      <BreadCrumbs />

      <div className="apply_filters" onClick={() => setFilter(true)}>Apply Filters</div>

      <MobileFilteration filter={filter} setFilter={setFilter} setQuery={setQueryParam} setContentId={setContentId} setPage={setPage} />
    

      <div className="product_listing_row">
        <div className="product_listing_filteration_col">
          {content?.sidebarHeader?.map((item, index) => {
            return (
              <div className="collection_header">
                <div key={index}>
                  <h2>{item?.name}</h2>
                  <div
                    dangerouslySetInnerHTML={createMarkup(item?.html_content)}
                  />
                </div>
              </div>
            );
          })}


          <div className="product_main_filter_wrapper">
            <ProductFilteration
              setQuery={setQueryParam}
              setContentId={setContentId}
              setPage={setPage}
            />
          </div>

          <div className="collection_footer">
            {content?.sidebarFooter?.map((item, index) => {
              return (
                <div key={index}>
                  <h2>{item?.name}</h2>
                  <div
                    dangerouslySetInnerHTML={createMarkup(item?.html_content)}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className="product_listing_products_col">
          {content?.header?.map((item, index) => {
            return (
              <div className="collection_header" key={index}>
                <div>
                  <h2>{item?.name}</h2>
                  <div
                    dangerouslySetInnerHTML={createMarkup(item?.html_content)}
                  />
                </div>
              </div>
            );
          })}

          <div className="product_main_filter_wrapper">
          <Sorting />
          </div>

          <div className="collection_product_row">
            {products?.map((product) => {
              return <ProductCard product={product} />;
            })}
          </div>

          {products?.length ? (
            <div className="collection_pagination">
              <Pagination pages={pages} page={page} setPage={setPage} />
            </div>
          ) : null}

          <div className="collection_footer">
            {content?.footer?.map((item, index) => {
              return (
                <div>
                  <h2>{item?.name}</h2>
                  <div
                    dangerouslySetInnerHTML={createMarkup(item?.html_content)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}




