import React, { useEffect, useState } from "react";
import { productListApiService } from "../../../../service/admin/productApiService";
import { useLoader } from "../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";
import { MdDelete, MdModeEdit, MdDescription } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { GrRevert } from "react-icons/gr";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import DeletePopUp from "../../../../component/library/deletePopUp";
import Pagination from "../../../../component/library/pagination";
import "./style.css";
import { exportToCSV } from "react-easy-export";
import WebButton from "../../../../component/library/webButton";
import WebDropdown from "../../../../component/library/webDropdown";
import { useSelector } from "react-redux";
import { categoryData } from "../../../../redux/slices/categorySlice";

export default function ProductList() {
  const [productData, setProductData] = useState([]);
  const [products, setProducts] = useState({
    name: "",
    sku: "",
    category: "",
  });
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);

  const [pages, setPages] = useState({
    currentPage: "",
    totalPages: "",
    totalItems: "",
  });
  // const [totalCount, setTotalCount] = useState("");
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [checkHeaderClick, setCheckHeaderClick] = useState(false);
  const [categories, setCategories] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [exportAllData, setExportAllData] = useState();
  const [status, setStatus] = useState("");
  const category = useSelector(categoryData);
  const navigate = useNavigate();
  const loader = useLoader();
  const message = useMessage();
  const [uploadFile, setUploadFile] = useState();
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc"); // Default ascending

  useEffect(() => {
    let array = [];
    productData.map((item) => {
      let obj = {
        Image: item?.imgUrl[0],
        "Category Name": item?.c_name,
        Title: item?.title,
        hsn: item?.hsn,
        Price: item?.sp,
        Quantity: item?.quantity,
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [productData]);

  // const buildQueryParams = () => {
  //   const { name, sku, category } = products;
  //   let queryParams = '';

  //   if (name) queryParams += `&name=${name}`;
  //   if (sku) queryParams += `&sku=${sku}`;
  //   if (category) queryParams += `&category=${category}`;

  //   return queryParams;
  // };

  const handleSort = (field) => {
    let order = "asc";
    if (sortField === field && sortOrder === "asc") {
      order = "desc";
    }
    setSortField(field);
    setSortOrder(order);

    const sortedData = [...productData].sort((a, b) => {
      const comparison = sortOrder === "asc" ? 1 : -1;

      return comparison * (a[field] > b[field] ? 1 : -1);
    });
    setProductData(sortedData);
  };

  const handleAllChecked = (e) => {
    if (e.target.checked) {
      let allSelectedRows = productData.map((item) => item);
      setSelectedRows(allSelectedRows);
      setCheckHeaderClick(true);
    } else {
      setSelectedRows([]);
      setCheckHeaderClick(false);
    }
  };
  const handleFile = (e) => {
    setUploadFile(e.target.files[0]);
  };
  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };
  const handleOnChange = (e) => {
    setProducts({ ...products, [e.target.name]: e.target.value });
  };

  const handleChange = (item) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some((row) => row._id === item._id);
      if (isSelected) {
        return prevSelectedRows.filter((row) => row._id !== item._id);
      } else {
        return [...prevSelectedRows, item];
      }
    });
  };

  const handleExportCsv = () => {
    const exportData = selectedRows.map((item) => ({
      Image: item.imgUrl[0],
      "Category Name": item.c_name,
      Title: item.title,
      hsn: item.hsn,
      Price: item.sp,
      Quantity: item.quantity,
    }));
    exportToCSV(exportData, "Selected_Products");
  };

  const queryParams = `?name=${
    encodeURIComponent(products.name) ? encodeURIComponent(products.name) : ""
  }&sku=${products.sku ? products?.sku : ""}&category=${
    encodeURIComponent(status) ? encodeURIComponent(status) : ""
  }&page=${page}&limit=${limit}`;

  const fetchProductsList = () => {
    loader.showLoading(true);
    productListApiService
      .getAllProductsList(queryParams)
      .then((res) => {
        loader.showLoading(false);

        if (res.status.code === 200) {
          setProductData(res.data.products);
          setPages({
            ...pages,
            currentPage: res.data.currentPage,
            totalPages: res.data.totalPages,
            totalItems: res.data.totalProduct,
          });
          setPage(res.data.currentPage);
        } else if (res.status.code === 402) {
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  useEffect(() => {
    fetchProductsList();
  }, [page, limit]);

  const searchProducts = () => {
    loader.showLoading(true);
    // const queryParams = buildQueryParams();
    productListApiService
      .getAllProductsList(queryParams ? queryParams : "")
      .then((res) => {
        if (res.status.code === 200) {
          setProductData(res.data.products);
          setPages({
            ...pages,
            currentPage: res.data.currentPage,
            totalPages: res.data.totalPages,
            totalItems: res.data.totalProduct,
          });
          setPage(res.data.currentPage);
          loader.showLoading(false);
        } else {
          loader.showLoading(false);
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  const handleEdit = (id) => {
    setId(id);
    navigate(`/admin/products/edit/${id}`);
  };

  const handleDescription = (id, title) => {
    setId(id);
    setTitle(title);
    navigate(`/admin/products/details?id=${id}`);
  };

  const handleStatus = (id, status) => {
    const payload = { status: status };
    productListApiService
      .editProducts(JSON.stringify(payload), id)
      .then((res) => {
        // setProducts(res.data);
        fetchProductsList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    setId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const options = [
    { name: "--Please Select", value: "" },
    { name: "Available", value: "available" },
    { name: "Removed", value: "removed" },
    { name: "Disable", value: "disable" },
    { name: "To Be Released", value: "toBeReleased" },
  ];

  const handleChangeDropdown = (value) => {
    setStatus(value);
    console.log("valu", value);
  };

  useEffect(() => {
    const array = [
      {
        name: "--Select Category--",
        value: "",
      },
    ];
    if (category?.length) {
      category.map((item) => {
        const obj = {
          name: item.c_name,
          value: item.c_name,
        };
        array.push(obj);
      });
      setCategories(array);
    }
  }, [category]);

  const handleSubmit = () => {
    loader.showLoading(true);
    productListApiService
      .deleteProducts(id)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          fetchProductsList();
          setOpen(false);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  const handleUploadProduct = () => {
    const data = new FormData();
    if (!uploadFile) {
      message.showToast("Please Choose a file to uplaod", ToastTypes.ERROR);
      return;
    }
    loader.showLoading(true);
    data.append("file", uploadFile);
    console.log("file", uploadFile);

    productListApiService
      .addExcelProduct(data)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };
  const handleRevert = (id, deleted) => {
    const payload = { is_delete: deleted };
    productListApiService
      .editProducts(JSON.stringify(payload), id)
      .then((res) => {
        // setProducts(res.data);
        fetchProductsList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  return (
    <div className="top">
      <div className="flex gap-4">
        <div className="flex flex-wrap items-center gap-2">
          <label className="">Product Name</label>
          <input
            className="input_field"
            onChange={handleOnChange}
            value={products?.name}
            name="name"
          />
        </div>
        <div className="flex flex-wrap items-center gap-2">
          <label className="">SKU</label>
          <input
            className="input_field"
            onChange={handleOnChange}
            value={products?.sku}
            name="sku"
          />
        </div>
        <div className="flex flex-wrap items-center gap-2">
          <label className="">Seller</label>
          <input className="input_field" onChange={handleOnChange} />
        </div>
        <div className="flex flex-wrap items-center gap-2">
          <label className="">Product Status</label>
          <WebDropdown
            options={options}
            className="input_field"
            onChange={handleOnChange}
          />
        </div>
        <div className="flex flex-wrap items-center gap-2">
          <label className="">Category</label>
          <WebDropdown
            options={categories}
            className="input_field"
            selected={status}
            onChange={(e) => handleChangeDropdown(e.target.value)}
            name="categories"
          />
        </div>
      </div>
      <WebButton
        text="Search"
        onClick={searchProducts}
        className="bg-gray-300 text-black py-1 px-2 rounded-md mt-2"
      />
      <div className="flex justify-between gap-10">
        <div>
          <WebButton
            text="Export Data"
            onClick={handleExportCsv}
            className="bg-blue-400 w-30 text-white rounded-md p-2 mt-4 outline-none"
          />
        </div>
        <div className="flex items-center gap-4 mt-4">
          <input
            type="file"
            onChange={handleFile}
            className="bg-blue-400 w-30 text-white rounded-md p-2 outline-none"
          />
          <WebButton
            text="New Product Upload"
            className="bg-blue-400 w-30 text-white rounded-md p-2 outline-none"
            onClick={handleUploadProduct}
          />
        </div>
        <div className="flex items-center">
          <label htmlFor="limit">Items per page:</label>
          <select
            id="limit"
            value={limit}
            onChange={handleLimitChange}
            className="border border-gray-400 p-2"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </div>
      </div>

      <table className="w-full mt-4 bg-white">
        <thead className="bg-slate-200 text-base text-slate-500">
          <tr className="products">
            <td>
              <input
                type="checkbox"
                className="checkbox_size py-1 px-4"
                onChange={handleAllChecked}
              />
            </td>
            <td className="py-1 px-4">Image</td>
            <td className="py-1 px-4">Category Name</td>
            <td className="py-1 px-4">Title</td>
            <td className="py-1 px-4">Sku</td>
            <td className="py-1 px-4">Variation</td>
            <td className="py-1 px-4">hsn</td>
            <th style={{ cursor: "pointer" }} onClick={() => handleSort("sp")}>
              Price
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => handleSort("quantity")}
            >
              Quantity
            </th>
            <td className="py-1 px-4">Actions</td>
          </tr>
        </thead>
        <tbody>
          {productData && productData.length > 0 ? (
            productData.map((item, index) => {
              let isDeleted = item.is_delete;
              const rowClass = isDeleted ? "line-through bg-red-300" : "";
              const isSelected = selectedRows.some(
                (row) => row._id === item._id
              );
              return (
                <tr
                  key={index}
                  className={`cursor-pointer text-wrap ${rowClass} `}
                >
                  <td>
                    <input
                      className="checkbox_size py-1 px-4"
                      type="checkbox"
                      onChange={() => handleChange(item)}
                      checked={isSelected}
                    />
                  </td>
                  <td>
                    <img src={item?.imgUrl[0]} width="70px" />
                  </td>
                  <td
                    onClick={() =>
                      navigate(`/admin/products/details?id=${item?._id}`)
                    }
                    className="py-2 px-4"
                  >
                    {item.c_name}
                  </td>
                  <td className="text-wrap">{truncateText(item.title, 50)}</td>
                  <td className="text-wrap">{item.sku}</td>
                  <td className="text-wrap">{item?.variations?.length}</td>
                  <td className="py-2 px-4">{item.hsn}</td>
                  <td className="py-2 px-4">{item.sp}</td>
                  <td
                    onClick={() =>
                      navigate(`/admin/products/details?id=${item?._id}`)
                    }
                    className="py-2 px-4"
                  >
                    {item.quantity}
                  </td>
                  <td>
                    <div className="flex gap-1">
                      <MdModeEdit
                        className="text-xl cursor-pointer text-blue-600"
                        onClick={() => handleEdit(item._id)}
                      />
                      {isDeleted ? (
                        <GrRevert
                          onClick={() => handleRevert(item._id, false)}
                        />
                      ) : (
                        <MdDelete
                          className="text-xl text-red-600"
                          onClick={() => handleDelete(item._id)}
                        />
                      )}
                      <MdDescription
                        className="text-xl text-blue-600"
                        onClick={() => handleDescription(item._id, item.title)}
                      />
                      {item.status === "0" ? (
                        <IoMdEyeOff
                          onClick={() => handleStatus(item._id, "5")}
                          className="text-xl text-black"
                        />
                      ) : (
                        <IoMdEye
                          onClick={() => handleStatus(item._id, "0")}
                          className="text-xl text-black"
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <p>No Data Found</p>
          )}
        </tbody>
      </table>
      <DeletePopUp
        heading="Delete Product"
        text="Are you sure you want to delete?"
        open={open}
        handleClose={handleClose}
        submitText="Yes"
        cancelText="No"
        handleSubmit={handleSubmit}
        handleCancel={handleClose}
      />

      {productData.length ? (
        <Pagination pages={pages} page={page} setPage={setPage} />
      ) : null}
    </div>
  );
}
