import React from "react";
import "./style.css";

export default function InventorySync({ data, handleOnClick }) {
  return (
    <div className="inventory_sync_bg">
      <p className="inventory_sync_head">Inventory Synchronization</p>

      <div className="inventory_sync_option_row">
        <div className="">
          <input
            type="checkbox"
            className="w-4 h-4"
            checked={data.inventorySync.amazon}
            onClick={() =>
              handleOnClick(
                "inventorySync",
                "amazon",
                !data.inventorySync.amazon
              )
            }
          />
          <p className="text-lg">Amazon</p>
        </div>

        <div className="">
          <input
            type="checkbox"
            className="w-4 h-4"
            checked={data.inventorySync.shopify}
            onClick={() =>
              handleOnClick(
                "inventorySync",
                "shopify",
                !data.inventorySync.shopify
              )
            }
          />
          <p className="text-lg">Shopify</p>
        </div>
      </div>
    </div>
  );
}
