import React from "react";
import { GoCheckCircleFill } from "react-icons/go";
import { formatDate } from "../../../../../helper/utils";
import "./style.css";

export default function OrderTracking({ data }) {
  return (
    <div className="order_status_row">
      {data?.orderStatus?.map((item, index) => {
        return (
          <div className="order_row">
            <div className="flex flex-col items-center justify-center text-center">
              <GoCheckCircleFill className="text-green-500 text-2xl" />
              <p className="text-base font-medium">{item.status}</p>
              <p className="text-sm">{formatDate(item.date)}</p>
            </div>

            {index + 1 < data?.orderStatus.length ? (
              <div className="order_line"></div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}
