import React, { useEffect, useState } from "react";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { SettingApiService } from "../../../service/seller/settingApiService";
import DashboardCard from "../../../component/views/seller/dashboard/card";
import DashboardChart from "../../../component/views/seller/dashboard/chart";
import "./style.css";

export default function Dashboard() {
  const [data, setData] = useState({
    walletBalance: 0,
    membershipPlan: "",
    totalOrders: 0,
    totalSales: 0,
    todayOrders: 0,
    todaySales: 0,
    salesByMonth: null,
    quantityByCategory: null,
    topSellingSKUs: null,
    topSellingCategories: null,
  });

  const loader = useLoader();
  const message = useMessage();

  const fetchData = async () => {
    loader.showLoading(true);
    try {
      const res = await SettingApiService.getDashboard();
      loader.showLoading(false);
      if (res.status.code === 200) {
        setData(res.data);
      } else {
        message.showToast(res.status.message, ToastTypes.ERROR);
      }
    } catch (err) {
      loader.showLoading(false);
      message.showToast("Server error. Try again later", ToastTypes.ERROR);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="dashboard_container">
      <div className="dashboard_cards_row">
        <DashboardCard
          heading="Total Orders"
          unit={data.totalOrders}
          baseColor="#9889f2"
          link='/seller/orders'
        />
        <DashboardCard
          heading="Total Sales"
          unit={data.totalSales}
          baseColor="#ffa7a7"
          link='/seller/wallet'
        />
        <DashboardCard
          heading="Today's Orders"
          unit={data.todayOrders}
          baseColor="#85c181"
          link={`/seller/orders?startDate=true&endDate=true`}
          
        />
        <DashboardCard
          heading="Today's Sales"
          unit={data.todaySales}
          baseColor="#fa9ecf"
          link={`/seller/wallet?startDate=true&endDate=true`}
        />
        <DashboardCard
          heading="Wallet Balance"
          unit={`₹${data.walletBalance}`}
          baseColor="#f2bc6d"
          link='/seller/wallet'
        />
        <DashboardCard
          heading="Pending Orders"
          unit={data.pendingOrders}
          baseColor="#52d9ab"
          link={`/seller/orders?status=payment pending`}
        />
        <DashboardCard
          heading="Subscribed Products"
          unit={data.subscribeProduct}
          baseColor="#6fc0f0"
          link='/seller/productSubscription'
        />
        <DashboardCard
          heading="Membership Plan"
          unit={data.membershipPlan}
          baseColor="#fcb39a"
          link='/seller/wallet'
        />
      </div>

      <div className="dashboard_charts_row">
        {data?.topSellingSKUs ? (
          <DashboardChart
            data={data.topSellingSKUs}
            heading="Top Selling SKUs"
          />
        ) : null}

        {data?.topSellingCategories ? (
          <DashboardChart
            data={data.topSellingCategories}
            heading="Top Selling Categories"
          />
        ) : null}
      </div>
    </div>
  );
}
