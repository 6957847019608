import { BrowserRouter } from "react-router-dom";
import Router from "./routes/router";
import ActiveUser from "./component/common/userTrack/index";
import Analytics from "./component/common/userTrack/analytics";
import "./App.css";
import "./assets/colors/color.css";

function App() {
  return (
    <BrowserRouter>
      <Analytics />
      <ActiveUser>
        <Router />
      </ActiveUser>
    </BrowserRouter>
  );
}

export default App;
