// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/terms_condition.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms_condition_banner {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 300px;
    border-radius: 8px;
}

/* Responsive styles for screens between 300px and 768px width */
@media screen and (max-width: 768px) and (min-width: 300px) {

    .terms_condition_banner {
        width: 100%;
        height: 110px
    }
}`, "",{"version":3,"sources":["webpack://./src/website/views/termsCondition/style.css"],"names":[],"mappings":"AAAA;IACI,yDAAmE;IACnE,2BAA2B;IAC3B,sBAAsB;IACtB,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;;AAEA,gEAAgE;AAChE;;IAEI;QACI,WAAW;QACX;IACJ;AACJ","sourcesContent":[".terms_condition_banner {\n    background-image: url('../../../assets/images/terms_condition.png');\n    background-position: center;\n    background-size: cover;\n    width: 100%;\n    height: 300px;\n    border-radius: 8px;\n}\n\n/* Responsive styles for screens between 300px and 768px width */\n@media screen and (max-width: 768px) and (min-width: 300px) {\n\n    .terms_condition_banner {\n        width: 100%;\n        height: 110px\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
