import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ToastTypes, useMessage } from '../../../../../base/context/MessageProvider';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryApiService } from '../../../../../service/website/categoryApiService';
import { addItems, categoryData } from '../../../../../redux/slices/categorySlice';
import { backIcon, closeIcon, nextVIcon } from '../../../../assets/icons';
import Logo from "../../../../assets/images/common/logo.png";
import { queryToUrl, setUrl } from '../../../../helper/utils';
import { navbarTopMenu } from '../../../../helper/constants';
import './style.css'


export default function MenuBar({ menu, setMenu,featuredCategories }) {
    
    const location = useLocation();
    const message = useMessage();
    const dispatch = useDispatch();

    const [showCategories,setShowCategories]=useState(false)
    
    const categories = useSelector(categoryData);

    useEffect(() => {
        setMenu(false);
    }, [location]);

    useEffect(() => {
        handleCategories();
    }, []);

  
  
    const handleCategories = () => {
      CategoryApiService.getCategories()
        .then((res) => {
          if (res.status.code === 200) {
            dispatch(addItems(res.data));
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
        });
    };


    console.log('categories',categories)
  
    return (
      <div>
        <div
          className={
            !menu
              ? "navbar_category_menu_container"
              : "navbar_category_menu_container_show"
          }
          onClick={() => setMenu(false)}
        ></div>
  
        <div
          className={
            !menu ? "navbar_category_menu_before" : "navbar_category_menu_after"
          }
        >

          <div className="navbar_category_menu_header">
            <img src={Logo} />
            <div onClick={() => setMenu(false)}>{closeIcon}</div>
          </div>
  

            {!showCategories?<>
            <div className="navbar_pages_row_on_mobile">
                {navbarTopMenu.map((menu, index) => {
                    return (
                    <div key={index}>
                        <Link
                        to={menu.link}
                        className="category_menu_item"
                        >
                        <p>{menu.name}</p>
                        </Link>

                        
                    </div>
                    );
                })}
            </div>

            <div className="navbar_pages_row_on_mobile">
            {featuredCategories?.map((menu, index) => {
                return (
                <div key={index}>
                    <Link
                    to={menu.pageUrl + `?id=${menu._id}`}
                    className="category_menu_item"
                    >
                    <p>{menu.tagName}</p>
                    </Link>              
                </div>
                );
            })}                
            </div>
  
            <div className="navbar_pages_row_on_mobile">
                <p className="all_category_menu"  onClick={()=>setShowCategories(true)} >All Categories {nextVIcon} </p> 
            </div>
            </>:null}
  
            
  
            {showCategories && categories?<Categories categories={categories} setShowCategories={setShowCategories}/>:null}

            {categories?<div className='category_on_lg_screen'>
              <Categories categories={categories} setShowCategories={setShowCategories}/>
            </div>:null}
            
          
          
        </div>
  
        
      </div>
    );
  };







const Categories=({categories,setShowCategories})=>{

    const [data,setData]=useState(categories)
    const [parentData,setParentData]=useState([])
    const [activeParent,setActiveParent]=useState('')

    const [searchParams,setSearchParams]=useSearchParams()

    const navigate=useNavigate()
    const location=useLocation()

    useEffect(()=>{
        if( parentData.length){
            setActiveParent(parentData[parentData.length - 1])
        }
    },[parentData])

    useEffect(()=>{
        setData(categories)
        setParentData([])
        setActiveParent('')
    },[location])

    const handleData=(e)=>{
        if(e.children.length){
            setData(e.children)
            setParentData([...parentData,e])
        }else{
            viewProduct(e.c_name)
        }
    }

    const handleParent=(e)=>{
        const index = parentData.findIndex((item)=>item.c_name===e.c_name);
        const newParentData = parentData.filter((item)=>item.c_name!==e.c_name);

    

        if(index===0){
            setData(categories)
            setActiveParent('')
            setParentData([]);
        }
       else if(index>0){
            setData(parentData[index-1].children)
            setActiveParent(parentData[index - 1])
            setParentData(newParentData);
        }
    }

    const viewProduct=(name)=>{
        let arr=[]
        let path;

        parentData.map((item)=>{
            arr.push(item.c_name) 
        })

        if(arr.length && name){
            path=queryToUrl(arr.join('/')+'/'+name)
        }
        else if(!arr.length && name){
            path=queryToUrl('/'+name)
        }
        else if(arr.length && !name){
            path=queryToUrl(arr.join('/')) 
        }
        setShowCategories(false)
        navigate(setUrl('/collection', searchParams, "category", path));
    }




    return <div>
        {activeParent? <div
            className="menubar_active_parent"
          >
         <p onClick={()=>handleParent(activeParent)}>{backIcon} {activeParent.c_name}</p><span onClick={()=>viewProduct('')}>View All</span>
          </div>: <p className="back_to_main" onClick={()=>setShowCategories(false)}>{backIcon} <span>Back to main menu</span></p>
        }

        {data.length && data?.map((item, index) => {
        return (
          <p
            key={index}
            className="menubar_item"
            onClick={()=>handleData(item)}
          >
            <p >{item.c_name}</p>
            {nextVIcon}
          </p>
        );
      })}
    </div>
}