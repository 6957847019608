// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_container{
    border-top: 1px solid var(--outline-color);
    padding: 1rem;
}

.dashboard_heading{
    font-size: var(--sans-serif-size-large);
    font-family: var(--sans-serif);
    color: var(--web-primary-color);
    margin-bottom: 20px;
    text-align: center;
}

.dashboard_cards_row{
    width: 100%;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    margin-bottom: 2rem;
}

.dashboard_cards_row .dashboard_card_box:nth-child(odd) .dashboard_card_circle_line_parent {
    position: absolute;
    top: -50px;
    right: -50px;
}

.dashboard_cards_row .dashboard_card_box:nth-child(even) .dashboard_card_circle_line_parent {
    position: absolute;
    top: -80px;
    right: 10%;
}

.dashboard_charts_row{
    width: 100%;
    display: flex;
    gap: 2rem;
}

@media (max-width:768px) {
    .dashboard_cards_row{
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        flex-grow: 1;
        flex-shrink: 1;
    }
    
    .dashboard_charts_row{
        display: flex;
        flex-wrap: wrap;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/seller/dashboard/style.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,aAAa;AACjB;;AAEA;IACI,uCAAuC;IACvC,8BAA8B;IAC9B,+BAA+B;IAC/B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,SAAS;IACT,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;AACd;;AAEA;IACI,WAAW;IACX,aAAa;IACb,SAAS;AACb;;AAEA;IACI;QACI,uBAAuB;QACvB,aAAa;QACb,eAAe;QACf,SAAS;QACT,YAAY;QACZ,cAAc;IAClB;;IAEA;QACI,aAAa;QACb,eAAe;IACnB;AACJ","sourcesContent":[".dashboard_container{\n    border-top: 1px solid var(--outline-color);\n    padding: 1rem;\n}\n\n.dashboard_heading{\n    font-size: var(--sans-serif-size-large);\n    font-family: var(--sans-serif);\n    color: var(--web-primary-color);\n    margin-bottom: 20px;\n    text-align: center;\n}\n\n.dashboard_cards_row{\n    width: 100%;\n    display: flex;\n    gap: 2rem;\n    flex-wrap: wrap;\n    margin-bottom: 2rem;\n}\n\n.dashboard_cards_row .dashboard_card_box:nth-child(odd) .dashboard_card_circle_line_parent {\n    position: absolute;\n    top: -50px;\n    right: -50px;\n}\n\n.dashboard_cards_row .dashboard_card_box:nth-child(even) .dashboard_card_circle_line_parent {\n    position: absolute;\n    top: -80px;\n    right: 10%;\n}\n\n.dashboard_charts_row{\n    width: 100%;\n    display: flex;\n    gap: 2rem;\n}\n\n@media (max-width:768px) {\n    .dashboard_cards_row{\n        justify-content: center;\n        display: flex;\n        flex-wrap: wrap;\n        gap: 1rem;\n        flex-grow: 1;\n        flex-shrink: 1;\n    }\n    \n    .dashboard_charts_row{\n        display: flex;\n        flex-wrap: wrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
