import React from 'react'
import ProductFilteration from '../productFilteration'
import Sorting from '../sorting'
import { closeIcon } from '../../../../assets/icons'
import './style.css'

export default function MobileFilteration({filter,setFilter ,setContentId,setQuery,setPage}) {
  return (
    <div className='mobile_filter_bg'>
        <div>
            <div
            className={
                !filter
                ? "navbar_category_menu_container"
                : "navbar_category_menu_container_show"
            }
            onClick={() => setFilter(false)}
            ></div>
  
            <div className={!filter ? "navbar_category_menu_before" : "navbar_category_menu_after"}>

                <div className='close_btn' onClick={() => setFilter(false)}>{closeIcon}</div>

                <ProductFilteration setQuery={setQuery} setContentId={setContentId} setPage={setPage}/>
                <Sorting />

            </div>
      </div>
    </div>
  )
}
