// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth_logo{
   height:3rem;
   cursor: pointer;  
}

.auth_navbar_bg{
    position: absolute;
    top: 0;
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.auth_navbar_row{
    width: 1280px;
}

@media (max-width:480px) {
    .auth_navbar_row{
        width: 100%;
        padding: 0 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/common/authNavbar/style.css"],"names":[],"mappings":"AAAA;GACG,WAAW;GACX,eAAe;AAClB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,WAAW;QACX,eAAe;IACnB;AACJ","sourcesContent":[".auth_logo{\n   height:3rem;\n   cursor: pointer;  \n}\n\n.auth_navbar_bg{\n    position: absolute;\n    top: 0;\n    width: 100%;\n    height: 4rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 99;\n}\n\n.auth_navbar_row{\n    width: 1280px;\n}\n\n@media (max-width:480px) {\n    .auth_navbar_row{\n        width: 100%;\n        padding: 0 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
