import Dashboard from "../../views/seller/dashboard";
import Wallet from "../../views/seller/wallet";
import Subscription from "../../views/seller/subscription";
import ProductSubscription from "../../views/seller/productSubscription";
import Settings from "../../views/seller/settings";
import Invoice from "../../views/seller/reports/invoice";
import Orders from "../../views/seller/orders/list";
import MarketplaceApis from "../../views/seller/marketPlaceApi";
import OrderDetails from "../../views/seller/orders/details";
import AddOrder from "../../views/seller/orders/add";
import UserProfile from "../../views/seller/profile";
import Ticket from "../../views/admin/Ticket";

const sellerRoutes = [
  {
    path: "seller/dashboard",
    element: <Dashboard />,
  },
  {
    path: "seller/orders",
    element: <Orders />,
  },
  {
    path: "seller/orders/addOrder",
    element: <AddOrder />,
  },
  {
    path: "seller/orders/orderDetails/:id",
    element: <OrderDetails />,
  },

  {
    path: "seller/marketplaceApis",
    element: <MarketplaceApis />,
  },

  {
    path: "seller/subscription",
    element: <Subscription />,
  },
  {
    path: "seller/productSubscription",
    element: <ProductSubscription />,
  },

  {
    path: "seller/wallet",
    element: <Wallet />,
  },
  {
    path: "seller/settings",
    element: <Settings />,
  },
  {
    path: "seller/reports/invoice",
    element: <Invoice />,
  },
  {
    path: "seller/profile",
    element: <UserProfile />,
  },
  {
    path: "/seller/orders/ticket/:id",
    element: <Ticket />,
  },
];

export default sellerRoutes;
