import React, { useState } from "react";
import WebButton from "../../../../library/webButton";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../../base/context/MessageProvider";
import { orderApiService } from "../../../../../service/seller/orderApiService";
import { useNavigate } from "react-router-dom";
import RaiseTicket from "../raiseTicketPopup";
import AlertPopup from "../../../../library/alertPopup";
import "./style.css";

export default function OrderStatus({
  data,
  handleRefresh,
  consumerPrice,
  upsaleoPrice,
}) {
  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();
  const [ticketPopup, setTicketPopup] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [successText, setSuccessText] = useState("");
  const [cancelText, setCancelText] = useState("");
  const [successFunction, setSuccessFunction] = useState("");

  const handlePaymentApi = () => {
    setIsOpen(false);
    loader.showLoading(true);
    orderApiService
      .PayOrder(`/${data._id}`)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          message.showToast(res.status.message, ToastTypes.SUCCESS);
          handleRefresh();
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handleCancelApi = () => {
    setIsOpen(false);
    loader.showLoading(true);
    orderApiService
      .CancelOrder(`/${data._id}`)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          message.showToast(res.status.message, ToastTypes.SUCCESS);
          handleRefresh();
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  const handlePay = () => {
    if (consumerPrice > upsaleoPrice) {
      setIsOpen(true);
      setAlertMessage("Are you sure you want to proceed?");
      setSuccessText("Pay Now");
      setCancelText("Cancel");
      setSuccessFunction(() => handlePaymentApi);
    } else {
      setCancelText("Cancel");
      setIsOpen(true);
      setAlertMessage(
        "Consumer Price is less than upsaleo price. Are you sure you want to proceed?"
      );
      setSuccessText("Pay Now");
      setSuccessFunction(() => handlePaymentApi);
    }
  };

  const handleCancelOrder = () => {
    setIsOpen(true);
    setAlertMessage("Are you sure you want to cancel?");
    setSuccessFunction(() => handleCancelApi);
    setSuccessText("Yes");
    setCancelText("No");
  };

  const handleTicket = (id) => {
    setTicketPopup(!ticketPopup);
    setOrderId(id);
  };

  const handleViewTicket = (id) => {
    navigate(`/seller/orders/ticket/${id}`);
  };

  return (
    <div className="order_detail_btn_row">
      {data.status === "payment pending" ? (
        <WebButton
          onClick={() => handlePay()}
          className="bg-orange-500 px-4 py-2 rounded-lg text-white"
          text="Pay Now"
        />
      ) : null}
      {data.status === "payment pending" ||
      data.status === "abnormal" ||
      (data.status === "processing" && data.orderExport === false) ? (
        <WebButton
          onClick={() => handleCancelOrder(data?._id)}
          className="bg-orange-500 px-4 py-2 rounded-lg text-white"
          text="Cancel Order"
        />
      ) : null}
      {(data.status === "shipped" && data?.ticket === false) ||
      (data.status === "partially-shipped" && data?.ticket === false) ||
      (data.status === "processing" &&
        data.orderExport === true &&
        data?.ticket === false) ? (
        <WebButton
          onClick={() => handleTicket(data?._id)}
          className="bg-orange-500 px-4 py-2 rounded-lg text-white"
          text="Raise Ticket"
        />
      ) : null}
      {data.ticket ? (
        <WebButton
          onClick={() => handleViewTicket(data?.ticketId)}
          className="bg-orange-500 px-4 py-2 rounded-lg text-white"
          text="View Ticket"
        />
      ) : null}
      <RaiseTicket
        modalIsOpen={ticketPopup}
        setIsOpen={setTicketPopup}
        id={orderId}
        handleRefresh={handleRefresh}
      />

      <AlertPopup
        message={alertMessage}
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        success={successFunction}
        successText={successText}
        cancel={() => setIsOpen(false)}
        cancelText={cancelText}
      />
    </div>
  );
}
