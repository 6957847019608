import React, { useState } from "react";
import DatePicker from "../../../../component/library/datePicker";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../../../base/context/LoaderProvider";
import { useMessage } from "../../../../base/context/MessageProvider";

export default function Invoice() {
  const message = useMessage();
  const loader = useLoader();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleDownloadCsv = () => {};

  return (
    <div className="w-full bg-white border p-6 mt-2">
      <div className="flex gap-5 items-center">
        <DatePicker setStartDate={setStartDate} setEndDate={setEndDate} />

        <button
          className="bg-slate-500 py-2 px-5 text-white rounded"
          onClick={() => handleDownloadCsv()}
        >
          Download
        </button>
      </div>

      <table className="w-full mt-4">
        <thead className="bg-slate-200 text-base text-slate-500">
          <tr>
            <td className="py-1 px-4">Order Id</td>
            <td className="py-1 px-4">Date</td>
            <td className="py-1 px-4">Transaction Id</td>
            <td className="py-1 px-4">Download</td>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  );
}
