export const NetworkConfiguration = {
  LOGIN: "user/login",
  PROFILE: "user/profile",
  SEND_OTP: "user/sendOtp",
  VERIFY_OTP: "user/verifyOtp",
  REGISTRATION: "user/registration",
  USER_DETAILS: "user/userDetails",
  EMAIL_VERIFICATION: "user/emailVerification",
  FORGET_PASSWORD: "user/forgotPassword",
  RESET_PASSWORD: "user/resetPassword",
  SUBSCRIPTION_PLANS: "user/subscription?status=active",
  TRANSACTIONS: "user/transaction",
  WALLET: "user/myWallet",
  MEMBERSHIP: "user/membership",
  UPGRADE_MEMBERSHIP: "user/membership/upgrade",
  CATEGORIES: "product/all/category",
  ALL_CATEGORIES: "product/search/category",
  BLANK_CATEGORY_CONTENT: "admin/categorycontent",
  PRODUCTS: "product",
  HOME_PRODUCTS: "product/home",
  PRODUCT_BY_ID: "product/",
  PRODUCT_VARIATION_BY_PARENT_ID: "product/variations/",
  PRODUCT_BY_SKU: "product/sku/",
  PRODUCT_BY_QUERY: "/product/search/main",
  CHANGE_PASSWORD: "user/changePassword",
  SUBSCRIBE_PRODUCT: "product/subscribe",
  UNSUBSCRIBE_PRODUCT: "product/unSubscribe",
  DELETE_SUBSCRIPTION: "admin/subscription",
  ADD_SUBSCRIPTION: "admin/subscription/create",
  SUBSCRIBED_PRODUCTS: "product/my/subscribed",
  EXCEL_UNSUBSCRIBED_PRODUCTS: "product/excel/unSubscribe",
  EXCEL_SUBSCRIBED_PRODUCTS: "product/excel/subscribe",
  SUBSCRIPTION_BY_ID: "user/subscription",
  EDIT_SUBSCRIPTION: "admin/subscription",
  SUBSCRIBED_PRODUCTS: "product/my/subscribed",
  EXCEL_UNSUBSCRIBED_PRODUCTS: "product/excel/unSubscribe",
  EXCEL_SUBSCRIBED_PRODUCTS: "product/excel/subscribe",
  MULTIPLE_PRODUCTS_UNSUBSCRIBE: "product/unSubscribe/multiple",
  EXPORT_SUBSCRIBE_PRODUCT: "product/listing/exports",
  EXPORT_SUBSCRIBE_PRODUCT_IN_IMAGE: "product/listing/image",
  EXPORT_AMAZON_TEMPLATE: "product/listing/amazon",
  EDIT_PRODUCTS: "admin/product",
  GET_ORDERS: "admin/orders",
  AUTOPAY: "user/wallet/autoPay",
  AUTORENEWEL: "user/wallet/autoRenewed",
  SETTINGS: "user/viewSetting",
  SELLER_DASHBORAD: "user/userDashBoard",
  UPDATE_SETTINGS: "user/updateSetting",
  GET_ALL_ORDERS: "order",
  GET_ORDER_BY_ID: "order",
  GET_POCESSING_ORDERS: "admin/orders/processed",
  UPLOAD_EXCEL_TRACKING: "admin/orders/tracking",
  GET_ORDER_DETAILS: "order",
  GET_ORDER_DETAILS_UPID: "order/upsaleoOrder",
  UPDATE_ORDERS: "order/",
  UPLOAD_ORDERS: "order/create/bulk",
  PAY_ORDER: "order/payment",
  RAISE_TICKET: "order/ticket",
  CANCEL_ORDER: "order/cancel",
  ABNORMAL_ORDER: "order/abnormal/all",
  GET_ALL_PRODUCTS: "product",
  ADMIN_PRODUCTS: "admin/products",
  CREATE_ORDER: "user/wallet/addmoney",
  VERIFY_ORDER: "user/wallet/payment",
  ADD_CATEGORY: "admin/category/create",
  EXISTING_CATEGORY: "admin/category",
  UPDATE_CATEGORY: "admin/category",
  DELETE_PRODUCT: "admin/product",
  UPLOAD_IMAGE: "admin/product",
  ADD_ORDER: "order/create",
  GOOGLE_LOGIN: "user/googleAuth",
  GET_USERS: "admin/users",
  GET_USERS_DAILY_REPORT: "admin/totalUsersToday",
  GET_USER_BY_ID: "admin/user/",
  UPDATE_USER: "admin/user/",
  ADD_PRODUCTS: "product",
  SYNC_ALL_PRODUCTS: "user/shopify/products",
  SYNC_SELECTED_PRODUCTS: "user/shopify/products",
  CONNECT_TO_SHOPIFY: "user/shopify/connection",
  SHOPIFY_STORE_LIST: "user/myStoreList",
  GET_CHAT: "order/ticket",
  UPDATE_CHAT: "order/ticket",
  TRANSACTION: "admin/transactions",
  TRANSACTION_DETAILS: "order/upsaleoOrder",
  FAILED_ORDERS: "admin/orders/failed",
  UPDATE_PROFILE: "user/update",
  UPLOAD_EXCEL_PRODUCT: "product/upload",
  WALLET_CHART_DATA: "user/wallet/yearlyData/",
  GET_ADMIN_TICKET: "order/tickets/all",
  GET_ORDER_REFUND: "admin/order/",
  AMAZON_CONNECT: "marketplaces/amazon",
  CONTACT: "user/contactUS",
  CATEGORY_CONTENT: "product/categoryContent/",

  //amazon marketplace api endpoints
  GET_SELLER_APP_ID: "amazon/app-data",
  CONNECT_TO_AMAZON: "amazon/connect",
  SAVE_AMAZON_DATA: "amazon/saveData",

  //featured categoies api endpoints
  GET_ALL_FC: "admin/featuredCategory",
  GET_FC_BY_ID: "admin/featuredCategory",

  //cart api endpoints
  ADD_TO_CART:"user/addToCart",
  REMOVE_FROM_CART:'user/removeToCart',
  REMOVE_ALL_CART:'user/removeAllToCart',
  GET_CART:'user/viewCart',
};
