// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section_box {
    margin-top: 3rem;
}

.home_main_head{
    font-size: var(--sans-serif-size-large);
    font-family: var(--sans-serif);
    font-weight: var(--sans-serif-semi-bold);
    text-align: center;
    padding-bottom: 2rem;
    line-height: 1.5rem;
}

.home_section_head{
    font-size: var(--sans-serif-size-large);
    font-family: var(--sans-serif);
    font-weight: var(--sans-serif-semi-bold);
    padding-bottom: 2rem;
    line-height: 1.5rem;
}


.home_small_head {
    font-size: var(--sans-serif-size-medium);
    font-family: "Inter", sans-serif;
    font-weight: 500;
}

`, "",{"version":3,"sources":["webpack://./src/website/views/home/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,uCAAuC;IACvC,8BAA8B;IAC9B,wCAAwC;IACxC,kBAAkB;IAClB,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,uCAAuC;IACvC,8BAA8B;IAC9B,wCAAwC;IACxC,oBAAoB;IACpB,mBAAmB;AACvB;;;AAGA;IACI,wCAAwC;IACxC,gCAAgC;IAChC,gBAAgB;AACpB","sourcesContent":[".section_box {\n    margin-top: 3rem;\n}\n\n.home_main_head{\n    font-size: var(--sans-serif-size-large);\n    font-family: var(--sans-serif);\n    font-weight: var(--sans-serif-semi-bold);\n    text-align: center;\n    padding-bottom: 2rem;\n    line-height: 1.5rem;\n}\n\n.home_section_head{\n    font-size: var(--sans-serif-size-large);\n    font-family: var(--sans-serif);\n    font-weight: var(--sans-serif-semi-bold);\n    padding-bottom: 2rem;\n    line-height: 1.5rem;\n}\n\n\n.home_small_head {\n    font-size: var(--sans-serif-size-medium);\n    font-family: \"Inter\", sans-serif;\n    font-weight: 500;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
