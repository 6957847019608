import React from "react";
import "./style.css";

export default function WebButton({ className, text, onClick, type, style }) {
  return (
    <button className={className} onClick={onClick} type={type} style={style}>
      {text}
    </button>
  );
}
