// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_card_box{
    min-width: calc(25% - 24px);
    height: 150px;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
}


.dashboard_card_circle{
    position: absolute;
    top: 15px;
    left: -35px;
    opacity: 0.5;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
    width: 70px;
    height: 70px;
    border-radius: 40px;
}


.dashboard_card_circle_line_parent{
  
    width: 150px;
    height: 150px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff28;
}




.dashboard_card_circle_line_child {
    width: 40px;
    height: 40px;
    border-radius: 40px;

}

.dashboard_card_content{
    position: absolute;
    bottom: 10px;
}

.dashboard_card_content p {
    font-family: var(--sans-serif);
    font-size: var(--sans-serif-size-medium);
    color: #ffffff;
}

.dashboard_card_content p:nth-child(1){
    font-weight: 600;
    font-size: var(--sans-serif-size-large);
}

@media (max-width:768px) {
   .dashboard_card_box{
        min-width: 300px;
   }
}
`, "",{"version":3,"sources":["webpack://./src/component/views/seller/dashboard/card/style.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,0CAA0C;IAC1C,eAAe;AACnB;;;AAGA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;;AAGA;;IAEI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,2BAA2B;AAC/B;;;;;AAKA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;;AAEvB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,8BAA8B;IAC9B,wCAAwC;IACxC,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,uCAAuC;AAC3C;;AAEA;GACG;QACK,gBAAgB;GACrB;AACH","sourcesContent":[".dashboard_card_box{\n    min-width: calc(25% - 24px);\n    height: 150px;\n    border-radius: 20px;\n    padding: 20px;\n    position: relative;\n    overflow: hidden;\n    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);\n    cursor: pointer;\n}\n\n\n.dashboard_card_circle{\n    position: absolute;\n    top: 15px;\n    left: -35px;\n    opacity: 0.5;\n    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);\n    width: 70px;\n    height: 70px;\n    border-radius: 40px;\n}\n\n\n.dashboard_card_circle_line_parent{\n  \n    width: 150px;\n    height: 150px;\n    border-radius: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #ffffff28;\n}\n\n\n\n\n.dashboard_card_circle_line_child {\n    width: 40px;\n    height: 40px;\n    border-radius: 40px;\n\n}\n\n.dashboard_card_content{\n    position: absolute;\n    bottom: 10px;\n}\n\n.dashboard_card_content p {\n    font-family: var(--sans-serif);\n    font-size: var(--sans-serif-size-medium);\n    color: #ffffff;\n}\n\n.dashboard_card_content p:nth-child(1){\n    font-weight: 600;\n    font-size: var(--sans-serif-size-large);\n}\n\n@media (max-width:768px) {\n   .dashboard_card_box{\n        min-width: 300px;\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
