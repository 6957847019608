import React, { useEffect, useState } from "react";
import { useLoader } from "../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";
import { orderApiService } from "../../../../service/seller/orderApiService";
import { useParams } from "react-router-dom";
import ProductOrderTable from "../../../../component/views/seller/orderDetails/productOrderTable";
import OrderStatus from "../../../../component/views/seller/orderDetails/orderStatus";
import OrderInfo from "../../../../component/views/seller/orderDetails/orderInformation";
import OrderTracking from "../../../../component/views/seller/orderDetails/orderTracking";
import "./style.css";

export default function OrderDetails() {
  const [data, setData] = useState({});
  const [consumerPrice, setConsumerPrice] = useState(0);
  const [upsaleoPrice, setUpsaleoPrice] = useState(0);

  const loader = useLoader();
  const params = useParams();
  const message = useMessage();

  const getOrderById = () => {
    loader.showLoading(true);
    orderApiService
      .getOrderById(params.id)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setData(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  useEffect(() => {
    getOrderById();
  }, []);

  useEffect(() => {
    const cPrice = data?.orderDetails?.reduce(
      (sum, item) => sum + item.consumerPrice,
      0
    );

    const uPrice = data?.orderDetails?.reduce(
      (sum, item) => sum + item.product.sp,
      0
    );

    setConsumerPrice(cPrice);
    setUpsaleoPrice(uPrice);
  }, [data]);

  return (
    <div className="order_detail_bg">
      <OrderStatus
        data={data}
        handleRefresh={getOrderById}
        consumerPrice={consumerPrice}
        upsaleoPrice={upsaleoPrice}
      />

      <OrderInfo data={data} />
      <OrderTracking data={data} />
      <ProductOrderTable data={data?.orderDetails} />
    </div>
  );
}
