import React, { useState, useEffect } from "react";
import axios from "axios";
import "./style.css";
import { useSearchParams } from "react-router-dom";

export default function AddContent() {
  const [searchParams] = useSearchParams();
  const [contentData, setContentData] = useState({
    header: [],
    footer: [],
    sidebarHeader: [],
    sidebarFooter: [],
  });

  const [modalData, setModalData] = useState({
    open: false,
    action: "",
    position: "",
    name: "",
    html_content: "",
    priority: 0,
  });

  useEffect(() => {
    fetchCategoryContent();
  }, []);

  const fetchCategoryContent = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_APIUrl
        }admin/categoryContent/${searchParams.get("id")}`
      );
      setContentData(response.data.data);
    } catch (error) {
      console.log(error);
      console.error("Error fetching category content:", error);
    }
  };

  const handleOpenModal = (
    action,
    position = "",
    contentId,
    name = "",
    html_content = "",
    priority = 0
  ) => {
    console.log(contentId);
    setModalData({
      open: true,
      action,
      position,
      contentId: contentId,
      name,
      html_content,
      priority,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      open: false,
      action: "",
      position: "",
      contentId: "",
      name: "",
      html_content: "",
      priority: 0,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModalData({ ...modalData, [name]: value });
    console.log(modalData);
  };

  const handleSaveContent = async () => {
    const { action, position, name, contentId, html_content, priority } =
      modalData;
    try {
      if (action === "add") {
        await axios.post(
          `${
            process.env.REACT_APP_APIUrl
          }admin/categoryContent/${searchParams.get("id")}/${position}`,
          {
            name,
            html_content,
            priority,
          }
        );
      } else if (action === "edit") {
        await axios.put(
          `${
            process.env.REACT_APP_APIUrl
          }admin/categoryContent/${searchParams.get(
            "id"
          )}/${position}/${contentId}`,
          {
            html_content,
            priority,
          }
        );
      }
      fetchCategoryContent();
      handleCloseModal();
    } catch (error) {
      console.error("Error saving content:", error);
    }
  };

  const handleDeleteContent = async (position, contentId) => {
    try {
      await axios.delete(
        `${
          process.env.REACT_APP_APIUrl
        }admin/categoryContent/${searchParams.get(
          "id"
        )}/${position}/${contentId}`
      );
      fetchCategoryContent();
    } catch (error) {
      console.error("Error deleting content:", error);
    }
  };

  return (
    <div className="container">
      <header className="header">
        <h1>Admin Portal - Add/Delete html content</h1>
      </header>
      <div className="main-content">
        <button
          className="button add-button"
          onClick={() => handleOpenModal("add")}
        >
          Add New Content
        </button>

        {["header", "footer", "sidebarHeader", "sidebarFooter"].map(
          (position) => (
            <div key={position} className="section">
              <h3>{position.charAt(0).toUpperCase() + position.slice(1)}</h3>
              <ul className="content-list">
                {contentData[position].map((item, index) => (
                  <li key={`${position}-${index}`} className="content-item">
                    <div className="content-details">
                      <strong>{item.name}:</strong>{" "}
                      <div
                        dangerouslySetInnerHTML={{ __html: item.html_content }}
                      />
                      <div>Priority: {index + 1}</div>
                    </div>
                    <div className="actions">
                      <button
                        className="button"
                        onClick={() =>
                          handleOpenModal(
                            "edit",
                            position,
                            item._id,
                            item.name,
                            item.html_content,
                            index + 1
                          )
                        }
                      >
                        Edit
                      </button>
                      <button
                        className="button delete-button"
                        onClick={() => handleDeleteContent(position, item._id)}
                      >
                        Delete
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )
        )}

        {modalData.open && (
          <div className="modal">
            <div className="modal-content">
              <h3>
                {modalData.action === "add"
                  ? "Add New Content"
                  : "Edit Content"}
              </h3>
              {modalData.action === "add" && (
                <div className="form-group">
                  <label htmlFor="position">Position</label>
                  <select
                    id="position"
                    name="position"
                    value={modalData.position}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Position</option>
                    <option value="header">Header</option>
                    <option value="footer">Footer</option>
                    <option value="sidebarHeader">Sidebar Header</option>
                    <option value="sidebarFooter">Sidebar Footer</option>
                  </select>
                </div>
              )}
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={modalData.name}
                  onChange={handleInputChange}
                  disabled={modalData.action === "edit"}
                />
              </div>
              <div className="form-group">
                <label htmlFor="html_content">HTML Content</label>
                <textarea
                  id="html_content"
                  name="html_content"
                  value={modalData.html_content}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="priority">Priority</label>
                <input
                  type="number"
                  id="priority"
                  name="priority"
                  value={modalData.priority}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-actions">
                <button
                  className="button save-button"
                  onClick={handleSaveContent}
                >
                  Save
                </button>
                <button
                  className="button cancel-button"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
