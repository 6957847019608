// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload_order_bg{
    border-top: 1px solid var(--outline-color);
    padding: 1rem 2rem;
    width: 100%;
}

.upload_order_bg h2{
    font-family:var(--sans-serif);
    font-weight: var(--sans-serif-medium);
    font-size: var(--sans-serif-size-medium);
    margin-bottom: 1rem;
}

.add_order_head{
    padding-top: 1rem;
}

.add_order_btn_row{
    margin-top: 1rem;
}


/* ///order item css */

.order_item_box{
    width: 100%;
    border: 1px solid var(--outline-color);
    border-radius: 0.5rem;
    padding:1rem;
}

.order_item_box_row{
    display: flex;
    flex-wrap: wrap;
    column-gap: calc((100% - 1050px)/2);
    row-gap: 1rem;
}


.add_more_order_item{
    color: var(--web-primary-color);
    font-family: var(--sans-serif);
    font-weight: var(--sans-serif-medium);
    background-color: rgb(225, 240, 252);
    padding: 0.5rem 1rem;
    border-radius:0.5rem;
    cursor: pointer;
    margin-top: 1rem;
}

.add_more_order_item:focus{
    border: 2px solid var(--web-primary-color);
}

.order_id_row{
    display: flex;
    margin: 1rem 0;
}


@media (max-width:768px) {
    .upload_order_bg{
        padding: 1rem;
    }
    .order_item_box_row div{
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/views/seller/orders/add/style.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,6BAA6B;IAC7B,qCAAqC;IACrC,wCAAwC;IACxC,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;;AAGA,sBAAsB;;AAEtB;IACI,WAAW;IACX,sCAAsC;IACtC,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,mCAAmC;IACnC,aAAa;AACjB;;;AAGA;IACI,+BAA+B;IAC/B,8BAA8B;IAC9B,qCAAqC;IACrC,oCAAoC;IACpC,oBAAoB;IACpB,oBAAoB;IACpB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,aAAa;IACb,cAAc;AAClB;;;AAGA;IACI;QACI,aAAa;IACjB;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".upload_order_bg{\n    border-top: 1px solid var(--outline-color);\n    padding: 1rem 2rem;\n    width: 100%;\n}\n\n.upload_order_bg h2{\n    font-family:var(--sans-serif);\n    font-weight: var(--sans-serif-medium);\n    font-size: var(--sans-serif-size-medium);\n    margin-bottom: 1rem;\n}\n\n.add_order_head{\n    padding-top: 1rem;\n}\n\n.add_order_btn_row{\n    margin-top: 1rem;\n}\n\n\n/* ///order item css */\n\n.order_item_box{\n    width: 100%;\n    border: 1px solid var(--outline-color);\n    border-radius: 0.5rem;\n    padding:1rem;\n}\n\n.order_item_box_row{\n    display: flex;\n    flex-wrap: wrap;\n    column-gap: calc((100% - 1050px)/2);\n    row-gap: 1rem;\n}\n\n\n.add_more_order_item{\n    color: var(--web-primary-color);\n    font-family: var(--sans-serif);\n    font-weight: var(--sans-serif-medium);\n    background-color: rgb(225, 240, 252);\n    padding: 0.5rem 1rem;\n    border-radius:0.5rem;\n    cursor: pointer;\n    margin-top: 1rem;\n}\n\n.add_more_order_item:focus{\n    border: 2px solid var(--web-primary-color);\n}\n\n.order_id_row{\n    display: flex;\n    margin: 1rem 0;\n}\n\n\n@media (max-width:768px) {\n    .upload_order_bg{\n        padding: 1rem;\n    }\n    .order_item_box_row div{\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
