import React, { useEffect, useState } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import WebButton from "../../../component/library/webButton";
import GmailIcon from "../../../assets/images/icons/gmail.png";
import ContactIcon from "../../../assets/images/icons/contact.png";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { SubscriptionApiService } from "../../../service/website/subscriptionApiService";
import "./style.css";

export default function Pricing() {
  const message = useMessage();

  const [subscriptions, setSubscriptions] = useState([]);

  const handleSubscriptions = () => {
    SubscriptionApiService.getSubscription()
      .then((res) => {
        if (res.status.code === 200) {
          setSubscriptions(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        if (err.status.code === 500) {
          message.showToast("Invalid credentials", ToastTypes.ERROR);
        } else {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
        }
      });
  };

  useEffect(() => {
    handleSubscriptions();
  }, []);

  return (
    <div className="pricing_bg">
      <div className="pricing_info">
        <h1>The Right Plan for Your Business</h1>
        <p>
          We have several powerful plans to showcase your business and get
          discovered as a creative entrepreneurs. Everything you need.
        </p>
      </div>

      <div className="plans_row">
        {subscriptions.map((subscription) => {
          return <PlansCard subscription={subscription} />;
        })}
      </div>
    </div>
  );
}

const PlansCard = ({ subscription }) => {
  return (
    <div className="plans_card_box">
      <h1>{subscription?.name}</h1>

      <h3>{subscription.orderDispatch} Order Dispatch</h3>

      {subscription.features.map((feature) => {
        return (
          <p>
            {feature.enabled ? <IoMdCheckmark /> : <RxCross2 />}
            {feature.name}
          </p>
        );
      })}

      <h2>
        {subscription?.timePeriod}
        <span> / &#8377; {subscription?.price} </span>
      </h2>

      <div className="plans_card_btn_row">
        <WebButton
          className="profile_submit_btn"
          text="Choose"
          onClick={null}
          type="button"
        />
      </div>
    </div>
  );
};

export const FooterQuery = () => {
  return (
    <div className="query_contact_section">
      <div className="query_contact_column">
        <h1>FOR QUERIES CONTACT US ON</h1>
      </div>
      <div className="query_contact_column">
        <img src={GmailIcon} />
        <p>hr@boundlesstrends.com</p>
      </div>
      <div className="query_contact_column">
        <img src={ContactIcon} />
        <p>+91 9876543210</p>
      </div>
    </div>
  );
};
