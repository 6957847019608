import React, { useState } from 'react'
import { closeIcon, minusIcon, plusIcon } from '../../../assets/icons'
import WebButton from '../../../../component/library/webButton'
import { fetchCartItems, selectCartItems } from '../../../../redux/slices/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { CartApiService } from '../../../service/cartApiService';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import OrderSteps from '../../../component/views/checkout/orderSteps';
import './style.css'

export default function Cart() {
  const cart=useSelector(selectCartItems);
  return (
    <div>
      <OrderSteps/>
      <div className='cart_bg'>
        <div className='cart_items_column'>
          {cart.length && cart.map((product)=>{
            return <CartItem product={product.product} quantity={product.quantity} />
          })}
        </div>
        <CheckOutBox/>
      </div>
    </div>
  )
}

function CartItem({product, quantity}){
  const [sizeListOpen,setSizeListOpen]=useState(false)

  const [count,setCount]=useState(quantity)
  const dispatch=useDispatch()


  const handleQuantity=(type)=>{
    let productPayload;

    if(type==='plus'){
      productPayload={
        productId:product._id,
        quantity:1
      }
      setCount(count+1)


      CartApiService.AddUpdateCart(JSON.stringify(productPayload)).then((res)=>{
        if(res.status){
          dispatch(fetchCartItems())
        }
      }).catch((error)=>{
        console.log('error',error)
      })



    }else if(type==='minus'){
           
    }   
  }

  const handleRemove=()=>{

   let productPayload={
      productId:product._id,
      quantity:1
    }

    CartApiService.RemoveFromCart(JSON.stringify(productPayload)).then((res)=>{
      if(res.status){
        dispatch(fetchCartItems())
      }
    }).catch((error)=>{
      console.log('error',error)
    })
  }


    return <div className='cart_item_box'>
      <div className='remove_item_from_cart_icon' onClick={()=>handleRemove()}>{closeIcon}</div>

      <img src={product?.imgUrl[0]} className='cart_item_img'/>
      <div className='cart_item_description'>
        <p className='cart_item_title'>{product?.title}</p>

        <div className='cart_item_size_quantity_row'>

          <div className='cart_item_size'>
            <p className='cart_item_selected_size' onMouseOver={()=>setSizeListOpen(true)} onMouseOut={()=>setSizeListOpen(false)}>Size : 28</p>
            {sizeListOpen?<div className='cart_item_size_list_box' onMouseOver={()=>setSizeListOpen(true)} onMouseOut={()=>setSizeListOpen(false)}>
              <p className='cart_item_size_list_item'>26</p>
              <p className='cart_item_size_list_item'>28</p>
              <p className='cart_item_size_list_item'>30</p>
              <p className='cart_item_size_list_item'>32</p>
            </div>:null}
            

            
          </div>

          <div className='cart_item_quantity'>
            <p onClick={()=>handleQuantity('minus')}>{minusIcon}</p>
            <p>Qty : {count}</p>
            <p onClick={()=>handleQuantity('plus')}>{plusIcon}</p>
          </div>
         
        </div>


        <p className='cart_item_price'>₹{product?.sp}</p>
      </div>
    </div>
}

export function CheckOutBox({type,validate}){

  const cart=useSelector(selectCartItems);
  const navigate=useNavigate()

  const date=new Date();

  const initialValue = 0;
  const total = cart.reduce(
    (accumulator, currentValue) => (accumulator + currentValue.quantity*currentValue.product.sp),
    initialValue,
  );
  
  return <div className='checkout_box'>
    {type==='address'?
    cart?.length && cart.map((product,index)=>{
      return <div key={index}>
      <div className='checkout_item_row'>
        <img src={product?.product?.imgUrl[0]} className='checkout_item_img'/>
        <div>
          <p>deliverd by {moment(date).add(product.product.handling_days, 'days').format('Do MMMM')}</p>
          <p> {product.quantity} X ₹{product.product.sp}</p>
        </div>
      </div>
    </div>
    })
    :null}

    <div className='checkout_mrp_row'><p>Total MRP</p><p>₹{total}</p></div>
    <div className='checkout_mrp_row'><p>GST (included)</p><p>₹{Math.round(total*0.18)}</p></div>

    <div className='checkout_amount_row'><p>Total Amount</p><p>₹{total}</p></div>

    <div className='checkout_btn_row'>
      <WebButton className='active_btn_without_shadow' text={type==='address'?'Continue':'Place Order'} onClick={type==='address'?validate:()=>navigate('/checkout/address')} type='button'/>
    </div>
  </div>
}


