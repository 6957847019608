import React from "react";

const FeatureList = ({ features, setFeatures }) => {
  const handleUpdateFeature = (e) => {
    const objIndex = features.findIndex((item) => item.name === e.name);
    if (objIndex !== -1) {
      const updatedFeatures = [...features];
      updatedFeatures[objIndex] = {
        ...updatedFeatures[objIndex],
        enabled: !updatedFeatures[objIndex].enabled,
      };
      setFeatures(updatedFeatures);
    }
  };

  return (
    <div className=" flex flex-col gap-2  ">
      <div className="flex justify-between text-lg font-semibold text-white">
        <h2>Feature Name</h2>
        <p>Enabled</p>
      </div>
      {features &&
        features.map((item) => {
          return (
            <div className="flex items-center justify-between">
              <p>{item.name}</p>
              <input
                type="checkbox"
                checked={item.enabled}
                className="w-5 h-5 cursor-pointer"
                onClick={() => handleUpdateFeature(item)}
              ></input>
            </div>
          );
        })}
    </div>
  );
};

export default FeatureList;
