import React from "react";
import Lottie from "react-lottie";
import notFound from "../../../assets/lotties/notFound.json";

export default function ErrorPage() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notFound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="h-screen flex justify-center items-center flex-col ">
      <Lottie options={defaultOptions} height={200} width={300} />
      <p className="text-xl font-semibold">Page Not Found</p>
    </div>
  );
}
