import React from "react";
import { Carousel } from "react-responsive-carousel";
import BannerOne from "../../../../../assets/images/home/banner_one.jpg";
import BannerTwo from "../../../../../assets/images/home/banner_two.jpg";
import BannerThree from "../../../../../assets/images/home/banner_three.jpg";

import "./style.css";

// carousel styles
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function BannerCarousel() {
  // return <div className="home_banner_img"></div>;

  return (
    <div className="home_banner_carousel">
      <Carousel
        animationHandler="fade"
        infiniteLoop={true}
        showIndicators={true}
        useKeyboardArrows={true}
        autoPlay={true}
        stopOnHover={true}
        swipeable={false}
        dynamicHeight={true}
        emulateTouch={false}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
      >
        <div className="home_banner_carousel_slide">
          <img src={BannerOne} />
        </div>
        <div className="home_banner_carousel_slide">
          <img src={BannerTwo} />
        </div>
        <div className="home_banner_carousel_slide">
          <img src={BannerThree} />
        </div>
      </Carousel>
    </div>
  );
}
