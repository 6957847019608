import React, { useState } from "react";
import WebInput from "../../../component/library/webInput";
import WebTextArea from "../../../component/library/webTextArea";
import WebButton from "../../../component/library/webButton";
import { SlLocationPin } from "react-icons/sl";
import { GoMail } from "react-icons/go";
import { IoCallOutline } from "react-icons/io5";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { BiLogoLinkedin } from "react-icons/bi";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ContactApiService } from "../../../service/website/contactApiService";
import { emailRegex } from "../../../helper/regex";
import "./style.css";

export default function ContactUs() {
  const [data, setData] = useState({
    name: "",
    email: "",
    service: "",
    message: "",
  });

  const [error, setError] = useState({
    name: "",
    email: "",
    service: "",
    message: "",
  });

  const message = useMessage();
  const loader = useLoader();

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const validate = () => {
    let result = true;
    if (!data.name) {
      setError({ ...error, name: "Name is required" });
      result = false;
    } else if (!data.email) {
      setError({ ...error, email: "Email is required" });
      result = false;
    } else if (!data.email.match(emailRegex)) {
      setError({ ...error, email: "Not a valid email address" });
      result = false;
    } else if (!data.service) {
      setError({ ...error, service: "Subject is required" });
      result = false;
    }
    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      loader.showLoading(true);
      ContactApiService.contactToAdmin(JSON.stringify(data))
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            message.showToast(res.status.message, ToastTypes.SUCCESS);
            setData({
              name: "",
              email: "",
              message: "",
              service: "",
            });
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          loader.showLoading(false);
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
        });
    }
  };

  return (
    <div className="web_contact_us_bg">
      <div className="web_contact_us_banner">
        <h1>Contact Us</h1>
      </div>

      <div className="web_contact_us_form_row">
        <form className="web_contact_us_form" onSubmit={handleSubmit}>
          {/* <h3>Contact with us</h3> */}
          <h1>Get In Touch</h1>
          <p>
            Have questions or need assistance? We're here to help! Reach out to
            our dedicated support team for any inquiries or concerns
          </p>

          <WebInput
            parentClassName="input_parent"
            className="input_auth"
            id="nameInput"
            type="text"
            placeholder=""
            name="name"
            label="Your name *"
            value={data.name}
            error={error.name}
            onChange={handleOnChange}
            inputStyle={{ borderRadius: "5px" }}
            labelStyle={{ padding: "5px", lineHeight: "30px" }}
          />

          <WebInput
            parentClassName="input_parent"
            className="input_auth"
            id="nameInput"
            type="email"
            placeholder=""
            name="email"
            label="Your Email *"
            value={data.email}
            error={error.email}
            onChange={handleOnChange}
            inputStyle={{ borderRadius: "5px" }}
            labelStyle={{ padding: "5px", lineHeight: "30px" }}
          />

          <WebInput
            parentClassName="input_parent"
            className="input_auth"
            id="nameInput"
            type="text"
            placeholder=""
            name="service"
            label="Subject *"
            value={data.service}
            error={error.service}
            onChange={handleOnChange}
            inputStyle={{ borderRadius: "5px" }}
            labelStyle={{ padding: "5px", lineHeight: "30px" }}
          />

          <WebTextArea
            className="auth_textarea"
            id="nameInput"
            type="text"
            name="message"
            label="Your Message"
            value={data.message}
            error={error.message}
            onChange={handleOnChange}
            labelStyle={{ padding: "5px", lineHeight: "30px" }}
          />

          <div className="web_contact_us_btn_row">
            <WebButton
              className="active_btn_without_shadow"
              text="Send Message"
            />
          </div>
        </form>
        <div className="web_contact_us_info">
          <h1>Contact Information</h1>

          <a className="address_row" href="tel:+8130596699">
            <IoCallOutline className="footer_icon l" />
            <p>+91 8130596699</p>
          </a>
          <a
            className="address_row"
            href="mailto:contact@upsaleo.com"
            target="_blank"
          >
            <GoMail className="footer_icon" />
            <p>contact@upsaleo.com</p>
          </a>
          <a
            className="address_row"
            href="https://www.google.com/maps/place/Sector+6,+Noida,+Uttar+Pradesh/@28.5928788,77.3135186,16z/data=!3m1!4b1!4m6!3m5!1s0x390ce4f72d5495a1:0xfd217b0c806989de!8m2!3d28.593565!4d77.3188016!16s%2Fg%2F1tdw1vt7?entry=ttu"
            target="_blank"
          >
            <SlLocationPin className="footer_icon" />
            <p>Sector 6, Gautam Buddha Nagar Noida, Uttar Pradesh</p>
          </a>

          <div className="web_contact_social_icons_row">
            <div
              className="social_icon"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/people/Upsaleo/61558625604511/",
                  "_blank"
                )
              }
            >
              <FaFacebookF />
            </div>
            <div
              className="social_icon"
              onClick={() =>
                window.open(
                  "https://www.instagram.com/upsaleoofficial/",
                  "_blank"
                )
              }
            >
              <FaInstagram />
            </div>
            <div
              className="social_icon"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/upsaleo",
                  "_blank"
                )
              }
            >
              <BiLogoLinkedin />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
