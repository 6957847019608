import React, { useEffect, useState } from "react";
import {
  adminSidebarArray,
  sellerSidebarArray,
  sidebarMenuHeight,
} from "../../../helper/utils";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addItems, sidebarData } from "../../../redux/slices/sidebarSlice";
import { getAuthLocalStorage } from "../../../storage/localStorage";
import { selectIsNightMode } from "../../../redux/slices/modeSlice";
import IconWrapper from "../../library/iconWrapper";
import Logo from "../../../assets/images/common/logo.png";
import LogoIcon from "../../../assets/images/common/logo_icon.png";
import { Link } from "react-router-dom";
import { closeIcon } from "../../../website/assets/icons";
import "./style.css";

export default function Sidebar({ collapse,menuDisplay,setMenuDisplay }) {
  const dispatch = useDispatch();
  const sidebarArray = useSelector(sidebarData);
  const isNightMode = useSelector(selectIsNightMode);
  const [userType, setUserType] = useState(getAuthLocalStorage().userType);

  const sidebar = useSelector(sidebarData);

  const handleUpdateSidebar = () => {
    if (userType === "seller") {
      dispatch(addItems(sellerSidebarArray));
    } else if (userType === "admin") {
      dispatch(addItems(adminSidebarArray));
    }
  };

  useEffect(() => {
    if (userType && !sidebar) {
      handleUpdateSidebar();
    }
  }, [userType]);


  const handleMobileMenu=()=>{
    setMenuDisplay(!menuDisplay)
  }

  const handleWidth=()=>{
    if(menuDisplay){
      return '100%'
    }else if (!menuDisplay && window.innerWidth<768){
      return '0'
    }else{
      return '232px'
    }
  }

  return (
    <div
      className={`sidebar_bg ${
        isNightMode ? "sidebar_bg_dark" : "sidebar_bg_light"
      }`}
      style={{ width: collapse ? '85px': handleWidth()}}
    >
      {menuDisplay?<div className="sidebar_small_menu_icon navbar_icon" onClick={()=>handleMobileMenu()}>
        {closeIcon}
      </div>:null}
      

      <div className="sidebar_menu_items">
        <Link to="/">
          {collapse ? (
            <img src={LogoIcon} className="sidebar_logo" />
          ) : (
            <img src={Logo} className="sidebar_logo" />
          )}
        </Link>

        {sidebarArray &&
          sidebarArray?.map((menu, index) => {
            return (
              <div key={index}>
                <SidebarMenu menu={menu} collapse={collapse} setMenuDisplay={setMenuDisplay} />
              </div>
            );
          })}
      </div>
    </div>
  );
}

const SidebarMenu = ({ menu, collapse, setMenuDisplay }) => {
  const location = useLocation();
  const isNightMode = useSelector(selectIsNightMode);

  const [subMenu, setSubMenu] = useState(false);


  const handleSubMenus = () => {
    setSubMenu(!subMenu);
  };


  useEffect(()=>{
    setMenuDisplay(false)
  },[location.pathname])

  return (
    <>
      <div
        className={`sidebar_options_col ${
          collapse ? "sidebar_options_col_icon" : ""
        }  ${
          location.pathname === menu.link
            ? isNightMode
              ? "option_active_dark"
              : "option_active_light"
            : ""
        }`}
        onClick={menu?.items?.length ? handleSubMenus : null}
      >
        <Link to={menu.link}>
          <IconWrapper
            name={menu?.icon}
            className={`sidebar_item ${
              isNightMode
                ? location.pathname === menu.link
                  ? "sidebar_item_light"
                  : "sidebar_item_dark"
                : location.pathname === menu.link
                ? "sidebar_item_dark"
                : "sidebar_item_light"
            }`}
          />
        </Link>

        {!collapse ? (
          <Link
            to={menu.link}
            className={`sidebar_item ${
              isNightMode
                ? location.pathname === menu.link
                  ? "sidebar_item_light"
                  : "sidebar_item_dark"
                : location.pathname === menu.link
                ? "sidebar_item_dark"
                : "sidebar_item_light"
            }`}
          >
            <p> {menu.name}</p>
          </Link>
        ) : null}
      </div>

      {!collapse && menu?.items?.length ? (
        <div
          className={`overflow-hidden ease-in duration-200 ${
            subMenu ? sidebarMenuHeight(menu.items.length) : "h-0"
          }`}
        >
          {menu?.items?.map((item, index) => {
            return (
              <Link
                key={index}
                to={item.link}
                className={
                  "text-stone-600 cursor-pointer text-base pl-14 flex items-center h-7"
                }

              >
                {item.name}
              </Link>
            );
          })}
        </div>
      ) : null}
    </>
  );
};
