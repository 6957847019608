import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import WebInput from "../../../../library/webInput";
import WebButton from "../../../../library/webButton";
import { categoryApiService } from "../../../../../service/admin/categoryApiService";
import { useLoader } from "../../../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../../../base/context/MessageProvider";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  px: 4,
  py:2,
};

const EditCategory = ({ open, handleClose, category , categories , ExistingCategory }) => {
  console.log("qt", categories)
  console.log("123456" , category)
    const loader = useLoader();
    const message = useMessage();
    const [data, setData] = useState({
        c_name: "",
        node_id:"",
    });
 

    const handleOnChange = (e) => {
        setData({...data, [e.target.name] : e.target.value})
    }
    
    const handleEditOrder = () => {
        loader.showLoading(true);
        const payload = {
            c_name: category+"/"+data.c_name,
            node_id: data.node_id,
      }
      console.log("1234" , payload)
        categoryApiService.addNewCategory(JSON.stringify(payload))
            .then((res) => {
                if (res.status.code === 200) {
                    message.showToast(res.status.message, ToastTypes.SUCCESS);         
                  loader.showLoading(false);
                  ExistingCategory();
                  handleClose();
                } else {
                    message.showToast(res.error.message, ToastTypes.ERROR);
                }
            }).catch((err) => {
                loader.showLoading(false);
                 message.showToast("Server error. Try again later", ToastTypes.ERROR);
        })
    }
    
    return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="h-auto w-60 ">
          <h1 className="text-xl font-bold text-center">Edit Sub-Category</h1>
          <div>
            <WebInput
              label="Category Name"
              name="c_name"
              value={category}
            //   error={error.recipientName}
            //   onChange={handleOnChange}
            />
            <WebInput
              label="Sub-category Name"
              type="text"
              name="c_name"
              value={data.c_name}
              onChange={handleOnChange}
             />
                        
              <WebInput
              label="Node ID"
              type="number"
              name="node_id"
              value={data.node_id}
            //   error={error.shippingPhoneNumber}
              onChange={handleOnChange}
            />
          
            <WebButton
              text="Submit"
              className="bg-blue-500 w-36 h-10 m-auto text-white rounded-md "
              onClick={handleEditOrder}
            />
          </div>
        </div>
      </Box>
    </Modal>
    )
}

export default EditCategory;