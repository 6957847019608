import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: [],
  reducers: {
    userDetails: (state, action) => {
      state.push(action.payload);
    },
  },
});

export const { userDetails } = userSlice.actions;

export const seller = (state) => state.user;

export default userSlice.reducer;
