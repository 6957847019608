import { NetworkConfiguration } from "../../network/networkConfriguation";
import { callAPI } from "../../network/networkConnection";

const getSettings = () => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.SETTINGS,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const updateSettings = (settingPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.UPDATE_SETTINGS,
      "PUT",
      {
        "Content-Type": "application/json",
      },
      settingPayload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getDashboard = () => {
  return new Promise((resolve, reject) => {
    callAPI(
      process.env.REACT_APP_APIUrl + NetworkConfiguration.SELLER_DASHBORAD,
      "GET",
      {
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const SettingApiService = {
  getSettings,
  updateSettings,
  getDashboard,
};
