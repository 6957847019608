import React, { useState } from "react";
import Modal from "react-modal";
import RefundForm from "../../../../views/admin/ticketManagement/RefundForm/index";
// Custom styles for the modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const h2Style = {
  textAlign: "center",
};
Modal.setAppElement("#root"); // Bind modal to your appElement (root div)

const RefundModel = ({ isOpen, onRequestClose, orderId }) => {
  console.log("----------------", orderId);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Refund Form Modal"
    >
      <h2 style={h2Style}>Refund Form</h2>
      <RefundForm orderId={orderId} />
    </Modal>
  );
};

export default RefundModel;
