// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs_row{
    display: flex;
    align-items: center;
    margin-top: -1rem;
}

.breadcrumbs_row p{
    font-size: var(--sans-serif-size-small);
    font-family: var(--sans-serif);
    font-weight: var(--sans-serif-medium);
    text-transform: capitalize;
}

@media (max-width:768px) {
    .breadcrumbs_row{
        width: 100%;
        flex-wrap: wrap;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/website/component/common/breadCrumbs/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,uCAAuC;IACvC,8BAA8B;IAC9B,qCAAqC;IACrC,0BAA0B;AAC9B;;AAEA;IACI;QACI,WAAW;QACX,eAAe;IACnB;;AAEJ","sourcesContent":[".breadcrumbs_row{\n    display: flex;\n    align-items: center;\n    margin-top: -1rem;\n}\n\n.breadcrumbs_row p{\n    font-size: var(--sans-serif-size-small);\n    font-family: var(--sans-serif);\n    font-weight: var(--sans-serif-medium);\n    text-transform: capitalize;\n}\n\n@media (max-width:768px) {\n    .breadcrumbs_row{\n        width: 100%;\n        flex-wrap: wrap;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
