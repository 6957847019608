// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination_row{
    display: flex;
    width: 100%;
    justify-content: center;
}

.pagination_box{
    background-color: #F7F9FF;
    height: 40px;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 50px;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding:0 15px;
}

.page{
    font-size: 1rem;
    color: #656565;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
}

.active_page{
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    background-color: #3DD0E5;
    text-align: center;
    cursor: pointer;
}

.next_prev_icon{
    font-size: 1.5rem;
    color: rgba(169, 169, 169, 0.8);
    cursor: pointer;
}

.dots{
    font-size: 1.5rem;
    color: rgba(169, 169, 169, 0.8);
}

.dots_row{
    display: flex;
    margin: 0 -10px 0 -10px;
    /* background-color: #fdd4d4; */
}


@media (max-width:480px) {
    .pagination_box{
        max-width:320px;
        gap: 0.5rem;
        padding:0 10px;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/component/library/pagination/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,uBAAkB;IAAlB,kBAAkB;IAClB,mBAAmB;IACnB,uCAAuC;IACvC,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,+BAA+B;IAC/B,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,+BAA+B;AACnC;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,+BAA+B;AACnC;;;AAGA;IACI;QACI,eAAe;QACf,WAAW;QACX,cAAc;IAClB;;AAEJ","sourcesContent":[".pagination_row{\n    display: flex;\n    width: 100%;\n    justify-content: center;\n}\n\n.pagination_box{\n    background-color: #F7F9FF;\n    height: 40px;\n    width: fit-content;\n    border-radius: 50px;\n    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);\n    display: flex;\n    align-items: center;\n    gap: 1.5rem;\n    padding:0 15px;\n}\n\n.page{\n    font-size: 1rem;\n    color: #656565;\n    width: 25px;\n    height: 25px;\n    border-radius: 15px;\n    text-align: center;\n    cursor: pointer;\n}\n\n.active_page{\n    font-size: 1rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #ffffff;\n    width: 25px;\n    height: 25px;\n    border-radius: 15px;\n    background-color: #3DD0E5;\n    text-align: center;\n    cursor: pointer;\n}\n\n.next_prev_icon{\n    font-size: 1.5rem;\n    color: rgba(169, 169, 169, 0.8);\n    cursor: pointer;\n}\n\n.dots{\n    font-size: 1.5rem;\n    color: rgba(169, 169, 169, 0.8);\n}\n\n.dots_row{\n    display: flex;\n    margin: 0 -10px 0 -10px;\n    /* background-color: #fdd4d4; */\n}\n\n\n@media (max-width:480px) {\n    .pagination_box{\n        max-width:320px;\n        gap: 0.5rem;\n        padding:0 10px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
