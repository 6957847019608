import React, { useEffect, useState } from "react";
import WebButton from "../../../component/library/webButton";
import WebDropdown from "../../../component/library/webDropdown";
import { orderApiService } from "../../../service/admin/ordersApiService";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import DatePicker from "../../../component/library/datePicker";
import { exportToCSV } from "react-easy-export/dist/react-easy-export";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../component/library/pagination/index";
import RefundModel from "../../../component/views/admin/RefundModel";
import moment from "moment";
const TicketManagement = () => {
  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [pages, setPages] = useState({
    currentPage: "",
    totalPages: "",
    totalItems: "",
  });
  const [ticketData, setTicketData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [checkHeaderClick, setCheckHeaderClick] = useState(false);
  const handleAllChecked = (e) => {
    if (e.target.checked) {
      let allSelectedRows = ticketData.map((item) => item);
      setSelectedRows(allSelectedRows);
      setCheckHeaderClick(true);
    } else {
      setSelectedRows([]);
      setCheckHeaderClick(false);
    }
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const openModal = (orderId) => {
    setModalIsOpen(true);
    setSelectedOrderId(orderId);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const options = [
    {
      name: "--Select Ticket Status--",
      value: "",
    },
    {
      name: "Open",
      value: "open",
    },
    {
      name: "Close",
      value: "close",
    },
  ];

  const handleExportCsv = () => {
    let array = [];
    // transactions?.map((item, index) => {
    //   let obj = {
    //     "S.No.": index + 1,
    //     "Full Name": item?.userId?.firstName + " " + item?.userId?.lastName,
    //     Email: item?.userId?.email,
    //     Type: item?.type,
    //     Amount: item?.amount,
    //     Balance: item?.balance,
    //     Description: item?.description,
    //   };
    //   array.push(obj);
    // });
    exportToCSV(array);
  };
  const handleDropdown = (value) => {
    setStatus(value);
  };
  useEffect(() => {
    fetchTicketList();
  }, [page]);
  const handleChange = (item) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some((row) => row._id === item._id);
      if (isSelected) {
        return prevSelectedRows.filter((row) => row._id !== item._id);
      } else {
        return [...prevSelectedRows, item];
      }
    });
  };
  const query = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;

  const fetchTicketList = () => {
    loader.showLoading(true);
    orderApiService
      .getAllTicket(query)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setTicketData(res.data.ticket);
          setPages({
            ...pages,
            currentPage: res.data.currentPage,
            totalPages: res.data.totalPages,
            totalItems: res.data.totalTicket,
          });
          setPage(res.data.currentPage);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  useEffect(() => {
    if (startDate && endDate) {
      fetchTicketList();
      //   handleCalculate();
    } else if (!startDate && !endDate) {
      fetchTicketList();
      //   handleCalculate();
    }
  }, [startDate, endDate]);
  const divStyle = {
    paddingBottom: "20px",
  };
  return (
    <div style={divStyle} className="container">
      <header className="header">
        <h1>Admin Portal -Ticket Management</h1>
      </header>
      <div className="main-content">
        <div className="flex gap-6">
          <DatePicker setStartDate={setStartDate} setEndDate={setEndDate} />
          <div className=" flex items-center gap-2">
            <label className="">Status</label>
            <WebDropdown
              options={options}
              selected={status}
              value={status}
              onChange={(e) => handleDropdown(e.target.value)}
            />
          </div>
          <div className="flex justify-end mr-20 button_fit">
            <WebButton
              onClick={handleExportCsv}
              text="Export"
              className="bg-blue-500 px-2 py-1 rounded-sm text-white"
            />
          </div>
        </div>

        <div>
          <table className="w-full mt-5">
            <thead className="bg-gray-300">
              <th>
                <input
                  type="checkbox"
                  className="checkbox_size"
                  onChange={handleAllChecked}
                />
              </th>
              <td>S.No.</td>
              <td>Upsaleo Order Id</td>
              <td>Seller Name</td>
              <td>Order Date</td>
              <td>Order Status</td>
              <td>Ticket Status</td>
              <td>State</td>
              <td>Ticket Created Date</td>
              <td>Message</td>
              <td>Refund</td>
            </thead>
            <tbody>
              {ticketData.map((item, index) => {
                const isSelected = selectedRows.some(
                  (row) => row._id === item._id
                );
                return (
                  <tr className="odd:bg-white cursor-pointer even:bg-slate-100">
                    <td>
                      <input
                        type="checkbox"
                        className="checkbox_size"
                        onChange={() => handleChange(item)}
                        checked={isSelected}
                      />
                    </td>
                    <td>{(pages.currentPage - 1) * limit + index + 1}</td>
                    <td
                      onClick={() => {
                        navigate(
                          `/admin/order-management/details/${item?.orderId?._id}`
                        );
                      }}
                    >
                      {item?.orderId?._id}
                    </td>
                    <td
                      onClick={() => {
                        navigate(
                          `/admin/order-management/details/${item?.orderId?._id}`
                        );
                      }}
                    >
                      {item?.orderId?.recipientName}
                    </td>
                    <td
                      onClick={() => {
                        navigate(
                          `/admin/order-management/details/${item?.orderId?._id}`
                        );
                      }}
                    >
                      {moment(item?.orderId?.orderDate).format(
                        "DD/MM/YYYY , LT"
                      )}
                    </td>
                    <td
                      onClick={() => {
                        navigate(
                          `/admin/order-management/details/${item?.orderId?._id}`
                        );
                      }}
                    >
                      {item?.orderId?.status}
                    </td>
                    <td
                      onClick={() => {
                        navigate(
                          `/admin/order-management/details/${item?.orderId?._id}`
                        );
                      }}
                    >
                      {item?.status}
                    </td>

                    <td>{item?.orderId?.shippingState}</td>
                    <td
                      onClick={() => {
                        navigate(
                          `/admin/order-management/details/${item?.orderId?._id}`
                        );
                      }}
                    >
                      {moment(item?.updatedAt).format("DD/MM/YYYY , LT")}
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          navigate(
                            `/admin/ticket-management/ticket/${item?._id}`
                          )
                        }
                      >
                        View
                      </button>
                    </td>
                    <td>
                      {item.status === "closed" ? (
                        <div>
                          <button
                            onClick={() => {
                              navigate(
                                `/admin/ticket-management/refund/${item?.orderId?._id}`
                              );
                            }}
                          >
                            View Refund Details
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={() => openModal(item?.orderId?._id)}
                            disabled={item.status === "closed"}
                          >
                            Refund
                          </button>

                          <RefundModel
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            orderId={selectedOrderId}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {ticketData.length ? (
          <Pagination pages={pages} page={page} setPage={setPage} />
        ) : null}
      </div>
    </div>
  );
};

export default TicketManagement;
