import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './style.css'

export default function OrderSteps() {

    const location=useLocation();
    const navigate=useNavigate()

    console.log('location',location.pathname)


    const handlePage=(path)=>{
        if(location.pathname!=='/checkout/cart'){
            navigate(path)
        }
    }


  return (
    <div className='order_steps'>
        <p className={location.pathname==='/checkout/cart'?'active_order_step':''} onClick={()=>handlePage('/checkout/cart')}>Bag</p>
        <div className='order_step_line'></div>
        <p className={location.pathname==='/checkout/address'?'active_order_step':''}>Address</p>
        <div className='order_step_line'></div>
        <p>Payment</p>
    </div>
  )
}
