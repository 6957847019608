import React, { useEffect, useState } from "react";
import { useLoader } from "../../../../base/context/LoaderProvider";
import { SubscriptionApiService } from "../../../../service/website/subscriptionApiService";
import { RiEdit2Fill } from "react-icons/ri";
import { AiFillDelete } from "react-icons/ai";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";
import { useNavigate } from "react-router-dom";
import "./style.css";
import DeletePopUp from "../../../../component/library/deletePopUp";

const SubscriptionList = () => {
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState();
  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();

  const fetchSubscriptionData = () => {
    loader.showLoading(true);
    SubscriptionApiService.getSubscription()
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setSubscriptionData(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  const handleDelete = (id) => {
    setOpen(true);
    setId(id);
  };

  const handleDeleteClose = () => {
    setOpen(false);
  };

  const handleDeleteSubscription = () => {
    loader.showLoading(true);
    SubscriptionApiService.deleteSubscription(id)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          fetchSubscriptionData();

          setOpen(false);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  const handleSubscriptionEdit = (id) => {
    navigate(`/admin/subscription/edit?id=${id}`);
  };

  return (
    <div>
      {/* <h1>Subscription List</h1> */}
      <table style={{ width: "100%", backgroundColor: "white" }}>
        <thead className="bg-gray-300">
          <th>S.No.</th>
          <th>Name</th>
          <th>Time Period</th>
          <th>Price</th>
          <th>Order Dispatch</th>
          <th>Description</th>
          <th>Status</th>
          <th>Action</th>
        </thead>
        <tbody>
          {subscriptionData?.map((item, index) => {
            return (
              <tr className="text-center  even:bg-slate-200">
                <td>{index + 1}</td>
                <td>{item?.name}</td>
                <td>{item?.timePeriod}</td>
                <td>{item?.price}</td>
                <td>{item?.orderDispatch}</td>
                <td>{item?.description}</td>
                <td>{item?.status}</td>
                <td>
                  <div className="flex justify-center items-center gap-2">
                    <RiEdit2Fill
                      className="text-blue-600 cursor-pointer"
                      onClick={() => handleSubscriptionEdit(item?._id)}
                    />
                    <AiFillDelete
                      className="text-red-600 cursor-pointer"
                      onClick={() => handleDelete(item?._id)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <DeletePopUp
        open={open}
        handleClose={handleDeleteClose}
        handleCancel={handleDeleteClose}
        submitText="Yes"
        cancelText="No"
        handleSubmit={handleDeleteSubscription}
        text="Are you sure you want to delete this Subscription?"
        heading="Delete Subscription"
      />
    </div>
  );
};

export default SubscriptionList;
