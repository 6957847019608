// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile_bg{
    padding: 0 2rem;
}
.user_profile_head{
    font-size: 1.3rem;
}

.user_profile_head_dark{
    color: var(--dark-text-color);
}

.user_profile_head_light {
    color: var(--light-tertiary-color);
}

.user_profile_input_row{
    display: flex;
    align-items: center;
    margin: 1rem 0;
    border-bottom: 1px solid var(--outline-color);
}

.user_active_row{
    border-bottom: 2px solid var(--outline-color);
}

.user_inactive_row{
    border-bottom: 1px solid var(--outline-color);
}

.user_profile_input_row_dark{
    color: var(--dark-text-color);
    font-weight: lighter !important;
}

.user_profile_input_row_light {
    color: var(--light-text-color);
}

.user_profile_icon{
    height: 40px;
    display: flex;
    align-self: center;
    font-size: 1.2rem;
}
.user_profile_icon_dark{
    color: var(--dark-tertiary-color);
}

.user_profile_icon_light {
    color: var(--light-tertiary-color);
}

.edit_icon{
    cursor: pointer;
}

.profile_error{
    height: 20px;
    display: flex;
    align-items: center;
    color: red;
    padding: 0 20px;
    font-size: 1rem;
    gap: 10px;
    margin-top: -10px;
    margin-left: -10px;
}

.profile_error_icon{
    display: inline;
}`, "",{"version":3,"sources":["webpack://./src/views/seller/profile/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,6CAA6C;AACjD;;AAEA;IACI,6CAA6C;AACjD;;AAEA;IACI,6CAA6C;AACjD;;AAEA;IACI,6BAA6B;IAC7B,+BAA+B;AACnC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,iCAAiC;AACrC;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,eAAe;IACf,eAAe;IACf,SAAS;IACT,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".profile_bg{\n    padding: 0 2rem;\n}\n.user_profile_head{\n    font-size: 1.3rem;\n}\n\n.user_profile_head_dark{\n    color: var(--dark-text-color);\n}\n\n.user_profile_head_light {\n    color: var(--light-tertiary-color);\n}\n\n.user_profile_input_row{\n    display: flex;\n    align-items: center;\n    margin: 1rem 0;\n    border-bottom: 1px solid var(--outline-color);\n}\n\n.user_active_row{\n    border-bottom: 2px solid var(--outline-color);\n}\n\n.user_inactive_row{\n    border-bottom: 1px solid var(--outline-color);\n}\n\n.user_profile_input_row_dark{\n    color: var(--dark-text-color);\n    font-weight: lighter !important;\n}\n\n.user_profile_input_row_light {\n    color: var(--light-text-color);\n}\n\n.user_profile_icon{\n    height: 40px;\n    display: flex;\n    align-self: center;\n    font-size: 1.2rem;\n}\n.user_profile_icon_dark{\n    color: var(--dark-tertiary-color);\n}\n\n.user_profile_icon_light {\n    color: var(--light-tertiary-color);\n}\n\n.edit_icon{\n    cursor: pointer;\n}\n\n.profile_error{\n    height: 20px;\n    display: flex;\n    align-items: center;\n    color: red;\n    padding: 0 20px;\n    font-size: 1rem;\n    gap: 10px;\n    margin-top: -10px;\n    margin-left: -10px;\n}\n\n.profile_error_icon{\n    display: inline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
