import React, { useEffect, useRef, useState } from "react";
import User from "../../../assets/images/common/user.png";
import { IoMdAttach } from "react-icons/io";
import { ticketApiService } from "../../../service/admin/ticketApiService";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { useParams } from "react-router-dom";
import { BsFillSendFill } from "react-icons/bs";
import { LuCheckCheck } from "react-icons/lu";
import moment from "moment";
import { IoIosClose } from "react-icons/io";
import "./style.css";

const Ticket = () => {
  const [ticketData, setTicketData] = useState([]);
  const [messageSent, setMessageSent] = useState("");
  const [file, setFile] = useState("");

  const [files, setFiles] = useState([]);
  const loader = useLoader();
  const message = useMessage();
  const { id } = useParams();

  useEffect(() => {
    fetchChat();
  }, []);

  const fetchChat = () => {
    loader.showLoading(true);
    ticketApiService
      .getTicket(id)
      .then((res) => {
        if (res.status.code === 200) {
          loader.showLoading(false);
          setTicketData(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  const handleSend = (e) => {
    e.preventDefault();
    if (messageSent || file) {
      const payload = new FormData();
      payload.append("message", messageSent);
      files.map((file) => {
        payload.append("images", file);
      });

      ticketApiService
        .updateTicket(id, payload)
        .then((res) => {
          if (res.status.code === 200) {
            loader.showLoading(false);
            setMessageSent("");
            setFile("");
            setFiles([]);
            fetchChat();
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    }
  };

  const handleAttachment = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    const check = files.some((item) => item.name === file.name);
    if (files.length === 0 && file) {
      setFiles([file]);
    } else if (!check && files.length && file) {
      setFiles([...files, file]);
    }
  }, [file]);

  const handleChange = (e) => {
    setMessageSent(e.target.value);
  };

  const chatBox = useRef(null);

  useEffect(() => {
    if (chatBox.current) {
      chatBox.current.scrollTop = chatBox.current.scrollHeight;
    }
  }, [chatBox, ticketData, files]);

  const msgReadUnRead = (msg) => {
    return msg.userId._id === ticketData.currentUser ? (
      <LuCheckCheck
        color={
          (msg.userId.role === "seller" && msg.seenByAdmin) ||
          (msg.userId.role === "admin" && msg.seenByUser)
            ? "#1bb9c4"
            : ""
        }
      />
    ) : null;
  };

  const handleRemoveImg = (fileName) => {
    const filteredImages = files.filter((file) => file.name !== fileName);
    setFiles(filteredImages);
  };

  return (
    <div className="ticket_chat_bg">
      <div className="ticket_chat_box">
        <div className="ticket_header">
          <img src={User} alt="Avatar" className="ticket_user_img_icon" />
          <p className="ticket_user_name">user Name</p>
        </div>

        <div className="ticket_heading">{ticketData.issue}</div>

        <div className="ticket_chats_box" ref={chatBox}>
          {ticketData?.chat?.map((msg, index) => {
            return (
              <>
                {msg.message ? (
                  <div
                    key={index}
                    className={`${
                      msg.userId._id === ticketData.currentUser
                        ? "ticket_sent_msg"
                        : "ticket_sent_received"
                    }`}
                  >
                    <p>
                      {msg.message}
                      <span className="msg_send_time_blank_box"></span>
                      <span className="msg_send_time">
                        {moment(msg.timestamp).format("h:mm a")}
                        {msgReadUnRead(msg)}
                      </span>
                    </p>
                  </div>
                ) : null}

                {msg.attachments.length >= 1 ? (
                  <div
                    key={index}
                    className={`${
                      msg.userId._id === ticketData.currentUser
                        ? "ticket_sent_img"
                        : "ticket_received_img"
                    }`}
                  >
                    {msg?.attachments?.map((img) => {
                      return (
                        <div className="ticket_image_wrapper">
                          <img
                            src={img}
                            alt="User"
                            className="ticket_chats_image"
                          />
                          <p className="ticket_chats_image_time">
                            {moment(msg.timestamp).format("h:mm a")}
                            {msgReadUnRead(msg)}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </>
            );
          })}

          <div className="ticket_selected_files_row">
            {files.map((file, index) => {
              return (
                <div key={index} className="ticket_selected_file">
                  <p>{file.name}</p>
                  <IoIosClose
                    className="ticket_selected_file_close"
                    onClick={() => handleRemoveImg(file.name)}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <form className="ticket_input_textfiled" onSubmit={handleSend}>
          <label for="fileInput">
            <IoMdAttach
              className="ticket_attachment_icon"
              color="#A6ADB5"
              size={20}
            />
          </label>
          <input
            id="fileInput"
            type="file"
            multiple
            onChange={handleAttachment}
            className="ticket_img_input"
          />

          <input type="text" onChange={handleChange} value={messageSent} />

          <BsFillSendFill
            color="#1976D2"
            size={20}
            className="ticket_send_btn"
            onClick={handleSend}
          />
        </form>
      </div>
    </div>
  );
};

export default Ticket;
