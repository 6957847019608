// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address_head{
    font-size: var(--sans-serif-size-regular);
    font-family: var(--sans-serif);
    font-weight: var(--sans-serif-medium);
    margin-bottom: 1rem;
}

.address_form{
    width: 65%;
    height: -moz-fit-content;
    height: fit-content;
    background-color: var(--web-white-color);
    border: 1px solid var(--web-grey-color);
    padding: 1rem;
    border-radius: 0.2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
}

.address_form div{
    width: 45%;
}


.address_form .input_wrapper{
    width: 100% !important;
}

.address_form_head{
    font-size: var(--sans-serif-size-extra-small);
    font-family: var(--sans-serif);
    font-weight: var(--sans-serif-medium);
    width: 100%;

}

.address_row{
    display:flex;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/website/views/checkout/address/style.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,8BAA8B;IAC9B,qCAAqC;IACrC,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,wBAAmB;IAAnB,mBAAmB;IACnB,wCAAwC;IACxC,uCAAuC;IACvC,aAAa;IACb,qBAAqB;IACrB,aAAa;IACb,eAAe;IACf,8BAA8B;IAC9B,SAAS;AACb;;AAEA;IACI,UAAU;AACd;;;AAGA;IACI,sBAAsB;AAC1B;;AAEA;IACI,6CAA6C;IAC7C,8BAA8B;IAC9B,qCAAqC;IACrC,WAAW;;AAEf;;AAEA;IACI,YAAY;IACZ,8BAA8B;AAClC","sourcesContent":[".address_head{\n    font-size: var(--sans-serif-size-regular);\n    font-family: var(--sans-serif);\n    font-weight: var(--sans-serif-medium);\n    margin-bottom: 1rem;\n}\n\n.address_form{\n    width: 65%;\n    height: fit-content;\n    background-color: var(--web-white-color);\n    border: 1px solid var(--web-grey-color);\n    padding: 1rem;\n    border-radius: 0.2rem;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    gap: 1rem;\n}\n\n.address_form div{\n    width: 45%;\n}\n\n\n.address_form .input_wrapper{\n    width: 100% !important;\n}\n\n.address_form_head{\n    font-size: var(--sans-serif-size-extra-small);\n    font-family: var(--sans-serif);\n    font-weight: var(--sans-serif-medium);\n    width: 100%;\n\n}\n\n.address_row{\n    display:flex;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
