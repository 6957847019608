import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectIsNightMode, toggleMode } from "../../../redux/slices/modeSlice";
import { IoMoonSharp } from "react-icons/io5";
import { IoIosSunny } from "react-icons/io";
import { IoMdNotificationsOutline } from "react-icons/io";
import { PiUserCircle } from "react-icons/pi";
import Profile from "../profile";
import { getAuthLocalStorage } from "../../../storage/localStorage";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import { AuthApiService } from "../../../service/authApiService";
import { addItems, sidebarData } from "../../../redux/slices/sidebarSlice";
import { menuIcon } from "../../../website/assets/icons";
import Logo from "../../../assets/images/common/logo.png";
import "./style.css";

export default function Tabs({setMenuDisplay,menuDisplay}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isNightMode = useSelector(selectIsNightMode);
  const [userProfile, setUserProfile] = useState(false);
  const [data, setData] = useState({});
  const message = useMessage();
  const tabs = useSelector(sidebarData);

  useEffect(() => {
    if (getAuthLocalStorage().isLoggedIn && getAuthLocalStorage().loginHeader) {
      handleUserProfileApi();
    }
  }, [getAuthLocalStorage().isLoggedIn]);

  /**
   * @summary This function checks if the user is logged in and updates the state of setUserProfile accordingly.
   * @author Diksha Lalit
   * @param {function} setUserProfile - A function to update the state of setUserProfile.
   * @returns {void}
   */

  const handleUserProfile = () => {
    if (getAuthLocalStorage().loginHeader && getAuthLocalStorage().isLoggedIn) {
      setUserProfile(true);
    } else {
      navigate("/login");
    }
  };

  /**
   * @summary Handles toggling between day and night mode.
   * @author Diksha Lalit
   * @param {function} dispatch - Redux dispatch function.
   * @param {function} toggleMode - Action creator function to toggle the mode state.
   * @returns {void}
   */
  const handleMode = () => {
    dispatch(toggleMode());
  };

  //user profile api integration
  const handleUserProfileApi = () => {
    AuthApiService.getProfile()
      .then((res) => {
        if (res.status.code === 200) {
          setData(res.data);
        } else if (res.status.code === 402) {
          message.showToast(res.error.message, ToastTypes.ERROR);
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  //remove the tab from opened tabs
  const updateTabs = async (e, item) => {
    e.preventDefault();
    const activeTabs = tabs.filter((tab) => tab.opened);
    const index = activeTabs.findIndex((x) => x.head === item.head);

    const updatedTabs = await tabs.map((tab) => {
      if (item.tabLink === tab.tabLink) {
        return { ...tab, active: false, opened: false };
      } else {
        return { ...tab };
      }
    });

    if (activeTabs.length > 1) {
      await dispatch(addItems(updatedTabs));
    }
    if (activeTabs.length !== index + 1) {
      navigate(activeTabs[index + 1].link);
    } else if (activeTabs.length === index + 1 && activeTabs.length > 1) {
      navigate(activeTabs[index - 1].link);
    }
  };

  const handlePage = (link) => {
    navigate(link);
  };

  const handleMobileMenu=()=>{
    setMenuDisplay(!menuDisplay)
  }

  return (
    <div className="tabs_bg">
      <div
        className={`tabs_row ${
          isNightMode ? "tabs_row_dark" : "tabs_row_light"
        }`}
      >
        {tabs?.length &&
          tabs
            ?.filter((tab) => tab?.opened)
            ?.map((tab, index) => {
              return (
                <div
                  key={index}
                  className={`tab_item ${
                    isNightMode ? "tab_item_dark" : "tab_item_light"
                  } ${
                    tab?.active
                      ? `${
                          isNightMode
                            ? "active_tab_item_dark"
                            : "active_tab_item_light"
                        }`
                      : ""
                  }`}
                  onClick={() => handlePage(tab.tabLink)}
                >
                  <div className="text-nowrap tab_head">{tab?.name}</div>

                  {tab?.active ? (
                    <IoClose
                      className="cursor-pointer tab_remove_icon"
                      onClick={(e) => updateTabs(e, tab)}
                    />
                  ) : null}
                </div>
              );
            })}
      </div>

      <div className="tabs_small_menu" onClick={()=>handleMobileMenu()}>
        <div className="navbar_icon">{menuIcon}</div>
        <img src={Logo} className="app_logo" onClick={()=>navigate('/')}/>
      </div>

      

      

      <div
        className={`navbar_icons ${
          isNightMode ? "navbar_icons_dark" : "navbar_icons_light"
        }`}
      >
        {/* {isNightMode ? (
          <IoIosSunny
            onClick={() => handleMode()}
            style={{ cursor: "pointer" }}
            color="orange"
          />
        ) : (
          <IoMoonSharp
            onClick={() => handleMode()}
            style={{ cursor: "pointer" }}
            color="grey"
          />
        )} */}

        <IoMdNotificationsOutline className="navbar_icon" />
        <PiUserCircle
          className="navbar_icon"
          onClick={() => handleUserProfile()}
        />
      </div>

      <div className="seller_panel_profile_wrapper">
        {userProfile ? (
          <Profile
            data={data}
            onClickOutside={() => {
              setUserProfile(false);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
