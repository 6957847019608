import React, { useEffect, useState } from "react";
import WebInput from "../../../../component/library/webInput";
import FeatureList from "../featureList";
import WebButton from "../../../../component/library/webButton";
import { SubscriptionApiService } from "../../../../service/website/subscriptionApiService";
import { useLoader } from "../../../../base/context/LoaderProvider";
import {
  ToastTypes,
  useMessage,
} from "../../../../base/context/MessageProvider";
import WebDropdown from "../../../../component/library/webDropdown";
import WebTextArea from "../../../../component/library/webTextArea";
import { useNavigate, useSearchParams } from "react-router-dom";

const EditSubscription = () => {
  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();
  const [features, setFeatures] = useState([]);
  const [timePeriod, setTimePeriod] = useState("days");
  const [time, setTime] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [subscription, setSubscription] = useState({});

  const options = [
    {
      name: "Days",
      value: "days",
    },
    {
      name: "Months",
      value: "months",
    },
    {
      name: "Year",
      value: "year",
    },
  ];

  const [data, setData] = useState({
    name: "",
    timePeriod: "",
    feature: {
      name: "",
      enabled: "",
    },
    description: "",
    price: "",
  });

  const [error, setError] = useState({
    name: "",
    timePeriod: "",
    features: "",
    description: "",
    price: "",
  });

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const handleFeature = (e) => {
    setData({
      ...data,
      feature: {
        name: e.target.value,
        enabled: true,
      },
    });
  };

  const handleAddFeature = (e) => {
    e.preventDefault();
    if (data.feature.name) {
      setFeatures([...features, data.feature]);
      setData({
        ...data,
        feature: {
          name: "",
          enabled: "",
        },
      });
      setError({ ...error, features: "" });
    }
  };

  const handleTimePeriod = (e) => {
    setTimePeriod(e.target.value);
  };

  const handleSubscriptionById = () => {
    loader.showLoading(true);
    SubscriptionApiService.getSubscriptionById(`/${searchParams.get("id")}`)
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setSubscription(res.data);
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
        loader.showLoading(false);
      });
  };

  useEffect(() => {
    handleSubscriptionById();
  }, []);

  const validate = () => {
    let result = true;

    if (!data.name) {
      setError({ ...error, name: "Name is required" });
      result = false;
    } else if (!data.timePeriod) {
      setError({ ...error, timePeriod: "Time period is required" });
      result = false;
    } else if (!features.length) {
      setError({ ...error, features: "Features is required" });
      result = false;
    } else if (!data.description) {
      setError({ ...error, description: "Description is required" });
      result = false;
    } else if (!data.price) {
      setError({ ...error, price: "Price is required" });
      result = false;
    }

    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      loader.showLoading(true);
      const payload = {
        name: data.name,
        timePeriod: time,
        features: features,
        description: data.description,
        price: data.price,
      };

      SubscriptionApiService.editSubscription(
        JSON.stringify(payload),
        `/${searchParams.get("id")}`
      )
        .then((res) => {
          loader.showLoading(false);
          if (res.status.code === 200) {
            navigate("/admin/subscription/list");
          } else {
            message.showToast(res.error.message, ToastTypes.ERROR);
          }
        })
        .catch((err) => {
          message.showToast("Server error. Try again later", ToastTypes.ERROR);
          loader.showLoading(false);
        });
    }
  };

  useEffect(() => {
    setData({
      ...data,
      name: subscription?.name,
      timePeriod: subscription?.timePeriod?.split(" ")[0],
      description: subscription?.description,
      price: subscription?.price,
    });
    setTimePeriod(subscription?.timePeriod?.split(" ")[1]);
    setFeatures(subscription?.features);
  }, [subscription]);

  useEffect(() => {
    setTime(data.timePeriod + " " + timePeriod);
    setError({ ...error, timePeriod: "" });
  }, [timePeriod, data.timePeriod]);

  return (
    <form className="flex justify-between" onSubmit={handleSubmit}>
      <div className="w-2/5">
        <WebInput
          label="Name"
          name="name"
          value={data.name}
          error={error.name}
          onChange={handleOnChange}
        />

        <div className="flex gap-2 items-center">
          <WebInput
            label="Time Period"
            name="timePeriod"
            type="number"
            value={data.timePeriod}
            error={error.timePeriod}
            onChange={handleOnChange}
          />
          <WebDropdown
            options={options}
            onChange={handleTimePeriod}
            selected={timePeriod}
          />
        </div>

        <div className="flex gap-2 items-center">
          <WebInput
            label="Feature Name"
            name="feature"
            error={error.features}
            value={data.feature.name}
            onChange={handleFeature}
          />
          <WebButton
            onClick={handleAddFeature}
            text="Add"
            className="border px-6 py-2 bg-slate-500 rounded my-1 text-white "
          />
        </div>

        <WebTextArea
          label="Description"
          name="description"
          type="text"
          value={data.description}
          error={error.description}
          onChange={handleOnChange}
        />

        <WebInput
          label="Price"
          name="price"
          type="number"
          value={data.price}
          error={error.price}
          onChange={handleOnChange}
        />

        <WebButton
          text="Edit Subscription"
          onClick={handleSubmit}
          className="bg-blue-600 w-72 h-10 rounded-md text-white mt-4"
        />
      </div>

      <div className="w-2/5 ">
        {features?.length > 0 ? (
          <FeatureList features={features} setFeatures={setFeatures} />
        ) : null}
      </div>
    </form>
  );
};

export default EditSubscription;
