import React, { useEffect, useState } from "react";
import { useLoader } from "../../../base/context/LoaderProvider";
import { ToastTypes, useMessage } from "../../../base/context/MessageProvider";
import Pagination from "../../../component/library/pagination";
import OrderDetailTable from "../../../component/views/admin/orderManagement/orderDetailsTable";
import OrderFilter from "../../../component/views/admin/orderManagement/orderFilter";
import { orderApiService } from "../../../service/admin/ordersApiService";
import { useNavigate } from "react-router-dom";

export default function OrderManagement() {
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [pages, setPages] = useState({
    currentPage: "",
    totalPages: "",
    totalItems: "",
  });
  const [query, setQuery] = useState({});
  const loader = useLoader();
  const message = useMessage();
  const navigate = useNavigate();

  const fetchAllOrders = () => {
    const queryParams = `?commentStatus=${
      query.commentStatus ? query.commentStatus : ""
    }&endDate=${query.endDate ? query.endDate : ""}&paymentStatus=${
      query.paymentStatus ? query.paymentStatus : ""
    }&returnStatus=${
      query.returnStatus ? query.returnStatus : ""
    }&searchFields=${query.searchFields ? query.searchFields : ""}&status=${
      query.status ? query.status : ""
    }&startDate=${query.startDate ? query.startDate : ""}&state=${
      query.state ? query.state : ""
    }&supplierOrders=${
      query.supplierOrders ? query.supplierOrders : ""
    }&ticketStatus=${query.ticket ? query.ticket : ""}&warehouseOrders=${
      query.warehouseOrders ? query.warehouseOrders : ""
    }&warehouseId=${
      query.warehouseId ? query.warehouseId : ""
    }&page=${pages}&limit=30`;
    loader.showLoading(true);
    orderApiService
      .getAllOrders(queryParams ? queryParams : "")
      .then((res) => {
        loader.showLoading(false);
        if (res.status.code === 200) {
          setData(res.data.orders);
          setPages({
            ...pages,
            currentPage: res.data.currentPage,
            totalPages: res.data.totalPages,
            totalItems: res.data.totalOrders,
          });
          setPage(res.data.currentPage);
        } else if (res.status.code === 404) {
          setData([]);
        } else if (res.status.code === 402) {
          navigate("/login");
        } else {
          message.showToast(res.error.message, ToastTypes.ERROR);
        }
      })
      .catch((err) => {
        loader.showLoading(false);
        message.showToast("Server error. Try again later", ToastTypes.ERROR);
      });
  };

  useEffect(() => {
    fetchAllOrders();
  }, [query]);

  const divStyle = {
    paddingBottom: "20px",
  };
  return (
    <div style={divStyle} className="container">
      <header className="header">
        <h1>Admin Portal - Order Management</h1>
      </header>
      <div className="main-content">
        <OrderFilter setQuery={setQuery} />
        <OrderDetailTable data={data} pages={pages} limit={limit} />

        {data && data.length > 0 ? (
          <Pagination pages={pages} page={page} setPage={setPage} />
        ) : (
          "No Data Found"
        )}
      </div>
    </div>
  );
}
