import React from "react";
import Logo from "../../../assets/images/common/logo.png";
import { Link } from "react-router-dom";
import {
  facebookIcon,
  instagramIcon,
  linkedinIcon,
  locationIcon,
  mailIcon,
  mobileIcon,
  nextVIcon,
  twitterIcon,
  whatsappIcon,
  youtubeIcon,
} from "../../../assets/icons";
import {
  FooterContent,
  FooterHowItWorks,
  FooterGetToKnowUs,
  FooterQuickLinks,
  upsaleoContact,
} from "../../../helper/constants";
import Maestro from "../../../assets/images/footer/maestro.png";
import MasterCard from "../../../assets/images/footer/master_card.png";
import Paypal from "../../../assets/images/footer/paypal.png";
import Rupay from "../../../assets/images/footer/rupay.png";
import Visa from "../../../assets/images/footer/visa.png";

import "./style.css";

export default function WebFooter() {
  const socialMedia = [
    { icon: linkedinIcon, link: "https://www.linkedin.com/company/upsaleo" },
    {
      icon: facebookIcon,
      link: "https://www.facebook.com/people/Upsaleo/61558625604511/",
    },
    { icon: twitterIcon, link: "https://x.com/upsaleoofficial" },
    {
      icon: instagramIcon,
      link: "https://www.instagram.com/upsaleoofficial/",
    },
    { icon: youtubeIcon, link: "https://www.youtube.com/@UpSaleo" },
    { icon: whatsappIcon, link: "https://wa.me/message/MKQJBLTLR5GAM1" },
  ];

  return (
    <div className="web_footer_bg">
      <div className="web_footer_row">
        <div className="web_footer_col web_footer_col_one">
          <div className="web_footer_col_one_text">
            <Link className="footer_logo" to="/">
              <img src={Logo} />
            </Link>
            <p className="web_footer_col_para">{FooterContent.about}</p>
            <Link className="footer_view_more_btn" to="/about-us">
              View More <span>{nextVIcon}</span>
            </Link>
          </div>

            <SocialMedia socialMedia={socialMedia}/>


        </div>
        <div className="web_footer_col web_footer_col_two">
          <h2 className="web_footer_col_head">{FooterContent.headOne}</h2>
          <Link
            className="web_footer_link"
            to={`https://www.google.com/maps/place/Sector+6,+Noida,+Uttar+Pradesh/@28.5928788,77.3135186,16z/data=!3m1!4b1!4m6!3m5!1s0x390ce4f72d5495a1:0xfd217b0c806989de!8m2!3d28.593565!4d77.3188016!16s%2Fg%2F1tdw1vt7?entry=ttu`}
            target="_blank"
          >
            <div>{locationIcon}</div>
            <p>{upsaleoContact.address}</p>
          </Link>
          <Link
            className="web_footer_link"
            to={`mailto:${upsaleoContact.email}`}
          >
            <div>{mailIcon}</div>
            <p> {upsaleoContact.email}</p>
          </Link>
          <Link
            className="web_footer_link"
            to="https://wa.me/message/MKQJBLTLR5GAM1"
            target="_blank"
          >
            <div>{mobileIcon}</div>
            <p>{upsaleoContact.phone}</p>
          </Link>
        </div>
        <div className="web_footer_col">
          <h2 className="web_footer_col_head">{FooterContent.headTwo}</h2>
          {FooterHowItWorks.map((item, index) => {
            return (
              <Link className="web_footer_link" to={item.link} key={index}>
                {item.name}
              </Link>
            );
          })}
        </div>
        <div className="web_footer_col">
          <h2 className="web_footer_col_head">{FooterContent.headThree}</h2>
          {FooterGetToKnowUs.map((item, index) => {
            return (
              <Link className="web_footer_link" to={item.link} key={index}>
                {item.name}
              </Link>
            );
          })}
        </div>
        <div className="web_footer_col ">
          <h2 className="web_footer_col_head">{FooterContent.headFour}</h2>
          {FooterQuickLinks.map((item, index) => {
            return (
              <Link className="web_footer_link" to={item.link} key={index}>
                {item.name}
              </Link>
            );
          })}
        </div>
      </div>



      <div className="web_footer_row_two">
        <p>Copyright@{new Date().getFullYear()} Upsaleo All right reserved</p>
        <div className="payment_cards_row">
          <div className="payment_cards_img_container">
            <img src={Paypal} />
          </div>
          <div className="payment_cards_img_container">
            <img src={Rupay} />
          </div>
          <div className="payment_cards_img_container">
            <img src={Visa} />
          </div>
          <div className="payment_cards_img_container">
            <img src={Maestro} />
          </div>
          <div className="payment_cards_img_container">
            <img src={MasterCard} />
          </div>
        </div>
      </div>
    </div>
  );
}


const SocialMedia=({socialMedia})=>{
  return <div className="social_media_row">
            {socialMedia.map((item, index) => {
              return (
                <Link to={item.link} key={index} target="_blank">
                  <div>{item.icon}</div>
                </Link>
              );
            })}
          </div>
  }