// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog_img{
    border-radius: 1rem;
    width: 300px;
    height: 300px;
}

.head_arrow_row{
    width: 300px;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.blog_arrow{
    cursor: pointer;
    font-size: 1.5rem;
}

.blog_head{
    width: 250px;
    font-size: 1rem;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    margin: 0.5rem 0;
}

.blog_para{
    width: 300px;
    font-size: 0.8rem;
    font-weight: 500;
    font-family: "Inter", sans-serif;
}

.blogs_row{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.blog_item{
    width: 426px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
}

.blog_outline{
    border-right: 1px solid #000000;
    border-left:1px solid #000000;
}


@media (max-width:768px) {
    .blog_outline{
        border:none;
    }
}
`, "",{"version":3,"sources":["webpack://./src/website/component/views/home/blogs/style.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,gCAAgC;IAChC,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,gCAAgC;AACpC;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,+BAA+B;IAC/B,6BAA6B;AACjC;;;AAGA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".blog_img{\n    border-radius: 1rem;\n    width: 300px;\n    height: 300px;\n}\n\n.head_arrow_row{\n    width: 300px;\n    display: flex;\n    gap: 1rem;\n    align-items: center;\n}\n\n.blog_arrow{\n    cursor: pointer;\n    font-size: 1.5rem;\n}\n\n.blog_head{\n    width: 250px;\n    font-size: 1rem;\n    font-weight: 500;\n    font-family: \"Inter\", sans-serif;\n    margin: 0.5rem 0;\n}\n\n.blog_para{\n    width: 300px;\n    font-size: 0.8rem;\n    font-weight: 500;\n    font-family: \"Inter\", sans-serif;\n}\n\n.blogs_row{\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n}\n\n.blog_item{\n    width: 426px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 1rem 0;\n}\n\n.blog_outline{\n    border-right: 1px solid #000000;\n    border-left:1px solid #000000;\n}\n\n\n@media (max-width:768px) {\n    .blog_outline{\n        border:none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
